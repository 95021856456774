import { CanvasTextureAllocator } from '@pixi-essentials/texture-allocator';
import { Matrix, Point, Rectangle } from 'pixi.js';
import { SVGPathNode } from '../../packages/svgscene/SVGPathNode';
import { updateGlobalConfigToDisplay } from '../../utils';
import { MCircle } from '../MCircle';
import { MLine } from '../MLine';
import { MPen } from '../MPen';
import { MPolygon } from '../MPolygon';
import { MRect } from '../MRect';
import { MSVGText } from '../MSVGText';
import { MSprite } from '../MSprite';
import { DISPLAY_TYPE } from '../type';
import { getMaskObj, removeMask, setMask } from './mask';
import { contains } from './objTree';
export * from './mask';
export * from './objTree';
export * from './sprite';
export function createDisplay(type, options) {
    try {
        switch (type) {
            case DISPLAY_TYPE.CIRCLE:
                return new MCircle(options);
            case DISPLAY_TYPE.LINE:
                return new MLine(options);
            case DISPLAY_TYPE.PEN:
                return new MPen(options);
            case DISPLAY_TYPE.RECT:
                return new MRect(options);
            case DISPLAY_TYPE.TEXT:
                // return new MText(options as TextJSON);
                return new MSVGText(options);
            case DISPLAY_TYPE.PATH:
                const context = {
                    atlas: new CanvasTextureAllocator(2048, 2048),
                    disableHrefSVGLoading: false,
                };
                return new SVGPathNode(context, options);
            case DISPLAY_TYPE.POLYGON:
                return new MPolygon(options);
            case DISPLAY_TYPE.BITMAP:
                const data = options;
                return new MSprite(data.base64, data);
            default:
                return undefined;
        }
    }
    catch (err) {
        return undefined;
    }
}
// 更新矢量填充规则
export function amendVectorFill(display) {
    if (display instanceof MSprite) {
        return;
    }
    updateGlobalConfigToDisplay(display);
    const addHitAreaDisabledFill = (display) => {
        if (!display.enableFill && !display.hitArea) {
            display.addHitArea();
        }
    };
    addHitAreaDisabledFill(display);
}
export function containsPointHasHitArea(display, point, hitArea = 0) {
    const tempPoint = new Point(0, 0);
    display.worldTransform.applyInverse(point, tempPoint);
    const width = display._texture.orig.width;
    const height = display._texture.orig.height;
    const x1 = -width * display.anchor.x;
    let y1 = 0;
    if (tempPoint.x >= x1 - hitArea && tempPoint.x < x1 + width + hitArea) {
        y1 = -height * display.anchor.y;
        if (tempPoint.y >= y1 - hitArea && tempPoint.y < y1 + height + hitArea) {
            return true;
        }
    }
    return false;
}
export function trimCanvas(canvas) {
    // https://gist.github.com/remy/784508
    let width = canvas.width;
    let height = canvas.height;
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, width, height);
    const pixels = imageData.data;
    const len = pixels.length;
    const bound = {
        top: null,
        left: null,
        right: null,
        bottom: null,
    };
    let data = null;
    let i;
    let x;
    let y;
    for (i = 0; i < len; i += 4) {
        if (pixels[i + 3] !== 0) {
            x = (i / 4) % width;
            y = ~~(i / 4 / width);
            if (bound.top === null) {
                bound.top = y;
            }
            if (bound.left === null) {
                bound.left = x;
            }
            else if (x < bound.left) {
                bound.left = x;
            }
            if (bound.right === null) {
                bound.right = x + 1;
            }
            else if (bound.right < x + 1) {
                bound.right = x + 1;
            }
            if (bound.bottom === null) {
                bound.bottom = y;
            }
            else if (bound.bottom < y) {
                bound.bottom = y;
            }
        }
    }
    if (bound.top !== null) {
        // XXX: 原pixi这里少了1导致宽度发生错误
        width = bound.right - bound.left;
        height = bound.bottom - bound.top + 1;
        data = context.getImageData(bound.left, bound.top, width, height);
    }
    return {
        height,
        width,
        data,
    };
}
/**
 * 导入maskJSON到sprite，如果maskJSON为null, 会删除sprite现有的mask
 * @param sprite
 * @param maskJSON
 */
export function importMaskJSONToSprite(sprite, maskJSON) {
    if (maskJSON) {
        if (!sprite.mask) {
            // 创建mask
            const mask = createDisplay(maskJSON.type, maskJSON);
            if (!mask) {
                return;
            }
            mask.parentGroup = undefined;
            mask.interactive = false;
            mask.x = maskJSON.offsetX;
            mask.y = maskJSON.offsetY;
            mask.transform.updateLocalTransform();
            mask.transform.updateTransform(sprite.transform);
            sprite.addChild(mask);
            setMask(sprite, mask);
        }
        else {
            // 更新mask
            const mask = getMaskObj(sprite);
            if (mask) {
                const isEmbed = contains(sprite, mask);
                if (isEmbed) {
                    mask.parseJSON(maskJSON);
                    mask.x = maskJSON.offsetX;
                    mask.y = maskJSON.offsetY;
                    mask.transform.updateLocalTransform();
                }
                mask.updateTransform();
            }
        }
    }
    else if (maskJSON === null) {
        // 移除mask
        const mask = getMaskObj(sprite);
        if (mask) {
            const isEmbed = contains(sprite, mask);
            if (isEmbed) {
                sprite.removeChild(mask);
            }
        }
        removeMask(sprite);
    }
}
/**
 * 计算缩放适合并居中到目标rect的matrix
 */
export function calcFitAndAlignCenterMatrix(rect, targetRect) {
    if (Array.isArray(targetRect)) {
        targetRect = new Rectangle(...targetRect);
    }
    const deltaScale = Math.min(targetRect.width / rect.width, targetRect.height / rect.height);
    const min = new Point(rect.x, rect.y);
    const max = new Point(rect.x + rect.width, rect.y + rect.height);
    const scaleMat = new Matrix().scale(deltaScale, deltaScale);
    const newMin = scaleMat.apply(min);
    const newMax = scaleMat.apply(max);
    const scaledBounds = new Rectangle(newMin.x, newMin.y, newMax.x - newMin.x, newMax.y - newMin.y);
    const offset = {
        x: targetRect.x +
            targetRect.width / 2 -
            (scaledBounds.x + scaledBounds.width / 2),
        y: targetRect.y +
            targetRect.height / 2 -
            (scaledBounds.y + scaledBounds.height / 2),
    };
    const matrix = new Matrix()
        .scale(deltaScale, deltaScale)
        .translate(offset.x, offset.y);
    return matrix;
}
