/**
 *
 * 需要遍历字符串的时候使用此方法分割字符串为数组，用于解决Array.from(str), [...str], for...of循环
 * 等方式无法把包含有Emoji、特殊字符的字符串正确地依照Unicode编码分割的问题
 *
 * @example
 * toUnicodeChars('☺️✡︎') // 返回 ['☺', '✡']，字符分割正确
 * Array.from('☺️✡︎')     // 返回 ['☺', '️', '✡', '︎']，字符分割错误
 *
 * 关于字符串中包含非BMP字符的更多信息
 * @see {@link [String.prototype.charCodeAt() - JavaScript | MDN](https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/String/charCodeAt)}
 */
export function toUnicodeCharArray(str) {
    let result;
    if (typeof str === 'string' && str) {
        const validRegex = /\ud83c[\udffb-\udfff](?=\ud83c[\udffb-\udfff])|(?:[^\ud800-\udfff][\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]?|[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\ud800-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
        result = str.match(validRegex);
    }
    return result ?? [];
}
