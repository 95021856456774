import { Align, ButtonGroup } from '@xtool/canvas-ui';
import { BLEND_MODES, Container, Graphics, Point, filters } from 'pixi.js';
import { DEFAULT_CUTTER_CONFIG, DEFAULT_ELEMENT_LINE_STYLE, } from '../../../config';
import { containsPointHasHitArea } from '../../../display/utils';
import { IMAGE_PROCESS_TOOL } from '../../../types';
export default class Cutter extends Container {
    config;
    addToContainer;
    isPointerDown;
    #shadow;
    #selector;
    buttonGroup;
    display;
    viewportScale;
    viewportWorldWidth;
    viewportWorldHeight;
    onRecord;
    selectDisplay;
    processController;
    imageProcessConfig;
    constructor(options) {
        super();
        const { newDisplay } = options;
        this.config = DEFAULT_CUTTER_CONFIG;
        this.name = IMAGE_PROCESS_TOOL.CUTTER;
        this.cursor = IMAGE_PROCESS_TOOL.CUTTER;
        this.addToContainer = true;
        this.isPointerDown = false;
        this.viewportScale = options.viewportScale;
        this.viewportWorldWidth = options.viewportWorldWidth;
        this.viewportWorldHeight = options.viewportWorldHeight;
        this.onRecord = options.onRecord;
        this.selectDisplay = options.selectDisplay;
        this.processController = options.processController;
        this.imageProcessConfig = options.imageProcessConfig;
        this.display = newDisplay;
        this.#renderShadow();
    }
    calIsNotIntersect(selectorBoundPoints) {
        const { x, y, width, height } = this.display.getBounds();
        const minPoint = new Point(x, y);
        const maxPoint = new Point(x + width, y + height);
        const rtPoint = new Point(maxPoint.x, minPoint.y);
        const lbPoint = new Point(minPoint.x, maxPoint.y);
        const displayBoundPoints = [minPoint, rtPoint, maxPoint, lbPoint];
        const isNotInSelector = displayBoundPoints.every((point) => !this.#selector.containsPoint(point));
        const isNotInDisplay = selectorBoundPoints.every((point) => !containsPointHasHitArea(this.display, point));
        return isNotInSelector && isNotInDisplay;
    }
    updateBounds(start, end) {
        const { x, y, width, height } = this.display.getBounds();
        const displayBoundX = x + width;
        const displayBoundY = y + height;
        if (start.x < x) {
            start.x = x;
        }
        else if (start.x > displayBoundX) {
            start.x = displayBoundX;
        }
        if (start.y < y) {
            start.y = y;
        }
        else if (start.y > displayBoundY) {
            start.y = displayBoundY;
        }
        if (end.x < x) {
            end.x = x;
        }
        else if (end.x > displayBoundX) {
            end.x = displayBoundX;
        }
        if (end.y < y) {
            end.y = y;
        }
        else if (end.y > displayBoundY) {
            end.y = displayBoundY;
        }
    }
    #clip() {
        const { display } = this;
        const { x, y, width, height } = this.#selector.getBounds();
        const minPoint = new Point(x, y);
        const maxPoint = new Point(x + width, y + height);
        // 边界值处理
        this.updateBounds(minPoint, maxPoint);
        const mWidth = Math.abs(maxPoint.x - minPoint.x);
        const mHeight = Math.abs(maxPoint.y - minPoint.y);
        // 忽略横线或竖线的情况
        if (mWidth <= 1 || mHeight <= 1) {
            return;
        }
        // 计算裁剪点路径位置
        const rtPoint = new Point(maxPoint.x, minPoint.y);
        const lbPoint = new Point(minPoint.x, maxPoint.y);
        // 计算裁剪位置是否与图像有相交
        const isNotIntersect = this.calIsNotIntersect([
            minPoint,
            rtPoint,
            maxPoint,
            lbPoint,
        ]);
        if (isNotIntersect) {
            return;
        }
        const minLocalPoint = display.toLocal(minPoint);
        const rtLocalPoint = display.toLocal(rtPoint);
        const maxLocalPoint = display.toLocal(maxPoint);
        const lbLocalPoint = display.toLocal(lbPoint);
        // 创建裁剪画布
        const localPoints = {
            minLocalPoint,
            rtLocalPoint,
            maxLocalPoint,
            lbLocalPoint,
        };
        this.processController.clip(localPoints);
        if (display.angle % 360 === 0) {
            const localMinPoint = display.parent.toLocal(minPoint);
            display.x = localMinPoint.x;
            display.y = localMinPoint.y;
        }
        else if (display.angle % 360 === 90) {
            const localRtPoint = display.parent.toLocal(rtPoint);
            display.x = localRtPoint.x;
            display.y = localRtPoint.y;
        }
        this.display.texture.update();
        this.onRecord();
    }
    updateAttr() { }
    #renderShadow() {
        const { viewportWorldWidth, viewportWorldHeight } = this;
        this.#shadow = new Graphics()
            .beginFill(0x000000, 0.6)
            .drawRect(0, 0, viewportWorldWidth, viewportWorldHeight)
            .endFill();
        this.#shadow.name = 'SHADOW';
        this.#shadow.visible = false;
        this.addChild(this.#shadow);
    }
    #renderSelector() {
        this.#selector = new Graphics();
        this.#selector.lockRatio = false;
        this.#selector.name = 'SELECTOR';
        this.#selector.transformUpdate = () => {
            this.#updateButtons();
        };
        this.addChild(this.#selector);
    }
    #renderButtons() {
        const config = this.imageProcessConfig.cutter;
        const { confirm, cancel, groupConfig } = config;
        if (confirm && cancel) {
            const buttonGroup = new ButtonGroup({
                ...groupConfig,
                buttons: [
                    {
                        iconSize: 16,
                        ...cancel,
                        icon: cancel?.icon || 'cancel',
                        iconHover: cancel?.iconHover || 'cancel_hover',
                        action: this.cancel,
                    },
                    {
                        iconSize: 16,
                        ...confirm,
                        icon: confirm?.icon || 'cancel',
                        iconHover: confirm?.iconHover || 'confirm_hover',
                        action: this.confirm,
                    },
                ],
                stroke: 0x92a399,
                strokeAlpha: 0.3,
            });
            this.addChild(buttonGroup);
            this.buttonGroup = buttonGroup;
            this.#updateButtons();
        }
    }
    #updateButtons() {
        if (this.buttonGroup) {
            const position = this.#getButtonGroupPosition();
            this.buttonGroup.position.set(position.x, position.y);
        }
    }
    #getButtonGroupPosition() {
        const margin = this.imageProcessConfig.cutter?.groupConfig?.margin ?? 0;
        const align = this.imageProcessConfig.cutter?.groupConfig?.align || 'end';
        const bounds = this.#selector.getBounds();
        const alignment = {
            [Align.start]: () => {
                const basePosition = this.toLocal(new Point(bounds.x, bounds.height + bounds.y));
                return { x: basePosition.x, y: basePosition.y + margin };
            },
            [Align.center]: () => {
                const basePosition = this.toLocal(new Point(bounds.x + bounds.width / 2, bounds.height + bounds.y));
                return {
                    x: basePosition.x - this.buttonGroup.width / 2,
                    y: basePosition.y + margin,
                };
            },
            [Align.end]: () => {
                const basePosition = this.toLocal(new Point(bounds.x + bounds.width, bounds.height + bounds.y));
                return {
                    x: basePosition.x - this.buttonGroup.width,
                    y: basePosition.y + margin,
                };
            },
        };
        return alignment[align]();
    }
    start = (point) => {
        if (this.#shadow.visible) {
            return;
        }
        this.isPointerDown = true;
        this.#renderSelector();
        this.#selector
            .clear()
            .lineStyle(DEFAULT_ELEMENT_LINE_STYLE)
            .drawRect(0, 0, 1, 1)
            .endFill();
        this.#selector.position.set(point.x, point.y);
    };
    update = (point) => {
        if (this.isPointerDown) {
            const { x, y } = this.#selector.position;
            const { x: ex, y: ey } = point;
            this.#selector
                .clear()
                .lineStyle(DEFAULT_ELEMENT_LINE_STYLE)
                .drawRect(0, 0, ex - x, ey - y);
        }
    };
    end = (point, display) => {
        if (!this.isPointerDown) {
            return;
        }
        this.isPointerDown = false;
        const { position: { x, y }, } = this.#selector;
        const { x: ex, y: ey } = point;
        // 单击不处理
        const isEqualX = x.toFixed() === ex.toFixed();
        const isEqualY = y.toFixed() === ey.toFixed();
        if (isEqualX && isEqualY) {
            this.#selector.clear();
            return;
        }
        if (ex < x) {
            this.#selector.position.x = ex;
        }
        if (ey < y) {
            this.#selector.position.y = ey;
        }
        const width = Math.abs(ex - x);
        const height = Math.abs(ey - y);
        // 忽略横线或竖线的情况
        if (width <= 1 || height <= 1) {
            this.#selector.clear();
            return;
        }
        this.display = display;
        this.drawCutterArea({ width, height });
    };
    drawCutterArea(size, pos) {
        const { width, height } = size;
        if (!this.#selector) {
            this.#renderSelector();
        }
        this.#selector
            .clear()
            .beginFill(0x000000, 1)
            .lineStyle(DEFAULT_ELEMENT_LINE_STYLE)
            .drawRect(0, 0, width, height)
            .endFill();
        this.interactive = true;
        if (pos) {
            this.#selector.position.set(pos.x, pos.y);
        }
        this.selectDisplay([this.#selector]);
        this.#selector.blendMode = BLEND_MODES.ERASE;
        this.#shadow.visible = true;
        this.filters = [new filters.AlphaFilter()];
        this.#renderButtons();
    }
    confirm = () => {
        if (!this.#shadow.visible) {
            return;
        }
        this.#clip();
        this.cancel();
    };
    actived() {
        const { display: { width, height, x, y }, } = this;
        const { cutter: { showDefaultArea = false }, } = this.imageProcessConfig;
        if (showDefaultArea) {
            this.drawCutterArea({
                width,
                height,
            }, { x, y });
        }
    }
    cancel = () => {
        if (!this.#shadow.visible) {
            return;
        }
        this.#shadow.visible = false;
        this.#selector.destroy();
        this.buttonGroup?.destroy();
        this.#selector = null;
        this.buttonGroup = null;
        this.filters = [];
        this.selectDisplay([]);
        this.interactive = false;
    };
}
