import { Transformer } from '@makeblock/transformer';
import { groupBy, isNumber, map } from 'lodash-es';
import { Matrix, Point } from 'pixi.js';
import { calculateBoundsDemisionChanged, groupTransferOffset, isLockRatio, } from '../../utils';
import { prependTransform, rotateMatrix } from '../../utils/matrix';
/**
 *
 * @param {{
 *     displaysAsGroup?: boolean; // true：所有的元素看成一个组调整为对应的w、h
 *   }} options
 */
export function updateDisplaysDemision(viewport, displays, options) {
    const { width, height, displaysAsGroup = false } = options;
    if (isNumber(width) || isNumber(height)) {
        const groups = displaysAsGroup
            ? [displays]
            : map(groupBy(displays, 'groupTag'), (g) => g);
        groups.forEach((g) => updateGroupDemision(viewport, g, options));
    }
}
export function updateDisplaysPosition(viewport, displays, options) {
    const { x, y, displaysAsGroup = false } = options;
    if (isNumber(x) || isNumber(y)) {
        const groups = displaysAsGroup
            ? [displays]
            : map(groupBy(displays, 'groupTag'), (g) => g);
        groups.forEach((g) => updateGroupPosition(viewport, g, options));
    }
}
export function updateDisplaysRotation(displays, options) {
    const { angle, displaysAsGroup = false } = options;
    if (isNumber(angle)) {
        const groups = displaysAsGroup
            ? [displays]
            : map(groupBy(displays, 'groupTag'), (g) => g);
        groups.forEach((g) => updateGroupRotation(g, options));
    }
}
export function updateGroupPosition(viewport, group, options) {
    const delta = groupTransferOffset(viewport, group, options);
    const matrix = Matrix.IDENTITY.translate(delta.x, delta.y);
    prependTransform(group, matrix);
}
export function updateGroupDemision(viewport, group, options) {
    const { width, height } = options;
    const lockAspectRatio = isLockRatio(group);
    if (!isNumber(width) && !isNumber(height)) {
        return;
    }
    const groupBounds = Transformer.calculateGroupOrientedBounds(group, 0);
    const innerBounds = groupBounds.innerBounds;
    if (innerBounds.width <= 0 || innerBounds.height <= 0) {
        return;
    }
    const oMaxPoint = innerBounds.bottomRight;
    const bound = calculateBoundsDemisionChanged(viewport, group, width, height, lockAspectRatio);
    const nMaxPoint = new Point(bound.maxX, bound.maxY);
    const { sx, sy } = Transformer.calculateScaleRatio(nMaxPoint, oMaxPoint, {
        bounds: groupBounds,
        lockAspectRatio,
    });
    const matrix = Matrix.IDENTITY;
    const hsOrigin = innerBounds.topLeft;
    if (sx !== 0) {
        matrix
            .translate(-hsOrigin.x, -hsOrigin.y)
            .scale(sx, 1)
            .translate(hsOrigin.x, hsOrigin.y);
    }
    if (sy !== 0) {
        matrix
            .translate(-hsOrigin.x, -hsOrigin.y)
            .scale(1, sy)
            .translate(hsOrigin.x, hsOrigin.y);
    }
    prependTransform(group, matrix);
}
export function updateGroupRotation(group, options) {
    const { angle } = options;
    const matrix = rotateMatrix(group, angle);
    prependTransform(group, matrix);
}
