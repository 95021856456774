import { EventEmitter } from '@pixi/utils';
import { UNDO_HISTORY_EVENT } from '../../types';
export class OperationStack extends EventEmitter {
    #undoStack = [];
    #redoStack = [];
    #lastCanUndo = false;
    #lastCanRedo = false;
    #capacity;
    // 初始值
    initOperation;
    constructor(capacity = Number.MAX_VALUE) {
        super();
        this.#capacity = capacity;
    }
    get undoStack() {
        return this.#undoStack;
    }
    get redoStack() {
        return this.#redoStack;
    }
    get canUndo() {
        return this.#undoStack.length > 0;
    }
    get canRedo() {
        return this.#redoStack.length > 0;
    }
    get top() {
        const length = this.#undoStack.length;
        if (length < 1) {
            // 如果有初始值
            if (this.initOperation) {
                return this.initOperation;
            }
            return undefined;
        }
        return this.#undoStack[length - 1];
    }
    push(t) {
        this.#undoStack.push(t);
        if (this.#capacity !== Number.MAX_VALUE &&
            this.#undoStack.length > this.#capacity) {
            this.#undoStack.shift();
        }
        this.#redoStack = [];
        // console.log(this.#undoStack, '记录');
        this.emitCanDoEvent();
    }
    undo() {
        if (!this.canUndo) {
            return;
        }
        const t = this.#undoStack.pop();
        this.#redoStack.push(t);
        this.emitCanDoEvent();
    }
    redo() {
        if (!this.canRedo) {
            return;
        }
        const t = this.#redoStack.pop();
        this.#undoStack.push(t);
        this.emitCanDoEvent();
    }
    clear() {
        this.#undoStack = [];
        this.#redoStack = [];
        this.emitCanDoEvent();
    }
    emitCanDoEvent() {
        if (this.#lastCanRedo !== this.canRedo ||
            this.#lastCanUndo !== this.canUndo) {
            this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, this.canUndo, this.canRedo);
        }
        this.updateCanDo();
    }
    updateCanDo() {
        this.#lastCanRedo = this.canRedo;
        this.#lastCanUndo = this.canUndo;
    }
}
