// @ts-nocheck
import * as libtess from 'libtess';
export function triangulate(graphicsData, graphicsGeometry) {
    try {
        const contour = graphicsData.points.filter((e) => !isNaN(e));
        const tessy = new libtess.GluTesselator();
        const outVerts = [];
        /* eslint-disable no-inner-declarations, @typescript-eslint/no-unused-vars */
        function vertexCallback(data, polyVertArray) {
            polyVertArray[polyVertArray.length] = data[0];
            polyVertArray[polyVertArray.length] = data[1];
        }
        function begincallback(type) {
            if (type !== libtess.primitiveType.GL_TRIANGLES) {
                console.warn(`expected TRIANGLES but got type: ${type}`);
            }
        }
        function errorcallback(errno) {
            console.error('error callback');
            console.error(`error number: ${errno}`);
        }
        // callback for when segments intersect and must be split
        function combinecallback(coords, _data, _weight) {
            // console.log('combine callback');
            return [coords[0], coords[1], coords[2]];
        }
        function edgeCallback(_flag) {
            // don't really care about the flag, but need no-strip/no-fan behavior
            // console.log('edge flag: ' + flag);
        }
        /* eslint-enable no-inner-declarations */
        tessy.gluTessProperty(libtess.gluEnum.GLU_TESS_WINDING_RULE, libtess.windingRule.GLU_TESS_WINDING_NONZERO);
        tessy.gluTessCallback(libtess.gluEnum.GLU_TESS_VERTEX_DATA, vertexCallback);
        tessy.gluTessCallback(libtess.gluEnum.GLU_TESS_BEGIN, begincallback);
        tessy.gluTessCallback(libtess.gluEnum.GLU_TESS_ERROR, errorcallback);
        tessy.gluTessCallback(libtess.gluEnum.GLU_TESS_COMBINE, combinecallback);
        tessy.gluTessCallback(libtess.gluEnum.GLU_TESS_EDGE_FLAG, edgeCallback);
        tessy.gluTessNormal(0, 0, 1);
        tessy.gluTessBeginPolygon(outVerts);
        tessy.gluTessBeginContour();
        for (let j = 0; j < contour.length;) {
            const x = contour[j++];
            const y = contour[j++];
            const data = [x, y, 0];
            tessy.gluTessVertex(data, data);
        }
        tessy.gluTessEndContour();
        tessy.gluTessEndPolygon();
        const verts = graphicsGeometry.points;
        const indices = graphicsGeometry.indices;
        const ibase = verts.length / 2;
        for (let i = 0; i < outVerts.length;) {
            verts.push(outVerts[i++], outVerts[i++]);
        }
        for (let i = 0; i < outVerts.length / 2; i++) {
            indices.push(i + ibase);
        }
    }
    catch (e) {
        console.error(e);
    }
}
