import { IMAGE_PROCESS_TOOL } from '../../../types';
export default class MagicWand {
    aberration;
    name;
    isPointerDown;
    cursor;
    onRecord;
    processController;
    constructor(options) {
        this.isPointerDown = false;
        this.name = IMAGE_PROCESS_TOOL.MAGIC_WAND;
        this.cursor = IMAGE_PROCESS_TOOL.MAGIC_WAND;
        this.aberration = 0;
        this.onRecord = options.onRecord;
        this.processController = options.processController;
    }
    updateAttr(options) {
        if ('aberration' in options) {
            this.aberration = Number(options.aberration) || 0;
        }
    }
    start = () => {
        this.isPointerDown = true;
    };
    update = () => { };
    end = (point, display) => {
        if (!this.isPointerDown) {
            return;
        }
        this.isPointerDown = false;
        if (this.aberration === 0) {
            return;
        }
        const globalPoint = display.parent.parent.toGlobal(point);
        const isClickInside = display.containsPoint(globalPoint);
        if (!isClickInside) {
            return;
        }
        const localPoint = display.toLocal(globalPoint);
        this.processController.magicSelect(localPoint, this.aberration);
        this.onRecord();
    };
}
