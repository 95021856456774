import { isFunction } from 'lodash-es';
import { Container, Point, Texture, TilingSprite } from 'pixi.js';
import { DEFAULT_IMAGE_PROCESS_ATTR } from '../../config';
import { default as ProcessController } from '../../controllers/image-process';
import { isSinglePoint } from '../../controllers/interaction-manager';
import { OperationStack } from '../../controllers/operation-stack';
import { DISPLAY_LIFE_CYCLE } from '../../display';
import { trimCanvas } from '../../display/utils';
import { IMAGE_PROCESS_TOOL } from '../../types';
import { calcAspectRatioFit, getDisplayBounds } from '../../utils';
import Tools from './tools';
export class ImageProcess {
    #options;
    container;
    newDisplay;
    #curTool = undefined;
    #canvas;
    #ctx;
    #initOperationStackLog;
    #isPointerDown;
    operationStack;
    rect;
    #processController;
    ratio;
    #locking = false;
    destroyed = false;
    #saveImageDataFn;
    #loadImageDataFn;
    constructor(options) {
        this.#isPointerDown = false;
        this.#options = options;
        this.#processController = new ProcessController();
        this.operationStack = new OperationStack();
        this.#createContainer();
        this.#renderBackdrop();
        this.#addDisplay();
        this.#options.addViewportChild(this.container);
        this.#updateCloneDisplayAttrs();
        this.#initTools(options.tool, options);
        this.#setInitOperationStackLog();
        if (this.asyncModeEnabled) {
            console.log('[ ImageProcess ] asyncMode', this.asyncModeEnabled);
            this.#setupAsyncModeHandler(options.imageProcessConfig.asyncMode);
        }
    }
    get locking() {
        return this.asyncModeEnabled ? this.#locking : false;
    }
    set locking(value) {
        if (this.asyncModeEnabled) {
            this.#locking = value;
        }
    }
    get asyncModeEnabled() {
        return this.#options?.imageProcessConfig?.asyncMode?.enabled ?? false;
    }
    #createContainer() {
        const { viewportWorldWidth, viewportWorldHeight } = this.#options;
        const container = new Container();
        container.width = viewportWorldWidth;
        container.height = viewportWorldHeight;
        container.position.set(0, 0);
        container.interactive = true;
        container?.on('pointerdown', this.#wrapEventHandler(this.#onPointerDown));
        container?.on('pointermove', this.#onPointerMove);
        container?.on('pointerup', this.#wrapEventHandler(this.#onPointerUp));
        container?.on('pointerupoutside', this.#wrapEventHandler(this.#onPointerUp));
        this.container = container;
    }
    #wrapEventHandler(action) {
        if (!this.asyncModeEnabled) {
            return action;
        }
        else {
            return (...args) => {
                if (this.locking) {
                    return;
                }
                return action(...args);
            };
        }
    }
    #renderBackdrop() {
        const { viewportWorldWidth, viewportWorldHeight, imageProcessConfig } = this.#options;
        const img = new Image();
        img.src = imageProcessConfig.backdrop;
        const texture = Texture.from(img);
        const sprite = new TilingSprite(texture, viewportWorldWidth, viewportWorldHeight);
        sprite.name = 'ALPHA_BG';
        sprite.interactive = false;
        this.container.addChild(sprite);
    }
    #addDisplay() {
        const { newDisplay } = this.#options;
        // @ts-ignore
        this.#canvas = newDisplay.texture.baseTexture.resource.source;
        this.#ctx = this.#canvas.getContext('2d');
        this.#processController.setOptions({
            canvas: this.#canvas,
            ctx: this.#ctx,
        });
        this.newDisplay = newDisplay;
        this.container.addChild(this.newDisplay);
    }
    #updateCloneDisplayAttrs() {
        const { viewportScale, viewportPosition, viewportWorldWidth, viewportWorldHeight, setDisplayPosition, finalPosition, } = this.#options;
        // 设置大小
        const { width, height } = calcAspectRatioFit(this.newDisplay.width, this.newDisplay.height, viewportWorldWidth, viewportWorldHeight);
        this.newDisplay.width = width;
        this.newDisplay.height = height;
        this.ratio = {
            x: finalPosition.width / width,
            y: finalPosition.height / height,
        };
        // 设置位置
        const newDisplayBounds = getDisplayBounds(this.newDisplay).getRectangle();
        const targetX = viewportPosition.x + (viewportWorldWidth * viewportScale.x) / 2;
        const targetY = viewportPosition.y + (viewportWorldHeight * viewportScale.y) / 2;
        const offsetX = (newDisplayBounds.x + newDisplayBounds.width / 2 - targetX) /
            viewportScale.x;
        const offsetY = (newDisplayBounds.y + newDisplayBounds.height / 2 - targetY) /
            viewportScale.y;
        const dPos = new Point(this.newDisplay.x - offsetX, this.newDisplay.y - offsetY);
        setDisplayPosition(this.newDisplay, dPos);
    }
    #initTools(tool = IMAGE_PROCESS_TOOL.MAGIC_WAND, options) {
        Tools.forEach((Tool) => {
            const { viewportWorldWidth, viewportWorldHeight, viewportScale, selectDisplay, imageProcessConfig, newDisplay, } = this.#options;
            const instance = new Tool({
                viewportWorldWidth,
                viewportWorldHeight,
                viewportScale,
                onRecord: this.#record,
                processController: this.#processController,
                selectDisplay,
                imageProcessConfig,
                newDisplay,
            });
            const t = instance.name;
            this[`#${t}`] = instance;
            if (this[`#${t}`].addToContainer) {
                this.container.addChild(this[`#${t}`]);
            }
            if (tool === t) {
                this.#curTool = this[`#${t}`];
                this.#curTool.updateAttr(options);
                this.container.cursor = this.#curTool.cursor;
            }
        });
    }
    updateTool(tool, options) {
        if (isFunction(this.#curTool.cancel)) {
            this.#curTool.cancel();
        }
        if (this[`#${tool}`]) {
            if (this.#curTool.name !== tool) {
                const lastTool = this.#curTool;
                lastTool.unactived?.();
                this.#curTool = this[`#${tool}`];
                this.#curTool.actived?.();
                this.container.cursor = this.#curTool.cursor;
            }
            if (this.#curTool.updateAttr && options) {
                this.#curTool.updateAttr(options);
            }
        }
    }
    cancel() {
        if (this.#curTool && isFunction(this.#curTool.cancel)) {
            this.#curTool.cancel();
        }
    }
    confirm() {
        if (this.#curTool && isFunction(this.#curTool.confirm)) {
            this.#curTool.confirm();
        }
    }
    #getPointByContainer(e) {
        return e.data.getLocalPosition(this.container);
    }
    #onPointerDown = (e) => {
        // 只有单指时才可触发逻辑
        if (isSinglePoint(e)) {
            this.#isPointerDown = true;
            this.#curTool?.start(this.#getPointByContainer(e), this.newDisplay);
            this.newDisplay.texture.update();
        }
    };
    #onPointerUp = (e) => {
        if (!this.#isPointerDown) {
            return;
        }
        this.#isPointerDown = false;
        this.#curTool?.end(this.#getPointByContainer(e), this.newDisplay);
        this.newDisplay.texture.update();
    };
    #onPointerMove = (e) => {
        if (isSinglePoint(e) && e.isInView) {
            this.#curTool?.update(this.#getPointByContainer(e), this.newDisplay);
            this.newDisplay.texture.update();
        }
    };
    destroyAll() {
        if (isFunction(this.#curTool.cancel)) {
            this.#curTool.cancel();
        }
        this.container.children.forEach((ele) => {
            ele.destroy(true);
        });
        this.container.destroy();
        this.container = null;
        this.newDisplay = null;
        this.#canvas = null;
        this.#ctx = null;
        this.#initOperationStackLog = null;
        this.#processController = null;
        this.destroyed = true;
    }
    setFinalDisplay() {
        // 在保存前对图形数据做一次空白移除
        const { width, height, data } = trimCanvas(this.#canvas);
        if (!data) {
            this.newDisplay = null;
            return;
        }
        const oldDisplay = this.#options.display;
        this.#canvas.width = width;
        this.#canvas.height = height;
        this.#ctx.putImageData(data, 0, 0);
        this.newDisplay.texture.update();
        if (this.ratio) {
            this.newDisplay.width *= this.ratio.x;
            this.newDisplay.height *= this.ratio.y;
        }
        this.newDisplay.interactive = true;
        this.newDisplay.lockRatio = true;
        if (!this.#options.isBackground) {
            this.newDisplay.id = oldDisplay.id;
        }
        // 由于MSprite在进行清晰度和灰度计算时会将base64转换成带有清晰度和灰度的base64，同时保留一份原始数据
        // 图片处理前base64会转换成带有清晰度和灰度的canvas，后续MSprite的texture就为canvas也不参与清晰度和灰度计算
        // 但是toJSON相关的操作会将带有清晰度和灰度的canvas转换为base64作为原始数据，这会导致再一次根据MSprite的清晰度和灰度计算
        // 所以处理完图形会当作一张新图，要重置回默认值
        this.newDisplay.grayValue = DEFAULT_IMAGE_PROCESS_ATTR.GRAY_VALUE;
        this.newDisplay.sharpness = DEFAULT_IMAGE_PROCESS_ATTR.SHARPNESS;
        this.newDisplay.anchor.set(0, 0);
        this.newDisplay.x = this.#options.finalPosition.x;
        this.newDisplay.y = this.#options.finalPosition.y;
        this.newDisplay.updateOriginDatasource(this.#canvas, this.#ctx);
        this.newDisplay.layerColor = oldDisplay.layerColor;
        this.newDisplay.layerTag = oldDisplay.layerTag;
        this.newDisplay.visible = oldDisplay.visible;
    }
    getOriginalDisplay() {
        return this.#options?.display;
    }
    enableDestroyOriginalDisplay() {
        return !this.#options.isBackground;
    }
    getResult() {
        this.setFinalDisplay();
        // if (!this.#options.isBackground) {
        //   this.#options.display.destroy(true);
        // }
        this.newDisplay?.emit(DISPLAY_LIFE_CYCLE.DRAW_DONE, this.newDisplay);
        this.operationStack.clear();
        return this.newDisplay;
    }
    #setInitOperationStackLog() {
        const { width, height } = this.#canvas;
        const { x, y } = this.newDisplay;
        const imageData = this.#ctx.getImageData(0, 0, width, height);
        this.#initOperationStackLog = {
            curTool: this.#curTool.name,
            curImageData: imageData,
            curCanvasWidth: width,
            curCanvasHeight: height,
            curDisplayX: x,
            curDisplayY: y,
        };
    }
    #record = () => {
        if (this.locking) {
            return;
        }
        const imageData = this.#ctx.getImageData(0, 0, this.#canvas.width, this.#canvas.height);
        if (this.asyncModeEnabled) {
            this.locking = true;
            this.#saveImageData(imageData)
                .then((imageDataKey) => {
                this.#pushOperationLog(imageDataKey);
            }, console.warn)
                .finally(() => {
                this.locking = false;
            });
        }
        else {
            this.#pushOperationLog(imageData);
        }
    };
    #pushOperationLog(imageData) {
        if (this.destroyed) {
            return;
        }
        const log = {
            curTool: this.#curTool.name,
            curCanvasWidth: this.#canvas.width,
            curCanvasHeight: this.#canvas.height,
            curDisplayX: this.newDisplay.x,
            curDisplayY: this.newDisplay.y,
            curImageData: imageData,
        };
        this.operationStack.push(log);
    }
    get canUndo() {
        return this.operationStack.canUndo;
    }
    get canRedo() {
        return this.operationStack.canRedo;
    }
    undo() {
        if (this.locking) {
            return;
        }
        this.operationStack.undo();
        this.redraw();
    }
    redo() {
        if (this.locking) {
            return;
        }
        this.operationStack.redo();
        this.redraw();
    }
    redraw() {
        if (this.locking) {
            return;
        }
        const { curImageData } = this.operationStackTop;
        if (this.asyncModeEnabled) {
            this.locking = true;
            this.#loadImageData(curImageData)
                .then((imageData) => {
                this.#applyOperationLog(imageData);
            }, console.warn)
                .finally(() => {
                this.locking = false;
            });
        }
        else {
            this.#applyOperationLog(curImageData);
        }
    }
    get operationStackTop() {
        return this.operationStack.top || this.#initOperationStackLog;
    }
    #applyOperationLog(imageData) {
        if (this.destroyed) {
            return;
        }
        if (!(imageData instanceof ImageData)) {
            throw TypeError('imageData not valid');
        }
        const { curCanvasWidth, curCanvasHeight, curDisplayX, curDisplayY } = this.operationStackTop;
        this.#canvas.width = curCanvasWidth;
        this.#canvas.height = curCanvasHeight;
        this.#ctx.putImageData(imageData, 0, 0);
        this.newDisplay.x = curDisplayX;
        this.newDisplay.y = curDisplayY;
        this.newDisplay.texture.update();
    }
    #setupAsyncModeHandler(options) {
        if (typeof options.saveImageData !== 'function' ||
            typeof options.loadImageData !== 'function') {
            throw TypeError('saveImageData and loadImageData must be a function');
        }
        this.#saveImageDataFn = options.saveImageData;
        this.#loadImageDataFn = options.loadImageData;
    }
    async #saveImageData(imageData) {
        try {
            const res = await this.#saveImageDataFn(imageData);
            if (res === undefined) {
                console.error('saveImageDataFn must return a not empty value');
            }
            return res ?? imageData;
        }
        catch (err) {
            console.error(err);
            return imageData;
        }
    }
    async #loadImageData(key) {
        if (key instanceof ImageData) {
            return key;
        }
        try {
            const res = await this.#loadImageDataFn(key);
            if (res === undefined) {
                console.error('loadImageDataFn must return a not empty value');
            }
            return res ?? key;
        }
        catch (err) {
            console.error(err);
            return key;
        }
    }
}
