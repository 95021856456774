import { Point } from 'pixi.js';
import { DEFAULT_OUTLINE_BITMAP_SCALE, DEFAULT_OUTLINE_CANVAS_BORDER, } from '../config';
import { DISPLAY_TYPE } from '../display';
import { createDisplay } from '../display/utils';
import { Potrace } from '../packages/portrace';
import { GET_ELE_INFO_TYPE } from '../types';
import { AbstractBase } from './AbstractBase';
export class AbstractOutline extends AbstractBase {
    /**
     * 边缘化处理
     *
     * @param {ImageData} imageData
     * @returns
     */
    marginalize(imageData) {
        const canvas = document.createElement('canvas');
        // 四周边缘加1px防止potrace生成多端路径
        canvas.width = imageData.width + DEFAULT_OUTLINE_CANVAS_BORDER * 2;
        canvas.height = imageData.height + DEFAULT_OUTLINE_CANVAS_BORDER * 2;
        const ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, DEFAULT_OUTLINE_CANVAS_BORDER, DEFAULT_OUTLINE_CANVAS_BORDER);
        const result = ctx.getImageData(0, 0, canvas.width, canvas.height);
        return result;
    }
    // TODO: 临时加入这个js文件，后续用wasm版本
    potraceOutline = (imageBuff, width, height, x = 0, y = 0) => {
        return new Promise((resolve) => {
            // 灰度值加透明度判断加载
            Potrace.loadImageFromGrayAlpha(imageBuff, width, height);
            const processCallback = () => {
                const path = Potrace.getTransformPath(1, x, y);
                resolve(path);
            };
            Potrace.process(processCallback);
        });
    };
    getPotracePath = async (bitmapShot) => {
        const { canvas } = bitmapShot;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const { data, width, height } = this.marginalize(imageData);
        // 提取所有轮廓
        const potracePath = await this.potraceOutline(data, width, height, -DEFAULT_OUTLINE_CANVAS_BORDER, -DEFAULT_OUTLINE_CANVAS_BORDER);
        return potracePath;
    };
    async getOutlineBitmapData(bitmapDisplays, needPointsList = true, type = GET_ELE_INFO_TYPE.SELECTED) {
        const shotTypes = {
            [GET_ELE_INFO_TYPE.SELECTED]: {
                getShot: () => this.getSelectedShot({
                    include: [DISPLAY_TYPE.BITMAP],
                    scaleNum: DEFAULT_OUTLINE_BITMAP_SCALE,
                }),
                displaysBounds: () => this.transformer.worldGroupBounds.getRectangle(),
            },
            [GET_ELE_INFO_TYPE.ALL]: {
                getShot: () => this.getAllDisplaysShot({
                    include: [DISPLAY_TYPE.BITMAP],
                    scaleNum: DEFAULT_OUTLINE_BITMAP_SCALE,
                }),
                displaysBounds: this.getDisplayLayerBounds,
            },
            [GET_ELE_INFO_TYPE.SPECIFIED]: {
                getShot: () => this.getSpecifiedDisplaysShot({
                    targetDisplays: bitmapDisplays,
                    scaleNum: DEFAULT_OUTLINE_BITMAP_SCALE,
                }),
                displaysBounds: () => this.getDisplaysBounds(bitmapDisplays),
            },
        };
        const displaysBounds = shotTypes[type]?.displaysBounds.bind(this)();
        const bitmapShot = await shotTypes[type]?.getShot.bind(this)();
        const potracePath = await this.getPotracePath(bitmapShot);
        const result = {
            displaysBounds: displaysBounds,
            bitmapScale: DEFAULT_OUTLINE_BITMAP_SCALE,
            bitmapShot,
        };
        if (needPointsList) {
            result.pathPointsList = this.getPathPoints(potracePath);
        }
        else {
            result.potracePath = potracePath;
        }
        return result;
    }
    async getOutlineVectorData(displays, offset) {
        if (offset !== 0) {
            const closePathPointsList = [];
            const openPathPointsList = [];
            displays.forEach((display) => {
                const realPointList = display.getRealPoints(this.viewport);
                realPointList.forEach((points) => {
                    if (display.isClosePath) {
                        closePathPointsList.push(points);
                    }
                    else {
                        openPathPointsList.push(points);
                    }
                });
            });
            return {
                closePathPointsList,
                openPathPointsList,
            };
        }
        else {
            const vectorDisplays = [];
            displays.forEach((display) => {
                if (display.type === DISPLAY_TYPE.PATH) {
                    const worldCoordinates = display.toGlobal(new Point(0, 0));
                    const { x, y } = this.viewport.toLocal({
                        x: worldCoordinates.x,
                        y: worldCoordinates.y,
                    });
                    const displayJSON = display.toJSON(this.viewport);
                    vectorDisplays.push({
                        type: display.type,
                        dPath: display.dPath,
                        pivot: displayJSON.pivot,
                        scale: displayJSON.scale,
                        angle: displayJSON.angle,
                        skew: displayJSON.skew,
                        x,
                        y,
                    });
                }
                else {
                    const realPointList = display.getRealPoints(this.viewport);
                    vectorDisplays.push({
                        type: display.type,
                        realPointList,
                    });
                }
            });
            return {
                vectorDisplays,
            };
        }
    }
    async getSelectedOutlineEleInfo(offset) {
        const displays = this.selected;
        const info = await this.getOutlineEleInfo(displays, {
            type: GET_ELE_INFO_TYPE.SELECTED,
            offset,
        });
        return info;
    }
    async getAllOutlineEleInfo(offset) {
        const displays = this.viewport.getInteractiveElements();
        const info = await this.getOutlineEleInfo(displays, {
            type: GET_ELE_INFO_TYPE.ALL,
            offset,
        });
        return info;
    }
    async getSpecifiedOutlineEleInfo(offset, displayIds = []) {
        if (displayIds.length === 0) {
            return null;
        }
        const displays = this.viewport.getInteractiveElements();
        const specifiedDisplays = displays.filter((d) => displayIds.includes(d.id));
        if (specifiedDisplays.length === 0) {
            return null;
        }
        const info = await this.getOutlineEleInfo(specifiedDisplays, {
            type: GET_ELE_INFO_TYPE.SPECIFIED,
            offset,
        });
        return info;
    }
    /**
     * 获取需要生成轮廓的元素信息
     *
     * @returns
     */
    async getOutlineEleInfo(displays, options) {
        const info = {};
        if (displays.length === 0) {
            return info;
        }
        // 区分元素
        const bitmapDisplays = [];
        const vectorDisplays = [];
        displays.forEach((display) => {
            if (display.type !== DISPLAY_TYPE.BITMAP) {
                vectorDisplays.push(display);
            }
            else {
                bitmapDisplays.push(display);
            }
        });
        // 按元素类别返回对应要处理的数据
        if (bitmapDisplays.length > 0) {
            const needPointsList = options?.offset !== 0;
            info['bitmapData'] = await this.getOutlineBitmapData(bitmapDisplays, needPointsList, options?.type);
        }
        if (vectorDisplays.length > 0) {
            info['vectorData'] = await this.getOutlineVectorData(vectorDisplays, options?.offset);
        }
        return info;
    }
    /**
     * 绘制轮廓
     *
     * @param path
     * @returns
     */
    drawOutline(path) {
        if (this.tempOutline) {
            this.clearOutline();
        }
        if (!path || path === 'M0,0') {
            return;
        }
        const { fillColor, lineColor, layerColor, layerTag, visible } = this.configManager.displayConfig;
        const newDisplay = createDisplay(DISPLAY_TYPE.PATH, {
            dPath: path,
            graphicX: 0,
            graphicY: 0,
            fillColor,
            lineColor,
            layerColor,
            layerTag,
            visible,
            isFill: false,
        });
        this.tempOutline = newDisplay;
        if (newDisplay) {
            this.tempLayer.addChild(newDisplay);
        }
    }
    clearOutline(destroy = true) {
        if (destroy) {
            if (this.tempOutline && !this.tempOutline.destroyed) {
                this.tempOutline?.destroy(true);
            }
        }
        else {
            // XXX: 不能不选中新创建的图形，后续需要设备判断新图形，否则报错
            if (this.tempOutline) {
                this.viewport.addToLayer(this.tempOutline);
                this.selected = [this.tempOutline];
                this.recordOperationLog(this.tempOutline);
            }
        }
        this.tempOutline = null;
    }
}
