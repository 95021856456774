import { pick } from 'lodash-es';
/**
 * 读取IGraphicsCurvesSetting数据
 */
export function getGraphicsCurveSetting(setting) {
    const data = pick(setting, [
        'maxLength',
        'minSegments',
        'maxSegments',
    ]);
    return data;
}
/**
 * 设置IGraphicsCurvesSetting数据
 */
export function setGraphicsCurvesSetting(setting, data) {
    const isValid = (value) => {
        return isFinite(value) && value > 0;
    };
    if (isValid(data.maxLength)) {
        setting.maxLength = data.maxLength;
    }
    if (isValid(data.minSegments)) {
        setting.minSegments = data.minSegments;
    }
    if (isValid(data.maxSegments)) {
        setting.maxSegments = data.maxSegments;
    }
}
/**
 * 为指定action临时变更IGraphicsCurvesSetting数据
 * @param action 需要执行的action
 * @param setting 被更新的IGraphicsCurvesSettings对象
 * @param data 更新数据
 * @returns
 */
export function changeGraphicsCurvesSettingFor(action, setting, data) {
    if (!data) {
        return;
    }
    // 保存属性值
    const backup = getGraphicsCurveSetting(setting);
    setGraphicsCurvesSetting(setting, data);
    try {
        return action();
    }
    catch (e) {
        console.error(e);
    }
    finally {
        // 恢复属性值
        setGraphicsCurvesSetting(setting, backup);
    }
}
