import { Bounds, Point, Rectangle } from 'pixi.js';
/**
 * 计算ImageData的bounds
 * @param imageData
 * @param [transform]
 * @returns
 */
export function getImageDataBounds(imageData, transform) {
    const bounds = new Bounds();
    if (imageData) {
        const pLen = imageData.width * imageData.height;
        for (let p = 0; p < pLen - 1; p++) {
            const a = imageData.data[p * 4 + 3];
            if (a) {
                const x = p % imageData.width;
                const y = Math.floor(p / imageData.width);
                const pt = new Point(x, y);
                if (transform) {
                    bounds.addPointMatrix(transform, pt);
                }
                else {
                    bounds.addPoint(pt);
                }
            }
        }
    }
    return bounds.isEmpty() ? new Rectangle(0, 0, 0, 0) : bounds.getRectangle();
}
