import { Bounds } from '@pixi/display';
import { GraphicsGeometry } from '@pixi/graphics';
import { Matrix } from '@pixi/math';
import { buildDashedLine } from './utils/buildDashedLine';
import { PATH } from './utils/Path';
const tmpBounds = new Bounds();
// @pixi/graphics should export this, ugh!
var SHAPES;
(function (SHAPES) {
    SHAPES[SHAPES["POLY"] = 0] = "POLY";
    SHAPES[SHAPES["RECT"] = 1] = "RECT";
    SHAPES[SHAPES["CIRC"] = 2] = "CIRC";
    SHAPES[SHAPES["ELIP"] = 3] = "ELIP";
    SHAPES[SHAPES["RREC"] = 4] = "RREC";
})(SHAPES || (SHAPES = {}));
/** @public */
export class SVGGraphicsGeometry extends GraphicsGeometry {
    processLine(data) {
        if (data.shape.type === PATH) {
            this.processPathLine(data);
            return;
        }
        const lineStyle = data.lineStyle;
        if (!lineStyle.dashArray) {
            super.processLine(data);
        }
        else {
            buildDashedLine(data, this);
        }
    }
    processPathLine(data) {
        const path = data.shape;
        const lineStyle = data.lineStyle;
        path.contours.forEach((contour) => {
            if (contour.find((e) => isNaN(e)) !== undefined) {
                console.error('Contour has NaN, oops!');
                return;
            }
            if (lineStyle.dashArray) {
                buildDashedLine({
                    points: contour,
                    holes: [],
                    // @ts-expect-error
                    shape: { points: contour, type: SHAPES.POLY },
                    lineStyle,
                }, this);
            }
            else {
                super.processLine({
                    closeStroke: true,
                    points: contour.slice(),
                    holes: [],
                    // @ts-expect-error
                    shape: { points: contour, type: SHAPES.POLY },
                    lineStyle,
                });
            }
        });
    }
    calculateBounds() {
        const bounds = this._bounds;
        const sequenceBounds = tmpBounds;
        let curMatrix = Matrix.IDENTITY;
        this._bounds.clear();
        sequenceBounds.clear();
        for (let i = 0; i < this.graphicsData.length; i++) {
            const data = this.graphicsData[i];
            const shape = data.shape;
            const type = data.type;
            const nextMatrix = data.matrix || Matrix.IDENTITY;
            const lineWidth = 0.0;
            // Note: 原逻辑对一些图像的尺寸增加了padding，是为了选中框与line不重合，但是得到的尺寸会不精确
            // if (lineStyle && lineStyle.visible) {
            //   const alignment = lineStyle.alignment;
            //   lineWidth = lineStyle.width;
            //   if (type === SHAPES.POLY) {
            //     lineWidth = lineWidth * (0.5 + Math.abs(0.5 - alignment));
            //   } else {
            //     lineWidth = lineWidth * Math.max(0, alignment);
            //   }
            // }
            if (curMatrix !== nextMatrix) {
                if (!sequenceBounds.isEmpty()) {
                    bounds.addBoundsMatrix(sequenceBounds, curMatrix);
                    sequenceBounds.clear();
                }
                curMatrix = nextMatrix;
            }
            if (type === SHAPES.RECT || type === SHAPES.RREC) {
                const rect = shape;
                sequenceBounds.addFramePad(rect.x, rect.y, rect.x + rect.width, rect.y + rect.height, lineWidth, lineWidth);
            }
            else if (type === SHAPES.CIRC) {
                const circle = shape;
                sequenceBounds.addFramePad(circle.x, circle.y, circle.x, circle.y, circle.radius + lineWidth, circle.radius + lineWidth);
            }
            else if (type === SHAPES.ELIP) {
                const ellipse = shape;
                sequenceBounds.addFramePad(ellipse.x, ellipse.y, ellipse.x, ellipse.y, ellipse.width + lineWidth, ellipse.height + lineWidth);
            }
            else if (type === PATH) {
                const path = shape;
                path.contours.forEach((contour) => {
                    bounds.addVerticesMatrix(Matrix.IDENTITY, new Float32Array(contour), 0, contour.length);
                });
            }
            else {
                const poly = shape;
                // adding directly to the bounds
                bounds.addVerticesMatrix(curMatrix, poly.points, 0, poly.points.length, lineWidth, lineWidth);
            }
        }
        if (!sequenceBounds.isEmpty()) {
            bounds.addBoundsMatrix(sequenceBounds, curMatrix);
        }
        bounds.pad(this.boundsPadding, this.boundsPadding);
    }
}
