// @ts-nocheck
import { LineStyle } from '@pixi/graphics';
export class DashedLineStyle extends LineStyle {
    /**
     * The dashing pattern of dashes and gaps to stroke paths.
     */
    dashArray = null;
    /**
     * The distance into the dash pattern to start from.
     */
    dashOffset = 0;
    /**
     * @override
     */
    clone() {
        const obj = super.clone();
        obj.dashArray = this.dashArray ? [...this.dashArray] : null;
        obj.dashOffset = this.dashOffset;
        return obj;
    }
    /**
     * @override
     */
    reset() {
        super.reset();
        this.dashArray = null;
        this.dashOffset = 0;
    }
}
