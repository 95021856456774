import { EventEmitter } from '@pixi/utils';
import { EDIT_MODE } from '../types';
import { MODE_EVENT } from '../types/event';
export default class ModeManager extends EventEmitter {
    #curMode = EDIT_MODE.SELECT;
    get curMode() {
        return this.#curMode;
    }
    set curMode(mode) {
        if (this.#curMode === mode) {
            return;
        }
        const preMode = this.#curMode;
        this.#curMode = mode;
        this.emit(MODE_EVENT.CHANGE, preMode, this.#curMode);
    }
}
