import { InteractionManager } from 'pixi.js';
import { PointerManager } from './PointerManager';
// 将 DOM 事件转换为 Pixi InteractionEvent 事件并得到 hit DisplayObject，传递给 PointerManager 处理
// 重写了父类私有方法，添加@ts-ignore避免报错
// @ts-ignore
export class MInteractionManager extends InteractionManager {
    pointerManager;
    constructor(renderer, options) {
        super(renderer, options);
        this.pointerManager = new PointerManager();
        this.passEvents();
    }
    // 适配Apple Pencil，避免笔触事件触发两次
    // 重写onPointerUp、onPointerMove和onPointerDown事件
    // 由于这三个事件为私有事件，需要添加@ts-ignore忽略ts检查避免报错
    onPointerUp(originalEvent) {
        if (this.supportsTouchEvents &&
            ['touch', 'pen'].includes(originalEvent.pointerType)) {
            return;
        }
        // @ts-ignore
        super.onPointerUp(originalEvent);
    }
    // @ts-ignore
    configureInteractionEventForDOMEvent(interactionEvent, pointerEvent, interactionData) {
        // 重写 configureInteractionEventForDOMEvent 方法
        // 在事件中新增 isInView 属性，标记当前事件是否触发在 app.view(即canvas标签中)
        if (pointerEvent.target === this.renderer.view) {
            // @ts-ignore
            interactionEvent.isInView = true;
        }
        else {
            // @ts-ignore
            interactionEvent.isInView = false;
        }
        // @ts-ignore
        return super.configureInteractionEventForDOMEvent(interactionEvent, pointerEvent, interactionData);
    }
    onPointerMove(originalEvent) {
        if (this.supportsTouchEvents &&
            ['touch', 'pen'].includes(originalEvent.pointerType)) {
            return;
        }
        // @ts-ignore
        super.onPointerMove(originalEvent);
    }
    onPointerDown(originalEvent) {
        if (this.supportsTouchEvents &&
            ['touch', 'pen'].includes(originalEvent.pointerType)) {
            return;
        }
        // @ts-ignore
        super.onPointerDown(originalEvent);
    }
    passEvents() {
        if (this.supportsPointerEvents) {
            this.interactionDOMElement.addEventListener('pointerdown', this.passPointerDownEvent.bind(this), 
            // @ts-ignore
            this._eventListenerOptions);
            this.interactionDOMElement.addEventListener('pointermove', this.passPointerMoveEvent.bind(this), 
            // @ts-ignore
            this._eventListenerOptions);
            this.interactionDOMElement.addEventListener('pointerup', this.passPointerUpEvent.bind(this), 
            // @ts-ignore
            this._eventListenerOptions);
            this.interactionDOMElement.addEventListener('pointercancel', this.passPointerUpEvent.bind(this), 
            // @ts-ignore
            this._eventListenerOptions);
            this.interactionDOMElement.addEventListener('pointerleave', this.passPointerOutEvent.bind(this), 
            // @ts-ignore
            this._eventListenerOptions);
            this.interactionDOMElement.addEventListener('pointerover', this.passPointerOverEvent.bind(this), 
            // @ts-ignore
            this._eventListenerOptions);
        }
        else {
            if (window.MouseEvent) {
                this.interactionDOMElement.addEventListener('mousedown', this.passPointerDownEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('mousemove', this.passPointerMoveEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('mouseup', this.passPointerUpEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('mouseout', this.passPointerOutEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('mouseover', this.passPointerOverEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
            }
            if (window.TouchEvent) {
                this.interactionDOMElement.addEventListener('touchstart', this.passPointerDownEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('touchmove', this.passPointerMoveEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('touchend', this.passPointerUpEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
                this.interactionDOMElement.addEventListener('touchcancel', this.passPointerUpEvent.bind(this), 
                // @ts-ignore
                this._eventListenerOptions);
            }
        }
    }
    // PointerDown
    // 参考 https://github.com/pixijs/pixijs/blob/cde15df7d38539bef853d821f2f41ec53d46763e/packages/interaction/src/InteractionManager.ts#L1118
    passPointerDownEvent(originalEvent) {
        // @ts-ignore
        const events = this.normalizeToPointerData(originalEvent);
        if (this.autoPreventDefault && events[0].isNormalized) {
            const cancelable = originalEvent.cancelable || !('cancelable' in originalEvent);
            if (cancelable) {
                originalEvent.preventDefault();
            }
        }
        this.passPointerComplete(events, originalEvent, this.processPointer.bind(this));
    }
    // PointerMove
    passPointerMoveEvent(originalEvent) {
        // @ts-ignore
        const events = this.normalizeToPointerData(originalEvent);
        if (events[0].pointerType === 'mouse' || events[0].pointerType === 'pen') {
            // @ts-ignore
            this._didMove = true;
            this.cursor = null;
        }
        this.passPointerComplete(events, originalEvent, this.processPointer.bind(this));
    }
    // PointerUp
    passPointerUpEvent(originalEvent) {
        // @ts-ignore
        const events = this.normalizeToPointerData(originalEvent);
        this.passPointerComplete(events, originalEvent, this.processPointer.bind(this));
    }
    passPointerComplete(events, originalEvent, func) {
        const eventLen = events.length;
        for (let i = 0; i < eventLen; i++) {
            const event = events[i];
            // @ts-ignore
            const interactionData = this.getInteractionDataForPointerId(event);
            // @ts-ignore
            const interactionEvent = this.configureInteractionEventForDOMEvent(this.eventData, event, interactionData);
            interactionEvent.data.originalEvent = originalEvent;
            func(interactionEvent);
        }
    }
    passPointerOutEvent(originalEvent) {
        // @ts-ignore
        const events = this.normalizeToPointerData(originalEvent);
        // Only mouse and pointer can call onPointerOut, so events will always be length 1
        const event = events[0];
        if (event.pointerType === 'mouse') {
            this.mouseOverRenderer = false;
            this.setCursorMode(null);
        }
        // @ts-ignore
        const interactionData = this.getInteractionDataForPointerId(event);
        // @ts-ignore
        const interactionEvent = this.configureInteractionEventForDOMEvent(this.eventData, event, interactionData);
        interactionEvent.data.originalEvent = event;
        this.processPointer(interactionEvent);
        if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
        }
        else {
            // we can get touchleave events after touchend, so we want to make sure we don't
            // introduce memory leaks
            // @ts-ignore
            this.releaseInteractionDataForPointerId(interactionData.identifier);
        }
    }
    passPointerOverEvent(originalEvent) {
        // @ts-ignore
        const events = this.normalizeToPointerData(originalEvent);
        // Only mouse and pointer can call onPointerOut, so events will always be length 1
        const event = events[0];
        // @ts-ignore
        const interactionData = this.getInteractionDataForPointerId(event);
        // @ts-ignore
        const interactionEvent = this.configureInteractionEventForDOMEvent(this.eventData, event, interactionData);
        interactionEvent.data.originalEvent = event;
        if (event.pointerType === 'mouse') {
            this.mouseOverRenderer = true;
        }
        this.processPointer(interactionEvent);
    }
    processPointer(e) {
        this.processInteractive(e, this.lastObjectRendered, this.hitPointer.bind(this), true);
    }
    hitPointer(interactionEvent, displayObject, hit) {
        if (hit) {
            this.pointerManager.processPointer(interactionEvent, displayObject);
        }
    }
}
