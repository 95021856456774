export function setMask(obj, mask) {
    if (obj.mask && obj.mask !== mask) {
        removeMask(obj);
    }
    obj.mask = mask;
}
export function removeMask(obj) {
    if (obj.mask) {
        obj.mask = null;
        return true;
    }
    return false;
}
export function getMaskObj(obj) {
    const mask = obj?.mask ?? null;
    return mask
        ? (mask.isMaskData
            ? mask.maskObject
            : mask)
        : mask;
}
