import { Container } from 'pixi.js';
Container.prototype.getInteractiveElements = function () {
    // TODO: 将绘制对象分层管理，不需要过滤操作
    const exclude = ['GRID', 'BACKDROP', 'SELECTOR'];
    const objects = this.children.filter((c) => !exclude.includes(c.name));
    const data = [];
    const findInteractiveObj = (obj) => {
        const { children = [], interactive } = obj;
        if (interactive) {
            data.push(obj);
        }
        else if (children && children.length > 0) {
            children.forEach((element) => {
                findInteractiveObj(element);
            });
        }
    };
    objects.forEach((child) => {
        findInteractiveObj(child);
    });
    return data;
};
Container.prototype.isVaild = function () {
    return this.width > 0 || this.height > 0;
};
