import { cloneDeep, isEmpty, merge } from 'lodash-es';
import { Bounds } from 'pixi.js';
import { DEFAULT_TEXT, DEFAULT_TEXT_STYLE } from '../config';
import { BASIC_FONT_SIZE, MGraphic, TextGlyphs } from '../display';
import { hasOneIn } from '../utils/math';
import { toUnicodeCharArray } from '../utils/stringUtils';
import { DISPLAY_LIFE_CYCLE, DISPLAY_TYPE } from './type';
import { createDisplay } from './utils';
export class MSVGText extends MGraphic {
    text = DEFAULT_TEXT;
    lines = [];
    style;
    // 触发字形的变化
    triggerGlyphsChange = [
        'text',
        'style.fontSize',
        'style.fontFamily',
        'style.fontSubfamily',
        'style.fontSource',
    ];
    // 触发坐标的变化
    triggerStyleChange = [
        'leading',
        'letterSpacing',
        'align',
        'curveX',
        'curveY',
    ];
    fontData;
    curveInfo;
    constructor(option) {
        option = merge(cloneDeep({
            text: DEFAULT_TEXT,
            style: DEFAULT_TEXT_STYLE,
        }), option);
        super();
        this.type = DISPLAY_TYPE.TEXT;
        this.interactive = true;
        // 文字的字符都是可以填充的路径，直接默认的闭合状态为true
        this.isClosePath = true;
        this.parseJSON(option);
    }
    highlightBound() {
        const nodes = this.children;
        nodes.forEach((element) => {
            const node = element;
            node.highlightBound();
        });
    }
    reset() {
        const nodes = this.children;
        nodes.forEach((element) => {
            const node = element;
            node.reset();
        });
    }
    createSVGPathNode(options) {
        const display = createDisplay(DISPLAY_TYPE.PATH, options);
        // SVGPathNode 的interactive 内默认是true，这里作为child，禁用interactive，禁止child被选中
        display.interactive = false;
        return display;
    }
    end() {
        this.done();
    }
    done() {
        this.emit(DISPLAY_LIFE_CYCLE.DRAW_DONE, this);
    }
    toJSON(relativeEle) {
        const { width, height, text, isFill } = this;
        const baseJSON = super.toJSON(relativeEle);
        const fontData = cloneDeep(this.fontData) ?? null;
        const charJSONs = this.children.map((child) => child.toJSON(relativeEle));
        return {
            ...baseJSON,
            text,
            isFill,
            width,
            height,
            resolution: 1,
            fillColor: this.config.fillColor,
            lineColor: this.config.lineColor,
            style: {
                ...this.style,
            },
            fontData,
            charJSONs,
        };
    }
    /**
     * 重新 graphic的 containsPoint 的方法
     * @param point global point
     * @returns
     */
    containsPoint(point) {
        const localBounds = this.getLocalBounds();
        const localPoint = this.toLocal(point);
        return localBounds.contains(localPoint.x, localPoint.y);
    }
    parseJSON(data) {
        const { width, height } = data;
        // 先设置width height 属性，避免因为字体缺失等情况导致宽高比例变化
        if (width) {
            this.width = width;
        }
        if (height) {
            this.height = height;
        }
        super.parseJSON(data);
        const { text = this.text, style = {}, fillColor, lineColor, isFill, layerTag, layerColor, enableFill, } = data;
        this.style = { ...this.style, ...style };
        let styleUpdated = false;
        this.text = text;
        // 只有触发了字形改变的属性才需要重新获取glyphs，并且更新style
        if (hasOneIn(data, this.triggerGlyphsChange)) {
            styleUpdated = true;
            this.updateText();
        }
        if (hasOneIn(style, this.triggerStyleChange) && !styleUpdated) {
            this.updateTextStyle();
        }
        if (hasOneIn(data, [
            'isFill',
            'lineColor',
            'fillColor',
            'layerTag',
            'layerColor',
            'enableFill',
        ])) {
            // NOTE: 因container.getLocalBounds 的到的数据会受childen的visible影响(若child的visible为false，不会计算child的边界)
            //  visible 属性不可以设置到 children中
            this.parseNodeJSON({
                fillColor,
                lineColor,
                isFill,
                layerTag,
                layerColor,
                enableFill,
            });
        }
    }
    parseNodeJSON(options) {
        const nodes = this.children;
        nodes.forEach((element) => {
            const node = element;
            node.parseJSON(options);
        });
    }
    reDraw() {
        this.children.forEach((child) => {
            const node = child;
            node.reDraw();
        });
    }
    toggleFill(isFill = this.isFill) {
        const nodes = this.children;
        nodes.forEach((element) => {
            const node = element;
            node.toggleFill(isFill);
        });
    }
    destroyChildren() {
        const oldChildren = this.removeChildren(0, this.children.length);
        for (let i = 0; i < oldChildren.length; ++i) {
            oldChildren[i].destroy();
        }
    }
    // 通过控制缩放产生的文字的尺寸变化，将scale值转换成fontSize值
    transformCommit() {
        this.converHeightToFontSize();
    }
    converHeightToFontSize() {
        // fontSize使用基准字号根据当前scale.y进行计算
        this.style.fontSize = BASIC_FONT_SIZE * this.scale.y;
    }
    updateText() {
        // fontSize, text 和 fontFamily+subfontFamily(bold italic)会影响形状的变化
        const { text, style: { fontFamily, fontSubfamily, fontSource, fontSize }, } = this;
        const fontData = TextGlyphs.measureText({
            fontFamily,
            fontSubfamily,
            fontSource,
            text,
            fontSize: TextGlyphs.ptToMM(Number(BASIC_FONT_SIZE)), // 使用基准字号获取文字图形
        });
        if (!fontData || isEmpty(fontData)) {
            console.warn('无法获取到对应文字信息', text, fontFamily, fontSubfamily);
            return;
        }
        // 根据当前fontSize和上次scale值计算新的scale值
        this.updateTextScaleByFontSize(fontSize);
        this.fontData = fontData;
        const { glyphData = {} } = fontData;
        const lines = TextGlyphs.splitTextToLines(text);
        this.destroyChildren();
        this.lines = lines;
        if (!glyphData) {
            console.warn('loss glyphData');
            return;
        }
        lines.forEach((line) => {
            const chars = toUnicodeCharArray(line);
            for (let strIndex = 0; strIndex < chars.length; strIndex++) {
                const str = chars[strIndex];
                const glyph = glyphData[str];
                if (!glyph) {
                    console.warn('There is no valid glyph to create display object for', str, glyphData);
                }
                else {
                    //
                    const node = this.createSVGPathNode({
                        dPath: glyph.dPath,
                        isFill: this.isFill,
                        layerColor: this.layerColor,
                        layerTag: this.layerTag,
                        visible: this.visible,
                        ...this.config,
                        enableFill: this.enableFill,
                    });
                    this.addChild(node);
                }
            }
        });
        this.updateTextStyle();
        if (!this.enableFill) {
            this.addHitArea();
        }
    }
    updateTextScaleByFontSize(fontSize) {
        const lastScaleX = this.scale.x;
        const lastScaleY = this.scale.y;
        const scaleY = Number(fontSize) / BASIC_FONT_SIZE;
        this.scale.y = scaleY;
        this.scale.x = (lastScaleX / lastScaleY) * scaleY;
    }
    updateTextStyle() {
        TextGlyphs.updateGlyphPositions(this.children, this.lines, this.fontData, {
            ...this.style,
            fontSize: BASIC_FONT_SIZE,
        });
        this.curveInfo = TextGlyphs.updateTextCurveEffect(this, this.style, this.lines, this.fontData);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update(point, e) { }
    /**
     * 通过保存的原始path数据，进行transform运算，得到realPoint
     */
    getRealPoints(relativeEle) {
        let result = [];
        for (let index = 0; index < this.children.length; index++) {
            const element = this.children[index];
            const points = element.getRealPoints(relativeEle);
            result = result.concat(points);
        }
        return result;
    }
    getRealBounds() {
        const _bounds = new Bounds();
        for (let index = 0; index < this.children.length; index++) {
            const element = this.children[index];
            element.getRealBounds(_bounds);
        }
        return _bounds;
    }
    isVaild() {
        const valid = (this.width > 0 || this.height > 0) && !(this.text.trim().length === 0);
        return valid;
    }
    getSVGData(options) {
        const childOptions = { ...options, relativeObj: this };
        return merge(super.getSVGData(options), {
            tag: 'g',
            children: this.children.map((c) => c.getSVGData(childOptions)),
        });
    }
}
