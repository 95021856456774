import { isNumber, merge } from 'lodash-es';
import { Graphics } from 'pixi.js';
import { DEFAULT_GRID_CONFIG } from '../../../config';
export class Grid extends Graphics {
    #options;
    constructor(option) {
        super();
        this.name = 'GRID';
        this.interactive = false;
        this.x = 0;
        this.y = 0;
        this.#options = Object.assign({}, DEFAULT_GRID_CONFIG, option);
        this.draw();
    }
    draw() {
        const { cellSize, lineNative, lineWidth, lineColor, lineAlpha, cellSize2, lineNative2, lineWidth2, lineColor2, lineAlpha2, } = this.#options;
        // 绘制小网格
        this.drawGridLines(cellSize, lineWidth, lineColor, lineAlpha, lineNative);
        // 绘制大网格
        this.drawGridLines(cellSize2, lineWidth2, lineColor2, lineAlpha2, lineNative2);
        this.endFill();
    }
    redraw() {
        this.clear();
        this.draw();
    }
    drawGridLines(cellSize, lineWidth, lineColor, lineAlpha, lineNative) {
        if (!isNumber(cellSize) || cellSize <= 0) {
            return;
        }
        const { width, height } = this.#options;
        const lineStyle = {
            width: lineWidth,
            color: lineColor,
            alpha: lineAlpha,
            // 如果native为true, WebGL绘制直线时使用LINES而不是TRIANGLE_STRIP,
            // native方式绘制的直线不会随着缩放而改变线宽，但线宽始终是1，于是width参数无效。
            native: lineNative,
        };
        this.lineStyle(lineStyle);
        this.horizontalLines(width, height, cellSize);
        this.verticalLines(width, height, cellSize);
    }
    updateOptions(options) {
        this.#options = merge(this.#options, options);
    }
    horizontalLines(width, height, size) {
        let y = size;
        const length = width;
        const max = height;
        if (height > size) {
            do {
                this.moveTo(0, y);
                this.lineTo(length, y);
                y += size;
            } while (y < max);
        }
    }
    verticalLines(width, height, size) {
        let x = size;
        const length = height;
        const max = width;
        if (width > size) {
            do {
                this.moveTo(x, 0);
                this.lineTo(x, length);
                x += size;
            } while (x < max);
        }
    }
}
