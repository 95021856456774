let saidHello = false;
export function sayHello(content) {
    if (saidHello) {
        return;
    }
    const args = [
        `%c【m-canvas-core】 - ✰ ${content} ✰ `,
        'color: green; padding:5px 0;',
    ];
    globalThis.console.log(...args);
    saidHello = true;
}
