import { Drag } from 'pixi-viewport';
/**
 * 针对Windows平台定制修改的Drag插件
 */
export class DragWins extends Drag {
    wheel(event) {
        // @ts-ignore
        this.options.wheelSwapAxes = event.shiftKey;
        return super.wheel(event);
    }
}
