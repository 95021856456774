import { chunk, pick } from 'lodash-es';
import { Rectangle } from 'pixi.js';
import { v4 as uuid } from 'uuid';
import { TextGlyphs } from '../../display';
import { DISPLAY_TYPE } from '../../display/type';
import { BATCH_CREATE_TYPE } from '../../types/edit';
import { calPointRotatedCoord, calRectangleRotatedCoord } from '../../utils';
export class BatchCreate {
    matrixResult;
    descriptionResult;
    #temp;
    constructor() {
        this.#temp = [];
        this.matrixResult = [];
        this.descriptionResult = [];
    }
    create(type, selectedJson, selectedGroupBounds, params) {
        let arr = new Array();
        switch (type) {
            case BATCH_CREATE_TYPE.LINER:
                arr = this.createLinerArrays(selectedJson, selectedGroupBounds, params);
                break;
            case BATCH_CREATE_TYPE.RADIAL:
                arr = this.createRadialArrays(selectedJson, selectedGroupBounds, params);
                break;
            default:
                arr = [];
                break;
        }
        return arr;
    }
    createLinerArrays(selectedJson, selectedGroupBounds, params) {
        this.clearTemp(true);
        const { colNum, colSpace, rowNum, rowSpace } = params;
        const res = [];
        const total = colNum * rowNum;
        for (let i = 1; i < total; i++) {
            const stepX = i % colNum;
            const stepY = Math.floor(i / colNum);
            const tempRes = [];
            selectedJson.forEach((ele) => {
                const cloneJSON = { ...ele };
                cloneJSON.x += stepX * (selectedGroupBounds.width + colSpace);
                cloneJSON.y += stepY * (selectedGroupBounds.height + rowSpace);
                cloneJSON.sourceId = ele.id;
                cloneJSON.id = uuid();
                tempRes.push(cloneJSON);
            });
            res.push(tempRes);
        }
        this.matrixResult = chunk([selectedJson[0], ...res.map((data) => data[0])].map((item) => item?.id), colNum);
        let descriptionArrays = [];
        const { title, xCoordinate, yCoordinate, label } = params;
        if (title &&
            xCoordinate &&
            yCoordinate &&
            label &&
            selectedJson.length === 1) {
            descriptionArrays = this.createDescriptionArrays(selectedJson, selectedGroupBounds, params);
        }
        return [...res, ...descriptionArrays];
    }
    createDescriptionArrays(selectedJson, selectedGroupBounds, params) {
        const descriptionArrays = [];
        const { colNum, colSpace, rowNum, rowSpace, title, xCoordinate, yCoordinate, label, } = params;
        // 选择多个元素时,以最先出现到画布中的元素为基准
        const ele = pick(selectedJson[0], [
            'layerColor',
            'layerTag',
            'lineColor',
            'fillColor',
            'originColor',
            'visible',
        ]);
        const { fontSizeScale, subTitleScale, coordinateSpace, coordinateLabelSpace, minFontSize, minSubTitleSize, } = label;
        // 单位是mm
        const fontSize = Math.max(Math.min(selectedGroupBounds.width, selectedGroupBounds.height) *
            fontSizeScale, minFontSize);
        const subTitleSize = Math.max(Math.min(selectedGroupBounds.width, selectedGroupBounds.height) *
            subTitleScale, minSubTitleSize);
        const textWidthMeasure = (text, font) => {
            const { width, fontScale } = TextGlyphs.measureTextJSON(text, {
                fontSize: TextGlyphs.mmToPT(font),
                fontFamily: 'Lato',
            });
            return width * fontScale;
        };
        const linerArraysWidth = selectedGroupBounds.width * colNum + colSpace * (colNum - 1);
        const linerArraysHeight = selectedGroupBounds.height * rowNum + rowSpace * (rowNum - 1);
        // x轴
        const { direction: xDirection, title: xTitle, label: xLabel } = xCoordinate;
        const originX_x = (label, font) => {
            return (selectedGroupBounds.x +
                (1 / 2) * selectedGroupBounds.width -
                (1 / 2) * textWidthMeasure(label, font));
        };
        const originX_y = xDirection === 0
            ? selectedGroupBounds.y - fontSize - coordinateSpace
            : selectedGroupBounds.y + linerArraysHeight + coordinateSpace;
        const createArray = (text, fontSize, x, y, rotate) => {
            descriptionArrays.push([
                {
                    ...ele,
                    id: uuid(),
                    text,
                    type: DISPLAY_TYPE.TEXT,
                    style: { fontSize: TextGlyphs.mmToPT(fontSize) },
                    angle: rotate || 0,
                    x,
                    y,
                },
            ]);
        };
        xLabel.forEach((label, index) => {
            createArray(`${label}`, fontSize, originX_x(`${label}`, fontSize) +
                index * (selectedGroupBounds.width + colSpace), originX_y);
        });
        createArray(`${xTitle}`, subTitleSize, selectedGroupBounds.x +
            (1 / 2) * linerArraysWidth -
            (1 / 2) * textWidthMeasure(`${xTitle}`, subTitleSize), xDirection === 0
            ? originX_y - coordinateSpace - subTitleSize
            : originX_y + fontSize + coordinateSpace);
        // y轴
        const { direction: yDirection, title: yTitle, label: yLabel } = yCoordinate;
        let maxWidth = 0;
        const originY_x = (label, font) => {
            if (textWidthMeasure(label, font) > maxWidth) {
                maxWidth = textWidthMeasure(label, font);
            }
            return yDirection === 0
                ? selectedGroupBounds.x -
                    textWidthMeasure(label, font) -
                    coordinateSpace
                : selectedGroupBounds.x + linerArraysWidth + coordinateSpace;
        };
        const originY_y = selectedGroupBounds.y +
            (1 / 2) * selectedGroupBounds.height -
            (1 / 2) * fontSize;
        yLabel.forEach((label, index) => {
            createArray(`${label}`, fontSize, originY_x(`${label}`, fontSize), originY_y + index * (selectedGroupBounds.height + rowSpace));
        });
        createArray(`${yTitle}`, subTitleSize, yDirection === 0
            ? selectedGroupBounds.x -
                coordinateSpace -
                subTitleSize -
                maxWidth -
                coordinateLabelSpace
            : selectedGroupBounds.x +
                linerArraysWidth +
                coordinateSpace +
                maxWidth +
                coordinateLabelSpace, selectedGroupBounds.y +
            (1 / 2) * linerArraysHeight +
            (1 / 2) * textWidthMeasure(`${yTitle}`, subTitleSize), 270);
        // 标题
        createArray(`${title}`, subTitleSize, selectedGroupBounds.x +
            (1 / 2) * linerArraysWidth -
            (1 / 2) * textWidthMeasure(`${title}`, subTitleSize), xDirection === 0
            ? originX_y - subTitleSize - coordinateSpace
            : selectedGroupBounds.y - coordinateSpace - subTitleSize);
        [...descriptionArrays.map((item) => item[0])].map(({ id }) => this.descriptionResult.push(id));
        return descriptionArrays;
    }
    createRadialArrays(selectedJson, selectedGroupBounds, params) {
        this.clearTemp(true);
        const { x, y, copies, start, step, rotateSelf } = params;
        const res = new Array();
        for (let i = 1; i < copies + 1; i++) {
            const angle = start + step * i;
            const rotation = (angle * Math.PI) / 180;
            const tempRes = [];
            if (rotateSelf) {
                selectedJson.forEach((ele) => {
                    const cloneJSON = { ...ele };
                    const rect = new Rectangle(cloneJSON.x, cloneJSON.y, cloneJSON.width, cloneJSON.height);
                    const pos = calRectangleRotatedCoord(rect, rotation, { x, y });
                    cloneJSON.x = pos.x;
                    cloneJSON.y = pos.y;
                    cloneJSON.angle += angle;
                    cloneJSON.sourceId = ele.id;
                    cloneJSON.id = uuid();
                    tempRes.push(cloneJSON);
                });
            }
            else {
                const center = {
                    x: selectedGroupBounds.x + selectedGroupBounds.width / 2,
                    y: selectedGroupBounds.y + selectedGroupBounds.height / 2,
                };
                const targetCenter = calPointRotatedCoord(center, rotation, { x, y });
                const offset = {
                    x: targetCenter.x - center.x,
                    y: targetCenter.y - center.y,
                };
                selectedJson.forEach((ele) => {
                    const cloneJSON = { ...ele };
                    cloneJSON.x += offset.x;
                    cloneJSON.y += offset.y;
                    cloneJSON.sourceId = ele.id;
                    cloneJSON.id = uuid();
                    tempRes.push(cloneJSON);
                });
            }
            res.push(tempRes);
        }
        return res;
    }
    /**
     * 添加缓存记录
     */
    appendToTemp(displayObjects) {
        displayObjects.forEach((displayObject) => {
            this.#temp.push(displayObject);
        });
        return displayObjects;
    }
    /**
     * 清空缓存记录，如果 destroy 为true，则在 viewport 内销毁
     */
    clearTemp(destroy) {
        if (destroy) {
            let displayObject = this.#temp.pop();
            while (displayObject) {
                displayObject.destroy(true);
                displayObject = this.#temp.pop();
            }
        }
        else {
            this.#temp = [];
        }
    }
    get temp() {
        return this.#temp;
    }
}
