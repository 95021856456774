import { EventEmitter } from '@pixi/utils';
import { GuideLine } from '../views';
import { BUILD_IN_PLUGIN_TYPE } from './util';
export var GUIDE_LINE_PLUGIN_EVENT;
(function (GUIDE_LINE_PLUGIN_EVENT) {
    GUIDE_LINE_PLUGIN_EVENT["addExtraCompareTarget"] = "addExtraCompareTarget";
    GUIDE_LINE_PLUGIN_EVENT["removeExtraCompareTarget"] = "removeExtraCompareTarget";
    GUIDE_LINE_PLUGIN_EVENT["updateExtraCompareTarget"] = "updateExtraCompareTarget";
})(GUIDE_LINE_PLUGIN_EVENT || (GUIDE_LINE_PLUGIN_EVENT = {}));
const DEFAULT_GUIDE_LINE_CONFIG = {
    unit: 'mm',
    pixelPerUnit: 1,
    fractionDigits: 1,
    enableAdsorb: true,
};
export class GuideLinePlugin extends EventEmitter {
    #guideLine;
    name = 'guideLine';
    type = BUILD_IN_PLUGIN_TYPE;
    constructor(options = DEFAULT_GUIDE_LINE_CONFIG) {
        super();
        this.#guideLine = new GuideLine(options);
    }
    init(viewport) {
        viewport.addChild(this.#guideLine.container);
    }
    destroy() { }
    updateConfig(options) {
        this.#guideLine.updateOptions(options);
    }
    onTransformerSelect(transformer) {
        this.#guideLine.onTransformSelected(transformer);
    }
    onMessage(message) {
        const { eventName, data } = message;
        switch (eventName) {
            case GUIDE_LINE_PLUGIN_EVENT.addExtraCompareTarget:
                this.#guideLine.addExtraCompareTarget(data);
                break;
            case GUIDE_LINE_PLUGIN_EVENT.updateExtraCompareTarget:
                // this.#guideLine.updateExtraCompareTarget();
                break;
            case GUIDE_LINE_PLUGIN_EVENT.removeExtraCompareTarget:
                this.#guideLine.removeExtraCompareTarget(data);
                break;
            default:
                break;
        }
    }
    onTransformerChange() { }
    onTransformerPointerDown(e) {
        this.#guideLine.onPointerdown(e);
    }
    onTransformerPointerUp() {
        this.#guideLine.onPointerup();
    }
    onTransformerPointerMove(e) {
        this.#guideLine.onObjectMoving(e);
    }
    onViewportScale(viewport) {
        this.#guideLine.updateTextScale(viewport.scaled);
    }
}
