import { MapSet } from './MapList';
export function* iterateInteractiveElements(containers) {
    for (const container of containers) {
        yield* container.getInteractiveElements();
    }
}
export function getInteractiveElementMap(...containers) {
    const mapSet = new MapSet();
    for (const element of iterateInteractiveElements(containers)) {
        mapSet.add(element.id, element);
    }
    return mapSet;
}
export function getInteractiveElements(...containers) {
    const elements = [];
    for (const e of iterateInteractiveElements(containers)) {
        elements.push(e);
    }
    return elements;
}
export function getInteractiveElementIDs(...containers) {
    const allIDs = new Set();
    for (const el of iterateInteractiveElements(containers)) {
        allIDs.add(el.id);
    }
    return allIDs;
}
