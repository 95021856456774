// 后续可以移到 @makeblock/image-process
/**
 * 根据灰度值范围设置 ImageData
 *
 * @export
 * @param {Uint8ClampedArray} imageData
 * @param {number} width
 * @param {number} height
 * @param {number[]} grayValue
 * @return {*}
 */
export function applyGrayRanger(imageData, width, height, grayValue) {
    const newImgData = new ImageData(imageData.slice(), width, height);
    const gray = parseGray(newImgData);
    if (grayValue[0] > grayValue[1]) {
        grayValue = grayValue.sort((a, b) => a - b);
    }
    const differ = grayValue[1] - grayValue[0];
    for (let i = 0; i < gray.length; i++) {
        gray[i] =
            differ === 0
                ? grayValue[0]
                : (((gray[i] - grayValue[0]) / differ) * 255) >> 0;
    }
    for (let i = 0; i < gray.length; i++) {
        const idx = i * 4;
        newImgData.data[idx] = gray[i];
        newImgData.data[idx + 1] = gray[i];
        newImgData.data[idx + 2] = gray[i];
    }
    return newImgData;
}
/**
 * 根据清晰度改变 ImageData
 * @param imageData
 * @param width
 * @param height
 * @param radius
 * @returns
 */
export function applySharpness(imageData, width, height, radius) {
    const newImgData = new ImageData(imageData.slice(), width, height);
    const gray = parseGray(newImgData);
    if (radius < 50) {
        radius = Math.abs((50 - radius) / 2);
        gaussBlur(gray, width, height, radius, Math.sqrt(radius));
    }
    else if (radius > 50) {
        radius = Math.abs(radius - 50);
        usm(gray, width, height, radius, 0, 60);
    }
    for (let i = 0; i < gray.length; i++) {
        const idx = i * 4;
        newImgData.data[idx] = gray[i];
        newImgData.data[idx + 1] = newImgData.data[idx];
        newImgData.data[idx + 2] = newImgData.data[idx];
    }
    return newImgData;
}
/**
 * 颜色反相
 *
 * @param imageData
 * @param width
 * @param height
 */
export function applyColorInversion(imageData, width, height) {
    const newImgData = new ImageData(imageData.slice(), width, height);
    for (let i = 0; i < newImgData.data.length; i += 4) {
        newImgData.data[i] = 255 - newImgData.data[i];
        newImgData.data[i + 1] = 255 - newImgData.data[i + 1];
        newImgData.data[i + 2] = 255 - newImgData.data[i + 2];
    }
    return newImgData;
}
/**
 * rgba 通道数据转灰度值
 *
 * @export
 * @param {ImageData} imageData
 * @return {*}
 */
export function parseGray(imageData) {
    const arr = [];
    for (let i = 0; i < imageData.data.length; i += 4) {
        const idx = i;
        const gray = (imageData.data[idx] * 0.299 +
            imageData.data[idx + 1] * 0.587 +
            imageData.data[idx + 2] * 0.114) >>
            0;
        arr.push(gray);
    }
    return arr;
}
/**
 * 高斯模糊
 *
 * @export
 * @param {number[]} imageData
 * @param {number} width
 * @param {number} height
 * @param {number} radius
 * @param {number} sigma
 */
export function gaussBlur(imageData, width, height, radius, sigma) {
    const gaussMatrix = [];
    let gaussSum = 0;
    radius = Math.floor(radius) || 3;
    sigma = sigma || radius / 3;
    const a = 1 / (Math.sqrt(2 * Math.PI) * sigma);
    const b = -1 / (2 * sigma * sigma);
    for (let i = 0, x = -radius; x <= radius; x++, i++) {
        const g = a * Math.exp(b * x * x);
        gaussMatrix[i] = g;
        gaussSum += g;
    }
    for (let i = 0; i < gaussMatrix.length; i++) {
        gaussMatrix[i] /= gaussSum;
    }
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            let r = 0;
            gaussSum = 0;
            for (let j = -radius; j <= radius; j++) {
                const k = x + j;
                if (k >= 0 && k < width) {
                    r += imageData[y * width + k] * gaussMatrix[j + radius];
                    gaussSum += gaussMatrix[j + radius];
                }
            }
            imageData[y * width + x] = Math.floor(r / gaussSum);
        }
    }
    for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
            let r = 0;
            gaussSum = 0;
            for (let j = -radius; j <= radius; j++) {
                const k = y + j;
                if (k >= 0 && k < height) {
                    r += imageData[k * width + x] * gaussMatrix[j + radius];
                    gaussSum += gaussMatrix[j + radius];
                }
            }
            imageData[y * width + x] = Math.floor(r / gaussSum);
        }
    }
}
/**
 * usm 锐化
 *
 * @param {number[]} imageData
 * @param {number} width
 * @param {number} height
 * @param {number} radius
 * @param {number} threshold
 * @param {number} amount
 */
export function usm(imageData, width, height, radius, threshold, amount) {
    const cloneImageData = imageData.slice();
    gaussBlur(cloneImageData, width, height, radius, Math.sqrt(radius));
    for (let i = 0; i < imageData.length; i++) {
        let c = imageData[i] - cloneImageData[i];
        if (c > threshold || c < -threshold) {
            c = imageData[i] + (amount * c) / 100;
            imageData[i] = c;
        }
    }
}
/**
 * 获取 ImageData 点的 RGBA
 *
 * @param {Uint8ClampedArray} imgData
 * @param {number} index
 */
export function getPointRGBA(imgData, index) {
    index *= 4;
    return {
        r: imgData[index],
        g: imgData[index + 1],
        b: imgData[index + 2],
        a: imgData[index + 3],
    };
}
/**
 * 设置 ImageData 对应位置的点透明度为0
 *
 * @param {Uint8ClampedArray} imgData
 * @param {number} index
 */
export function setZeroAlpha(imgData, index) {
    index *= 4;
    imgData[index + 3] = 0;
}
/**
 * 判断两个点的RGB是否相近
 *
 * @param {RGBA} targetPointRGB
 * @param {RGBA} nextPointRGB
 * @param {number} aberration
 */
export function isSimilar(targetPointRGB, nextPointRGB, aberration) {
    if (nextPointRGB.a === 0) {
        return false;
    }
    const dr = targetPointRGB.r - nextPointRGB.r;
    const dg = targetPointRGB.g - nextPointRGB.g;
    const db = targetPointRGB.b - nextPointRGB.b;
    return (-aberration < dr &&
        dr < aberration &&
        -aberration < dg &&
        dg < aberration &&
        -aberration < db &&
        db < aberration);
}
/**
 * 移除 ImageData 中 RGBA 相似的点
 *
 * @param {RGBA} targetPointRGB
 * @param {RGBA} nextPointRGB
 * @param {number} aberration
 */
export function removeRepeatImageData(imgData, width, height, x, y, aberration) {
    const rotations = [
        [-1, 0],
        [1, 0],
        [0, -1],
        [0, 1],
    ];
    const targePointIndex = width * y + x;
    const targetPointRGBA = getPointRGBA(imgData, targePointIndex);
    const willDealPoints = [{ x, y }];
    while (willDealPoints.length > 0) {
        const dealPoint = willDealPoints.pop();
        setZeroAlpha(imgData, width * dealPoint.y + dealPoint.x);
        for (let i = 0; i < rotations.length; i++) {
            const nextDealPointX = dealPoint.x + rotations[i][0];
            const nextDealPointY = dealPoint.y + rotations[i][1];
            const nextPointIndex = width * nextDealPointY + nextDealPointX;
            const nextPointRGBA = getPointRGBA(imgData, nextPointIndex);
            if (nextDealPointX >= 0 &&
                nextDealPointX < width &&
                nextDealPointY >= 0 &&
                nextDealPointY < height &&
                isSimilar(targetPointRGBA, nextPointRGBA, aberration)) {
                willDealPoints.push({ x: nextDealPointX, y: nextDealPointY });
            }
        }
    }
}
/**
 * 模糊
 * @param {*} imageData
 * @param {*} w
 * @param {*} h
 * @param {*} radius
 */
export function applyBlur(imageData, w, h, radius) {
    let sumgray = 0;
    const R = (radius * 2 + 1) * (radius * 2 + 1);
    for (let x = 0; x < w; x++) {
        for (let y = 0; y < h; y++) {
            // Index of the pixel in the array
            const idx = x + y * w;
            for (let subCol = -radius; subCol <= radius; subCol++) {
                let colOff = subCol + x;
                if (colOff < 0 || colOff >= w) {
                    colOff = 0;
                }
                for (let subRow = -radius; subRow <= radius; subRow++) {
                    let rowOff = subRow + y;
                    if (rowOff < 0 || rowOff >= h) {
                        rowOff = 0;
                    }
                    const idx2 = colOff + rowOff * w;
                    const g = imageData[idx2];
                    sumgray += g;
                }
            }
            const ng = sumgray / R;
            sumgray = 0.0;
            imageData[idx] = ng;
        }
    }
}
// 漫画1 2 3
export function applyComic(imageData, width, height, type) {
    const data = imageData;
    const len = data.length;
    let avg = [];
    let blur = [];
    type = type ?? 'comic1';
    if (type === 'comic3') {
        const hist = [];
        for (let i = 0; i < 256; i++) {
            hist[i] = 0;
        }
        for (let i = 0, len = data.length; i < len; i += 4) {
            const gray = (0.299 * data[i] + 0.578 * data[i + 1] + 0.114 * data[i + 2]) >> 0;
            data[i] = data[i + 1] = data[i + 2] = gray;
            if (data[i + 3] === 0) {
                hist[0xff]++;
                continue;
            }
            hist[data[i]]++;
        }
        const lum = [];
        let count = 0;
        const total = len / 4;
        for (let i = 0; i < 256; i++) {
            count += hist[i];
            lum[i] = ((count / total) * 255) >> 0;
        }
        for (let j = 0, len = data.length; j < len; j += 4) {
            data[j] = lum[data[j]] > 80 ? 255 : 0;
            data[j + 1] = data[j];
            data[j + 2] = data[j];
        }
    }
    else if (type === 'comic1') {
        let nindex;
        let index;
        const h = height;
        const w = width;
        for (let i = 0, len = data.length; i < len; i += 4) {
            const gray = (0.299 * data[i] + 0.578 * data[i + 1] + 0.114 * data[i + 2]) >> 0;
            blur.push(gray);
            avg.push(gray);
        }
        applyBlur(blur, width, height, 2);
        usm(avg, w, h, 10, 0, 20);
        for (let i = 0; i < h; i++) {
            for (let j = 0; j < w; j++) {
                nindex = i * w + j;
                index = nindex * 4;
                const v = avg[nindex] - blur[nindex];
                let vv = 127 + v;
                vv = vv < 0 ? 0 : vv > 255 ? 255 : vv;
                vv = vv > 120 ? 255 : 0;
                data[index] = vv;
                data[index + 1] = data[index];
                data[index + 2] = data[index];
                data[index + 3] = data[index + 3];
            }
        }
    }
    else if (type === 'comic2') {
        for (let i = 0, len = data.length; i < len; i += 4) {
            const gray = (0.299 * data[i] + 0.578 * data[i + 1] + 0.114 * data[i + 2]) >> 0;
            blur.push(gray);
            avg.push(gray);
        }
        applyBlur(blur, width, height, 1);
        const lineWidth = 4;
        const R = 4;
        const BlendOverlay = (base, blend) => {
            return base < 127
                ? (2 * (base * blend)) / 255
                : 255 - (2 * (255 - base) * (255 - blend)) / 255;
        };
        let nindex;
        let index;
        const h = height;
        const w = width;
        for (let i = 0; i < h; i++) {
            for (let j = 0; j < w; j++) {
                nindex = i * w + j;
                index = nindex * 4;
                const v = avg[nindex] - blur[nindex];
                let vv = 127 + (Math.abs(v) * v) / (2 * R); // 127+v;//
                vv = vv < 0 ? 0 : vv > 255 ? 255 : vv;
                vv = vv > 124 ? 255 : 0;
                const vvv = BlendOverlay(data[index], vv) >> 0;
                if (Math.abs(data[index] - 90) < 60) {
                    data[index] =
                        Math.min(255, i % lineWidth === j % lineWidth
                            ? vvv / (3 * (Math.random() / 2 + 0.75))
                            : vvv) >> 0;
                }
                else {
                    data[index] = Math.min(255, data[index] <= 30 ? vvv / 4 : vvv) >> 0;
                }
                data[index + 1] = data[index];
                data[index + 2] = data[index];
                data[index + 3] = data[index + 3];
            }
        }
        avg = [];
        blur = [];
    }
    return new ImageData(imageData, width, height);
}
// 二值图
export function applyBinary(imageData, width, height, threshold) {
    threshold = threshold ?? 127;
    for (let i = 0; i < imageData.length; i += 4) {
        const gray = (imageData[i] * 0.299 +
            imageData[i + 1] * 0.587 +
            imageData[i + 2] * 0.114) >>
            0;
        let target = 255;
        if (gray < threshold) {
            target = 0;
        }
        imageData[i] = target;
        imageData[i + 1] = target;
        imageData[i + 2] = target;
    }
    return new ImageData(imageData, width, height);
}
// 素描
export function applySketch(imageData, width, height, params) {
    const { sigma = 10 } = params || {};
    const data = imageData;
    const w = width;
    const h = height;
    let ori = [];
    let blur = [];
    for (let i = 0, len = data.length; i < len; i += 4) {
        const idx = i;
        const gray = (data[idx] * 0.3 + data[idx + 1] * 0.6 + data[idx + 2] * 0.1) >> 0;
        ori.push(gray);
        blur.push(255 - gray);
    }
    gaussBlur(blur, w, h, 8, sigma);
    for (let i = 0, len = ori.length; i < len; i++) {
        const idx = i * 4;
        data[idx] = Math.min(255, Math.max(0, (ori[i] + (ori[i] * blur[i]) / (256 - blur[i])) >> 0));
        data[idx + 1] = data[idx];
        data[idx + 2] = data[idx];
    }
    ori = [];
    blur = [];
    return new ImageData(imageData, width, height);
}
