import { merge } from 'lodash-es';
import { Point } from 'pixi.js';
import { DEFAULT_ELEMENT_LINE_STYLE } from '../config';
import { MGraphic } from './MGraphic';
import { DISPLAY_TYPE } from './type';
const DEFAULT_DIMENSION = 0.001;
const MIN_SIZE = 3;
export class MLine extends MGraphic {
    endPoint = new Point(0, 0);
    constructor(options) {
        super();
        if (options) {
            this.parseJSON(options);
            this.draw();
            this.addLineHitArea(new Point(0, 0), this.endPoint, MIN_SIZE);
        }
        this.type = DISPLAY_TYPE.LINE;
        this.isClosePath = false;
        this.lockRatio = false;
    }
    update(point, e) {
        const { x, y } = this.position;
        let deltaX = point.x - x;
        let deltaY = point.y - y;
        if (e && e.data.originalEvent.shiftKey) {
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                deltaY = 0;
            }
            else {
                deltaX = 0;
            }
        }
        this.endPoint = new Point(deltaX, deltaY);
        this.draw();
    }
    draw() {
        const { x, y } = this.endPoint;
        this.clear()
            .lineStyle(DEFAULT_ELEMENT_LINE_STYLE)
            .moveTo(0, 0)
            .lineTo(x, y)
            .endFill();
        super.draw();
    }
    transformCommit() {
        const { width: originW, height: originH } = this.getLocalBounds();
        const { width, height, scale: { x, y }, } = this;
        if (originW === DEFAULT_DIMENSION) {
            this.width = width / x;
            this.scale.x = 1;
        }
        if (originH === DEFAULT_DIMENSION) {
            this.height = height / y;
            this.scale.y = 1;
        }
        this.draw();
    }
    end(point, e) {
        const { position: { x, y }, } = this;
        const { x: ex, y: ey } = point;
        let deltaX = ex - x;
        let deltaY = ey - y;
        if (e && e.data.originalEvent.shiftKey) {
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                deltaY = 0;
            }
            else {
                deltaX = 0;
            }
        }
        this.endPoint = new Point(deltaX, deltaY);
        this.draw();
        this.toggleFill(this.isFill);
        // 添加 hitArea 使line可触发交互事件
        this.addLineHitArea(new Point(0, 0), this.endPoint, MIN_SIZE);
        this.done();
    }
    toJSON(relativeEle) {
        const { endPoint } = this;
        const baseJSON = super.toJSON(relativeEle);
        return {
            ...baseJSON,
            endPoint: { x: endPoint.x, y: endPoint.y },
        };
    }
    parseJSON(data) {
        const { endPoint = this.endPoint } = data;
        super.parseJSON(data);
        this.endPoint = new Point(endPoint.x, endPoint.y);
    }
    embedLine(element) {
        element.x1.baseVal.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        element.y1.baseVal.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        element.x2.baseVal.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        element.y2.baseVal.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        const x1 = element.x1.baseVal.valueInSpecifiedUnits;
        const y1 = element.y1.baseVal.valueInSpecifiedUnits;
        const x2 = element.x2.baseVal.valueInSpecifiedUnits;
        const y2 = element.y2.baseVal.valueInSpecifiedUnits;
        this.x = x1;
        this.y = y1;
        this.endPoint = new Point(x2 - x1, y2 - y1);
        this.draw();
        this.addLineHitArea(new Point(0, 0), this.endPoint, MIN_SIZE);
    }
    embedPolyline(element) {
        const points = element
            .getAttribute('points')
            .replace(/,/g, ' ')
            .trim()
            .split(/\s+/)
            .map((p) => parseFloat(p));
        const [x1, y1, x2, y2] = points;
        this.x = x1;
        this.y = y1;
        this.endPoint = new Point(x2 - x1, y2 - y1);
        this.draw();
        this.addLineHitArea(new Point(0, 0), this.endPoint, MIN_SIZE);
    }
    // 重写 getLocalBounds 方法，对只有两个顶点且两个顶点在同一水平或垂直情况下的宽高设为默认是1，避免默认为0带来的计算和尺寸显示问题
    getLocalBounds() {
        const localBounds = super.getLocalBounds();
        const { width, height } = localBounds;
        localBounds.width = width || DEFAULT_DIMENSION;
        localBounds.height = height || DEFAULT_DIMENSION;
        return localBounds;
    }
    getSVGData(options) {
        return merge(super.getSVGData(options), {
            tag: 'line',
            attrs: {
                x1: 0,
                y1: 0,
                x2: this.endPoint.x,
                y2: this.endPoint.y,
            },
        });
    }
}
