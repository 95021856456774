export class Session {
    // 第一个 input
    firstInput;
    // 第一个多点 input
    firstMultiple;
    // 上一个 input
    prevInput;
    // 上一个 Scale
    prevScale;
    offsetDelta;
    // 上一个 Delta
    prevDelta;
    // 用于计算 IntervalInputData
    lastInterval;
}
