import { isEmpty, isUndefined, merge } from 'lodash-es';
import { Point } from 'pixi.js';
// import { shiftTransform } from 'src/utils';
import { DEFAULT_ELEMENT_LINE_STYLE } from '../config';
import { hasOneIn } from '../utils/math';
import { MGraphic } from './MGraphic';
import { DISPLAY_TYPE } from './type';
export const SMOOTH_SCALE = 100;
export class MCircle extends MGraphic {
    constructor(options) {
        super();
        if (options) {
            this.parseJSON(options);
        }
        this.type = DISPLAY_TYPE.CIRCLE;
        this.isClosePath = true;
    }
    update(point, e) {
        const { x, y } = this.position;
        const { x: ex, y: ey } = point;
        const width = ex - x;
        let isSquared = false;
        if (e && e.data.originalEvent.shiftKey) {
            isSquared = true;
        }
        const height = isSquared ? width : ey - y;
        this.originWidth = Math.abs(width);
        this.originHeight = Math.abs(height);
        this.draw(new Point(width / 2, height / 2));
    }
    draw(centerPoint = new Point(0, 0)) {
        this.clear().lineStyle(DEFAULT_ELEMENT_LINE_STYLE);
        this.drawEllipse(centerPoint.x, centerPoint.y, this.originWidth / 2, this.originHeight / 2);
        super.draw();
        if (!this.enableFill) {
            this.addHitArea();
        }
    }
    // 圆形元素缩放时 根据比例进行重绘（目的是产生更多的点）
    transformUpdate() {
        const { geometry: { points }, width, height, } = this;
        if (height || width) {
            const pointRadio = points.length / ((Math.abs(width) + Math.abs(height)) * 2);
            if (pointRadio > 0 && pointRadio < 1) {
                this.smooth();
            }
        }
    }
    toJSON(relativeEle) {
        const baseJSON = super.toJSON(relativeEle);
        const { scale } = baseJSON;
        const { width, height } = this.getLocalBounds();
        // toJSON 返回的width与height 预期是相对于 relativeEle的尺寸
        // 所以width height 需要用local的原始尺寸与relativeEle转换得到的scale进行运算
        return {
            ...baseJSON,
            width: scale.x * width,
            height: scale.y * height,
        };
    }
    parseJSON(data) {
        const { width, height, scale } = data;
        // 同时有 width, height, scale 的值的时候（通常是 复制，撤销，重做），需要重新绘制一个相应的图形
        if (!isUndefined(width) && !isUndefined(height) && !isEmpty(scale)) {
            this.originWidth = width / scale.x;
            this.originHeight = height / scale.y;
            this.draw(new Point(this.originWidth / 2, this.originHeight / 2));
        }
        super.parseJSON(data);
        // 设置了宽高后，动态补点
        if (hasOneIn(data, ['height', 'width'])) {
            this.transformUpdate();
        }
    }
    end(point, e) {
        const { position: { x, y }, } = this;
        // 绘制结束之后，根据起点和终点坐标调整到圆形是和 x 正轴和y正轴相切的状态
        const { x: ex, y: ey } = point;
        if (ex < x) {
            this.position.x = ex;
        }
        if (ey < y) {
            this.position.y = ey;
        }
        this.smooth();
        super.end(point, e);
    }
    smooth(scale = SMOOTH_SCALE) {
        let { width, height } = this;
        // 放大宽高重新绘制，通过比例缩小到原始大小，这样可生成更多的点
        this.clear();
        width *= scale;
        height *= scale;
        this.originWidth = Math.abs(width);
        this.originHeight = Math.abs(height);
        this.scale.set(1 / scale);
        this.draw(new Point(width / 2, height / 2));
    }
    embedCircle(element) {
        element.cx?.baseVal?.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        element.cy?.baseVal?.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        element.r?.baseVal?.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
        const cx = element.cx.baseVal.valueInSpecifiedUnits;
        const cy = element.cy.baseVal.valueInSpecifiedUnits;
        const r = element.r.baseVal.valueInSpecifiedUnits;
        this.originWidth = r * 2;
        this.originHeight = r * 2;
        this.position.x = cx - r;
        this.position.y = cy - r;
        this.draw(new Point(r, r));
        this.transform.updateLocalTransform();
    }
    getSVGData(options) {
        const baseData = super.getSVGData(options);
        const rx = this.originWidth / SMOOTH_SCALE / 2;
        const ry = this.originHeight / SMOOTH_SCALE / 2;
        return merge(baseData, {
            tag: this.originWidth === this.originHeight ? 'circle' : 'ellipse',
            attrs: {
                transform: `${baseData.attrs.transform} scale(${SMOOTH_SCALE})`,
                cx: rx,
                cy: ry,
                ...(this.originWidth === this.originHeight ? { r: rx } : { rx, ry }),
            },
        });
    }
}
