import { Transformer } from '@makeblock/transformer';
import { DEG_TO_RAD, Matrix, Point } from 'pixi.js';
import { getNumByFractionPrecision } from './math';
// 这个方法来自 @pixi-essentials/transformer
// 因为要使图形达到相同的旋转效果可以只设置 skew值 也可以只设置 rotation的值，这里默认只作用在 rotation 的值上
export function decomposeTransform(transform, matrix, rotation, pivot = transform.pivot) {
    const a = matrix.a;
    const b = matrix.b;
    const c = matrix.c;
    const d = matrix.d;
    const skewX = -Math.atan2(-c, d);
    const skewY = Math.atan2(b, a);
    rotation = rotation !== undefined && rotation !== null ? rotation : skewY;
    // set pivot
    transform.pivot.set(pivot.x, pivot.y);
    // next set rotation, skew angles
    transform.rotation = rotation;
    transform.skew.x = rotation + skewX;
    transform.skew.y = -rotation + skewY;
    // next set scale
    transform.scale.x = Math.sqrt(a * a + b * b);
    transform.scale.y = Math.sqrt(c * c + d * d);
    // next set position
    transform.position.x = matrix.tx + (pivot.x * matrix.a + pivot.y * matrix.c);
    transform.position.y = matrix.ty + (pivot.x * matrix.b + pivot.y * matrix.d);
    return transform;
}
// 这个方法来自 @pixi-essentials/transformer
export function multiplyTransform(displayObject, transform, skipUpdate) {
    const tempParentMatrix = new Matrix();
    const tempMatrix = new Matrix();
    if (!skipUpdate) {
        const parent = !displayObject.parent
            ? displayObject.enableTempParent()
            : displayObject.parent;
        displayObject.updateTransform();
        displayObject.disableTempParent(parent);
    }
    const worldTransform = displayObject.worldTransform;
    const parentTransform = displayObject.parent
        ? tempParentMatrix.copyFrom(displayObject.parent.worldTransform)
        : Matrix.IDENTITY;
    tempMatrix.copyFrom(worldTransform);
    tempMatrix.prepend(transform);
    tempMatrix.prepend(parentTransform.invert()); // gets new "local" transform
    decomposeTransform(displayObject.transform, tempMatrix);
}
// matrixResult =     matrixB * matrixC
export function multiMatrix(matrixB, matrixC) {
    return matrixB.clone().append(matrixC.clone());
}
// matrixResult =  matrixB * matrixC
// 已知 matrixResult 和 matrixB 或 matrixC 中的一项，求另一项
export function divisionMatrix(matrixResult, multiplier) {
    const invertMatrix = multiplier.clone().invert();
    return matrixResult.clone().prepend(invertMatrix);
}
export function convertBounds(target, bounds) {
    const min = target.toLocal(new Point(bounds.minX, bounds.minY));
    const max = target.toLocal(new Point(bounds.maxX, bounds.maxY));
    bounds.minX = min.x;
    bounds.minY = min.y;
    bounds.maxX = max.x;
    bounds.maxY = max.y;
    return bounds;
}
/**
 * 获取pixi Matrix的数组形式[a,b,c,d,tx,ty]
 * @param mat 输入Matrix
 * @param [options]
 * @param [options.relMat] 相对Matrix。指定此值时，输入Matrix会转换为基于相对Matrix的本地数值
 * @param [options.fractionPrecision] Matrix元素值的小数部分有效数字位数
 */
export function getMatrixArray(mat, options = {}) {
    let matrix = mat;
    if (options.relMat) {
        matrix = mat.clone().prepend(options.relMat.clone().invert());
    }
    let matArr = [matrix.a, matrix.b, matrix.c, matrix.d, matrix.tx, matrix.ty];
    const { fractionPrecision: precision } = options;
    if (isFinite(precision)) {
        matArr = matArr.map((v) => getNumByFractionPrecision(v, precision));
    }
    return matArr;
}
export function prependTransform(group, delta) {
    for (let i = 0, j = group.length; i < j; i++) {
        multiplyTransform(group[i], delta, false);
    }
}
export function rotateMatrix(group, angle) {
    const groupBounds = Transformer.calculateGroupOrientedBounds(group, 0);
    const matrix = Matrix.IDENTITY;
    const center = groupBounds.center;
    matrix
        .translate(-center.x, -center.y)
        .rotate(angle * DEG_TO_RAD - groupBounds.rotation)
        .translate(center.x, center.y);
    return matrix;
}
/**
 * 获取相对matrix, relativeObj缺省时表示相对global
 * @param obj
 * @param [relativeObj]
 * @returns
 */
export function getRelativeMatrix(obj, relativeObj) {
    const matrix = !relativeObj
        ? obj.worldTransform.clone()
        : relativeObj === obj.parent
            ? obj.localTransform.clone()
            : relativeObj !== obj
                ? divisionMatrix(obj.worldTransform.clone(), relativeObj.worldTransform.clone())
                : undefined;
    return matrix;
}
