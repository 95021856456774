/**
 * 适应尺寸
 */
export function fitSize(source, target) {
    let size = { width: source.width, height: source.height };
    if (isFinite(target.width) || isFinite(target.height)) {
        size = fitToSize(size, {
            width: target.width,
            height: target.height,
            fitMode: target.fitMode,
        });
    }
    if (isFinite(target.minWidth) ||
        isFinite(target.minHeight) ||
        isFinite(target.maxWidth) ||
        isFinite(target.maxHeight)) {
        size = fitToMinMaxSize(size, target);
    }
    return size;
}
/**
 * 适应到指定尺寸，可选contain或cover模式
 */
export function fitToSize(source, target) {
    const { width: srcWidth, height: srcHeight } = source;
    let { width: targetWidth, height: targetHeight } = target;
    const { fitMode = 'contain' } = target;
    // 没有指定宽度和高度
    if (!isFinite(targetWidth) && !isFinite(targetHeight)) {
        targetWidth = srcWidth;
        targetHeight = srcHeight;
    }
    else if (!isFinite(targetWidth) && isFinite(targetHeight)) {
        // 只指定了高度
        if (srcHeight === 0) {
            // 如果原始高度为0（比如水平直线），则目标高度也为0，宽度不变
            targetHeight = 0;
            targetWidth = srcWidth;
        }
        else {
            targetWidth = (srcWidth / srcHeight) * targetHeight;
        }
    }
    else if (!isFinite(targetHeight) && isFinite(targetWidth)) {
        // 只指定了宽度
        if (srcWidth === 0) {
            // 如果原始宽度为0（比如垂直直线），则目标宽度也为0，高度不变
            targetWidth = 0;
            targetHeight = srcHeight;
        }
        else {
            targetHeight = (srcHeight / srcWidth) * targetWidth;
        }
    }
    else {
        // 同时指定了宽度和高度
        const ratio = fitMode === 'contain'
            ? Math.min(targetWidth / srcWidth, targetHeight / srcHeight)
            : Math.max(srcWidth !== 0 ? targetWidth / srcWidth : 0, srcHeight !== 0 ? targetHeight / srcHeight : 0);
        targetWidth = srcWidth * ratio;
        targetHeight = srcHeight * ratio;
    }
    return { width: targetWidth, height: targetHeight };
}
/**
 * 适应到指定最小最大尺寸
 */
export function fitToMinMaxSize(source, target) {
    let { minWidth, minHeight, maxWidth, maxHeight } = target;
    if (isFinite(minWidth) && isFinite(maxWidth)) {
        if (minWidth > maxWidth) {
            [minWidth, maxWidth] = [maxWidth, minWidth];
        }
    }
    if (isFinite(minHeight) && isFinite(maxHeight)) {
        if (minHeight > maxHeight) {
            [minHeight, maxHeight] = [maxWidth, minHeight];
        }
    }
    let size = { width: source.width, height: source.height };
    // 适应min尺寸
    if (size.width < minWidth || size.height < minHeight) {
        size = fitToSize(size, {
            width: minWidth,
            height: minHeight,
            fitMode: 'cover',
        });
    }
    // 适应max尺寸
    if (size.width > maxWidth || size.height > maxHeight) {
        size = fitToSize(size, {
            width: maxWidth,
            height: maxHeight,
            fitMode: 'contain',
        });
    }
    return size;
}
