import { removeRepeatImageData } from '../../utils/imageProcess';
class ImageProcess {
    #options;
    setOptions(options) {
        this.#options = options;
    }
    clip(points) {
        const { canvas, ctx } = this.#options;
        const { minLocalPoint, rtLocalPoint, maxLocalPoint, lbLocalPoint } = points;
        // 创建裁剪画布
        const clipCanvas = document.createElement('canvas');
        clipCanvas.width = canvas.width;
        clipCanvas.height = canvas.height;
        const clipCtx = clipCanvas.getContext('2d');
        clipCtx.beginPath();
        clipCtx.moveTo(minLocalPoint.x, minLocalPoint.y);
        clipCtx.lineTo(lbLocalPoint.x, lbLocalPoint.y);
        clipCtx.lineTo(maxLocalPoint.x, maxLocalPoint.y);
        clipCtx.lineTo(rtLocalPoint.x, rtLocalPoint.y);
        clipCtx.clip();
        clipCtx.drawImage(canvas, 0, 0);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // if (displayAngle % 360 === 0) {
        canvas.width = maxLocalPoint.x - minLocalPoint.x;
        canvas.height = maxLocalPoint.y - minLocalPoint.y;
        ctx.drawImage(clipCanvas, Math.round(minLocalPoint.x), Math.round(minLocalPoint.y), Math.floor(maxLocalPoint.x - minLocalPoint.x), Math.floor(maxLocalPoint.y - minLocalPoint.y), 0, 0, canvas.width, canvas.height);
        // } else if (displayAngle % 360 === 90) {
        //   canvas.width = lbLocalPoint.x - rtLocalPoint.x;
        //   canvas.height = lbLocalPoint.y - rtLocalPoint.y;
        //   ctx.drawImage(
        //     clipCanvas,
        //     rtLocalPoint.x,
        //     rtLocalPoint.y,
        //     lbLocalPoint.x - rtLocalPoint.x,
        //     lbLocalPoint.y - rtLocalPoint.y,
        //     0,
        //     0,
        //     lbLocalPoint.x - rtLocalPoint.x,
        //     lbLocalPoint.y - rtLocalPoint.y,
        //   );
        // } else {
        //   ctx.drawImage(clipCanvas, 0, 0);
        // }
    }
    erasing(options) {
        const { ctx } = this.#options;
        const { radius, isCurveTo, beginPoint, controlPoint, endPoint } = options;
        ctx.globalCompositeOperation = 'destination-out';
        ctx.lineJoin = ctx.lineCap = 'round';
        ctx.lineWidth = radius;
        ctx.beginPath();
        ctx.moveTo(beginPoint.x, beginPoint.y);
        if (isCurveTo) {
            ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, endPoint.x, endPoint.y);
        }
        else {
            // 添加0.01偏移是为修复移动端单击无法进行擦除的问题
            ctx.lineTo(beginPoint.x + 0.01, beginPoint.y + 0.01);
        }
        ctx.closePath();
        ctx.stroke();
        ctx.globalCompositeOperation = 'source-over';
    }
    magicSelect(point, aberration) {
        const { canvas, ctx } = this.#options;
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        removeRepeatImageData(imageData.data, imageData.width, imageData.height, Number(point.x.toFixed(0)), Number(point.y.toFixed(0)), aberration);
        ctx.putImageData(imageData, 0, 0);
    }
}
export default ImageProcess;
