function stringifyValues(values, chunkNum, separator1, separator2 = '') {
    if (!values.length) {
        return '';
    }
    const chunks = [];
    if (Array.isArray(chunkNum)
        ? chunkNum.length > 0
        : isFinite(chunkNum) && chunkNum > 0) {
        let i = 0;
        if (Array.isArray(chunkNum)) {
            for (const num of chunkNum) {
                chunks.push(values.slice(i, (i += num)).join(separator1));
            }
        }
        else {
            while (i < values.length) {
                chunks.push(values.slice(i, (i += chunkNum)).join(separator1));
            }
        }
        if (i < values.length) {
            chunks.push(values.slice(i).join(separator1));
        }
    }
    else {
        chunks.push(values.join(separator1));
    }
    return chunks.join(separator2);
}
export class PathData {
    commands = [];
    toString(options) {
        const { cmdSeparator = '', ptSeparator = ',' } = options ?? {};
        let str = '';
        for (const { type, values = [] } of this.commands) {
            if (str) {
                str += cmdSeparator;
            }
            switch (type) {
                case 'M':
                case 'L':
                case 'Q':
                case 'C':
                case 'Z':
                    str += `${type}${stringifyValues(values, 2, ptSeparator, ' ')}`;
                    break;
                case 'A':
                    str += `${type}${stringifyValues(values, [2, 3, 2], ptSeparator, ' ')}`;
                    break;
                default:
                    break;
            }
        }
        return str;
    }
    moveTo(x, y) {
        this.commands.push({ type: 'M', values: [x, y] });
    }
    lineTo(x, y) {
        this.commands.push({ type: 'L', values: [x, y] });
    }
    closePath() {
        this.commands.push({ type: 'Z' });
    }
    quadraticCurveTo(cpx, cpy, x, y) {
        this.commands.push({ type: 'Q', values: [cpx, cpy, x, y] });
    }
    bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y) {
        this.commands.push({ type: 'C', values: [cp1x, cp1y, cp2x, cp2y, x, y] });
    }
    ellipticalArcTo(rx, ry, rotation, largeArc, clockwise, x, y) {
        this.commands.push({
            type: 'A',
            values: [rx, ry, rotation, largeArc, clockwise, x, y],
        });
    }
    rect(x, y, w, h) {
        this.moveTo(x, y);
        this.lineTo(x + w, y);
        this.lineTo(x + w, y + h);
        this.lineTo(x, y + h);
    }
    roundRect(x, y, w, h, 
    // radii?: number | DOMPointInit | (number | DOMPointInit)[],
    r) {
        const [rx, ry] = Array.isArray(r) ? r : [r, r];
        drawRoundedRect(this, x, y, w, h, rx, ry);
    }
    circle(cx, cy, r) {
        return this.ellipse(cx, cy, r, r);
    }
    ellipse(cx, cy, rx, ry) {
        this.moveTo(cx - rx, cy);
        this.ellipticalArcTo(rx, ry, 0, 1, 1, cx + rx, cy);
        this.ellipticalArcTo(rx, ry, 0, 1, 1, cx - rx, cy);
    }
    // @TODO
    arcTo() { }
    arc() { }
    ellipseArc() { }
}
export function drawRoundedRect(path, x, y, width, height, rx, ry) {
    // 参考了 fabric.js/src/shapes/rect.class.js 的实现
    /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
    const k = 1 - 0.5522847498;
    path.moveTo(x + rx, y);
    path.lineTo(x + width - rx, y);
    path.bezierCurveTo(x + width - k * rx, y, x + width, y + k * ry, x + width, y + ry);
    path.lineTo(x + width, y + height - ry);
    path.bezierCurveTo(x + width, y + height - k * ry, x + width - k * rx, y + height, x + width - rx, y + height);
    path.lineTo(x + rx, y + height);
    path.bezierCurveTo(x + k * rx, y + height, x, y + height - k * ry, x, y + height - ry);
    path.lineTo(x, y + ry);
    path.bezierCurveTo(x, y + k * ry, x + k * rx, y, x + rx, y);
    path.closePath();
}
