import { Transformer } from '@makeblock/transformer';
import { Rectangle } from '@pixi/math';
import { isArray, isEqualWith, isFunction, isNumber } from 'lodash-es';
import { Bounds, Container, DEG_TO_RAD, Matrix, Point, Polygon, Sprite, } from 'pixi.js';
import { v4 as uuid } from 'uuid';
import { BITMAP_RENDER_CONFIGS, DISPLAY_TYPE, getDisplayBoundsForMaskedSprite, } from '../display';
import GlobalConfigManager from '../models/GlobalConfigManager';
import { previewElement } from './debug';
import { fitSize } from './fit';
import { divisionMatrix, multiplyTransform } from './matrix';
import { points2Paths } from './pathPoint';
import { boundsAddRectangle, rectTransform } from './rect';
export * from './MapList';
export * from './accessGraphicsCurvesSetting';
export * from './bounds';
export * from './debug';
export * from './fit';
export * from './geometry';
export * from './imageSource';
export * from './matrix';
export * from './rect';
/**
 * 迁移变换
 */
export function shiftTransform(target, origin) {
    const x = origin.position.x;
    const y = origin.position.y;
    const scaleX = origin.skew.x;
    const scaleY = origin.skew.y;
    const rotation = origin.rotation;
    const skewX = origin.skew.x;
    const skewY = origin.skew.y;
    const pivotX = origin.pivot.x;
    const pivotY = origin.pivot.y;
    target.setTransform(x, y, scaleX, scaleY, rotation, skewX, skewY, pivotX, pivotY);
}
/** TODO: 优化这个方法到viewport中
 * 深度销毁节点, 节点的父节点只有一个子节点时层层销毁
 * @param target
 */
export function deepDestroy(target) {
    const deepDestroy = (child) => {
        if (child.parent) {
            if (child.parent.children.length <= 1) {
                const parent = child.parent;
                child.destroy(true);
                deepDestroy(parent);
            }
            else {
                child.destroy(true);
            }
        }
        else {
            child.destroy(true);
        }
    };
    deepDestroy(target);
}
export function getSelectedElements(start, end, elements) {
    const { x, y } = start;
    const { x: ex, y: ey } = end;
    const movementX = ex - x;
    const movementY = ey - y;
    const delta = 5;
    let selected = [];
    // 鼠标移动小于 delta 值就认为是单击
    if (Math.abs(movementX) < delta || Math.abs(movementY) < delta) {
        // 单击时 取区域内的顶层元素
        const sort = elements.sort((a, b) => b.displayOrder - a.displayOrder);
        selected = [sort[0]];
    }
    else {
        selected = elements;
    }
    return selected;
}
export function updateDisplaySize(display, width, height) {
    const { width: displayWidth, height: displayHeight, lockRatio } = display;
    if (isNumber(width) && isNumber(height)) {
        display.width = width;
        display.height = height;
        return;
    }
    const ratio = displayHeight / displayWidth;
    if (width) {
        display.width = width;
        if (lockRatio) {
            display.height = ratio * width;
        }
    }
    if (height) {
        display.height = height;
        if (lockRatio) {
            display.width = height / ratio;
        }
    }
}
export function updateDisplayAngle(display, angle) {
    if (angle || angle === 0) {
        const bounds = Transformer.calculateOrientedBounds(display);
        const matrix = Matrix.IDENTITY;
        const center = bounds.center;
        matrix
            .translate(-center.x, -center.y)
            .rotate(angle * DEG_TO_RAD)
            .translate(center.x, center.y);
        multiplyTransform(display, matrix);
    }
}
/**
 *
 * @param display
 * @param point
 * @param relativeEle  point 相对的元素
 */
export function updateDisplayPosition(display, position, relativeEle) {
    const matrix = Matrix.IDENTITY;
    const { x, y } = display.getPointToRelativeElement(relativeEle);
    const globalTargetPoint = relativeEle.toGlobal(position);
    const globalLastPoint = relativeEle.toGlobal(new Point(x, y));
    matrix.translate(globalTargetPoint.x - globalLastPoint.x, globalTargetPoint.y - globalLastPoint.y);
    multiplyTransform(display, matrix);
}
export function updateDisplayAttributes(display, attr, relativeEle) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { matrixByViewport, ...rest } = attr;
    // 当同时设置多个属性的时候，先设置除尺寸坐标之外的数据，因为可能这里的数据（例如text的文本长度）会引发尺寸坐标的变化
    // 下一步才是将最终渲染的内容，设置在传入的尺寸坐标内
    display.parseJSON(rest);
    if (matrixByViewport && display.parent) {
        const vwt = relativeEle.worldTransform.clone();
        const pwt = display.parent.worldTransform.clone();
        const parentMatrixByViewport = divisionMatrix(pwt, vwt);
        const matrixByParent = divisionMatrix(matrixByViewport.clone(), parentMatrixByViewport.clone());
        display.transform.setFromMatrix(matrixByParent);
    }
}
export function getDisplayImageData(canvasExtract, display) {
    if (display.type !== DISPLAY_TYPE.BITMAP) {
        return getDisplayRenderData(canvasExtract, display).imageData;
    }
    else {
        return getImageOriginData(display);
    }
}
export function imageDataToCanvas(imageData) {
    const canvas = document.createElement('canvas');
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const ctx = canvas.getContext('2d');
    ctx.putImageData(imageData, 0, 0);
    return canvas;
}
export function getDisplayDPath(display, relativeEle) {
    const isClose = display.isClosePath;
    let dPath = '';
    if (isFunction(display.getRealPath)) {
        dPath = display.getRealPath(relativeEle);
    }
    else {
        dPath = points2Paths(display.getRealPoints(relativeEle), 1, false, isClose);
    }
    return dPath;
}
export function imgToBase64(img) {
    if (img.getContext) {
        return img.toDataURL('image/png', 1);
    }
    else {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        canvas.getContext('2d').drawImage(img, 0, 0);
        return canvas.toDataURL('image/png', 1);
    }
}
export function imageDataToBase64(imageData, cutFrame) {
    const canvas = document.createElement('canvas');
    if (!cutFrame) {
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        canvas.getContext('2d')?.putImageData(imageData, 0, 0);
    }
    else {
        canvas.width = cutFrame.width;
        canvas.height = cutFrame.height;
        canvas.getContext('2d')?.putImageData(imageData, -cutFrame.x, -cutFrame.y);
    }
    return canvas.toDataURL('image/png', 1);
}
/**
 * 获取显示对象的在画布上的渲染数据
 */
export function getDisplayRenderData(canvasExtract, display, scaleNum = 1, padding, cutFrame, maxTextureSize) {
    // 需要外层有一层container才能导出缩放之后的对象的数据
    // 所以先放在临时container中，再加回原来容器中
    const parent = display.parent;
    const lastVisible = display.visible;
    const tempContainer = new Container();
    // 添加一个缩放的容器是为了将缩放作用在容器上而不作用在元素上, 这样需要放大或缩小元素进行截图时，不再需要经历容器内元素逐个遍历clone的过程，提升获取截图数据的效率
    const scaleContainer = new Container();
    scaleContainer.scale.set(scaleNum, scaleNum);
    tempContainer.addChild(scaleContainer);
    scaleContainer.addChild(display);
    // 确保对象的 visible 属性
    display.visible = true;
    const localBounds = tempContainer.getLocalBounds();
    let canvas = document.createElement('canvas');
    let imageDataScale = 1;
    // 因为在canvasExtract.canvas 中对 localBounds < 0.5 的值会 四舍五入， 导致getImageData 传入 width 为0 报错；
    // 所以给内容为空的 display 默认创建一个默认的canvas
    // 意味着 无法单独获取到一条水平或垂直的直线的截图
    if (localBounds.width < 0.5 || localBounds.height < 0.5) {
        canvas.width = 1;
        canvas.height = 1;
    }
    else {
        // padding的设置是为了解决 当元素的宽高和尺寸是小数的时候，由于四舍五入导致获取的截图不全的问题
        // 默认值为0，传入padding会影响得到的位图数据的精度，对精度要求高的（例如用来加工的数据）要慎重设值
        padding = padding ?? 0;
        const { x, y, width, height } = tempContainer.getLocalBounds();
        const frame = new Rectangle(x - padding, y - padding, width + padding * 2, height + padding * 2);
        // 带蒙版位图的裁剪
        if (!cutFrame && display.mask && display instanceof Sprite) {
            const clippedBounds = getDisplayBoundsForMaskedSprite(display, tempContainer);
            cutFrame = new Rectangle(clippedBounds.x - x, clippedBounds.y - y, clippedBounds.width + padding * 2, clippedBounds.height + padding * 2);
        }
        /*
          Note:
          1. 此处canvasExtract.renderer实际就是当前App所用的renderer，目前一般是WebGL renderer，
          在initApp()执行初始化时指定了resolution等于window.devicePixelRatio。
          2. canvasExtract.renderer.generateTexture()没有使用renderer.resolution作为默认值，
          其默认值是settings.RESOLUTION，默认为1。
          3. 此处canvasExtract.canvas()只在renderTexture为空的情况下会使用renderer.resolution，
          可以认为通常情况下也是1。
        */
        const extractRenderCanvas = (display, region, extractFrame) => {
            const renderTexture = canvasExtract.renderer.generateTexture(display, {
                region,
            });
            const canvas = canvasExtract.canvas(renderTexture, extractFrame);
            renderTexture.destroy(true);
            return canvas;
        };
        // 对于修正尺寸过大，如果resolution使用大于1的值，会导致当前的外轮廓功能输出的图形大小不正确。
        // 对于修正尺寸过小，resolution使用1可以覆盖resolution大于1的情况。综合考虑后，这里resolution取1。
        const resolution = 1;
        // 修正尺寸过大
        maxTextureSize = maxTextureSize ?? getBitmapMaxSize();
        if (frame.width * resolution > maxTextureSize ||
            frame.height * resolution > maxTextureSize) {
            const newSize = fitSize(frame, {
                width: maxTextureSize,
                height: maxTextureSize,
            });
            imageDataScale = newSize.width / frame.width;
        }
        // 修正尺寸过小
        const smallerFrame = cutFrame ?? frame;
        if (smallerFrame.width * resolution < 1 ||
            smallerFrame.height * resolution < 1) {
            const newSize = fitSize(smallerFrame, {
                width: 1,
                height: 1,
                fitMode: 'cover',
            });
            imageDataScale = newSize.width / smallerFrame.width;
        }
        // 附加修正缩放
        if (imageDataScale !== 1) {
            tempContainer.width *= imageDataScale;
            tempContainer.height *= imageDataScale;
            const matrix = new Matrix().scale(imageDataScale, imageDataScale);
            rectTransform(frame, matrix);
            if (cutFrame) {
                rectTransform(cutFrame, matrix);
            }
        }
        canvas = extractRenderCanvas(tempContainer, frame, cutFrame);
    }
    if (parent) {
        parent.addChild(display);
    }
    display.visible = lastVisible;
    if (window.canvas_debug.previewImage) {
        previewElement(canvas);
    }
    const ctx = canvas.getContext('2d');
    const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
    return { imageData, imageDataScale };
}
export function createSpriteCanvas(sprite) {
    const { texture: { baseTexture: { resource }, }, } = sprite;
    const baseSource = resource;
    if (!baseSource.source) {
        return null;
    }
    const img = baseSource.source;
    if (img.getContext) {
        const ctx = img.getContext('2d');
        return { canvas: img, ctx };
    }
    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth || 0;
    canvas.height = img.naturalHeight || 0;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }
    ctx.drawImage(img, 0, 0);
    return { canvas, ctx };
}
// 从图片以指定宽高创建 canvas
export function createFitSpriteCanvas(sprite, width, height) {
    const { texture: { baseTexture: { resource }, }, } = sprite;
    const baseSource = resource;
    if (!baseSource.source) {
        return null;
    }
    const img = baseSource.source;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }
    ctx.drawImage(img, 0, 0, width, height);
    return { canvas, ctx };
}
export function getDisplaysBounds(displays, relativeEle) {
    const groupBounds = Transformer.calculateGroupOrientedBounds(displays, 0);
    const bounds = new Bounds();
    const topLeft = relativeEle.toLocal(groupBounds.topLeft);
    const bottomRight = relativeEle.toLocal(groupBounds.bottomRight);
    bounds.minX = topLeft.x;
    bounds.minY = topLeft.y;
    bounds.maxX = bottomRight.x;
    bounds.maxY = bottomRight.y;
    return bounds;
}
export function calculateBoundsDemisionChanged(viewport, group, width, height, lockAspectRatio) {
    const bounds = new Bounds();
    if (!isNumber(width) && !isNumber(height)) {
        return bounds;
    }
    const { minX, maxX, minY, maxY } = getDisplaysBounds(group, viewport);
    const oWidth = maxX - minX;
    const oHeight = maxY - minY;
    const ratio = oHeight / oWidth;
    let targetWidth = isNumber(width) ? width : oWidth;
    let targetHeight = isNumber(height) ? height : oHeight;
    if (lockAspectRatio) {
        if (width) {
            targetHeight = targetWidth * ratio;
        }
        if (height) {
            targetWidth = targetHeight / ratio;
        }
    }
    const deltaX = targetWidth - oWidth;
    const deltaY = targetHeight - oHeight;
    let targetPosition = new Point(maxX + deltaX, maxY + deltaY);
    targetPosition = viewport.toGlobal(targetPosition);
    bounds.minX = minX;
    bounds.minY = minY;
    bounds.maxX = targetPosition.x;
    bounds.maxY = targetPosition.y;
    return bounds;
}
export function groupTransferOffset(viewport, group, options) {
    const groupBounds = Transformer.calculateGroupOrientedBounds(group, 0);
    const { topLeft: { x: ox, y: oy }, } = groupBounds;
    // 没有值时，默认给当前元素的坐标
    const { x: lx, y: ly } = viewport.toLocal({ x: ox, y: oy });
    const { x = lx, y = ly } = options;
    const { x: nx, y: ny } = viewport.toGlobal({ x, y });
    const delta = new Point(nx - ox, ny - oy);
    return delta;
}
/**
 * 获取display的bounds, 相对对象缺省时表示获取global的bounds
 * @param display
 * @param [relativeEle]
 */
export function getDisplayBounds(display, relativeEle) {
    // 带有蒙版的位图
    if (display instanceof Sprite && display.mask) {
        return boundsAddRectangle(new Bounds(), getDisplayBoundsForMaskedSprite(display, relativeEle));
    }
    if (!relativeEle) {
        return boundsAddRectangle(new Bounds(), display.getBounds());
    }
    // 获取单个元素的边界的算法和选中的算法保持一致，确保选中元素数据和输出的数据保持一致
    return getDisplaysBounds([display], relativeEle);
}
/**
 * 图像源转canvas
 */
export function imageSourceToCanvas(img) {
    let canvas;
    if (!(img instanceof HTMLCanvasElement)) {
        canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth ?? img.width;
        canvas.height = img.naturalHeight ?? img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
    }
    else {
        canvas = img;
    }
    return canvas;
}
/**
 * 获取图片的原始数据
 */
export function getImageOriginData(sprite) {
    const result = createSpriteCanvas(sprite);
    if (!result) {
        return null;
    }
    const { canvas, ctx } = result;
    return ctx.getImageData(0, 0, canvas.width || 1, canvas.height || 1);
}
/**
 * 获取图片的原始 Base64 数据
 */
export function getImageOriginBase64(sprite, 
// 绘制时候的缩放参数
options) {
    const result = createSpriteCanvas(sprite);
    if (!result) {
        return null;
    }
    const { canvas } = result;
    return canvas.toDataURL(options.format);
}
export function recurseInteractiveDisplay(container) {
    const interactiveFilter = (display) => {
        return display.interactive;
    };
    const displays = recurseDisplay(container, interactiveFilter);
    return displays;
}
export function recurseInvaildDisplay(container) {
    const displays = recurseInteractiveDisplay(container);
    const { validDisplays } = destroyInvaildDisplay(displays);
    return validDisplays;
}
export function recurseDisplay(container, filter) {
    const displays = [];
    const findChild = (obj) => {
        const { children = [] } = obj;
        if (filter && filter(obj)) {
            displays.push(obj);
            return;
        }
        if (children && children.length > 0) {
            children.forEach((child) => {
                findChild(child);
            });
        }
        else {
            if (filter) {
                if (filter(obj)) {
                    displays.push(obj);
                }
            }
            else {
                displays.push(obj);
            }
        }
    };
    findChild(container);
    return displays;
}
export function updateGlobalConfigToDisplay(display) {
    const enableFill = GlobalConfigManager.getInstance().enableFill;
    display.parseJSON({
        enableFill,
    });
}
export function getDisplayJSONMap(displayJSONs) {
    const map = new Map();
    displayJSONs.forEach((data) => {
        const { id } = data;
        map.set(id, data);
    });
    return map;
}
export function isEqualDisplayJSONs(value, other) {
    if (value === other) {
        return true;
    }
    if ((value === undefined && other !== undefined) ||
        (value !== undefined && other === undefined)) {
        return false;
    }
    if (value.length !== other.length) {
        return false;
    }
    for (let index = 0; index < value.length; index++) {
        const v = value[index];
        const o = other[index];
        if (!isEqualDisplayJSON(v, o)) {
            return false;
        }
    }
    return true;
}
const JSONNumberThreshold = 0.01;
export function isEqualDisplayJSON(value, other) {
    if ((value.id !== value.id || value.type) !== other.type ||
        value.lockRatio !== other.lockRatio) {
        return false;
    }
    function isNoEqual(v, o) {
        const dis = Math.abs(v - o);
        return dis >= JSONNumberThreshold;
    }
    function customizer(v, o) {
        if (isNoEqual(v.x, o.x) ||
            isNoEqual(v.y, o.y) ||
            isNoEqual(v.width, o.width) ||
            isNoEqual(v.height, o.height) ||
            isNoEqual(v.angle, o.angle) ||
            isNoEqual(v.scale.x, o.scale.x) ||
            isNoEqual(v.scale.y, o.scale.y) ||
            isNoEqual(v.skew.x, o.skew.x) ||
            isNoEqual(v.skew.y, o.skew.y) ||
            isNoEqual(v.pivot.x, o.pivot.x) ||
            isNoEqual(v.pivot.y, o.pivot.y) ||
            isNoEqual(v.offsetX, o.offsetX) ||
            isNoEqual(v.offsetY, o.offsetY)) {
            return false;
        }
        return true;
    }
    return isEqualWith(value, other, customizer);
}
// TODO: 测试可用性
// 来源 https://www.delftstack.com/howto/typescript/typescript-cloning-an-object/
export function deepCopy(instance) {
    if (instance === null) {
        return instance;
    }
    // handle Dates
    if (instance instanceof Date) {
        return new Date(instance.getTime());
    }
    // handle Array types
    if (instance instanceof Array) {
        const cloneArr = [];
        instance.forEach((value) => {
            cloneArr.push(value);
        });
        // for nested objects
        return cloneArr.map((value) => deepCopy(value));
    }
    // handle objects
    if (instance instanceof Object) {
        const copyInstance = { ...instance };
        for (const attr in instance) {
            if (instance.hasOwnProperty(attr)) {
                copyInstance[attr] = deepCopy(instance[attr]);
            }
        }
        return copyInstance;
    }
    // handling primitive data types
    return instance;
}
export function calcAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {
        width: Math.min(srcWidth * ratio, srcWidth),
        height: Math.min(srcHeight * ratio, srcHeight),
    };
}
export function fixScaleNum(data) {
    const { srcWidth, srcHeight, scaleNum, maxWidth, maxHeight } = data;
    if (!isFinite(maxWidth) || !isFinite(maxHeight)) {
        return scaleNum;
    }
    const { width, height } = calcAspectRatioFit(srcWidth * scaleNum, srcHeight * scaleNum, maxWidth, maxHeight);
    const newScaleNum = Math.min(width / srcWidth, height / srcHeight);
    return isFinite(newScaleNum) ? newScaleNum : scaleNum;
}
export function filterInvaildDisplay(displays) {
    const validDisplays = [];
    const invalidDisplays = displays.filter((display) => {
        const invalid = display.isVaild && !display.isVaild();
        if (!invalid) {
            validDisplays.push(display);
        }
        return invalid;
    });
    return { validDisplays, invalidDisplays };
}
export function destroyInvaildDisplay(displays) {
    // const validDisplays: MDisplayObject[] = [];
    // const invalidDisplays = displays.filter((display) => {
    //   const invalid = display.isVaild && !display.isVaild();
    //   if (!invalid) {
    //     validDisplays.push(display);
    //   }
    //   return invalid;
    // });
    const { validDisplays, invalidDisplays } = filterInvaildDisplay(displays);
    const invalidData = [];
    invalidDisplays.forEach((display) => {
        invalidData.push({ id: display.id });
        display.destroy();
        display = null;
    });
    return { validDisplays, invalidData };
}
export function createGroupUUID() {
    const id = 'g-' + uuid();
    return id;
}
export function setGroupTag(selected) {
    const groupID = createGroupUUID();
    selected.forEach((el) => {
        el.groupTag = groupID;
    });
    return selected;
}
// 规范选择对象，同一组的认为是一个对象
export function formatSelected(selected) {
    const collection = [];
    const group = {};
    selected.forEach((el) => {
        if (el.groupTag) {
            if (!group[el.groupTag]) {
                group[el.groupTag] = [];
            }
            group[el.groupTag].push(el);
        }
        else {
            collection.push(el);
        }
    });
    Object.values(group).forEach((value) => {
        if (value.length > 1) {
            collection.push(value);
        }
        else {
            collection.push(value[0]);
        }
    });
    return collection;
}
// 从selected中查可能存在的组，并给它们设置新的groupTag, 用于各种复制场景
export function createNewGroupTagForCollection(displays) {
    // 从零散的元件中，把相同组的元件放在一起
    const collection = formatSelected(displays);
    // 新建的元素，每个分组都需要创建一个新的组id
    collection.forEach((group) => {
        group = isArray(group) ? group : [group];
        setGroupTag(group);
    });
}
export function calculateHitAreaCorners(displayObject, hitArea = displayObject.hitArea, transform = displayObject.worldTransform, corners) {
    corners = corners || [];
    if (hitArea instanceof Rectangle) {
        corners.push(new Point(hitArea.x, hitArea.y));
        corners.push(new Point(hitArea.x + hitArea.width, hitArea.y));
        corners.push(new Point(hitArea.x + hitArea.width, hitArea.y + hitArea.height));
        corners.push(new Point(hitArea.x, hitArea.y + hitArea.height));
    }
    else if (hitArea instanceof Polygon) {
        for (let i = 0; i < hitArea.points.length; i += 2) {
            corners.push(new Point(hitArea.points[i], hitArea.points[i + 1]));
        }
    }
    for (let i = 0; i < corners.length; i++) {
        transform.apply(corners[i], corners[i]);
    }
    return corners;
}
// 判断两条线段是否相交
// p1和p2是线段1，p3和p4是线段2
export function calcLineIntersection(p1, p2, p3, p4) {
    if (p1.x > p2.x) {
        [p1, p2] = [p2, p1];
    }
    if (p3.x > p4.x) {
        [p3, p4] = [p4, p3];
    }
    const getDelta = (delta, tolerance = 1e-12) => {
        const absDelta = Math.abs(delta);
        return absDelta >= 0 && absDelta <= tolerance ? 0 : delta;
    };
    const k1 = getDelta(p2.y - p1.y) / getDelta(p2.x - p1.x);
    const k2 = getDelta(p4.y - p3.y) / getDelta(p4.x - p3.x);
    const b = p1.y - k1 * p1.x;
    const d = p3.y - k2 * p3.x;
    // line1是垂直线段
    if (Math.abs(k1) === Infinity) {
        const y = k2 * p1.x + d;
        const minY = Math.min(p1.y, p2.y);
        const maxY = Math.max(p1.y, p2.y);
        if (y >= minY && y <= maxY) {
            if (p3.x <= p1.x && p4.x >= p1.x) {
                return true;
            }
        }
    }
    // line2是垂直线段
    if (Math.abs(k2) === Infinity) {
        const y = k1 * p3.x + b;
        const minY = Math.min(p3.y, p4.y);
        const maxY = Math.max(p3.y, p4.y);
        if (y >= minY && y <= maxY) {
            if (p1.x <= p3.x && p2.x >= p3.x) {
                return true;
            }
        }
    }
    const x = (d - b) / (k1 - k2);
    // const y = k1 * x + b;
    if (x >= p1.x && x <= p2.x && x >= p3.x && x <= p4.x) {
        return true;
    }
    return false;
}
// 判断两个图形的边框线条是否存在交点
export function calcIntersect(lines1, lines2) {
    for (let i = 0; i < lines1.length; i++) {
        for (let j = 0; j < lines2.length; j++) {
            const res = calcLineIntersection(lines1[i][0], lines1[i][1], lines2[j][0], lines2[j][1]);
            if (res) {
                return true;
            }
        }
    }
    return false;
}
export function getSpecifiedDisplays(displays, options) {
    const { include = [], exclude = [] } = options || {};
    let targetDisplays = displays;
    if (include.length > 0) {
        targetDisplays = targetDisplays.filter((display) => {
            if (include.includes(display.type)) {
                return display;
            }
            return false;
        });
    }
    if (exclude.length > 0) {
        targetDisplays = targetDisplays.filter((display) => {
            if (!exclude.includes(display.type)) {
                return display;
            }
            return false;
        });
    }
    return targetDisplays;
}
/**
 * 判断是否为image类型的dataURL
 *
 * @export
 * @param {string} url
 * @return {boolean}
 */
export function isImageDataURL(url) {
    url = url.trim();
    const isImageMIME = url.startsWith('data:img/') || url.startsWith('data:image/');
    return isImageMIME;
}
let maxTextureSizeCache;
/**
 * 获取gl.MAX_TEXTURE_SIZE值
 */
export function getMaxTextureSize() {
    if (!maxTextureSizeCache) {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl2') ?? canvas.getContext('webgl');
        if (!gl) {
            return undefined;
        }
        maxTextureSizeCache = gl.getParameter(gl.MAX_TEXTURE_SIZE);
        // 主动销毁webgl context，避免触发 WARNING: Too many active WebGL contexts. Oldest context will be lost.
        gl.getExtension('WEBGL_lose_context').loseContext();
    }
    return maxTextureSizeCache;
}
/**
 * 获取位图渲染的最大尺寸
 */
export function getBitmapMaxSize() {
    const values = [BITMAP_RENDER_CONFIGS.maxSize, getMaxTextureSize()].filter((v) => typeof v === 'number' && isFinite(v) && v > 0);
    const maxSize = Math.min(...values);
    return maxSize;
}
export function splitRectByMaxSize(srcRect, maxWidth, maxHeight) {
    const { x: offsetX = 0, y: offsetY = 0, width: srcWidth, height: srcHeight, } = srcRect;
    const xNum = Math.ceil(srcWidth / maxWidth);
    const yNum = Math.ceil(srcHeight / maxHeight);
    const rects = [];
    let x;
    let y = 0;
    for (let j = 0; j < yNum; j++) {
        x = 0;
        const height = Math.min(maxHeight, srcHeight - y);
        for (let i = 0; i < xNum; i++) {
            const width = Math.min(maxWidth, srcWidth - x);
            const rect = new Rectangle(x + offsetX, y + offsetY, width, height);
            rects.push(rect);
            x += width;
        }
        y += height;
    }
    return rects;
}
export function getImageSourceSize(imgSource) {
    let width;
    let height;
    if (imgSource instanceof HTMLImageElement) {
        width = imgSource.naturalWidth;
        height = imgSource.naturalHeight;
    }
    else {
        width = imgSource.width;
        height = imgSource.height;
    }
    return { width, height };
}
export function getSafeTextureImageSource(imgSource) {
    const { width, height } = getImageSourceSize(imgSource);
    const maxTextureSize = getBitmapMaxSize();
    if (width > maxTextureSize || height > maxTextureSize) {
        const { width: targetWidth, height: targetHeight } = calcAspectRatioFit(width, height, maxTextureSize, maxTextureSize);
        const canvas = document.createElement('canvas');
        canvas.width = Math.floor(targetWidth);
        canvas.height = Math.floor(targetHeight);
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.drawImage(imgSource, 0, 0, canvas.width, canvas.height);
        return canvas;
    }
    else {
        return imgSource;
    }
}
export function isSafeTextureImageSource(imgSource) {
    const { width, height } = getImageSourceSize(imgSource);
    const maxTextureSize = getBitmapMaxSize();
    return !(width > maxTextureSize || height > maxTextureSize);
}
/**
 *
 * @param rotation 弧度值
 * @param anchor 旋转锚点
 */
export function rotatePtByAnchor(pt, rotation, anchor) {
    return new Matrix()
        .translate(-anchor.x, -anchor.y)
        .rotate(rotation)
        .translate(anchor.x, anchor.y)
        .apply(pt);
}
export function isLockRatio(displays) {
    return displays.some((display) => display.lockRatio);
}
