import { Transformer } from '@makeblock/transformer';
import { cloneDeep, isArray, isEmpty, isNumber, isUndefined, merge, round, } from 'lodash-es';
import { Bounds, Container, Rectangle } from 'pixi.js';
import { DEFAULT_DISPLAY_SHOT_SCALE_NUM, DEFAULT_EXPORT_SVG_OPTIONS, DEFAULT_GET_CANVAS_DATA_OPTIONS, } from '../config';
import { DISPLAY_TYPE, MSprite } from '../display';
import { createDisplay } from '../display/utils';
import { loadFile } from '../loader';
import { makeSVGDoc } from '../packages/export/svg';
import { TRANSFORMER_EVENT } from '../types';
import { calcAspectRatioFit, convertBounds, destroyInvaildDisplay, fitSize, fixScaleNum, getDisplayBounds, getDisplayDPath, getDisplaysBounds, getSpecifiedDisplays, imageDataToCanvas, imgToBase64, previewAndDownloadSvg, previewElement, recurseDisplay, } from '../utils';
import { calOffset } from '../utils/math';
import { AbstractBase } from './AbstractBase';
export class AbstractImportExport extends AbstractBase {
    /**
     * 导入 Display 数据到 viewport
     *
     * @param {DisplayJSONType[]} [data]
     * @memberof MCanvas
     */
    importDisplayJSON(data, options) {
        const { createNewID = false, needRecord = true, autoSelected = true, } = options || {};
        if (isArray(data) && !isEmpty(data)) {
            let displays = this.addDisplayToContainerByJSON(data, createNewID);
            const { validDisplays } = destroyInvaildDisplay(displays);
            displays = validDisplays;
            if (needRecord) {
                this.recordOperationLog(...displays);
            }
            else {
                // // 如果是导入文件，需要记录到栈的初始值中
                this.operationStack.initOperation =
                    this.createOperationLogOnViewport(displays);
            }
            if (autoSelected) {
                this.selected = displays;
            }
            return this.getMultiDisplayJSON(displays, { texture: false });
        }
        return [];
    }
    // 更新初始画布的log，用于导入项目阶段 完成之后更新初始画布log数据（例如 文字转换会在导入阶段删除对象再插入新对象）
    updateInitLog() {
        const displays = this.getInteractiveElements();
        this.operationStack.initOperation =
            this.createOperationLogOnViewport(displays);
    }
    /**
     * 导出当前画布中所有的 Display 数据
     *
     * @return {*}  {DisplayJSONType[]}
     * @memberof MCanvas
     */
    exportCanvasJSON(options = { texture: true }) {
        const elements = this.getInteractiveElements();
        // 按渲染顺序排序
        const soredElements = elements.sort((a, b) => a.displayOrder - b.displayOrder);
        return this.getMultiDisplayJSON(soredElements, options);
    }
    /**
     * 获取displayLayer的边界
     * 这个方法会忽略不可见的对象
     */
    getDisplayLayerBounds() {
        return this.viewport.displayLayer.getLocalBounds();
    }
    /**
     * 获取指定display数组的整体的边界
     */
    getDisplaysBounds(query) {
        const displays = this.getViewportDisplayObjects(query);
        const groupBounds = Transformer.calculateGroupOrientedBounds(displays, 0);
        const bounds = new Bounds();
        const topLeft = this.viewport.displayLayer.toLocal(groupBounds.topLeft);
        const bottomRight = this.viewport.displayLayer.toLocal(groupBounds.bottomRight);
        bounds.minX = topLeft.x;
        bounds.minY = topLeft.y;
        bounds.maxX = bottomRight.x;
        bounds.maxY = bottomRight.y;
        return bounds.getRectangle();
    }
    /**
     * 获取选中元素的截图
     * @param scaleNum  截图的放大倍数，用以提升清晰度
     * @returns
     */
    async getSelectedShot(options) {
        const targetDisplays = getSpecifiedDisplays(this.selected, options);
        const data = await this.getDisplaysShot(targetDisplays, this.transformer.worldGroupBounds.getRectangle(), options?.scaleNum);
        return data;
    }
    /**
     * 获取画布中所有元素的截图
     * @param scaleNum  截图的放大倍数，用以提升清晰度
     * @returns
     */
    async getAllDisplaysShot(options) {
        const targetDisplays = getSpecifiedDisplays(this.viewport.getInteractiveElements(), options);
        const data = await this.getDisplaysShot(targetDisplays, this.getDisplayLayerBounds(), options?.scaleNum);
        return data;
    }
    /**
     * 获取画布中指定元素的截图
     * @param scaleNum  截图的放大倍数，用以提升清晰度
     * @returns
     */
    async getSpecifiedDisplaysShot(options) {
        const data = await this.getDisplaysShot(options.targetDisplays, this.getDisplaysBounds(options.targetDisplays), options.scaleNum);
        return data;
    }
    /**
     * 通过元素ID获取画布中指定元素的截图
     * @param scaleNum  截图的放大倍数，用以提升清晰度
     * @param tintColor  对非位图（包含填充和非填充）替换的着色颜色
     * @returns
     */
    async getSpecifiedDisplaysShotById(options) {
        const displays = this.viewport.getInteractiveElements();
        const targets = displays.filter((d) => options.displayIds.includes(d.id));
        const bounds = this.getDisplaysBounds(targets);
        const { x, y } = bounds;
        if (targets.length === 0) {
            return null;
        }
        let scaleNum = options?.scaleNum
            ? options?.scaleNum
            : DEFAULT_DISPLAY_SHOT_SCALE_NUM;
        scaleNum = fixScaleNum({
            srcWidth: bounds.width,
            srcHeight: bounds.height,
            scaleNum,
            maxWidth: this.contextInfo.bitmapMaxSize,
            maxHeight: this.contextInfo.bitmapMaxSize,
        });
        const clonedDisplays = [];
        for (let index = 0; index < targets.length; index++) {
            const display = targets[index];
            let targetDisplay;
            if (display instanceof MSprite) {
                targetDisplay = display.cloneWithoutCompress(this.viewport);
                targetDisplay.scale.x = display.scale.x;
                targetDisplay.scale.y = display.scale.y;
            }
            else {
                const json = display.toJSON(this.viewport);
                json.x = json.offsetX;
                json.y = json.offsetY;
                if (!isUndefined(options.tintColor)) {
                    json.layerColor = options.tintColor;
                }
                targetDisplay = createDisplay(json.type, json);
            }
            clonedDisplays.push(targetDisplay);
        }
        const tempContainer = new Container();
        tempContainer.addChild(...clonedDisplays);
        const { width, height } = tempContainer.getLocalBounds();
        const { imageData } = this.getDisplayRenderData(tempContainer, scaleNum, 1);
        tempContainer.destroy({ children: true });
        const canvas = imageDataToCanvas(imageData);
        return {
            canvas,
            width,
            height,
            oWidth: imageData.width,
            oHeight: imageData.height,
            x,
            y,
        };
    }
    /**
     * 获取绘制元素层级的截图（默认保留全部元素截图），不传就是截取整个绘制元素层
     * Displayer 不包含网格和背景
     * @param options
     * @returns
     */
    async getDisplayLayerShot(options) {
        const { include, exclude } = options || {};
        let { scaleNum = DEFAULT_DISPLAY_SHOT_SCALE_NUM } = options || {};
        if (exclude || include) {
            // 这个分支 主要用于需要 隐藏指定对象，但是整体的截图还需要保留该对象的尺寸信息（例如打印刀切的业务）
            const displays = this.viewport.getInteractiveElements();
            const targetDisplays = getSpecifiedDisplays(displays, options);
            // 整体截图的尺寸是所有对象覆盖的尺寸
            const bounds = this.getDisplaysBounds(displays);
            const data = await this.getDisplaysShot(targetDisplays, bounds, scaleNum);
            const { canvas, ...rest } = data;
            return {
                url: imgToBase64(canvas),
                ...rest,
            };
        }
        else {
            // 此分支主要用于获取整个画布的截图，不可见的对象会被忽略
            // 没有过滤条件时 获取整个layer的层级，计算的方式可以得到简化
            const layer = this.viewport.displayLayer;
            const displayLayerBounds = this.getDisplayLayerBounds();
            // 修正放大倍数，避免获取的截图超出上限
            scaleNum = fixScaleNum({
                srcWidth: displayLayerBounds.width,
                srcHeight: displayLayerBounds.height,
                scaleNum: scaleNum,
                maxWidth: this.contextInfo?.bitmapMaxSize || 4096,
                maxHeight: this.contextInfo?.bitmapMaxSize || 4096,
            });
            console.log('[ 缩略图最终放大尺寸 ] >', scaleNum);
            const { imageData } = this.getDisplayRenderData(layer, scaleNum, 1);
            const canvas = imageDataToCanvas(imageData);
            return { canvas, scaleNum };
        }
    }
    /**
     * 获取指定元素的截图
     * @param targets 元素数组
     * @param bounds 元素最终截图的尺寸
     * @param scaleNum 每个元素的放大比例，用以提升清晰度
     * @returns
     */
    async getDisplaysShot(targets, bounds, scaleNum) {
        const { x, y, width, height } = bounds;
        scaleNum = scaleNum ? scaleNum : DEFAULT_DISPLAY_SHOT_SCALE_NUM;
        scaleNum = fixScaleNum({
            srcWidth: bounds.width,
            srcHeight: bounds.height,
            scaleNum: scaleNum,
            maxWidth: this.contextInfo.bitmapMaxSize,
            maxHeight: this.contextInfo.bitmapMaxSize,
        });
        const canvas = document.createElement('canvas');
        // XXX: canvas.width或者height会取整导致数据错误，需要精确指定对应的宽高
        canvas.width = Math.round(Number((width * scaleNum).toFixed(3)));
        canvas.height = Math.round(Number((height * scaleNum).toFixed(3)));
        const ctx = canvas.getContext('2d');
        for (let index = 0; index < targets.length; index++) {
            const display = targets[index];
            let targetDisplay;
            if (display instanceof MSprite) {
                targetDisplay = display.cloneWithoutCompress(this.viewport);
                targetDisplay.scale.x = display.scale.x * scaleNum;
                targetDisplay.scale.y = display.scale.y * scaleNum;
            }
            else {
                const json = display.toJSON(this.viewport);
                targetDisplay = createDisplay(json.type, json);
                targetDisplay.scale.x *= scaleNum;
                targetDisplay.scale.y *= scaleNum;
            }
            const { imageData } = this.getDisplayRenderData(targetDisplay);
            const { minX: relateLayerX, minY: relateLayerY } = getDisplayBounds(targets[index], this.viewport);
            const image = await createImageBitmap(imageData);
            targetDisplay.destroy();
            ctx.drawImage(image, (relateLayerX - x) * scaleNum, (relateLayerY - y) * scaleNum);
        }
        if (window.canvas_debug.previewImage) {
            previewElement(canvas);
        }
        return {
            canvas,
            width,
            height,
            oWidth: canvas.width,
            oHeight: canvas.height,
        };
    }
    /**
     * 获取舞台数据
     *
     * @return {*}
     * @memberof MCanvas
     */
    async getCanvasData(options = DEFAULT_GET_CANVAS_DATA_OPTIONS) {
        console.time('getCanvasData');
        this.finishCurMode();
        const { imageData: exportImageData = true, path: exportPath = true, excludeOutSide = true, outSideRange, exportVectorPathWhenFill = false, ids, excludeIds, clearSelected = true, } = options;
        let displays = this.viewport.getInteractiveElements({
            excludeOutSide,
            outSideRange,
            ids,
            excludeIds,
        });
        // 因为在输出数据时会清除无效元素，先将选中清空，避免元素被销毁带来的异常
        if (clearSelected) {
            // @TODO: transformer设置selected时添加合法性校验，这里就不需要额外的调用clear()
            this.transformer.clear();
        }
        const { validDisplays } = destroyInvaildDisplay(displays);
        displays = validDisplays;
        const canvasData = [];
        for (let indeX = 0; indeX < displays.length; indeX++) {
            const display = displays[indeX];
            const displayJSON = display.toJSON(this.viewport);
            // 视图上显示的对象的边界值(基于真实的点的边界进行矩阵变换后的值，与选中框和标尺的值一致)
            const frameBounds = getDisplaysBounds([display], this.viewport);
            // 视图上元素真实内容的边界值（基于矩阵变换后的点得出的真实边界, 在某些元素旋转后的情况下与frameBounds不一致）
            let contentBounds = frameBounds;
            if (display.getRealBounds) {
                contentBounds = display.getRealBounds();
                contentBounds = convertBounds(this.viewport, contentBounds);
            }
            const { minX, minY, maxX, maxY } = contentBounds;
            let extraData = {};
            const enableExportImageData = () => {
                const isBitMap = displayJSON.type === DISPLAY_TYPE.BITMAP;
                const result = isBitMap || (displayJSON.isFill && display.enableFill);
                return result;
            };
            // 矢量填充状态是否输出矢量路径（默认是false）
            const isExportVectorPathWhenFill = () => {
                const isBitMap = displayJSON.type === DISPLAY_TYPE.BITMAP;
                const result = !isBitMap && displayJSON.isFill && exportVectorPathWhenFill;
                return result;
            };
            // 是否允许输出位图数据 -- 位图或矢量填充并展示填充
            if (enableExportImageData()) {
                // 是否获取位图数据  某下情况下调用此接口可以不需要输出位图数据
                if (exportImageData) {
                    extraData = await this.configDisplayBitMap(options, display, contentBounds, frameBounds);
                    if (isExportVectorPathWhenFill()) {
                        const extraPathData = await this.configDisplayDPath(options, display);
                        extraData = {
                            ...extraData,
                            ...extraPathData,
                        };
                    }
                }
            }
            else {
                if (exportPath) {
                    extraData = await this.configDisplayDPath(options, display);
                }
            }
            canvasData.push({
                ...displayJSON,
                ...extraData,
                x: minX,
                y: minY,
                width: maxX - minX,
                height: maxY - minY,
            });
        }
        if (window.canvas_debug.logGetCanvasData) {
            console.log('[Canvas-getCanvasData]', canvasData);
        }
        console.timeEnd('getCanvasData');
        return canvasData;
    }
    async configDisplayBitMap(options, display, contentBounds, frameBounds) {
        let targetDisplay = display;
        const { minX, minY, maxX, maxY } = contentBounds;
        let frame = null;
        if (display.type === DISPLAY_TYPE.BITMAP) {
            targetDisplay = display.cloneWithoutCompress(this.viewport);
        }
        else {
            const json = display.toJSON(this.viewport);
            targetDisplay = createDisplay(json.type, json);
            // TODO: 后续将每个元素需要放大的倍数由设备计算决定
            // 对填充的非位图，输出像素数据时进行放大处理, 为减少尺寸过大的矢量填充放大后产生的数据量过多而导致内存溢出
            // 只针对 面积在 100 * 100 的图形进行放大30倍处理（以提升加工效果）， 其余尺寸都只放大默认倍数10倍
            let targetVectorScaleNum = options.vectorScaleNum;
            if ((maxX - minX) * (maxY - minY) < 100 * 100) {
                targetVectorScaleNum = 30;
            }
            else {
                targetVectorScaleNum = 10;
            }
            targetVectorScaleNum = fixScaleNum({
                srcWidth: maxX - minX,
                srcHeight: maxY - minY,
                scaleNum: targetVectorScaleNum,
                maxWidth: this.contextInfo.bitmapMaxSize,
                maxHeight: this.contextInfo.bitmapMaxSize,
            });
            targetDisplay.scale.x *= targetVectorScaleNum;
            targetDisplay.scale.y *= targetVectorScaleNum;
            // 得到一个元素的实际内容相对这个元素的偏移
            const getTargetFrame = () => {
                const x = contentBounds.minX - frameBounds.minX;
                const y = contentBounds.minY - frameBounds.minY;
                const width = contentBounds.maxX - contentBounds.minX;
                const height = contentBounds.maxY - contentBounds.minY;
                const rect = new Rectangle(x * targetVectorScaleNum, y * targetVectorScaleNum, width * targetVectorScaleNum, height * targetVectorScaleNum);
                return rect;
            };
            frame = getTargetFrame();
        }
        // 在某些情况下
        const { imageData } = this.getDisplayRenderData(targetDisplay, 1, 0, frame);
        // clone出的对象需要销毁
        targetDisplay.destroy();
        const { data, width, height } = imageData;
        if (options.dataImageExport) {
            const savePath = await options.dataImageExport(data);
            return {
                oWidth: width,
                oHeight: height,
                extraPath: savePath,
            };
        }
        return {
            imgData: data,
            oWidth: width,
            oHeight: height,
        };
    }
    async configDisplayDPath(options, display) {
        const path = getDisplayDPath(display, this.viewport);
        if (options.dataPathExport) {
            const savePath = await options.dataPathExport(path);
            return {
                extraPath: savePath,
            };
        }
        return { path };
    }
    /**
     * 加载文件资源到 viewport
     *
     * @param {File} file
     * @return {*}  {Promise<void>}
     * @memberof MCanvas
     */
    loadResToViewport(loaderName, data, options) {
        // 导入素材前结束当前模式
        this.finishCurMode();
        console.time('loadResToViewport');
        const display = loadFile(loaderName, data);
        if (!display) {
            throw new Error('load res error, loader name: ${loaderName}.');
        }
        const { width, height } = data;
        const allLeafDisplays = recurseDisplay(display);
        const { validDisplays } = destroyInvaildDisplay(allLeafDisplays);
        if (validDisplays.length > 0) {
            this.beforeAddDisplayToViewport(validDisplays);
            //  这里解析返回 display 是一个container，需要将 display 添加到viewport
            // 解析的transform是嵌套关系的，所以不可直接将 allLeafDisplays 添加到viewpor
            this.addDisplayToViewport(display, false);
            this.selected = validDisplays;
            this.placeSelectedToViewport({
                width,
                height,
                ...options,
            });
        }
        else {
            this.selected = [];
        }
        console.timeEnd('loadResToViewport');
        return this.getMultiDisplayJSON(validDisplays, { texture: false });
    }
    placeSelectedToViewport(options) {
        const bounds = this.transformer.worldGroupBounds.getRectangle();
        let { width, height } = options;
        const { needRecord = true } = options;
        const { fitToViewPort = false, fitViewportRatio = 1 } = options;
        // 缩放至指定适应尺寸
        if (options.fitSize) {
            ({ width, height } = fitSize({ width, height }, options.fitSize));
        }
        // 确定内容展示宽高
        if (!width && !height) {
            width = bounds.width;
            height = bounds.height;
        }
        else if (!width) {
            width = (height / bounds.height) * bounds.width;
        }
        else if (!height) {
            height = (width / bounds.width) * bounds.height;
        }
        if (fitToViewPort) {
            // 缩小展示宽高适应画幅
            ({ width, height } = calcAspectRatioFit(width, height, this.viewport.worldWidth * fitViewportRatio, this.viewport.worldHeight * fitViewportRatio));
        }
        // TODO: 因为svg中的一些标签被忽略解析，导致外部指定的宽高，被设置到了实际渲染的内容的宽高而发生变形
        // 这里先只设置宽度，高度按比例动态计算
        // 后续需要对被忽略的元素和被清理的无效元素进行优化
        // 变换内容为展示宽高
        if (width !== bounds.width || height !== bounds.height) {
            this.transformer.updateGroupSize(width, null, true);
        }
        // 对于导入的元素，因为添加到viewport有缩放，坐标换算的流程，在一些数值上可能会得到非0的坐标，此处显式设置一下默认到 （0，0）点
        const { offsetX = 0, offsetY = 0 } = options;
        let targetX = offsetX;
        let targetY = offsetY;
        if (!isNumber(offsetX)) {
            targetX = calOffset(offsetX, this.viewport.worldWidth, width);
        }
        if (!isNumber(offsetY)) {
            targetY = calOffset(offsetY, this.viewport.worldHeight, height);
        }
        this.transformer.updateGroupPosition(targetX, targetY);
        // 最终再派发 CANVAS_EVENT.SELECTED_TRANSFORM_COMMIT 属性修改完成的事件, 可能在前面的逻辑中会对一些属性有调整
        if (needRecord) {
            this.transformer.emit(TRANSFORMER_EVENT.TRANSFORM_COMMIT);
        }
        /*
          Note:
          XCS项目对于TRANSFORMER_EVENT.TRANSFORM_COMMIT和TRANSFORMER_EVENT.TRANSFORM_CHANGE的响应添加了防抖延时，
          而对TRANSFORMER_EVENT.SELECT未进行防抖延时，会导致放置文字、形状时位置或尺寸数值跳变（Bug XCSPC-3636）。
          但是直接给TRANSFORMER_EVENT.SELECT会引发另外的问题（Bug XCSPC-3667）
          这里暂时使用额外派发一次TRANSFORMER_EVENT.SELECT来同时规避以上两个Bug，后续对selected数组重构时
          再考虑更合适的解决方案。
         */
        this.transformer.emit(TRANSFORMER_EVENT.SELECT);
    }
    addTextToViewport(options, textJSON) {
        this.finishCurMode();
        const parseTextJSON = (display) => {
            display.parseJSON(textJSON);
        };
        const display = createDisplay(DISPLAY_TYPE.TEXT);
        this.beforeAddDisplayToViewport(display, [{ action: parseTextJSON }]);
        this.addDisplayToViewport(display, true);
        this.placeSelectedToViewport(options);
    }
    /**
     * 导出对象为SVG
     */
    exportSVG(options) {
        this.finishCurMode();
        const usedOptions = merge(cloneDeep(DEFAULT_EXPORT_SVG_OPTIONS), { getDataOptions: { relativeObj: this.viewport } }, options);
        const objs = this.getViewportDisplayObjects(usedOptions.objs);
        if (usedOptions?.sortByZOrder) {
            objs.sort((a, b) => a.zOrder - b.zOrder);
        }
        const objTypeCounts = {};
        let elementsStr = '';
        for (const obj of objs) {
            try {
                elementsStr += obj?.toSVG(usedOptions.getDataOptions) ?? '';
                objTypeCounts[obj.type] = (objTypeCounts[obj.type] ?? 0) + 1;
            }
            catch (e) {
                console.error(e);
            }
        }
        const getNum = (value) => {
            return isFinite(value) ? round(value, 1) : 0;
        };
        const bounds = this.getDisplaysBounds(objs);
        const contentWidth = getNum(bounds.width);
        const contentHeight = getNum(bounds.height);
        const contentX = getNum(bounds.x);
        const contentY = getNum(bounds.y);
        const svgStr = makeSVGDoc({
            content: elementsStr,
            contentWidth,
            contentHeight,
            viewBox: [contentX, contentY, contentWidth, contentHeight],
        }, usedOptions?.template);
        return {
            data: svgStr,
            objTypeCounts,
            preview() {
                return previewAndDownloadSvg(this.data);
            },
        };
    }
}
