import { DisplayObject } from 'pixi.js';
import { createDisplay } from '../display';
import { AbstractBase } from './AbstractBase';
export var BUILD_IN_PLUGIN_EVENT;
(function (BUILD_IN_PLUGIN_EVENT) {
    BUILD_IN_PLUGIN_EVENT["MESSAGE_TO_MAIN"] = "MESSAGE_TO_MAIN";
    // 用以插件直接派发事件到插件
    // 当单个插件定义了具体的事件之后，派发此事件，会直接透传到监听了该事件的插件中
    BUILD_IN_PLUGIN_EVENT["MESSAGE_TO_PLUGIN"] = "MESSAGE_TO_PLUGIN";
})(BUILD_IN_PLUGIN_EVENT || (BUILD_IN_PLUGIN_EVENT = {}));
function createDisplayFn(type, options) {
    const display = createDisplay(type, options);
    // 减小插件创建的对象的设置，将对象的可交互设置为false
    if (display) {
        display.interactive = false;
        display.interactiveChildren = false;
    }
    return display;
}
export class AbstractPlugin extends AbstractBase {
    registerPlugin(plugin) {
        if (this.plugins.find((p) => p.name === plugin.name)) {
            return;
        }
        this.plugins.push(plugin);
        this.initPluginEvent(plugin);
        if (plugin.getDisplayObject) {
            const display = plugin.getDisplayObject();
            if (display) {
                this.app.stage.addChild(display);
            }
        }
        if (!plugin.init) {
            return;
        }
        plugin.init(this.viewport, this.transformer);
        if (plugin.initStageDisplay) {
            this.app.stage.addChild(plugin.initStageDisplay(createDisplayFn));
        }
        if (plugin.initViewportDisplay) {
            const data = plugin.initViewportDisplay(createDisplayFn);
            if (data instanceof DisplayObject) {
                this.viewport.addChild(data);
            }
            else {
                const { display, targetContainer } = data;
                this.viewport.addToContainer(display, targetContainer);
            }
        }
    }
    initPluginEvent(plugin) {
        if (plugin) {
            // 单个插件注册 MESSAGE_TO_PLUGIN 事件，收到该事件时由执行canvas逻辑
            plugin.on?.(BUILD_IN_PLUGIN_EVENT.MESSAGE_TO_MAIN, (message) => {
                this.emit(BUILD_IN_PLUGIN_EVENT.MESSAGE_TO_MAIN, message);
            });
            // 单个插件注册 MESSAGE_TO_PLUGIN 事件，收到该事件时由canvas直接派发到其他插件中
            plugin.on?.(BUILD_IN_PLUGIN_EVENT.MESSAGE_TO_PLUGIN, (message) => {
                this.emit(BUILD_IN_PLUGIN_EVENT.MESSAGE_TO_PLUGIN, message);
            });
        }
    }
    unRegisterPlugin(plugin) {
        // TODO 增加销毁插件要做的事情
        const index = this.plugins.indexOf(plugin, 0);
        if (index > -1) {
            plugin?.destroy();
            this.plugins.splice(index, 1);
        }
    }
    unRegisterPluginByName(name) {
        const plugin = this.plugins.find((p) => p.name === name);
        if (plugin) {
            this.unRegisterPlugin(plugin);
        }
    }
    getPluginByName(name) {
        const plugin = this.plugins.find((p) => p.name === name);
        return plugin;
    }
    unRegisterPluginByType(type) {
        const plugins = this.plugins.filter((p) => p.type === type);
        plugins.forEach((plugin) => {
            this.unRegisterPlugin(plugin);
        });
    }
}
