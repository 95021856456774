import { Graphics, Point } from 'pixi.js';
import { DEFAULT_ELEMENT_LINE_STYLE } from '../../../config';
import { IMAGE_PROCESS_TOOL } from '../../../types';
export default class Eraser extends Graphics {
    size;
    addToContainer;
    isPointerDown;
    viewportWorldWidth;
    viewportWorldHeight;
    #beginPoint;
    #points;
    onRecord;
    processController;
    constructor(options) {
        super();
        this.name = IMAGE_PROCESS_TOOL.ERASER;
        this.cursor = 'none';
        this.size = 0;
        this.#points = [];
        this.viewportWorldHeight = options.viewportWorldHeight;
        this.viewportWorldWidth = options.viewportWorldWidth;
        this.processController = options.processController;
        this.onRecord = options.onRecord;
        this.visible = false;
        this.addToContainer = true;
        this.isPointerDown = false;
        this.#draw();
    }
    #draw(centerPoint = new Point(0, 0)) {
        this.clear().lineStyle(DEFAULT_ELEMENT_LINE_STYLE);
        const size = this.size || 1;
        this.drawEllipse(centerPoint.x, centerPoint.y, size / 2, size / 2);
    }
    #erasing(display, controlPoint, endPoint) {
        const radius = this.size / display.scale.x;
        const globalBeginPoint = display.parent.parent.toGlobal(this.#beginPoint);
        const localBeginPoint = display.toLocal(globalBeginPoint);
        let localControlPoint;
        let localEndPoint;
        let isCurveTo = false;
        if (controlPoint && endPoint) {
            const globalControlPoint = display.parent.parent.toGlobal(controlPoint);
            localControlPoint = display.toLocal(globalControlPoint);
            const globalEndPoint = display.parent.parent.toGlobal(endPoint);
            localEndPoint = display.toLocal(globalEndPoint);
            isCurveTo = true;
        }
        this.processController.erasing({
            isCurveTo,
            radius,
            beginPoint: localBeginPoint,
            controlPoint: localControlPoint,
            endPoint: localEndPoint,
        });
    }
    updateAttr(options) {
        if ('size' in options) {
            this.size = Number(options.size) || 0;
            this.#draw();
        }
    }
    start = (point, display) => {
        // const globalPoint = display.parent.parent.toGlobal(point);
        // const isClickInside = containsPointHasHitArea(
        //   display,
        //   globalPoint,
        //   this.size / display.scale.x / 2,
        // );
        // if (!isClickInside) {
        //   return;
        // }
        if (this.size === 0) {
            return;
        }
        this.#beginPoint = point;
        this.#points.push(point);
        this.isPointerDown = true;
        this.#erasing(display);
    };
    update = (point, display) => {
        this.updateCursorVisible(point);
        if (!this.isPointerDown) {
            return;
        }
        if (this.size === 0) {
            return;
        }
        this.#points.push(point);
        if (this.#points.length > 3) {
            const lastTwoPoints = this.#points.slice(-2);
            const controlPoint = lastTwoPoints[0];
            const endPoint = new Point((lastTwoPoints[0].x + lastTwoPoints[1].x) / 2, (lastTwoPoints[0].y + lastTwoPoints[1].y) / 2);
            this.#erasing(display, controlPoint, endPoint);
            this.#beginPoint = endPoint;
        }
    };
    end = (point, display) => {
        if (!this.isPointerDown) {
            return;
        }
        if (this.size === 0) {
            return;
        }
        this.#points.push(point);
        if (this.#points.length > 3) {
            const lastTwoPoints = this.#points.slice(-2);
            const controlPoint = lastTwoPoints[0];
            const endPoint = lastTwoPoints[1];
            this.#erasing(display, controlPoint, endPoint);
        }
        this.#beginPoint = null;
        this.isPointerDown = false;
        this.#points = [];
        this.onRecord();
    };
    updateCursorVisible(point) {
        this.x = point.x;
        this.y = point.y;
        this.visible =
            point.x >= 0 &&
                point.x <= this.viewportWorldWidth &&
                point.y >= 0 &&
                point.y <= this.viewportWorldHeight;
    }
    unactived() {
        this.visible = false;
    }
}
