import { EventEmitter } from '@pixi/utils';
import { debounce, merge } from 'lodash-es';
import { DISPLAY_TYPE } from '../../display';
import { buildSkPath, deleteSkPath, init as initSkPath, isSkPath, } from '../../packages/skia/SkPath';
import { DISPLAY_OBJECT_EVENT, TRANSFORMER_EVENT } from '../../types';
import { hasOneIn } from '../../utils/math';
import { ScheduledTaskSet } from '../../utils/ScheduledTaskSet';
import { updateSkPathByDisplay } from './updateSkPathByDisplay';
export var HitAreaManagerEvent;
(function (HitAreaManagerEvent) {
    HitAreaManagerEvent["ADD_HIT_AREA"] = "addHitArea";
    HitAreaManagerEvent["REMOVE_HIT_AREA"] = "removeHitArea";
    HitAreaManagerEvent["UPDATE_HIT_AREA"] = "updateHitArea";
})(HitAreaManagerEvent || (HitAreaManagerEvent = {}));
const DefaultHitAreaPluginOptions = {
    enabled: false,
    expansion: {
        mode: 'stroke',
        padding: 6,
        defaultFill: false,
    },
    zoomDebounceDelay: 500,
    painter: {
        enabled: false,
        mode: 'listener',
    },
    excludeFn: (display) => display.type === DISPLAY_TYPE.TEXT || display.type === DISPLAY_TYPE.BITMAP,
};
export class HitAreaManager extends EventEmitter {
    static events = HitAreaManagerEvent;
    get events() {
        return HitAreaManagerEvent;
    }
    get hitAreaManager() {
        return this;
    }
    options;
    viewport;
    transformer;
    painter;
    #pathsMap = new WeakMap();
    /**
     * 更新hitArea任务集
     */
    updateHitAreaTasks;
    debouncedHandle;
    constructor(options) {
        super();
        this.options = merge(DefaultHitAreaPluginOptions, options);
        this.updateHitAreaTasks = new ScheduledTaskSet(this.options.updateTask);
    }
    init(viewport, transformer) {
        console.log('[ HitAreaManager ] enabled:', this.options.enabled);
        if (this.options.enabled === false) {
            return;
        }
        this.viewport = viewport;
        this.transformer = transformer;
        this.debouncedHandle = debounce((ctx, action) => {
            action.call(ctx);
        }, this.options.zoomDebounceDelay, { 'trailing': true });
        initSkPath().then(() => {
            this.initEvents();
            // 事件注册前已经在画布中的元素添加hitArea（SkPath改为按需加载后，在双击打开项目文件的情况会落后于初始元素添加到画布中）
            const initialDisplays = this.viewport.getInteractiveElements();
            initialDisplays.forEach((display) => {
                this.addHitAreaFor(display);
            });
            this.hitAreaManager.initPainter();
        });
    }
    initEvents() {
        // display添加到viewport.displayLayer时
        this.viewport.displayLayer.on('childAdded', (display) => {
            // console.log('%c[ DisplayLayer ] child added', 'color: green', display);
            // 元素添加到viewport前可能修改过transform，此处确保worldTransform为最新状态
            display.updateTransform();
            this.addHitAreaFor(display);
        });
        // viewport zoom改变时
        this.viewport.on('zoomed', debounce(() => {
            if (this.viewport.localTransform.a !== this.viewport.scale.x) {
                this.viewport.updateTransform();
            }
            this.viewport.displayLayer.children.forEach((display) => {
                this.hitAreaManager.updateHitAreaFor(display);
            });
        }, this.options.zoomDebounceDelay));
        // transformer transform commit
        this.transformer.on(TRANSFORMER_EVENT.TRANSFORM_COMMIT, ({ type = '' } = {}) => {
            // console.log('transformer commit: ', type);
            if (!type || type === 'scale') {
                this.requestUpdateHitAreaForDisplays(this.transformer.selected);
            }
        });
        // transformer selected attrs change
        this.transformer.on(TRANSFORMER_EVENT.TRANSFORM_CHANGE, (attrs) => {
            this.handleDisplaysAttrsChanged(this.transformer.selected, attrs);
        });
    }
    handleDisplaysAttrsChanged(displays, attrs) {
        if (hasOneIn(attrs, ['width', 'height', 'isFill'])) {
            this.requestUpdateHitAreaForDisplays(displays);
        }
    }
    async initPainter() {
        try {
            if (this.options.painter.enabled || isDebugPaintEnabled()) {
                const { HitAreaDebugPainter } = await import('./HitAreaDebugPainter');
                console.log('%c[ DebugPainter ] init success', 'font-weight: bold');
                this.painter = new HitAreaDebugPainter(this, this.options.painter);
                this.viewport.addChild(this.painter.container);
            }
        }
        catch (e) {
            console.warn('[ DebugPainter ] init failed');
        }
    }
    updateConfig(options) {
        this.options = merge(this.options, options);
    }
    _addHitArea(obj) {
        let skPath;
        if (!this.#pathsMap.has(obj)) {
            skPath = buildSkPath();
            this.#pathsMap.set(obj, skPath);
            obj.on(DISPLAY_OBJECT_EVENT.ATTRS_CHANGED, (attrs) => {
                // console.log('%c[ DisplayLayer ] attrsChanged', 'color: blue', attrs);
                this.handleDisplaysAttrsChanged([obj], attrs);
            });
            obj.on('destroyed', () => {
                this.updateHitAreaTasks.delete(obj);
                deleteSkPath(skPath);
                this.#pathsMap.delete(obj);
                obj.hitArea = null;
            });
            obj.on('removed', () => {
                this.updateHitAreaTasks.delete(obj);
                this.emit(HitAreaManagerEvent.REMOVE_HIT_AREA, obj);
            });
        }
        else {
            skPath = this.#pathsMap.get(obj);
        }
        obj.hitArea = skPath;
        this._updateHitArea(obj);
        this.emit(HitAreaManagerEvent.ADD_HIT_AREA, obj);
    }
    _updateHitArea(obj) {
        if (isSkPath(obj.hitArea)) {
            const { a, b, c, d } = obj.worldTransform;
            const sx = Math.sqrt(a * a + b * b);
            const sy = Math.sqrt(c * c + d * d);
            const expansion = this.options.expansion;
            const opts = {
                mode: expansion.mode,
                width: 1 + expansion.padding * 2,
                fill: obj.isFill ?? expansion.defaultFill,
                sx,
                sy,
                viewportScale: this.viewport.scale.x,
            };
            const skPath = obj.hitArea;
            skPath.rewind();
            updateSkPathByDisplay(obj, skPath, opts);
            skPath.style(opts);
        }
    }
    _addUpdateHitAreaTask(obj) {
        this.updateHitAreaTasks.add(obj, (obj) => {
            if (obj.destroyed) {
                return;
            }
            this._updateHitArea(obj);
            if (obj.hitArea) {
                this.emit(HitAreaManagerEvent.UPDATE_HIT_AREA, obj);
            }
        });
    }
    traverseDisplay(display, action) {
        const visited = new Set();
        const recurse = (obj) => {
            if (!obj || visited.has(obj)) {
                return;
            }
            visited.add(obj);
            const result = action(obj);
            if (result !== false && Array.isArray(obj.children)) {
                obj.children.forEach(recurse);
            }
        };
        recurse(display);
    }
    addHitAreaFor(display) {
        this.traverseDisplay(display, (obj) => {
            if (this.options.excludeFn?.(obj)) {
                return false;
            }
            if (obj.interactive) {
                if (!isSkPath(obj.hitArea)) {
                    this._addHitArea(obj);
                }
                else {
                    this._addUpdateHitAreaTask(obj);
                }
            }
            return true;
        });
    }
    updateHitAreaFor(display) {
        this.traverseDisplay(display, (obj) => {
            if (this.options.excludeFn?.(obj)) {
                return false;
            }
            this._addUpdateHitAreaTask(obj);
            return true;
        });
    }
    requestUpdateHitAreaForDisplays(displays) {
        displays.forEach((display) => {
            this.updateHitAreaFor(display);
        });
    }
}
function isDebugPaintEnabled() {
    try {
        return (globalThis.sessionStorage.getItem('HIT_AREA_DEBUG_PAINT') === 'true' ||
            new URLSearchParams(window.location.hash.split('?').slice(-1)[0]).get('HIT_AREA_DEBUG_PAINT') === 'true');
    }
    catch (e) { }
    return false;
}
