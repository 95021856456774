import { DEG_TO_RAD, DisplayObject, Matrix, Point, RAD_TO_DEG, Transform, } from 'pixi.js';
import { v4 as uuid } from 'uuid';
import { getSVGTransformAttr, stringifySVG } from '../../packages/export/svg';
import { DISPLAY_OBJECT_EVENT } from '../../types';
import { decomposeTransform, divisionMatrix } from '../../utils';
import { DISPLAY_TYPE } from '../type';
/**
 * 参考 pixi 对类扩展的方式进行扩展
 */
DisplayObject.prototype.type = DISPLAY_TYPE.LINE;
DisplayObject.prototype._id = '';
DisplayObject.prototype.lockRatio = true;
DisplayObject.prototype.isValid = true;
DisplayObject.prototype.enableFill = true;
// 对象原本的颜色（默认是#000000，svg图纸在解析阶段会重新赋值）
DisplayObject.prototype.originColor = '#000000';
DisplayObject.prototype.isClosePath = false;
DisplayObject.prototype.exportOption = {
    removeSamePoint: true,
};
Object.defineProperty(DisplayObject.prototype, 'id', {
    set: function (value) {
        this._id = value;
    },
    get: function () {
        if (!this._id) {
            this._id = uuid();
        }
        return this._id;
    },
});
// 单个对象有默认的groupTag
Object.defineProperty(DisplayObject.prototype, 'groupTag', {
    set: function (value) {
        this._groupTag = value;
    },
    get: function () {
        if (!this._groupTag) {
            this._groupTag = uuid();
        }
        return this._groupTag;
    },
});
DisplayObject.prototype.toJSON = function (relativeEle) {
    const { id, type, lockRatio, isClosePath, zOrder, sourceId, groupTag, layerTag, layerColor, visible, originColor, } = this;
    // XXX: 常规graphic绘制从0,0开始，此处获取graphic相对它的canvas坐标(可视内容的左上角坐标)转化为世界坐标，再转为相对于viewport的坐标进行导出
    const { x: localX, y: localY } = this.getLocalBounds();
    // 改成 toGlobal 方法内部会update一次，可以解决设置属性之后立即获取得到的结果不正确的问题
    const worldCoordinates = this.toGlobal(new Point(localX, localY));
    const { x: relativeX, y: relativeY } = relativeEle.toLocal({
        x: worldCoordinates.x,
        y: worldCoordinates.y,
    });
    // 计算相对 relativeEle 的矩阵
    const matrixByViewport = divisionMatrix(this.worldTransform.clone(), relativeEle.worldTransform.clone());
    const transformByRelativeEle = new Transform();
    decomposeTransform(transformByRelativeEle, matrixByViewport);
    const { scale, skew, pivot, rotation } = transformByRelativeEle;
    const relativeOffset = relativeEle.toLocal(this.position, this.parent);
    return {
        id,
        type,
        x: relativeX,
        y: relativeY,
        angle: rotation * RAD_TO_DEG,
        scale: {
            x: scale.x,
            y: scale.y,
        },
        skew: {
            x: skew.x,
            y: skew.y,
        },
        pivot: {
            x: pivot.x,
            y: pivot.y,
        },
        localSkew: {
            x: this.skew.x,
            y: this.skew.y,
        },
        offsetX: relativeOffset.x,
        offsetY: relativeOffset.y,
        lockRatio,
        isClosePath,
        zOrder,
        sourceId,
        groupTag,
        layerTag,
        layerColor,
        visible,
        originColor,
    };
};
// @ts-ignore
DisplayObject.prototype.getPointToRelativeElement = function (relativeEle) {
    // XXX: 常规graphic绘制从0,0开始，此处获取graphic相对它的canvas坐标(可视内容的左上角坐标)转化为世界坐标，再转为相对于viewport的坐标进行导出
    const { x: localX, y: localY } = this.getLocalBounds();
    // 改成 toGlobal 方法内部会update一次，可以解决设置属性之后立即获取得到的结果不正确的问题
    const worldCoordinates = this.toGlobal(new Point(localX, localY));
    const { x: relativeX, y: relativeY } = relativeEle.toLocal({
        x: worldCoordinates.x,
        y: worldCoordinates.y,
    });
    return { x: relativeX, y: relativeY };
};
DisplayObject.prototype.parseJSON = function (data) {
    const { x = this.x, y = this.y, pivot = this.pivot, scale = this.scale, angle = this.angle, skew = this.skew, id = this.id, type = this.type, lockRatio = this.lockRatio, isClosePath = this.isClosePath, layerTag = this.layerTag, layerColor = this.layerColor, visible = this.visible, originColor = this.originColor, } = data;
    const matrix = new Matrix();
    if (id) {
        this.id = id;
    }
    // 如果data含有该key，则用它的值，不管它的值是0还是undefined，否则用自身的值。
    // 解决组合撤销之后，组还在，zOrder未还原的问题
    const keys = ['groupTag', 'zOrder', 'sourceId'];
    keys.forEach((key) => {
        this[key] = data.hasOwnProperty(key) ? data[key] : this[key];
    });
    this.type = type;
    this.lockRatio = lockRatio;
    this.isClosePath = isClosePath;
    this.layerTag = layerTag;
    this.layerColor = layerColor;
    this.visible = visible;
    this.originColor = originColor;
    const rotation = angle * DEG_TO_RAD;
    matrix.setTransform(x, y, pivot.x, pivot.y, scale.x, scale.y, rotation, skew.x, skew.y);
    this.transform.setFromMatrix(matrix);
    this.emit(DISPLAY_OBJECT_EVENT.ATTRS_CHANGED, data);
};
DisplayObject.prototype.toSVG = function (options) {
    return stringifySVG(this.getSVGData(options));
};
DisplayObject.prototype.getSVGData = function (options) {
    return { attrs: getSVGTransformAttr(this, options) };
};
