import { cloneDeep, groupBy, isBoolean, isEqual, isFunction, isNumber, isPlainObject, isString, merge, } from 'lodash-es';
import { DotFilter, EmbossFilter } from 'pixi-filters';
import { Bounds, DisplayObject, Matrix, Sprite, Texture } from 'pixi.js';
import { DEFAULT_IMAGE_PROCESS_ATTR } from '../config';
import { importMaskJSONToSprite } from '../display/utils';
import { getSVGFillAndStrokeAttrs, } from '../packages/export/svg';
import { boundsAddRectangle, createSpriteCanvas, getImageDataBounds, getImageSourceSize, getSafeTextureImageSource, imageDataToBase64, imgToBase64, isSafeTextureImageSource, } from '../utils';
import { applyBinary, applyColorInversion, applyComic, applyGrayRanger, applySharpness, applySketch, } from '../utils/imageProcess';
import { DISPLAY_TYPE } from './type';
import { contains, getBitmapClippedImageData, getDisplayBoundsForMaskedSprite, getMaskObj, getSpriteResourceScale, isResourceScaled, } from './utils';
/**
 * 位图渲染配置
 */
export const BITMAP_RENDER_CONFIGS = {
    maxSize: undefined,
};
export class MSprite extends Sprite {
    isFill;
    svgPath;
    originBase64;
    grayValue;
    sharpness;
    colorInverted;
    filterList;
    updateProcessTypes;
    filterAttrsMap;
    get resourceScale() {
        return getSpriteResourceScale(this);
    }
    get isResourceScaled() {
        return isResourceScaled(this);
    }
    get originTextureData() {
        const originTextureData = Symbol.for('originTextureData');
        !this[originTextureData] && this.updateOriginTexture();
        return this[originTextureData];
    }
    set originTextureData(texture) {
        const originTextureData = Symbol.for('originTextureData');
        this[originTextureData] = texture;
    }
    constructor(img, option) {
        super();
        this.isFill = true;
        this.type = DISPLAY_TYPE.BITMAP;
        this.interactive = true;
        this.filters = [];
        this.filterList = [];
        this.filterAttrsMap = {
            emboss: { strength: 5 },
        };
        this.updateProcessTypes = [];
        this.grayValue = DEFAULT_IMAGE_PROCESS_ATTR.GRAY_VALUE;
        this.sharpness = DEFAULT_IMAGE_PROCESS_ATTR.SHARPNESS;
        this.colorInverted = DEFAULT_IMAGE_PROCESS_ATTR.COLOR_INVERTED;
        this.lockRatio = true;
        let image = img;
        // pixi中当source为string时，baseTexture的cacheId就是source，如果source为base64就区分不了
        if (typeof image === 'string') {
            image = new Image();
            image.src = img;
        }
        this.setupTexture(image);
        const originSize = this.getOriginSizeFromJSON(option);
        const isImageLoading = image instanceof HTMLImageElement && !image.complete;
        if (isImageLoading) {
            // 图像未加载完成时，使用json数据的原图尺寸信息预先设置texture，避免历史栈记录width和height为1的错误数据
            if (originSize) {
                this.texture.orig.width = originSize.width;
                this.texture.orig.height = originSize.height;
            }
            image.addEventListener('load', () => {
                const imgEl = image;
                if (!isSafeTextureImageSource(imgEl)) {
                    this.setupTexture(imgEl);
                    this.updateOriginTexture();
                    this.setImageAttrs(this, true);
                    this.updateProcessEffect();
                }
                this.updateTextureOriginSize(originSize);
                this.updateMaskedCache();
            });
        }
        else {
            this.updateTextureOriginSize(originSize);
        }
        if (option) {
            super.parseJSON(option);
            this.setImageAttrs(option);
            if (option?.mask) {
                this.importMaskJSON(option?.mask);
            }
            this.texture.baseTexture.once('update', () => {
                this.updateProcessEffect();
                this.updateMaskedCache();
            });
            this.texture.update();
        }
        if (typeof img === 'string') {
            this.originBase64 = img;
        }
        else {
            this.originBase64 = imgToBase64(img);
        }
    }
    getOriginSizeFromJSON(option) {
        if (option) {
            if (isFinite(option.originWidth) && isFinite(option.originHeight)) {
                return {
                    width: option.originWidth,
                    height: option.originHeight,
                };
            }
            else if (isFinite(option.width) &&
                isFinite(option.height) &&
                isFinite(option.scale.x) &&
                isFinite(option.scale.y)) {
                return {
                    width: option.width / option.scale.x,
                    height: option.height / option.scale.y,
                };
            }
        }
        return undefined;
    }
    updateTextureOriginSize(originSize) {
        if (originSize) {
            const { width, height } = originSize;
            if (isFinite(width) && isFinite(height)) {
                this.texture.baseTexture.setRealSize(width, height);
            }
        }
    }
    updateOriginDatasource(canvas, ctx) {
        canvas = this.updateOriginTexture(canvas, ctx);
        this.updateOriginBase64(canvas);
    }
    updateOriginTexture(canvas, ctx) {
        if (!canvas) {
            ({ canvas, ctx } = createSpriteCanvas(this));
        }
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        this.originTextureData = imageData;
        return canvas;
    }
    updateOriginBase64(canvas) {
        this.originBase64 = imgToBase64(canvas);
    }
    // @ts-ignore
    toJSON(relativeEle, options) {
        const { texture = false } = options || {};
        const { filterList, grayValue, sharpness, colorInverted } = this;
        let base64 = '';
        if (texture) {
            base64 = this.originBase64;
        }
        const baseJSON = super.toJSON(relativeEle);
        const { width: originWidth, height: originHeight } = this.texture;
        return {
            base64,
            ...baseJSON,
            width: originWidth * baseJSON.scale.x,
            height: originHeight * baseJSON.scale.y,
            isFill: this.isFill,
            filterList: filterList.slice(),
            grayValue: grayValue.slice(),
            sharpness,
            colorInverted,
            filterAttrsMap: cloneDeep(this.filterAttrsMap),
            mask: this.exportMaskJSON(relativeEle, options),
            originWidth,
            originHeight,
        };
    }
    // @ts-ignore
    parseJSON(data) {
        const { base64 } = data;
        if (base64) {
            // 由于base64加载需要时间，这时候的宽高是0，所以要提前设置一下宽高
            // 通过base64数据创建时 设置宽高需要在设置scale属性(super.parseJSON(data))之前，因为设置宽高会先改变scale的值，而此时texture未加载完成，此时换算得到的scale值是错误的，
            // 所以参数中的scale值的设置在这一步之后
            const { width, height } = data;
            this.width = width || this.width;
            this.height = height || this.height;
        }
        super.parseJSON(data);
        if (data.base64 && data.base64 !== this.originBase64) {
            // pixi中当source为string时，baseTexture的cacheId就是source，如果source为base64就区分不了
            const img = new Image();
            img.src = data.base64;
            this.setupTexture(img);
            const forceUpdate = !!this.originTextureData;
            this.setImageAttrs(data, forceUpdate);
            this.texture.baseTexture.once('update', () => {
                this.updateTextureOriginSize(this.getOriginSizeFromJSON(data));
                this.updateOriginDatasource();
                /**  resource被压缩的情况下，这里调用完{@link updateOriginDatasource()}后需要修正originBase64 */
                this.originBase64 = data.base64;
                this.updateProcessEffect();
                if ('mask' in data) {
                    this.importMaskJSON(data.mask);
                }
            });
            this.texture.update();
        }
        else {
            if (this.texture.baseTexture.resource.valid) {
                this.setImageAttrs(data);
                this.updateProcessEffect();
                if ('mask' in data) {
                    this.importMaskJSON(data.mask);
                }
            }
        }
    }
    /**
     * 设置图像处理属性
     */
    setImageAttrs(data, forceUpdate = false) {
        // 选取有效的变更属性
        const { attrs: changed } = this.pickValidChangedProcessAttrs(data);
        // 检测图像处理更新类型
        if (forceUpdate) {
            this.updateProcessTypes.push('imageData', 'filter');
        }
        else {
            this.updateProcessTypes.push(...this.resolveUpdateProcessTypes(changed));
        }
        // 写入变更属性
        Object.entries(changed).forEach(([key, value]) => {
            if (key in this) {
                this[key] = isPlainObject(value) ? merge(this[key], value) : value;
            }
        });
    }
    /**
     * 选取有效的、变更的图像处理属性
     */
    pickValidChangedProcessAttrs(data, current = this) {
        const { filterList, grayValue, sharpness, colorInverted, filterAttrsMap } = data;
        const changedAttrs = {};
        // 更新前类型校验，记录实际变更属性名
        if (Array.isArray(grayValue) &&
            grayValue.length === 2 &&
            isNumber(grayValue[0]) &&
            isNumber(grayValue[1]) &&
            !isEqual(current.grayValue, grayValue)) {
            changedAttrs.grayValue = grayValue;
        }
        if (isNumber(sharpness) && current.sharpness !== sharpness) {
            changedAttrs.sharpness = sharpness;
        }
        if (isBoolean(colorInverted) && current.colorInverted !== colorInverted) {
            changedAttrs.colorInverted = colorInverted;
        }
        if (Array.isArray(filterList) &&
            filterList.every((v) => isString(v)) &&
            !isEqual(current.filterList, filterList)) {
            changedAttrs.filterList = filterList;
        }
        if (isNumber(filterAttrsMap?.emboss?.strength) &&
            !isEqual(current.filterAttrsMap?.emboss, filterAttrsMap?.emboss)) {
            changedAttrs.filterAttrsMap = filterAttrsMap;
        }
        return { attrs: changedAttrs };
    }
    /**
     * 确定图像处理更新类型
     */
    resolveUpdateProcessTypes(changed, current = this) {
        const attrNames = new Set(Object.keys(changed));
        const updateTypes = new Set();
        if (['grayValue', 'sharpness', 'colorInverted'].some((name) => attrNames.has(name))) {
            updateTypes.add('imageData');
        }
        const shaderFilterTypes = ['dot', 'emboss'];
        if (attrNames.has('filterList')) {
            [...changed.filterList, ...current.filterList].forEach((filter) => {
                if (shaderFilterTypes.includes(filter)) {
                    updateTypes.add('filter');
                }
                else {
                    updateTypes.add('imageData');
                }
            });
        }
        if (attrNames.has('filterAttrsMap')) {
            const activeFilter = current.filterList[0];
            if (activeFilter in changed.filterAttrsMap &&
                !isEqual(changed.filterAttrsMap[activeFilter], current.filterAttrsMap[activeFilter])) {
                if (shaderFilterTypes.includes(activeFilter)) {
                    updateTypes.add('filter');
                }
                else {
                    updateTypes.add('imageData');
                }
            }
        }
        return updateTypes;
    }
    /**
     * 更新图像处理效果
     */
    updateProcessEffect(updateTypes = this.updateProcessTypes) {
        const { grayValue, sharpness, colorInverted, filterList, filterAttrsMap } = this;
        const filters = filterList.map((filterType) => {
            if (filterType.startsWith('comic')) {
                return { type: 'comic', params: filterType };
            }
            return {
                type: filterType,
                params: filterAttrsMap[filterType],
            };
        });
        const shaderFilterTypes = ['dot', 'emboss'];
        const { shaderFilter = [], jsFilter = [] } = groupBy(filters, (filter) => {
            return shaderFilterTypes.includes(filter.type)
                ? 'shaderFilter'
                : 'jsFilter';
        });
        if (updateTypes.includes('imageData')) {
            const settings = this.getUpdateImageDataSetting(grayValue, colorInverted, sharpness, jsFilter);
            this.updateImageTexture(settings);
        }
        // shader滤镜
        if (updateTypes.includes('filter')) {
            this.updateFilterList(shaderFilter);
        }
        this.updateProcessTypes = [];
    }
    getUpdateImageDataSetting(grayValue, colorInverted, sharpness, jsFilter) {
        const settings = [];
        // 灰度
        if (!isEqual(grayValue, [0, 255])) {
            settings.push({ name: 'grayValue', value: grayValue });
        }
        // 反色
        if (colorInverted !== false) {
            settings.push({ name: 'colorInverted', value: colorInverted });
        }
        // 清晰度
        if (sharpness !== 50) {
            settings.push({ name: 'sharpness', value: sharpness });
        }
        // js滤镜(ImageData)
        if (jsFilter.length > 0) {
            const convertedJsFilter = jsFilter.map((item) => {
                return { name: item.type, value: item.params };
            });
            settings.push(...convertedJsFilter);
        }
        // 如果settings不为空，第一个必须是灰度处理
        if (settings.length > 0 && settings[0].name !== 'grayValue') {
            settings.unshift({ name: 'grayValue', value: grayValue });
        }
        return settings;
    }
    cloneWithoutCompress(relativeEle) {
        const newSprite = MSprite.from(this.texture.clone());
        const json = this.toJSON(relativeEle || this.parent);
        newSprite.parseJSON(json);
        importMaskJSONToSprite(newSprite, this.exportMaskJSON(relativeEle || this.parent));
        newSprite.filters = this.filters;
        const originRatio = this.texture.width / this.texture.height;
        const ratio = this.width / this.height;
        // x 方向拉伸 较多时，高度的像素先恢复至原图的高度，宽度按比例拉伸
        // y 方向拉伸 较多时，宽度的像素先恢复至原图的宽度，高度按比例拉伸
        // 确保输出的像素数据至少包含了原图的像素数据
        if (ratio > originRatio) {
            newSprite.height = this.texture.height;
            newSprite.width = this.texture.height * ratio;
        }
        else {
            newSprite.width = this.texture.width;
            newSprite.height = this.texture.width / ratio;
        }
        return newSprite;
    }
    updateFilterList(filterList) {
        if (filterList.length === 0) {
            this.filters = [];
            return;
        }
        this.filters = [];
        filterList.forEach((filter) => {
            const { type, params } = filter;
            const hasFilterFunc = this[type] && isFunction(this[type]);
            if (hasFilterFunc) {
                this[type](params);
            }
        });
    }
    getTextureImageSource() {
        return this.texture.baseTexture.resource?.source;
    }
    updateImageTexture(settings) {
        const settingMap = {
            grayValue: applyGrayRanger,
            colorInverted: applyColorInversion,
            sharpness: applySharpness,
            // js 实现的滤镜
            comic: applyComic,
            sketch: applySketch,
            binary: applyBinary,
        };
        const { canvas, ctx } = createSpriteCanvas(this);
        if (!this.originTextureData) {
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            this.originTextureData = imageData;
        }
        let newImgData = this.originTextureData;
        if (settings.length > 0) {
            settings.forEach(({ name, value }) => {
                newImgData = settingMap[name](newImgData.data, canvas.width, canvas.height, value);
            });
        }
        ctx.putImageData(newImgData, 0, 0);
        this.setupTexture(canvas);
        this.texture.update();
    }
    dot() {
        this.filters.push(new DotFilter(1, 0));
    }
    // 浮雕
    emboss(params) {
        const { strength = 5 } = params || {};
        this.filters.push(new EmbossFilter(strength));
    }
    transformUpdate() {
        // 因为通过选中框进行缩放时控制的是scale的变化，
        // 这一步是运行一下width height的set get 方法, 最终可以设置内部的_width变量，_width值与后续渲染有关
        this.width = this.width;
        this.height = this.height;
    }
    embedImage(element) {
        this.x = element.x.baseVal.value;
        this.y = element.y.baseVal.value;
        if (element.getAttribute('width') !== null) {
            this.width = element.width.baseVal.value;
        }
        if (element.getAttribute('height') !== null) {
            this.height = element.height.baseVal.value;
        }
    }
    setupTexture(imgSource) {
        imgSource = getSafeTextureImageSource(imgSource);
        this.texture = Texture.from(imgSource);
    }
    hitTest(p) {
        const localBounds = this.mask
            ? this.getMaskedLocalBounds()
            : this.getLocalBounds();
        if (!localBounds.contains(p.x, p.y)) {
            return false;
        }
        const imageData = this.mask
            ? this.getMaskedImageData()
            : this.originTextureData;
        // resource可能被压缩
        if (this.isResourceScaled) {
            new Matrix()
                .scale(this.resourceScale.x, this.resourceScale.y)
                .apply(p, p);
        }
        p.x = Math.round(p.x);
        p.y = Math.round(p.y);
        const w = imageData.width;
        const h = imageData.height;
        const pRatio = (p.x + p.y * w) / (w * h);
        const pIndex = Math.round((imageData.data.length / 4) * pRatio);
        const a = imageData.data[pIndex * 4 + 3];
        // 如果位图当前点是透明的，则认为没点中
        if (a === 0) {
            return false;
        }
        return true;
    }
    // @ts-ignore
    getSVGData(options) {
        const fillAndStrokeAttrs = getSVGFillAndStrokeAttrs(this, options);
        let maskedLocalBounds;
        const imgAttrs = !this.mask
            ? {
                x: 0,
                y: 0,
                width: this.texture.width,
                height: this.texture.height,
                'xlink:href': this.originBase64,
            }
            : ((maskedLocalBounds = this.getMaskedLocalBounds()),
                {
                    x: maskedLocalBounds.x,
                    y: maskedLocalBounds.y,
                    width: maskedLocalBounds.width,
                    height: maskedLocalBounds.height,
                    'xlink:href': this.getMaskedBase64(),
                });
        return merge(super.getSVGData(options), {
            tag: 'image',
            attrs: {
                ...fillAndStrokeAttrs,
                ...imgAttrs,
            },
        });
    }
    getMaskedBase64() {
        const matrix = this.isResourceScaled
            ? new Matrix().scale(this.resourceScale.x, this.resourceScale.y)
            : undefined;
        const rect = boundsAddRectangle(new Bounds(), this.getMaskedLocalBounds(), matrix).getRectangle();
        return imageDataToBase64(this.getMaskedImageData(), rect);
    }
    // 剪切蒙版
    maskedImageData;
    maskedLocalBounds;
    get isMaskOverlap() {
        const maskedLocalBounds = this.getMaskedLocalBounds();
        return (maskedLocalBounds &&
            maskedLocalBounds.width > 0 &&
            maskedLocalBounds.height > 0);
    }
    get isMaskEmbed() {
        return contains(this, getMaskObj(this));
    }
    exportMaskJSON(_, options) {
        let maskJSON;
        if (this.mask instanceof DisplayObject) {
            maskJSON = this.mask.toJSON(this, options);
            this.mask.updateTransform();
        }
        return maskJSON ?? null;
    }
    importMaskJSON(maskJSON) {
        importMaskJSONToSprite(this, maskJSON);
        this.updateMaskedCache();
    }
    updateMaskedCache() {
        const maskObj = getMaskObj(this);
        if (maskObj) {
            const { width, height } = getImageSourceSize(this.getTextureImageSource());
            if (!width || !height) {
                return;
            }
            maskObj.updateTransform();
            const { imageData, rect } = this.calculateMaskedCache();
            this.maskedImageData = imageData;
            this.maskedLocalBounds = rect;
        }
        else {
            this.maskedImageData = undefined;
            this.maskedLocalBounds = undefined;
        }
    }
    calculateMaskedCache() {
        const imageData = getBitmapClippedImageData(this);
        // resource可能被压缩
        let matrix;
        if (this.isResourceScaled) {
            matrix = new Matrix().scale(1 / this.resourceScale.x, 1 / this.resourceScale.y);
        }
        const rect = getImageDataBounds(imageData, matrix);
        return { imageData, rect };
    }
    getTransformerBounds() {
        if (this.mask && this.isMaskEmbed) {
            const localBounds = this.getMaskedLocalBounds();
            if (localBounds && localBounds.width && localBounds.height) {
                return {
                    localBounds,
                    transform: this.worldTransform,
                };
            }
            else {
                const maskObj = getMaskObj(this);
                return {
                    localBounds: maskObj.getLocalBounds(),
                    transform: maskObj.worldTransform,
                };
            }
        }
        else {
            return {
                localBounds: this.getLocalBounds(),
                transform: this.worldTransform,
            };
        }
    }
    /**
     * @cached
     */
    getMaskedImageData() {
        if (!this.maskedImageData) {
            this.updateMaskedCache();
        }
        return this.maskedImageData;
    }
    /**
     * @cached
     */
    getMaskedLocalBounds() {
        if (!this.maskedLocalBounds) {
            this.updateMaskedCache();
        }
        return this.maskedLocalBounds;
    }
    getRealBounds() {
        return boundsAddRectangle(new Bounds(), this.mask ? getDisplayBoundsForMaskedSprite(this) : this.getBounds());
    }
}
