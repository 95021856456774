import { colord } from 'colord';
// TODO: 需要优化svg 颜色转换的方式，这种方式会全局生效，无法满足不需要修改的场景
export class ColorUtil {
    static converToLayerColor(color) {
        return color;
    }
    // 将svg对象的fill状态进行转换，这里默认是转换为不填充
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static convertFillColor(color) {
        return false;
    }
    static convertToLayerColorHex(color) {
        color = colord(color).toHex();
        color = ColorUtil.converToLayerColor(color);
        color = colord(color).toHex();
        return color;
    }
}
