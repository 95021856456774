import { Point } from 'pixi.js';
import { divisionMatrix } from '../utils';
import { getInteractiveElements } from './container';
export function createOperationLog(curElements, containers, relativeEle, options = { texture: true }) {
    // 记录当前画布内的所有元素的id
    const allElements = getInteractiveElements(...containers);
    const allIDs = new Set();
    allElements.forEach((element) => {
        allIDs.add(element.id);
    });
    const { curIDs, curAttributes } = getElementsLogAttrs(curElements, relativeEle, options);
    curIDs.forEach((id) => {
        // 所有元素的id是画布剩余的元素的id与被操作的元素的id的合并
        allIDs.add(id);
    });
    const log = {
        allIDs,
        curIDs,
        curAttributes,
    };
    return log;
}
export function getElementsLogAttrs(curElements, relativeEle, options = { texture: true }) {
    const curAttributes = new Map();
    const curIDs = new Set();
    // 记录被操作的对象的id列表和属性
    curElements.forEach((element) => {
        curIDs.add(element.id);
        // 更新 localTransform 与 worldTransform
        element.transform.updateLocalTransform();
        element.updateTransform();
        const json = element.toJSON(relativeEle, options);
        const matrixByViewport = divisionMatrix(element.transform.worldTransform.clone(), relativeEle.transform.worldTransform.clone());
        curAttributes.set(element.id, {
            ...json,
            matrixByViewport,
        });
    });
    return {
        curIDs,
        curAttributes,
    };
}
// relativeEle（viewport）记录的坐标转换成元素当前所在容器的坐标
export function transformRelativePosToLocal(display, relativeEle, x, y) {
    let pos = relativeEle.toGlobal(new Point(x, y));
    pos = display.parent.toLocal(pos);
    display.x = pos.x;
    display.y = pos.y;
}
