import { INTERACTION_EVENT } from '../../../types';
import { Recognizer } from './Recognizer';
export class Pan extends Recognizer {
    changing = false;
    count = 0;
    constructor() {
        super();
        this.options = {
            event: INTERACTION_EVENT.PAN,
            pointers: 2,
            angle: 5,
            distance: 5,
            dithering: 5,
        };
    }
    process(input) {
        const { options } = this;
        const validPointers = input.pointers.length === options.pointers;
        const validAngle = 
        // 0, 90, -90, -180
        Math.abs(input.angle) < options.angle ||
            Math.abs(input.angle - 90) < options.angle ||
            Math.abs(input.angle + 90) < options.angle ||
            Math.abs(input.angle + 180) < options.angle;
        if (validPointers) {
            if (input.eventType & 2 /* INPUT.MOVE */) {
                if (validAngle && input.distance > options.distance) {
                    this.count++;
                    // 处理抖动问题
                    if (this.count >= options.dithering) {
                        if (this.count === options.dithering) {
                            return 2 /* STATE.BEGAN */;
                        }
                        this.changing = true;
                        return 4 /* STATE.CHANGED */;
                    }
                }
            }
        }
        else if (input.eventType & 4 /* INPUT.END */ && input.isFinal) {
            if (this.changing) {
                this.changing = false;
                this.count = 0;
                return 8 /* STATE.ENDED */;
            }
            this.changing = false;
            this.count = 0;
            return 32 /* STATE.FAILED */;
        }
        return 32 /* STATE.FAILED */;
    }
    reset() { }
}
