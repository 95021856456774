import { EventEmitter } from '@pixi/utils';
import { DEFAULT_DISPLAY_CONFIG } from '../config';
import { CONFIG_EVENT } from '../types';
export default class ConfigManager extends EventEmitter {
    #displayConfig = DEFAULT_DISPLAY_CONFIG;
    #imageProcessConfig;
    get displayConfig() {
        return this.#displayConfig;
    }
    set displayConfig(config) {
        this.#displayConfig = {
            ...this.#displayConfig,
            ...config,
        };
        this.emit(CONFIG_EVENT.DISPLAY_CONFIG_CHANGE, this.#displayConfig);
    }
    get imageProcessConfig() {
        return this.#imageProcessConfig;
    }
    set imageProcessConfig(config) {
        this.#imageProcessConfig = config;
    }
}
