import { CANVAS_EVENT, EDIT_MODE, TRANSFORMER_EVENT } from '../types';
import { updateDisplayAttributes } from '../utils';
import { getInteractiveElementMap } from '../utils/container';
import { createOperationLog } from '../utils/operationStack';
import { AbstractBase } from './AbstractBase';
export class AbstractHistory extends AbstractBase {
    /**
     * 是否可撤销
     *
     * @readonly
     * @memberof MCanvas
     */
    get canUndo() {
        if (this.imageProcess) {
            return this.imageProcess.canUndo;
        }
        if (this.imageTracing) {
            return false;
        }
        return this.operationStack.canUndo;
    }
    /**
     * 是否可重做
     *
     * @readonly
     * @memberof MCanvas
     */
    get canRedo() {
        if (this.imageProcess) {
            return this.imageProcess.canRedo;
        }
        if (this.imageTracing) {
            return false;
        }
        return this.operationStack.canRedo;
    }
    /**
     * 撤销
     *
     * @memberof MCanvas
     */
    undo() {
        if (this.imageProcess?.canUndo) {
            this.imageProcess.undo();
            return;
        }
        if (this.imageTracing) {
            return;
        }
        if (!this.canUndo) {
            return;
        }
        this.transformer.clear();
        const top = this.operationStack.top;
        const { curIDs, otherData } = top;
        this.operationStack.undo();
        // 撤销重做时根据操作栈数据处理即将增删改的元素:更新xcs中layerData
        this.emit(CANVAS_EVENT.BEFORE_HISTORY_CHANG, 'undo', { otherData });
        top?.onUndo?.();
        if (curIDs) {
            this.redraw(curIDs, false);
        }
        this.emit(CANVAS_EVENT.HISTORY_CHANG, 'undo', { otherData });
    }
    redo() {
        if (this.imageProcess?.canRedo) {
            this.imageProcess.redo();
            return;
        }
        if (this.imageTracing) {
            return;
        }
        if (!this.canRedo) {
            return;
        }
        this.transformer.clear();
        this.operationStack.redo();
        const top = this.operationStack.top;
        const { curIDs, otherData } = top;
        // 撤销重做时根据操作栈数据处理即将增删改的元素:更新xcs中layerData
        this.emit(CANVAS_EVENT.BEFORE_HISTORY_CHANG, 'redo', { otherData });
        if (top?.onRedo) {
            top?.onRedo?.();
        }
        if (curIDs) {
            this.redraw(curIDs, true);
        }
        this.emit(CANVAS_EVENT.HISTORY_CHANG, 'redo', { otherData });
    }
    redraw(curIDs, byRedo) {
        const top = this.operationStack.top;
        const allElements = getInteractiveElementMap(this.viewport, this.tempLayer);
        // top 为空，清空所有元素
        if (!top) {
            allElements.forEach((element) => {
                // 因为可能对象内部后续会对children作其他操作，这里不对对象进行深度销毁
                this.removeElement(element, false);
            });
            this.updateEditMode(EDIT_MODE.SELECT);
            this.curDisplayObject = undefined;
            // 执行撤销重做时统一派发 TRANSFORMER_EVENT.TRANSFORM_CHANGE 的事件
            this.transformer.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, 'all');
            return;
        }
        const idsWillAdd = new Set(top.allIDs);
        allElements.forEach((element) => {
            const id = element.id;
            // 移除不需要在画布上的
            if (!top.allIDs.has(id)) {
                this.removeElement(element, false);
                this.updateEditMode(EDIT_MODE.SELECT);
            }
            else {
                idsWillAdd.delete(id);
            }
        });
        const getAttributesByID = (id) => {
            // 如果 top 中正好有
            if (top.curAttributes.has(id)) {
                return top.curAttributes.get(id);
            }
            const stack = this.operationStack.undoStack;
            // 倒序遍历栈
            for (let index = stack.length - 1; index >= 0; index--) {
                const log = stack[index];
                if (log?.curAttributes?.has(id)) {
                    return log.curAttributes.get(id);
                }
            }
            // 在栈中找不到，尝试在导入的 initOperation 中查找
            const initOperation = this.operationStack.initOperation;
            if (initOperation) {
                if (initOperation.curAttributes.has(id)) {
                    return initOperation.curAttributes.get(id);
                }
            }
            return undefined;
        };
        // 更新在画布上的
        const updateOnCanvas = (updatedIDs) => {
            updatedIDs.forEach((id) => {
                const els = allElements.getKey(id);
                const elements = els ? [...els] : [];
                if (elements.length === 1) {
                    const element = elements[0];
                    // 有可能在此时 element 已经被销毁了
                    if (element && !element.destroyed) {
                        const data = getAttributesByID(element.id);
                        if (data) {
                            // TODO: 临时处理加工参数不能同时撤销的问题，暂时忽略元素填充状态的撤销,
                            delete data.isFill;
                            if (element.updateData) {
                                element.updateData(data, byRedo);
                            }
                            updateDisplayAttributes(element, data, this.viewport);
                            if (element.isVaild?.() === false) {
                                this.removeElement(element, false);
                            }
                        }
                    }
                }
                else if (elements.length > 1) {
                    console.error('find redundant elements', id);
                }
            });
        };
        // 更新 top.curIDs
        updateOnCanvas(curIDs);
        // 添加没在画布上的
        idsWillAdd.forEach((id) => {
            const json = getAttributesByID(id);
            // TODO: 临时方案 处理填充状态撤销问题
            if (json) {
                json.isFill = this.elementsFillMap[json.id];
                const objs = this.addDisplayToContainerByJSON([json], false, false);
                objs.forEach((obj) => {
                    if (obj.isVaild?.() === false) {
                        this.removeElement(obj, false);
                    }
                });
            }
            else {
                console.log('can not find attributes for', id);
            }
        });
        // 执行撤销重做时统一派发 TRANSFORMER_EVENT.TRANSFORM_CHANGE 的事件
        this.transformer.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, 'all');
    }
    recordOperationLog(...data) {
        const log = this.createOperationLogOnViewport(data);
        this.recordFinalOperationLog(log);
    }
    recordOperationLogWithProps(objs, props) {
        const log = this.createOperationLogOnViewport(objs);
        Object.assign(log, props);
        this.recordFinalOperationLog(log);
    }
    recordFinalOperationLog(log) {
        const otherData = this.getOtherData();
        const recordLog = otherData ? { otherData, ...log } : log;
        this.operationStack.push(recordLog);
    }
    createOperationLogOnViewport(curElements) {
        const log = createOperationLog(curElements, [this.viewport, this.tempLayer], this.viewport, { texture: true });
        return log;
    }
}
