import { INTERACTION_EVENT } from '../../../types';
import { Recognizer } from './Recognizer';
export class LongPress extends Recognizer {
    constructor() {
        super();
        this.options = {
            event: INTERACTION_EVENT.LONG_PRESS,
            pointers: 1,
            time: 1000,
            threshold: 9, // 移动的距离不超过
        };
    }
    process(input) {
        const { options } = this;
        const validPointers = input.pointers.length === options.pointers;
        const validMovement = input.distance < options.threshold;
        const validTouchTime = input.deltaTime >= options.time;
        if (input.eventType & 1 /* INPUT.START */ && validMovement && validPointers) {
            return 2 /* STATE.BEGAN */;
        }
        else if (input.isFinal &&
            input.maxPointers === options.pointers &&
            validTouchTime) {
            return 8 /* STATE.RECOGNIZED */;
        }
        return 32 /* STATE.FAILED */;
    }
    reset() { }
}
