// @ts-nocheck
import { Cull } from '@pixi-essentials/cull';
import { CanvasTextureAllocator } from '@pixi-essentials/texture-allocator';
import { RenderTexture, Texture } from '@pixi/core';
import { Container, DisplayObject } from '@pixi/display';
import { LINE_CAP, LINE_JOIN } from '@pixi/graphics';
import { Matrix, Rectangle } from '@pixi/math';
import { colord } from 'colord';
import { MCircle, MGraphic, MLine, MSprite } from '../../display';
import { ColorUtil } from '../../display/utils/Color';
import { isImageDataURL } from '../../utils';
import { SVGGraphicsNode } from './SVGGraphicsNode';
import { SVGPathNode } from './SVGPathNode';
import { SVGUseNode } from './SVGUseNode';
import * as Loader from './loader';
import { MaskServer } from './mask/MaskServer';
import { InheritedPaintProvider } from './paint/InheritedPaintProvider';
import { PaintProvider } from './paint/PaintProvider';
import { PaintServer } from './paint/PaintServer';
const tempMatrix = new Matrix();
const tempRect = new Rectangle();
/**
 * {@link SVGScene} can be used to build an interactive viewer for scalable vector graphics images. You must specify the size
 * of the svg viewer.
 *
 * ## SVG Scene Graph
 *
 * SVGScene has an internal, disconnected scene graph that is optimized for lazy updates. It will listen to the following
 * events fired by a node:
 *
 * * `nodetransformdirty`: This will invalidate the transform calculations.
 *
 * @public
 */
export class SVGScene extends DisplayObject {
    /**
     * The SVG image content being rendered by the scene.
     */
    content;
    /**
     * The root display object of the scene.
     */
    root;
    /**
     * Display objects that don't render to the screen, but are required to update before the rendering
     * nodes, e.g. mask sprites.
     */
    renderServers;
    /**
     * The scene context
     */
    _context;
    /**
     * The width of the rendered scene in local space.
     */
    _width;
    /**
     * The height of the rendered scene in local space.
     */
    _height;
    /**
     * This is used to cull the SVG scene graph before rendering.
     */
    _cull;
    /**
     * Maps content elements to their paint. These paints do not inherit from their parent element. You must
     * compose an {@link InheritedPaintProvider} manually.
     */
    _elementToPaint;
    /**
     * Maps `SVGMaskElement` elements to their nodes. These are not added to the scene graph directly and are
     * only referenced as a `mask`.
     */
    _elementToMask;
    /**
     * Flags whether any transform is dirty in the SVG scene graph.
     */
    _transformDirty;
    sortDirty = false;
    /**
     * @param content - The SVG node to render
     * @param context - This can be used to configure the scene
     */
    constructor(content, context, parseColor = true) {
        super();
        this.content = content;
        this.parseColor = parseColor;
        this.initContext(context);
        this._width = content.viewBox.baseVal.width;
        this._height = content.viewBox.baseVal.height;
        this._cull = new Cull({ recursive: true, toggle: 'renderable' });
        this._elementToPaint = new Map();
        this._elementToMask = new Map();
        this._transformDirty = true;
        this.renderServers = new Container();
        console.log('[ this.content ] >', this.content.sheet);
        if (!context || !context.disableRootPopulation) {
            this.populateScene();
        }
    }
    initContext(context) {
        context = context || {};
        context.atlas = context.atlas || new CanvasTextureAllocator(2048, 2048);
        context.disableHrefSVGLoading =
            typeof context.disableHrefSVGLoading === 'undefined'
                ? false
                : context.disableHrefSVGLoading;
        this._context = context;
    }
    /**
     * Calculates the bounds of this scene, which is defined by the set `width` and `height`. The contents
     * of this scene are scaled to fit these bounds, and don't affect them whatsoever.
     *
     * @override
     */
    calculateBounds() {
        this._bounds.clear();
        this._bounds.addFrameMatrix(this.worldTransform, 0, 0, this.content.viewBox.baseVal.width, this.content.viewBox.baseVal.height);
    }
    removeChild() {
        // Just to implement DisplayObject
    }
    /**
     * @override
     */
    render(renderer) {
        if (!this.visible || !this.renderable) {
            return;
        }
        // Update render-server objects
        this.renderServers.render(renderer);
        // Cull the SVG scene graph
        this._cull.cull(renderer.renderTexture.sourceFrame, true);
        // Render the SVG scene graph
        this.root.render(renderer);
        // Uncull the SVG scene graph. This ensures the scene graph is fully 'renderable'
        // outside of a render cycle.
        this._cull.uncull();
    }
    /**
     * @override
     */
    updateTransform() {
        super.updateTransform();
        this.root.alpha = this.worldAlpha;
        const worldTransform = this.worldTransform;
        const rootTransform = this.root.transform.worldTransform;
        // Don't update transforms if they didn't change across frames. This is because the SVG scene graph is static.
        if (rootTransform.a === worldTransform.a &&
            rootTransform.b === worldTransform.b &&
            rootTransform.c === worldTransform.c &&
            rootTransform.d === worldTransform.d &&
            rootTransform.tx === worldTransform.tx &&
            rootTransform.ty === worldTransform.ty &&
            rootTransform._worldID !== 0 &&
            !this._transformDirty) {
            return;
        }
        this.root.enableTempParent();
        this.root.transform.setFromMatrix(this.worldTransform);
        this.root.updateTransform();
        this.root.disableTempParent(null);
        // Calculate bounds in the SVG scene graph. This ensures they are updated whenever the transform changes.
        this.root.calculateBounds();
        // Prevent redundant recalculations.
        this._transformDirty = false;
    }
    /**
     * Creates a display object that implements the corresponding `embed*` method for the given node.
     *
     * @param element - The element to be embedded in a display object.
     */
    createNode(element) {
        if (!element) {
            return null;
        }
        let renderNode = null;
        switch (element.nodeName.toLowerCase()) {
            case 'circle':
                renderNode = new MCircle();
                break;
            case 'line':
                renderNode = new MLine();
                break;
            case 'polyline':
                if (element.points.length === 2) {
                    renderNode = new MLine();
                }
                else {
                    renderNode = new SVGGraphicsNode();
                }
                break;
            case 'ellipse':
            case 'g':
            case 'polygon':
            // switch 标签在规范中用作条件选择，具体可看 https://developer.mozilla.org/zh-CN/docs/Web/SVG/Element/switch
            // 使用 switch测试文件 在几款软件（lb, gf, cricut）中进行测试，都会将标签内的内容全部渲染出，无法做到浏览器的条件选择
            // 所以在xcs中遇到 switch 标签会当成一个容器，将包裹的内容全部解析渲染
            case 'switch':
            case 'rect':
                renderNode = new SVGGraphicsNode(this._context);
                break;
            case 'image':
                const imageURL = element.getAttribute('href') || element.getAttribute('xlink:href');
                renderNode = isImageDataURL(imageURL) ? new MSprite(imageURL) : null;
                break;
            case 'mask':
            case 'svg':
                renderNode = new Container();
                break;
            case 'path':
                renderNode = new SVGPathNode(this._context);
                break;
            // 没有对svg中的text完全支持，包括渲染，包括后续的输出打印数据，先忽略解析
            // case 'text':
            //   renderNode = new SVGTextNode();
            //   break;
            case 'use':
                renderNode = new SVGUseNode();
                break;
            default:
                renderNode = null;
                break;
        }
        return renderNode;
    }
    /**
     * Creates a `Paint` object for the given element. This should only be used when sharing the `Paint`
     * is not desired; otherwise, use {@link SVGScene.queryPaint}.
     *
     * This will return `null` if the passed element is not an instance of `SVGElement`.
     *
     * @alpha
     * @param element
     */
    createPaint(element) {
        if (!(element instanceof SVGElement)) {
            return null;
        }
        return new PaintProvider(element);
    }
    /**
     * Creates a lazy paint texture for the paint server.
     *
     * @alpha
     * @param paintServer - The paint server to be rendered.
     */
    createPaintServer(paintServer) {
        const renderTexture = RenderTexture.create({
            width: 128,
            height: 128,
        });
        return new PaintServer(paintServer, renderTexture);
    }
    /**
     * Creates a lazy luminance mask for the `SVGMaskElement` or its rendering node.
     *
     * @param ref - The `SVGMaskElement` or its rendering node, if already generated.
     */
    createMask(ref) {
        if (ref instanceof SVGElement) {
            ref = this.populateSceneRecursive(ref, {
                basePaint: this.queryInheritedPaint(ref),
            });
        }
        const localBounds = ref.getLocalBounds();
        ref.getBounds();
        const maskTexture = RenderTexture.create({
            width: localBounds.width,
            height: localBounds.height,
        });
        const maskSprite = new MaskServer(maskTexture);
        // Lazily render mask when needed.
        maskSprite.addChild(ref);
        return maskSprite;
    }
    /**
     * Returns the rendering node for a mask.
     *
     * @alpha
     * @param ref - The mask element whose rendering node is needed.
     */
    queryMask(ref) {
        let queryHit = this._elementToMask.get(ref);
        if (!queryHit) {
            queryHit = this.createMask(ref);
            this._elementToMask.set(ref, queryHit);
        }
        return queryHit;
    }
    /**
     * Returns the cached paint of a content element. The returned paint will not account for any paint
     * attributes inherited from ancestor elements.
     *
     * @alpha
     * @param ref - A reference to the content element.
     */
    queryPaint(ref) {
        let queryHit = this._elementToPaint.get(ref);
        if (!queryHit) {
            queryHit = this.createPaint(ref);
            this._elementToPaint.set(ref, queryHit);
        }
        return queryHit;
    }
    /**
     * Returns an (uncached) inherited paint of a content element.
     *
     * @alpha
     * @param ref
     */
    queryInheritedPaint(ref) {
        const paint = this.queryPaint(ref);
        const parentPaint = ref.parentElement &&
            this.queryPaint(ref.parentElement);
        if (!parentPaint) {
            return paint;
        }
        return new InheritedPaintProvider(parentPaint, paint);
    }
    /**
     * Parses the internal URL reference into a selector (that can be used to find the
     * referenced element using `this.content.querySelector`).
     *
     * @param url - The reference string, e.g. "url(#id)", "url('#id')", "#id"
     */
    parseReference(url) {
        if (url.startsWith('url')) {
            let contents = url.slice(4, -1);
            if (contents.startsWith("'") && contents.endsWith("'")) {
                contents = contents.slice(1, -1);
            }
            return contents;
        }
        return url;
    }
    /**
     * Embeds a content `element` into the rendering `node`.
     *
     * This is **not** a stable API.
     *
     * @alpha
     * @param node - The node in this scene that will render the `element`.
     * @param element - The content `element` to be rendered. This must be an element of the SVG document
     *  fragment under `this.content`.
     * @param options - Additional options
     * @param {Paint} [options.basePaint] - The base paint that the element's paint should inherit from
     * @return The base attributes of the element, like paint.
     */
    embedIntoNode(node, element, options = {}) {
        const { basePaint } = options;
        // Paint
        const paint = basePaint
            ? new InheritedPaintProvider(basePaint, this.queryPaint(element))
            : this.queryPaint(element);
        const { 
        // fill,
        // opacity,
        stroke, strokeDashArray, strokeDashOffset, strokeLineCap, strokeLineJoin, strokeMiterLimit, strokeWidth, } = paint;
        // Transform
        const transform = element instanceof SVGGraphicsElement
            ? element.transform.baseVal.consolidate()
            : null;
        // identity()只是重置matrix为单位矩阵，不会返回新的矩阵，
        // 如果这里不进行clone()，递归情况下会污染先前的transformMatrix。
        // 比如元素是<use>时，会通过populateSceneRecursive()再次递归到这里，
        // 递归前<use>的transformMatrix会被错误地修改，导致实际transform不正确
        const transformMatrix = tempMatrix.clone().identity();
        // 要将直接从标签中取到的matrix转换为 pixi的matrix
        if (transform && transform.matrix) {
            const { matrix: { a, b, c, d, e, f }, } = transform;
            transformMatrix.set(a, b, c, d, e, f);
        }
        if (node instanceof SVGGraphicsNode) {
            node.beginFill(0, 0);
            // if (fill === 'none') {
            //   node.beginFill(0, 0);
            // } else if (typeof fill === 'number') {
            //   node.beginFill(fill, opacity === null ? 1 : opacity);
            // } else if (!fill) {
            //   // 在标签中没有显式设置fill属性 默认设置为 none
            //   node.beginFill(0, 0);
            // } else {
            //   // 在标签中通过其他方式设置的fill属性(如 fill="url(#linearGradient-3)"),原逻辑有误，直接设置为默认值
            //   node.beginFill(0, 0);
            //   // const ref = this.parseReference(fill);
            //   // const paintElement = this.content.querySelector(ref);
            //   // if (paintElement && paintElement instanceof SVGGradientElement) {
            //   //   const paintServer = this.createPaintServer(paintElement);
            //   //   const paintTexture = paintServer.paintTexture;
            //   //   node.paintServers.push(paintServer);
            //   //   node.beginTextureFill({
            //   //     texture: paintTexture,
            //   //     alpha: opacity === null ? 1 : opacity,
            //   //     matrix: new Matrix(),
            //   //   });
            //   // }
            // }
            let strokeTexture;
            if (typeof stroke === 'string' && stroke.startsWith('url')) {
                const ref = this.parseReference(stroke);
                const paintElement = this.content.querySelector(ref);
                if (paintElement && paintElement instanceof SVGGradientElement) {
                    const paintServer = this.createPaintServer(paintElement);
                    const paintTexture = paintServer.paintTexture;
                    node.paintServers.push(paintServer);
                    strokeTexture = paintTexture;
                }
            }
            // attributes 的优先级最低最先查找
            const color = stroke === null ? 0 : typeof stroke === 'number' ? stroke : 0xffffff;
            node.lineTextureStyle({
                /* eslint-disable no-nested-ternary */
                color,
                cap: strokeLineCap === null
                    ? LINE_CAP.SQUARE
                    : strokeLineCap,
                dashArray: strokeDashArray,
                dashOffset: strokeDashOffset === null ? strokeDashOffset : 0,
                join: strokeLineJoin === null
                    ? LINE_JOIN.MITER
                    : strokeLineJoin,
                matrix: new Matrix(),
                miterLimit: strokeMiterLimit === null ? 150 : strokeMiterLimit,
                texture: strokeTexture || Texture.WHITE,
                width: strokeWidth === null
                    ? typeof stroke === 'number'
                        ? 1
                        : 0
                    : strokeWidth,
                /* eslint-enable no-nested-ternary */
            });
        }
        // 恢复矢量的描边
        const style = getComputedStyle(element);
        if (node instanceof MGraphic || node instanceof MSprite) {
            if (this.parseColor) {
                // 没有描边时，使用填充色作为描边色
                const stroke = style.stroke === 'none' ? style.fill : style.stroke;
                // 当 stroke和 fill 同时为none时认为无效
                if (style.stroke === 'none' && style.fill === 'none') {
                    node.isValid = false;
                }
                // '#00ff00';
                const originColor = colord(stroke).toHex();
                // 如果需要将颜色进行拟合可以重写converToLayerColor的方法
                let color = ColorUtil.converToLayerColor(originColor);
                // 导入的元素默认以得到的色块作为对应的tag，保持各处数据统一以 "#aabcc"的形式存储
                color = colord(color).toHex();
                node.layerColor = color;
                node.layerTag = color;
                node.originColor = originColor;
            }
            const fillColor = ColorUtil.convertFillColor(style.fill);
            const isFill = fillColor ? (fillColor === 'none' ? false : true) : false;
            node.isFill = isFill;
        }
        switch (element.nodeName.toLowerCase()) {
            case 'circle':
                node.embedCircle(element);
                break;
            case 'ellipse':
                node.embedEllipse(element);
                break;
            case 'image':
                node.embedImage(element);
                break;
            case 'line':
                node.embedLine(element);
                break;
            case 'path':
                node.embedPath(element, style['fill-rule']);
                break;
            case 'polyline':
                if (element.points.length === 2) {
                    node.embedPolyline(element);
                }
                else {
                    node.embedPolyline(element);
                }
                break;
            case 'polygon':
                node.embedPolygon(element);
                break;
            case 'rect':
                node.embedRect(element);
                break;
            case 'text':
                node.embedText(element);
                break;
            case 'use': {
                const useElement = element;
                const useTargetURL = useElement.getAttribute('href') ||
                    useElement.getAttribute('xlink:href');
                const usePaint = this.queryPaint(useElement);
                node.embedUse(useElement);
                if (useTargetURL.startsWith('#')) {
                    const useTarget = this.content.querySelector(useTargetURL);
                    const contentNode = this.populateSceneRecursive(useTarget, {
                        basePaint: usePaint,
                    });
                    if (contentNode) {
                        node.ref = contentNode;
                    }
                }
                else if (!this._context.disableHrefSVGLoading) {
                    node.isRefExternal = true;
                    Loader._load(useTargetURL)
                        .then((svgDocument) => [
                        new SVGScene(svgDocument, {
                            ...this._context,
                            disableRootPopulation: true,
                        }),
                        svgDocument.querySelector('#' + useTargetURL.split('#')[1]),
                    ])
                        .then(([shellScene, useTarget]) => {
                        if (!useTarget) {
                            console.error(`SVGScene failed to resolve ${useTargetURL} and SVGUseNode is empty!`);
                        }
                        const contentNode = shellScene.populateSceneRecursive(useTarget, {
                            basePaint: usePaint,
                        });
                        node.ref = contentNode;
                        this._transformDirty = true;
                        shellScene.on('transformdirty', () => {
                            this._transformDirty = true;
                        });
                    });
                }
            }
            default:
                break;
        }
        // PIXI.Transform的localTransform和worldTransform实例属性是惰性更新的，
        // 在修改了transform实例的position, rotation, scale等属性后，
        // 需要手动调动updateLocalTransform()或updateTransform()方法，localTransform才会更新为最新值
        node.transform.updateLocalTransform();
        // 矩阵数据是 标签中 的矩阵属性与元素自身的矩阵数据的叠加
        transformMatrix.append(node.transform.localTransform);
        node.transform.setFromMatrix(transformMatrix);
        if (element instanceof SVGMaskElement) {
            this._elementToMask.set(element, this.createMask(node));
        }
        // 没有对mask标签完全支持，注释这部分逻辑
        // const maskURL = element.getAttribute('mask');
        // if (maskURL) {
        //   const maskElement: SVGMaskElement = this.content.querySelector(
        //     this.parseReference(maskURL),
        //   );
        //   if (maskElement) {
        //     const maskServer = this.queryMask(maskElement);
        //     const maskSprite = maskServer.createMask(node);
        //     this.renderServers.addChild(maskServer);
        //     node.mask = maskSprite;
        //     node.addChild(maskSprite);
        //   }
        // }
        return {
            paint,
        };
    }
    /**
     * Recursively populates a subscene graph that embeds {@code element}. The root of the subscene is returned.
     *
     * @param element - The SVGElement to be embedded.
     * @param options - Inherited attributes from the element's parent, if any.
     * @return The display object that embeds the element for rendering.
     */
    populateSceneRecursive(element, options) {
        const node = this.createNode(element);
        if (!node) {
            return null;
        }
        node.on('nodetransformdirty', this.onNodeTransformDirty);
        let paint;
        if (element instanceof SVGGraphicsElement ||
            element instanceof SVGMaskElement) {
            const opts = this.embedIntoNode(node, element, options);
            paint = opts.paint;
            // 对于pathnode 解析完成后进行裁剪
            // 统一添加矩形hitArea可减少复杂图形的点击判断，修复复杂图形无法响应事件的问题
            if (node instanceof SVGPathNode) {
                node.addHitArea();
            }
            if (node instanceof MGraphic) {
                // TODO: 先去除svg中根据fillColor决定填充状态，待规则完善再处理
                // node.setDefaultConfig();
            }
        }
        for (let i = 0, j = element.children.length; i < j; i++) {
            // eslint-disable-next-line
            // @ts-ignore
            const childNode = this.populateSceneRecursive(element.children[i], {
                basePaint: paint,
            });
            if (childNode) {
                node.addChild(childNode);
            }
        }
        if (node instanceof SVGGraphicsNode) {
            const bbox = node.getLocalBounds(tempRect);
            const paintServers = node.paintServers;
            const { x, y, width: bwidth, height: bheight } = bbox;
            node.paintServers.forEach((paintServer) => {
                paintServer.resolvePaintDimensions(bbox);
            });
            const geometry = node.geometry;
            const graphicsData = geometry.graphicsData;
            if (graphicsData) {
                graphicsData.forEach((data) => {
                    const fillStyle = data.fillStyle;
                    const lineStyle = data.lineStyle;
                    if (fillStyle.texture &&
                        paintServers.find((server) => server.paintTexture === fillStyle.texture)) {
                        const width = fillStyle.texture.width;
                        const height = fillStyle.texture.height;
                        data.fillStyle.matrix
                            .invert()
                            .scale(bwidth / width, bheight / height)
                            .invert();
                    }
                    if (fillStyle.matrix) {
                        fillStyle.matrix.invert().translate(x, y).invert();
                    }
                    if (lineStyle.texture &&
                        paintServers.find((server) => server.paintTexture === lineStyle.texture)) {
                        const width = lineStyle.texture.width;
                        const height = lineStyle.texture.height;
                        data.lineStyle.matrix
                            .invert()
                            .scale(bwidth / width, bheight / height)
                            .invert();
                    }
                    if (lineStyle.matrix) {
                        lineStyle.matrix.invert().translate(x, y).invert();
                    }
                });
                geometry.updateBatches();
            }
        }
        if (element instanceof SVGMaskElement) {
            // Mask elements are *not* a part of the scene graph.
            return null;
        }
        return node;
    }
    /**
     * Populates the entire SVG scene. This should only be called once after the {@link SVGScene.content} has been set.
     */
    populateScene() {
        if (this.root) {
            this._cull.remove(this.root);
        }
        const root = this.populateSceneRecursive(this.content);
        this.root = root;
        this._cull.add(this.root);
    }
    /**
     * Handles `nodetransformdirty` events fired by nodes. It will set {@link SVGScene._transformDirty} to true.
     *
     * This will also emit `transformdirty`.
     */
    onNodeTransformDirty = () => {
        this._transformDirty = true;
        this.emit('transformdirty', this);
    };
    /**
     * The width at which the SVG scene is being rendered. By default, this is the viewbox width specified by
     * the root element.
     */
    get width() {
        return this._width;
    }
    set width(value) {
        this._width = value;
        this.scale.x = this._width / this.content.viewBox.baseVal.width;
    }
    /**
     * The height at which the SVG scene is being rendered. By default, this is the viewbox height specified by
     * the root element.
     */
    get height() {
        return this._height;
    }
    set height(value) {
        this._height = value;
        this.scale.y = this._height / this.content.viewBox.baseVal.height;
    }
    /**
     * Load the SVG document and create a {@link SVGScene} asynchronously.
     *
     * A cache is used for loaded SVG documents.
     *
     * @param url
     * @param context
     * @returns
     */
    static async from(url, context) {
        return new SVGScene(await Loader._load(url), context);
    }
}
