/**
 * DisplayObject对象树操作的的工具函数
 */
import { divisionMatrix } from '../../utils';
/**
 * 从obj自身开始往上搜索匹配matcher的最近对象
 * @param obj
 * @param matcher
 * @returns
 */
export function closest(obj, matcher) {
    let target = obj;
    while (target) {
        if (typeof matcher === 'function' ? matcher(target) : target === matcher) {
            break;
        }
        target = target.parent;
    }
    return target;
}
/**
 * 判断container的子树中是否包含obj
 * @param container
 * @param obj
 */
export function contains(container, obj) {
    return !!obj && obj !== container && closest(obj, container) === container;
}
/**
 * container内指定的child替换为obj
 * @export
 * @param container
 * @param obj
 * @param child
 */
export function replaceChild(container, obj, child) {
    const index = container.getChildIndex(child);
    if (index >= 0) {
        container.removeChildAt(index);
        container.addChildAt(obj, index);
    }
}
/**
 * 使用一个或多个对象原地替换obj
 * @param obj
 * @param others
 * @returns
 */
export function replaceWith(obj, others) {
    if (!obj.parent) {
        return;
    }
    const container = obj.parent;
    const index = container.getChildIndex(obj);
    if (index >= 0) {
        container.removeChildAt(index);
        others = Array.isArray(others) ? others : [others];
        for (let i = 0; i < others.length; i++) {
            container.addChildAt(others[i], index + i);
        }
    }
}
/**
 * 把后代对象转移为子对象，保持transform效果不变
 * （主要用于viewport.displayLayer的多层嵌套的矢量对象进行扁平化）
 * @param obj
 * @param container
 * @param [replace]
 * @returns
 */
export function changeDescendantAsChildKeepTransform(obj, container, replace = true) {
    // 判断是否为container的后代
    const target = closest(obj, (o) => o.parent === container);
    if (!target || target === obj) {
        return obj;
    }
    // 调整localTransform
    const matrix = divisionMatrix(obj.worldTransform.clone(), container.worldTransform.clone());
    obj.transform.setFromMatrix(obj.localTransform.copyFrom(matrix));
    if (replace) {
        replaceChild(container, obj, target);
    }
    return obj;
}
/**
 * 把obj移动到指定container
 * @param obj
 * @param container
 * @param [options.keepTransform = true] - 保持transform相对不变
 * @param [options.insertNear = true] - 是否搜索直系parent就近插入
 * @param [options.clearEmpty = true] - 是否清除空的parent容器
 * @returns
 */
export function moveTo(obj, container, options) {
    const { keepTransform = true, insertNear = true, clearEmpty = true, } = options ?? {};
    if (obj.parent === container) {
        return;
    }
    if (!obj.parent) {
        container.addChild(obj);
        return;
    }
    if (keepTransform) {
        // 调整localTransform为相对container
        const matrix = divisionMatrix(obj.worldTransform.clone(), container.worldTransform.clone());
        obj.transform.setFromMatrix(obj.localTransform.copyFrom(matrix));
        obj.transformCommit?.();
    }
    // 从对象原来容器中移除
    obj.parent.removeChild(obj);
    let target;
    if (insertNear) {
        // 搜索直系parent
        target = obj.parent;
        let next;
        while (target) {
            if (target.parent === container) {
                // 找到匹配对象，就近插入
                const index = container.getChildIndex(target);
                container.addChildAt(obj, index + 1);
                next = null;
            }
            else {
                next = target.parent;
            }
            if (clearEmpty && !target.children.length) {
                if (target.parent) {
                    target.parent.removeChild(target);
                }
                target.destroy(true);
            }
            target = next;
        }
    }
    if (!target) {
        container.addChild(obj);
    }
}
