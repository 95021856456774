import { stateStr } from './State';
export class Recognizer {
    state = 1 /* STATE.POSSIBLE */;
    options;
    recognize(input) {
        // 复制一个 input，避免 Input 被修改，影响手势识别
        const inputClone = Object.create(input);
        if (this.state & (8 /* STATE.RECOGNIZED */ | 16 /* STATE.CANCELLED */ | 32 /* STATE.FAILED */)) {
            this.state = 1 /* STATE.POSSIBLE */;
        }
        this.state = this.process(inputClone);
        if (this.state &
            (2 /* STATE.BEGAN */ |
                4 /* STATE.CHANGED */ |
                8 /* STATE.RECOGNIZED */ |
                8 /* STATE.ENDED */ |
                16 /* STATE.CANCELLED */)) {
            this.emit(inputClone);
        }
    }
    emit(input) {
        if (!input.displayObject) {
            return;
        }
        const { state } = this;
        input.displayObject.emit(this.options.event + '-' + stateStr(state), input.interactionEvent, input);
        if (state & 8 /* STATE.RECOGNIZED */) {
            input.displayObject.emit(this.options.event, input.interactionEvent, input);
        }
    }
}
