const DefaultScheduledTaskSetOptions = {
    startDelay: 1,
    stepInterval: 16,
    stepDuration: 16,
    timerMode: 'timeout',
};
/**
 * 计划任务
 * @template K
 */
export class ScheduledTaskSet {
    #tasks = new Map();
    #timer = null;
    #state = 'idle'; // 'idle' | 'pending' | 'running'
    options;
    constructor(options) {
        this.options = Object.assign(DefaultScheduledTaskSetOptions, options);
    }
    has(key) {
        return this.#tasks.has(key);
    }
    add(key, action) {
        if (typeof action !== 'function') {
            console.warn(`action is not a function, will be ignored`);
            return;
        }
        if (this.#tasks.has(key)) {
            this.#tasks.delete(key);
        }
        this.#tasks.set(key, action);
        this.#setupTaskConsumption();
    }
    delete(key) {
        this.#tasks.delete(key);
        if (this.#timer && !this.#tasks.size) {
            this.#cancelTimer();
            this.#state === 'idle';
        }
    }
    clear() {
        return this.#tasks.clear();
    }
    size() {
        return this.#tasks.size;
    }
    #setupTaskConsumption() {
        if (this.#state === 'pending') {
            return;
        }
        if (this.#state === 'running') {
            // 中止任务消费
            this.#cancelTimer();
        }
        this.#state = 'pending';
        const continueFn = () => {
            // 继续任务消费
            this.#timer = null;
            this.#consumeTasks(this.options.stepDuration);
            if (this.#tasks.size) {
                this.#setTimer(continueFn, this.options.stepInterval);
            }
            else {
                // 结束任务消费
                this.#state = 'idle';
            }
        };
        // 启动任务消费
        this.#setTimer(() => {
            this.#state = 'running';
            continueFn();
        }, Math.max(this.options.startDelay, 1));
    }
    #setTimer(callback, timeout) {
        if (this.options.timerMode === 'idle-callback') {
            this.#timer = requestIdleCallback(callback, { timeout });
        }
        else {
            this.#timer = setTimeout(callback, timeout);
        }
    }
    #cancelTimer() {
        if (this.#timer) {
            if (this.options.timerMode === 'idle-callback') {
                cancelIdleCallback(this.#timer);
            }
            else {
                clearTimeout(this.#timer);
            }
            this.#timer = null;
        }
    }
    #consumeTasks(ms = 16) {
        const startTime = Date.now();
        let currTime;
        for (const [key, action] of this.#tasks.entries()) {
            currTime = Date.now();
            const elapsed = currTime - startTime;
            if (elapsed <= ms) {
                this.#tasks.delete(key);
                action(key);
            }
            else {
                break;
            }
        }
    }
}
polyfill();
function polyfill() {
    /**
     * window.requestIdleCallback()
     * version 0.0.0
     * Browser Compatibility:
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#browser_compatibility
     */
    if (!globalThis.requestIdleCallback) {
        globalThis.requestIdleCallback = function (callback, options) {
            options = options || {};
            const relaxation = 1;
            const timeout = options.timeout || relaxation;
            const start = performance.now();
            return setTimeout(function () {
                callback({
                    get didTimeout() {
                        return options.timeout
                            ? false
                            : performance.now() - start - relaxation > timeout;
                    },
                    timeRemaining: function () {
                        return Math.max(0, relaxation + (performance.now() - start));
                    },
                });
            }, relaxation);
        };
    }
    /**
     * window.cancelIdleCallback()
     * version 0.0.0
     * Browser Compatibility:
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/cancelIdleCallback#browser_compatibility
     */
    if (!globalThis.cancelIdleCallback) {
        globalThis.cancelIdleCallback = function (id) {
            clearTimeout(id);
        };
    }
}
