export class Input {
    constructor() { }
    // 当前中心点与第一个中心点的 X 轴移动距离
    deltaX;
    // 当前中心点与第一个中心点的 Y 轴移动距离
    deltaY;
    // 当前点与第一个点的时间差
    deltaTime;
    // 当前中心点与第一个中心点的距离
    distance;
    // 当前中心点与第一个中心点的角度
    angle;
    // 间隔时间（实时）的 X 轴的速度 px/ms
    velocityX;
    // 间隔时间（实时）的 Y 轴的速度 px/ms
    velocityY;
    // velocityX/Y 的最大值.
    velocity;
    // X 轴的速度 px/ms = deltaX/deltaTime
    overallVelocityX;
    // Y 轴的速度 px/ms = deltaX/deltaTime
    overallVelocityY;
    // overallVelocityX/Y 的最大值
    overallVelocity;
    // 间隔时间（实时）的方向
    direction;
    // deltaX 和 deltaY 确定的方向
    offsetDirection;
    // 尺寸变化，单指为 1
    scale;
    // 与上一次的尺寸变化大小，单指为 0
    deltaScale;
    // 旋转，单指为 0
    rotation;
    // 单个点或者多个点的中心点
    center;
    // 原始事件 TouchEvent, MouseEvent, PointerEvent
    srcEvent;
    interactionEvent;
    // 点击的 DisplayObject
    displayObject;
    // pointer type (touch, mouse, pen)
    pointerType;
    // 事件类型
    eventType;
    // 所有 pointers, 包括结束的 pointers (touchend, mouseup).
    pointers;
    // 所有 new/moved/lost 的 pointers.
    changedPointers;
    // 最大的点数
    maxPointers;
    // 时间戳
    timeStamp;
    // 是否是第一个点
    get isFirst() {
        const pointersLen = this.pointers.length;
        const changedPointersLen = this.changedPointers.length;
        const result = this.eventType & 1 /* INPUT.START */ && pointersLen - changedPointersLen === 0;
        return result;
    }
    // 是否是最后一个点
    get isFinal() {
        const pointersLen = this.pointers.length;
        const changedPointersLen = this.changedPointers.length;
        const result = this.eventType & (4 /* INPUT.END */ | 8 /* INPUT.CANCEL */) &&
            pointersLen - changedPointersLen === 0;
        return result;
    }
}
