import { EventEmitter } from '@pixi/utils';
import { GLOBAL_EVENT } from '../types/event';
export default class GlobalConfigManager extends EventEmitter {
    constructor() {
        super();
    }
    static instance = new GlobalConfigManager();
    static getInstance() {
        return this.instance;
    }
    config = { enableFill: true };
    get enableFill() {
        return this.config.enableFill;
    }
    set enableFill(value) {
        this.config.enableFill = value;
        this.emit(GLOBAL_EVENT.ENABLE_FILL, value);
    }
}
