import { Matrix } from 'pixi.js';
import { importMaskJSONToSprite, MSprite } from '../display';
import { CANVAS_EVENT, UNDO_HISTORY_EVENT, XAlignOption, YAlignOption, } from '../types';
import { getDisplayBounds, updateDisplayPosition } from '../utils';
import { ImageProcess, ImageTracing } from '../views';
import { AbstractBase } from './AbstractBase';
export class AbstractImageProcess extends AbstractBase {
    preViewportPosition = null;
    closeImageTracing(traceDisplays) {
        let display = null;
        if ((!traceDisplays || traceDisplays.length === 0) && this.imageTracing) {
            display = this.imageTracing.getOriginalDisplay();
        }
        this.toggleTransformer(true);
        this.imageTracing?.destroyAll();
        this.imageTracing = null;
        this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, this.canUndo, this.canRedo);
        if (this.centralAxis) {
            this.centralAxis.mask.visible = true;
            this.centralAxis.axis.visible = true;
        }
        if (display && display.interactive) {
            this.transformer.selected = [display];
        }
        this.resetViewportPosition();
    }
    /**
     * 关闭预处理图像
     *
     * @memberof MCanvas
     * @param {MSprite} [display] - 关闭后图像处理后会被自动选中的元素，默认值为图像处理的原图像
     */
    closeImageProcess(display) {
        if (!this.imageProcess) {
            return;
        }
        if (!display && this.imageProcess) {
            display = this.imageProcess.getOriginalDisplay();
        }
        this.toggleTransformer(true);
        this.imageProcess?.destroyAll();
        this.imageProcess = null;
        this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, this.canUndo, this.canRedo);
        if (this.centralAxis) {
            this.centralAxis.mask.visible = true;
            this.centralAxis.axis.visible = true;
        }
        if (display && display.interactive) {
            this.transformer.selected = [display];
        }
        this.configManager.imageProcessConfig.lifecycle?.onClose?.();
        this.resetViewportPosition();
    }
    setViewportCenter() {
        this.preViewportPosition = this.viewport.position.clone();
        this.viewport.alignment(XAlignOption.CENTER, YAlignOption.CENTER);
    }
    resetViewportPosition() {
        if (this.preViewportPosition) {
            const { x, y } = this.preViewportPosition;
            // this.viewport.position.set(x, y);
            this.viewport.updatePosition(x, y);
            this.preViewportPosition = null;
        }
    }
    /**
     * 更新预处理工具及参数
     *
     * @param {IMAGE_PROCESS_TOOL} tool
     * @param {ImageProcessToolOptions} options
     * @memberof MCanvas
     */
    updateImageProcessTool(tool, options) {
        this.imageProcess?.updateTool(tool, options);
    }
    /**
     * 保存预处理图像
     *
     * @memberof MCanvas
     */
    saveImageProcess() {
        if (!this.imageProcess) {
            return;
        }
        if (this.imageProcess.locking) {
            throw Error('failed to save imageProcess when it is locking');
        }
        this.toggleTransformer(true);
        const display = this.imageProcess?.getResult();
        if (this.imageProcess?.enableDestroyOriginalDisplay()) {
            // 销毁原操作对象统一调用 removeElements 方法，统一触发后续的流程
            this.removeElements([this.imageProcess?.getOriginalDisplay()], false, true);
        }
        if (display) {
            this.addDisplayToViewport(display);
            this.recordOperationLog(display);
        }
        else {
            this.recordOperationLog();
        }
        this.configManager.imageProcessConfig.lifecycle?.onSave?.();
        this.closeImageProcess(display);
    }
    toggleTransformer(value) {
        this.viewport.displayLayerVisible = value;
        // 为了在图像编辑时也可以缩放画布 不能将  this.viewport.interactive 设置为false
        // this.viewport.interactive = value;
        this.transformer.enableEmit = value;
        this.transformer.rotateEnabled = value;
    }
    /**
     * 图片预处理
     *
     * @param {boolean} isBackground
     * @memberof MCanvas
     */
    processImage(isBackground, fitViewportToCenter = true) {
        let display = this.selected[0];
        if (isBackground) {
            // display = this.viewport.bgLayer as unknown as MDisplayObject;
            display = this.viewport.backgroundSprite;
        }
        if (!display) {
            return;
        }
        // 进入图像编辑前结束当前的模式
        this.finishCurMode();
        this.transformer.selected = [];
        if (!this.imageProcess) {
            this.configManager.imageProcessConfig.lifecycle?.onOpen?.();
            const newDisplay = this.cloneImageProcessDisplay(display);
            // const newDisplay = this.cloneImageProcessDisplay(display, !isBackground);
            const bounds = getDisplayBounds(display, this.viewport);
            const finalPosition = {
                x: bounds.minX,
                y: bounds.minY,
                width: bounds.maxX - bounds.minX,
                height: bounds.maxY - bounds.minY,
            };
            const setDisplayPosition = (newDisplay, dPos) => {
                updateDisplayPosition(newDisplay, dPos, this.viewport);
            };
            const addViewportChild = (container) => {
                this.viewport.addChild(container);
            };
            const selectDisplay = (display) => {
                this.selected = display;
            };
            // 禁用viewport 和 transformer 的某些操作和事件
            this.toggleTransformer(false);
            this.imageProcess = new ImageProcess({
                viewportWorldWidth: this.viewport.worldWidth,
                viewportWorldHeight: this.viewport.worldHeight,
                viewportHeight: this.viewport.height,
                viewportWidth: this.viewport.width,
                viewportPosition: this.viewport.position,
                viewportScale: this.viewport.scale,
                display,
                newDisplay,
                finalPosition,
                isBackground,
                setDisplayPosition,
                addViewportChild,
                selectDisplay,
                imageProcessConfig: this.configManager.imageProcessConfig,
            });
            this.setUpImageProcessOperationStackEvent();
            this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, this.imageProcess.canUndo, this.imageProcess.canRedo);
        }
        this.emit(CANVAS_EVENT.UPDATE_IMAGE_PROCESS_VISIBLE, true);
        if (this.centralAxis) {
            this.centralAxis.mask.visible = false;
            this.centralAxis.axis.visible = false;
        }
        if (fitViewportToCenter) {
            this.setViewportCenter();
        }
    }
    setImageTracingMode(fitViewportToCenter = true) {
        const display = this.selected[0];
        if (!display) {
            return;
        }
        this.finishCurMode();
        if (!this.imageTracing) {
            const setDisplayPosition = (newDisplay, dPos) => {
                updateDisplayPosition(newDisplay, dPos, this.viewport);
            };
            const addViewportChild = (container) => {
                this.viewport.addChild(container);
            };
            const selectDisplay = (display) => {
                this.selected = display;
            };
            this.imageTracing = new ImageTracing({
                viewportWorldWidth: this.viewport.worldWidth,
                viewportWorldHeight: this.viewport.worldHeight,
                viewportPosition: this.viewport.position,
                viewportScale: this.viewport.scale,
                display: display,
                moldDisplay: this.cloneImageTracingDisplay(display),
                setDisplayPosition,
                addViewportChild,
                selectDisplay,
                displayConfig: this.configManager.displayConfig,
                imageProcessConfig: this.configManager.imageProcessConfig,
            });
            // this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, false, false);
        }
        if (fitViewportToCenter) {
            this.setViewportCenter();
        }
        this.emit(CANVAS_EVENT.UPDATE_IMAGE_TRACING_VISIBLE, true);
        this.transformer.selected = [];
        this.toggleTransformer(false);
        if (this.centralAxis) {
            this.centralAxis.mask.visible = false;
            this.centralAxis.axis.visible = false;
        }
    }
    getTracingDisplayImageData() {
        const display = this.imageTracing.originDisplay;
        const newSprite = this.copySpriteDisplayStyle(display);
        const { imageData, imageDataScale } = this.getDisplayRenderData(newSprite);
        if (this.imageTracing) {
            this.imageTracing.imageDataScale = imageDataScale;
        }
        return imageData;
    }
    addPathToContainer(path, lineColor, originColor) {
        if (this.imageTracing) {
            return this.imageTracing.addPathToContainer(path, lineColor, originColor);
        }
        return null;
    }
    clearTracingPath() {
        this.imageTracing && this.imageTracing.removeTraceDisplay();
    }
    getTraceDisplay() {
        return this.imageTracing?.traceDisplays.map((display) => ({
            ...display.toJSON(this.viewport),
            points: display.geometry.points,
        }));
    }
    getTracingOriginDisplay() {
        return this.imageTracing.originDisplay.toJSON(this.viewport, {});
    }
    saveImageTracing() {
        this.toggleTransformer(true);
        const displays = this.imageTracing?.getTraceDisplay();
        console.time('add display');
        displays.forEach((display) => {
            if (display) {
                // 生成的对象的所属的层在解析时已经有结果，这里是将加到viewport时的展示的颜色设置为对应层的颜色
                display.parseJSON({
                    layerColor: display.layerTag,
                });
                this.beforeAddDisplayToViewport(display);
                this.addDisplayToViewport(display);
            }
        });
        // create group里会执行一次log记录
        this.createGroup(displays);
        this.closeImageTracing(displays);
        console.timeEnd('add display');
        return displays.map(({ id, originColor }) => ({
            id,
            originColor,
        }));
    }
    cloneImageProcessDisplay(display) {
        const clonedSprite = display.cloneWithoutCompress(this.viewport);
        // 计算位图在viewport的包围盒
        const bounds = getDisplayBounds(display, this.viewport);
        const boundsRect = bounds.getRectangle();
        // 原始对象的resource相对原图的压缩比
        const resourceScale = display.resourceScale;
        clonedSprite.scale.x *= resourceScale.x;
        clonedSprite.scale.y *= resourceScale.y;
        const { imageData } = this.getDisplayRenderData(clonedSprite);
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        canvas.getContext('2d').putImageData(imageData, 0, 0);
        const newSprite = new MSprite(canvas);
        newSprite.width = boundsRect.width;
        newSprite.height = boundsRect.height;
        newSprite.interactive = false;
        return newSprite;
    }
    copySpriteDisplayStyle(sprite) {
        const newSprite = MSprite.from(sprite.texture.clone());
        importMaskJSONToSprite(newSprite, sprite.exportMaskJSON(this.viewport));
        const { grayValue, sharpness, colorInverted, filterList, filters } = sprite;
        newSprite.parseJSON({
            grayValue,
            sharpness,
            colorInverted,
            filterList,
        });
        newSprite.filters = filters;
        return newSprite;
    }
    cloneImageTracingDisplay(display) {
        const setTransformBy = (source, target) => {
            const matrix = new Matrix();
            matrix.prepend(target.localTransform);
            source.transform.setFromMatrix(matrix);
        };
        const newSprite = this.copySpriteDisplayStyle(display);
        setTransformBy(newSprite, display);
        newSprite.width = display.width;
        newSprite.height = display.height;
        newSprite.transform.updateLocalTransform();
        newSprite.interactive = false;
        return newSprite;
    }
    setUpImageProcessOperationStackEvent() {
        this.imageProcess.operationStack.on(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, (canUndo, canRedo) => {
            this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, canUndo, canRedo);
        });
    }
    cancelImageProcessTool() {
        this.imageProcess?.cancel();
    }
    confirmImageProcessTool() {
        this.imageProcess?.confirm();
    }
}
