/**
 * key-value形式的数据分组索引表的结构类
 * 同一key的值只有一个时，value为单个值；有两个以上时，value为列表类型值（如Array或Set)
 * @template K
 * @template V
 * @template List
 */
export class MapListBase {
    map = new Map();
    constructor(list, iteratee) {
        if (list) {
            let i = 0;
            for (const value of list) {
                const key = iteratee?.(value, i, list) ?? value;
                this.add(key, value);
                i++;
            }
        }
    }
    [Symbol.iterator]() {
        return this.values();
    }
    add(key, value) {
        let list = this.map.get(key);
        if (!list) {
            list = value;
            this.map.set(key, list);
        }
        else {
            if (!this._isList(list)) {
                list = this._toList(list);
                this._listAddValue(list, value);
                this.map.set(key, list);
            }
            else {
                this._listAddValue(list, value);
            }
        }
    }
    has(key, value) {
        if (this.map.has(key)) {
            const list = this.map.get(key);
            return !this._isList(list)
                ? list === value
                : this._listHasValue(list, value);
        }
        else {
            return false;
        }
    }
    delete(key, value) {
        if (this.has(key, value)) {
            const list = this.map.get(key);
            if (!this._isList(list)) {
                return this.map.delete(key);
            }
            else if (this._listDeleteValue(list, value)) {
                const size = this._listSize(list);
                if (size === 1) {
                    this.map.set(key, list[0]);
                    return true;
                }
                else if (size === 0) {
                    return this.map.delete(key);
                }
            }
            else {
                return false;
            }
        }
        return false;
    }
    hasValue(value) {
        for (const key of this.map.keys()) {
            if (this.has(key, value)) {
                return true;
            }
        }
        return false;
    }
    deleteValue(value) {
        for (const key of this.map.keys()) {
            if (this.delete(key, value)) {
                return true;
            }
        }
        return false;
    }
    hasKey(key) {
        return this.map.has(key);
    }
    getKey(key) {
        return this._toList(this.map.get(key));
    }
    deleteKey(key) {
        return this.map.delete(key);
    }
    *iterateKeyList() {
        for (const key of this.map.keys()) {
            let list = this.map.get(key);
            if (!list) {
                continue;
            }
            list = this._toList(list);
            yield { key, list };
        }
    }
    *keys() {
        yield* this.map.keys();
    }
    *values() {
        for (const { list } of this.iterateKeyList()) {
            yield* list;
        }
    }
    *entries() {
        for (const { list, key } of this.iterateKeyList()) {
            for (const value of list) {
                yield [key, value];
            }
        }
    }
    get size() {
        let count = 0;
        for (const { list } of this.iterateKeyList()) {
            count += this._listSize(list);
        }
        return count;
    }
    forEach(callbackFn, thisArg) {
        if (thisArg) {
            callbackFn = callbackFn.bind(thisArg);
        }
        for (const [key, value] of this.entries()) {
            callbackFn(value, key, this);
        }
    }
}
/**
 * 基于Array的key-value形式的数据分组索引表
 * 同一key的值只有一个时，value为单个值；有两个以上时，value为Array类型
 * @template K
 * @template V
 */
export class MapArray extends MapListBase {
    _listHasValue(list, value) {
        return list.includes(value);
    }
    _listAddValue(list, value) {
        if (!this._listHasValue(list, value)) {
            list.push(value);
        }
    }
    _listDeleteValue(list, value) {
        const index = list.findIndex((item) => item === value);
        if (index !== -1) {
            list.splice(index, 1);
            return true;
        }
        return false;
    }
    _listSize(list) {
        return list.length;
    }
    _isList(value) {
        return Array.isArray(value);
    }
    _toList(value) {
        return this._isList(value)
            ? [...value]
            : value !== undefined
                ? [value]
                : [];
    }
}
/**
 * 基于Set的key-value形式的数据分组索引表
 * 同一key的值只有一个时，value为单个值；有两个以上时，value为Set类型
 * @template K
 * @template V
 */
export class MapSet extends MapListBase {
    _listHasValue(list, value) {
        return list.has(value);
    }
    _listAddValue(list, value) {
        list.add(value);
    }
    _listDeleteValue(list, value) {
        return list.delete(value);
    }
    _listSize(list) {
        return list.size;
    }
    _isList(value) {
        return value instanceof Set;
    }
    _toList(value) {
        return this._isList(value)
            ? new Set(value)
            : value !== undefined
                ? new Set([value])
                : new Set();
    }
}
