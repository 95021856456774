import { Matrix, Sprite } from 'pixi.js';
import svgPath from 'svgpath';
import { divisionMatrix, getImageDataBounds, getImageSourceSize, getMatrixArray, getRelativeMatrix, } from '../../utils';
import { MSprite } from '../MSprite';
import { drawImageWithClipPath } from './canvas';
import { getMaskObj } from './mask';
import { displayObjectToPath } from './path/toPath';
/**
 * 基于<canvas>的clip()获取位图被mask剪切后的ImageData
 * @param bitmap
 * @param rotation
 */
export function getBitmapClippedImageData(bitmap, rotation = 0) {
    const mask = getMaskObj(bitmap);
    const img = bitmap?.getTextureImageSource?.() ??
        bitmap.texture.baseTexture.resource?.source;
    const { width, height } = getImageSourceSize(img);
    if (!mask || !img || !width || !height) {
        return new ImageData(1, 1);
    }
    let clipPath = displayObjectToPath(mask);
    const mat = divisionMatrix(mask.worldTransform.clone(), bitmap.worldTransform.clone());
    const resourceScale = getSpriteResourceScale(bitmap);
    if (isResourceScaled(resourceScale)) {
        mat.scale(resourceScale.x, resourceScale.y);
    }
    clipPath = svgPath(clipPath).matrix(getMatrixArray(mat)).toString();
    const clipCanvas = drawImageWithClipPath(img, clipPath, {
        imgRotation: rotation,
        mode: 'composition',
    });
    // @ts-ignore
    if (window.canvas_debug.previewCanvasClip) {
        previewCanvas(clipCanvas);
    }
    const imageData = clipCanvas
        .getContext('2d')
        ?.getImageData?.(0, 0, clipCanvas.width, clipCanvas.height) ??
        new ImageData(1, 1);
    return imageData;
}
/**
 * 获取sprite被mask剪切后的bounds
 * @param sprite
 * @param relativeObj
 */
export function getDisplayBoundsForMaskedSprite(sprite, relativeObj) {
    let matrix = getRelativeMatrix(sprite, relativeObj);
    let imageData;
    if (sprite instanceof MSprite) {
        imageData = sprite.getMaskedImageData();
    }
    else {
        imageData = getBitmapClippedImageData(sprite);
    }
    const resourceScale = getSpriteResourceScale(sprite);
    if (isResourceScaled(resourceScale)) {
        matrix = matrix ?? new Matrix();
        matrix.append(new Matrix().scale(1 / resourceScale.x, 1 / resourceScale.y));
    }
    return getImageDataBounds(imageData, matrix);
}
/**
 * 获取sprite的resource缩放比，小于1表示被压缩，大于1表示被扩大
 * @param bitmap
 * @returns
 */
export function getSpriteResourceScale(bitmap) {
    const sx = bitmap.texture.baseTexture.resource.width / bitmap.texture.width;
    const sy = bitmap.texture.baseTexture.resource.height / bitmap.texture.height;
    if (isFinite(sx) && isFinite(sy)) {
        return {
            x: sx,
            y: sy,
        };
    }
    else {
        return { x: 1, y: 1 };
    }
}
export function isResourceScaled(bitmapOrScale) {
    const scale = bitmapOrScale instanceof Sprite
        ? getSpriteResourceScale(bitmapOrScale)
        : bitmapOrScale;
    return scale.x !== 1 || scale.y !== 1;
}
function previewWithDownload(svgStr, blob, fileName = 'preview') {
    const blobURL = URL.createObjectURL(blob);
    const htmlStr = `<html>
        <head>
          <style>
            body { padding: 0; }
            a { position: fixed; right: 10px; }
            img { max-width: 100%; max-height: 100%; outline: 1px solid; }
          </style>
        </head>
        <body>
          <div><a href=${blobURL} download="${fileName}">Download</a></div>
          ${svgStr}
        </body>
      </html>
      `;
    console.log('Preview Link: %o', URL.createObjectURL(new Blob([htmlStr], { type: 'text/html' })));
}
function previewCanvas(canvas) {
    canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const elStr = `<img src=${url}></img>`;
        previewWithDownload(elStr, blob);
    });
}
