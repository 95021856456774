import { isFunction } from 'lodash-es';
import { Rectangle } from 'pixi.js';
import { getPointerId, getPointerIdArr, hoverable, isSinglePoint, } from '../controllers/interaction-manager';
import { DISPLAY_LIFE_CYCLE, DISPLAY_TYPE } from '../display';
import { createDisplay } from '../display/utils';
import { ASSISTANT_EVENT, CANVAS_EVENT, DISPLAY_EDITMODE_MAP, EDIT_MODE, INTERACTION_EVENT, MODE_EVENT, TRANSFORMER_EVENT, UNDO_HISTORY_EVENT, VIEW_EVENT, } from '../types';
import { filterInvaildDisplay, recurseInteractiveDisplay } from '../utils';
import { AbstractBase } from './AbstractBase';
import { BUILD_IN_PLUGIN_EVENT } from './AbstractPlugin';
export var PLUGIN_TO_MAIN_MESSAGE_EVENT_NAME;
(function (PLUGIN_TO_MAIN_MESSAGE_EVENT_NAME) {
    PLUGIN_TO_MAIN_MESSAGE_EVENT_NAME["SELECT_OBJECT"] = "SELECT_OBJECT";
})(PLUGIN_TO_MAIN_MESSAGE_EVENT_NAME || (PLUGIN_TO_MAIN_MESSAGE_EVENT_NAME = {}));
var KEYCODE;
(function (KEYCODE) {
    KEYCODE["Shift"] = "shiftKey";
    KEYCODE["Control"] = "ctrlKey";
    KEYCODE["Alt"] = "altKey";
    KEYCODE["Win"] = "metaKey";
    KEYCODE["Command"] = "metaKey";
})(KEYCODE || (KEYCODE = {}));
export class AbstractEvent extends AbstractBase {
    currentPointerOverTargets = [];
    // 标记transformer的pointerdown是自动触发的还是手动触发的
    manualSelect;
    multiKey;
    setupEvents() {
        this.setupViewportEvent();
        this.setupTransformEvent();
        this.setupSelectorEvent();
        this.setupModeEvent();
        this.setupOperationStackEvent();
        this.setupPluginEvent();
        const multiKey = this.canvasOptions.shortcutKeyConfig?.multiSelectKey;
        this.multiKey = KEYCODE[multiKey] || KEYCODE.Shift;
    }
    isMultiSelectKeyDown({ data }) {
        const { originalEvent } = data;
        return originalEvent[this.multiKey];
    }
    handleMessageFromPlugin(message) {
        const { eventName } = message;
        switch (eventName) {
            case PLUGIN_TO_MAIN_MESSAGE_EVENT_NAME.SELECT_OBJECT:
                console.log('---插件选中对象');
                break;
            default:
                console.log('[  ] >');
        }
    }
    setupPluginEvent() {
        this.on(BUILD_IN_PLUGIN_EVENT.MESSAGE_TO_PLUGIN, (message) => {
            this.plugins.forEach((plugin) => {
                plugin.onMessage?.(message);
            });
        });
        this.on(BUILD_IN_PLUGIN_EVENT.MESSAGE_TO_MAIN, (message) => {
            this.handleMessageFromPlugin(message);
        });
    }
    setupViewportEvent() {
        // 记录第一个 pointerDown 为 当前被激活的pointer的id
        let activePointerId;
        // 记录是否只有一个 pointerDown 触发
        let onlySinglePoint = true;
        const moveHandle = ({ viewport }) => {
            this.plugins.forEach((plugin) => {
                if (plugin.onViewportMove) {
                    plugin.onViewportMove(viewport);
                }
            });
        };
        const zoomHandle = ({ viewport }) => {
            const { minScale, maxScale } = viewport.plugins.get('clamp-zoom').options;
            if (viewport.scaled >= minScale && viewport.scaled <= maxScale) {
                this.plugins.forEach((plugin) => {
                    if (plugin.onViewportScale) {
                        plugin.onViewportScale(viewport);
                    }
                });
                this.emit(CANVAS_EVENT.ZOOMED, viewport.scaled);
            }
        };
        const getCurDisplay = () => {
            const curMode = this.modeManager.curMode;
            return curMode === EDIT_MODE.SELECT || curMode === EDIT_MODE.BITMAP_MASK
                ? this.selector
                : this.curDisplayObject;
        };
        const onPointerDown = (e, fromTransformer) => {
            if (this.imageProcess) {
                return;
            }
            if (!isSinglePoint(e)) {
                // 有多个触控点按下
                onlySinglePoint = false;
                // 有多个触控点按下时 直接结束selector的选中框
                this.selector?.done();
                return;
            }
            // fromTransformer 事件是否来自transformer的穿透
            // 因现有的container的层级，transformer在viewport之上，处理事件逻辑时将作用在transformer的事件透传到transformer上
            if (fromTransformer) {
                return;
            }
            onlySinglePoint = true;
            activePointerId = getPointerId(e);
            if (!this.isDragMode) {
                const curMode = this.modeManager.curMode;
                if (curMode === EDIT_MODE.SELECT || curMode === EDIT_MODE.BITMAP_MASK) {
                    this.handleSelectModePointerDown(e);
                }
                if (DISPLAY_EDITMODE_MAP[curMode]) {
                    this.handleEditModePointerDown(e);
                }
            }
            this.plugins.forEach((plugin) => {
                if (plugin.onViewportPointerDown) {
                    plugin.onViewportPointerDown(e, this.viewport);
                }
            });
        };
        const onPointerMove = (e, fromTransformer) => {
            if (!e.isInView) {
                return;
            }
            if (fromTransformer) {
                return;
            }
            if (!isSinglePoint(e)) {
                return;
            }
            if (!this.isDragMode) {
                const point = this.getPointByViewport(e);
                // 对于 move事件 只有在必须 先要触发down的逻辑时进行move的事件的id是否和down的id是否一致
                // 例如：选中框不允许非按下的手指移动时绘制选框
                if (activePointerId) {
                    if (activePointerId === getPointerId(e)) {
                        getCurDisplay()?.update?.(point, e);
                    }
                }
                else {
                    // 当没有激活的pointerId时，move事件直接响应
                    // 例如：钢笔绘制过程中一个点按下抬起，鼠标移动过程中需要响应
                    getCurDisplay()?.update?.(point, e);
                }
                const curMode = this.modeManager.curMode;
                if ((curMode === EDIT_MODE.SELECT || curMode === EDIT_MODE.BITMAP_MASK) &&
                    e.target &&
                    e.target !== e.currentTarget) {
                    const { minX, minY, maxX, maxY } = this.transformer.worldGroupBounds;
                    const rect = new Rectangle(minX, minY, maxX - minX, maxY - minY);
                    // 如果当前鼠标碰到transformer，则不显示元件的hover效果
                    if (!rect.contains(point.x, point.y)) {
                        const currentElement = this.getPenetrableElement(e);
                        if (currentElement) {
                            // 只有鼠标事件hover才可以高亮元素
                            if (hoverable(e)) {
                                highlightElement(currentElement);
                            }
                        }
                        else {
                            // 如果hover的是图片的镂空部分，则重置所有元素高亮
                            this.resetPointerOverTarget();
                        }
                    }
                    else {
                        this.resetPointerOverTarget();
                    }
                }
                else {
                    this.resetPointerOverTarget();
                }
            }
            this.plugins.forEach((plugin) => {
                if (plugin.onViewportPointerMove) {
                    plugin.onViewportPointerMove(e, this.viewport);
                }
            });
        };
        const highlightElement = (target) => {
            if (!this.currentPointerOverTargets.includes(target)) {
                const sameGroupElements = this.findSameGroupElements(target);
                this.resetPointerOverTarget();
                this.currentPointerOverTargets.push(...sameGroupElements);
                this.highlightPointerOverTarget();
            }
        };
        const clearSelector = (e, isCancel = false) => {
            if (!this.isDragMode) {
                if (!isCancel) {
                    // 事件取消时不响应选择事件
                    getCurDisplay()?.end(this.getPointByViewport(e), e);
                }
                // 解决 pointerdown时主动触发 transformer 的 pointerdown 时状态没有改变
                this.transformer.emit('pointerup', e);
            }
            // 鼠标弹起要将选框清空，无论那种模式下
            this.selector.done();
            this.plugins.forEach((plugin) => {
                if (plugin.onViewportPointerUp) {
                    plugin.onViewportPointerUp(e, this.viewport);
                }
            });
        };
        const onPointerUp = (e, fromTransformer) => {
            if (fromTransformer) {
                return;
            }
            if (!getPointerIdArr(e).includes(activePointerId)) {
                // 又有响应的事件数据中包含按下的pointer才会触发;
                // 例如： 先按下手指1，然后按下手指2，同时抬起手指1和2或只抬起1会触发，只抬起2不会触发
                return;
            }
            activePointerId = null;
            const { data: { originalEvent }, } = e;
            if (e.target && e.target === e.currentTarget) {
                // 触控屏 在viewport空白区域抬起才取消选中，并且期间只有一个 pointer按下 抬起
                if (originalEvent instanceof TouchEvent && onlySinglePoint) {
                    console.log('[ clear, 手势触发取消选中 ] >');
                    this.transformer.clear();
                }
            }
            clearSelector(e);
        };
        // 增加pointercancel，适配pad端息屏
        const onPointerCancel = (e, fromTransformer) => {
            if (fromTransformer) {
                return;
            }
            clearSelector(e, true);
        };
        this.viewport?.on('pointerdown', onPointerDown);
        this.viewport?.on('pointermove', onPointerMove);
        this.viewport?.on('pointerup', onPointerUp);
        this.viewport?.on('pointercancel', onPointerCancel);
        this.viewport?.on('moved', moveHandle);
        this.viewport?.on('zoomed', zoomHandle);
        this.viewport?.on(ASSISTANT_EVENT.GET_POSITION, (payload) => {
            this.emit(ASSISTANT_EVENT.GET_POSITION, payload);
        });
        this.viewport?.on(INTERACTION_EVENT.DOUBLE_CLICK, () => {
            // this.setViewportToFit();
        });
        // 当鼠标在画布之外再抬起
        // NOTE: 当点击了与画布有交集的dom节点时也会触发，例如一些操作的下拉框
        this.viewport?.on('pointerupoutside', (e) => {
            if (!this.isDragMode) {
                getCurDisplay()?.end?.(this.getPointByViewport(e), e);
            }
            this.plugins.forEach((plugin) => {
                if (plugin.onViewportPointerupoutside) {
                    plugin.onViewportPointerupoutside(e, this.viewport);
                }
            });
        });
        this.viewport.displayLayer?.on('childAdded', (display) => {
            const displays = recurseInteractiveDisplay(display);
            // 不能直接使用全局属性，导入文件的时候，需要去修改变量，不方便
            this.updateNewElesOrder(displays);
            this.emit(CANVAS_EVENT.NEW_DISPLAYS_ADDED, this.getMultiDisplayJSON(displays, { texture: true }));
        });
    }
    resetPointerOverTarget() {
        this.currentPointerOverTargets.forEach((el) => {
            if (el) {
                if (el && el.parent && el.reset) {
                    el.reset();
                }
            }
        });
        this.currentPointerOverTargets = [];
    }
    highlightPointerOverTarget() {
        this.currentPointerOverTargets.forEach((el) => {
            if (el) {
                if (isFunction(el.highlightBound)) {
                    el.highlightBound();
                }
            }
        });
    }
    // CANVAS_EVENT.SELECTED_TRANSFORM_COMMIT 事件,
    // 发生在通过接口更新选中元素的属性结束\通过transformer拖动结束\导入元素结束后
    emitTransformerCommit() {
        this.emit(CANVAS_EVENT.SELECTED_TRANSFORM_COMMIT, {
            getData: () => ({
                ...this.getSelectedData(),
                enableRedo: this.canRedo,
                enableUndo: this.canUndo,
            }),
        });
    }
    setupTransformEvent() {
        this.transformer.on(TRANSFORMER_EVENT.SELECT, () => {
            if (this.transformer.enableEmit) {
                const data = {
                    getData: () => ({
                        ...this.getSelectedData(),
                        enableCombine: !!this.selectedPathsClosed,
                        enableRedo: this.canRedo,
                        enableUndo: this.canUndo,
                    }),
                };
                this.emit(CANVAS_EVENT.SELECT_OBJECT, data);
            }
            this.plugins.forEach((plugin) => {
                if (plugin.onTransformerSelect) {
                    plugin.onTransformerSelect(this.transformer);
                }
            });
        });
        const transformHandle = (changeAttrs) => {
            if (this.transformer.enableEmit) {
                this.emit(CANVAS_EVENT.SELECT_OBJECT_TRANSFORMERED, {
                    getData: () => ({
                        ...this.getSelectedData(),
                        enableRedo: this.canRedo,
                        enableUndo: this.canUndo,
                    }),
                }, changeAttrs);
            }
            this.plugins.forEach((plugin) => {
                if (plugin.onTransformerChange) {
                    plugin.onTransformerChange(this.transformer);
                }
            });
        };
        this.transformer.on(TRANSFORMER_EVENT.TRANSFORM_CHANGE, transformHandle);
        this.transformer?.on(INTERACTION_EVENT.DOUBLE_CLICK, (e) => {
            console.log(INTERACTION_EVENT.DOUBLE_CLICK);
            // 只选中一个时，双击才进入编辑状态
            if (this.selected.length !== 1 ||
                this.modeManager.curMode !== EDIT_MODE.SELECT) {
                return;
            }
            const display = this.selected[0];
            // 当前先只支持 PEN
            if (!(display.type === DISPLAY_TYPE.PEN ||
                display.type === DISPLAY_TYPE.BITMAP)) {
                return;
            }
            if (display.enterEditMode) {
                display.enterEditMode(e);
                // 清除选中
                this.transformer.clear();
            }
            if (display.type === DISPLAY_TYPE.PEN) {
                this.updateEditMode(EDIT_MODE.PEN);
                this.moveDisplayToTempLayer(display);
                display.visible = true;
            }
            else if (display.type === DISPLAY_TYPE.BITMAP) {
                this.onSelectedDoubleClick?.(display);
            }
            this.curDisplayObject = display;
            this.curDisplayObject.once(DISPLAY_LIFE_CYCLE.DRAW_DONE, () => {
                this.updateEditMode(EDIT_MODE.SELECT);
                this.beforeAddDisplayToViewport(display);
                this.addDisplayToViewport(display);
                this.recordOperationLog(display);
            });
        });
        this.transformer?.on(INTERACTION_EVENT.PINCH_START, () => {
            console.log(INTERACTION_EVENT.PINCH_START);
        });
        this.transformer?.on(INTERACTION_EVENT.PINCH_CHANGE, (change, dist) => {
            console.log(INTERACTION_EVENT.PINCH_CHANGE, change, dist);
            // 只选中一个时，双击才进入编辑状态
            if (this.selected.length !== 1) {
                return;
            }
            const display = this.selected[0];
            display.scale.x += change;
            display.scale.y += change;
        });
        this.transformer?.on(INTERACTION_EVENT.PINCH_END, () => {
            console.log(INTERACTION_EVENT.PINCH_END);
        });
        this.transformer?.on(INTERACTION_EVENT.TWO_FINGER_TAP, () => {
            console.log(INTERACTION_EVENT.TWO_FINGER_TAP);
        });
        this.transformer?.on(INTERACTION_EVENT.LONG_PRESS, () => {
            console.log(INTERACTION_EVENT.LONG_PRESS);
        });
        this.transformer?.on(TRANSFORMER_EVENT.TRANSFORM_COMMIT, () => {
            if (this.transformer.enableEmit) {
                this.recordOperationLog(...this.selected);
                this.emitTransformerCommit();
            }
        });
        this.transformer?.on('pointerdown', (e) => {
            this.viewport.emit('pointerdown', e, true);
            this.transformer.isPointDown = true;
            this.plugins.forEach((plugin) => {
                if (plugin.onTransformerPointerDown) {
                    plugin.onTransformerPointerDown(e);
                }
            });
            // 如果是viewport手动emit的pointerdown事件，则不做以下处理，防止循环响应
            if (this.manualSelect) {
                this.manualSelect = false;
                return;
            }
            if (this.isMultiSelectKeyDown(e)) {
                const currentElement = this.getPenetrableElement(e);
                // 多选的时候，shift点击transformer内的空白区域，选框会逐渐放大，因此目标不可以是transformer自身
                if (currentElement && currentElement !== this.transformer) {
                    this.multiSelect(currentElement);
                    // 这里要emit pointerup事件，防止多选之后，transformer突然跳动
                    this.transformer.emit('pointerup', e);
                }
            }
        });
        this.transformer?.on('pointermove', (e) => {
            this.viewport.emit('pointermove', e, true);
            if (isSinglePoint(e) && this.transformer.pointerId === getPointerId(e)) {
                this.plugins.forEach((plugin) => {
                    if (plugin.onTransformerPointerMove) {
                        plugin.onTransformerPointerMove(e);
                    }
                });
            }
        });
        const onTransformerPointerUp = (e) => {
            this.viewport.emit('pointerup', e, true);
            if (getPointerIdArr(e).includes(this.transformer.pointerId)) {
                if (this.transformer.isPointDown) {
                    this.transformer.isPointDown = false;
                    // TODO:
                    this.transformer.pointerId = null;
                    this.plugins.forEach((plugin) => {
                        if (plugin.onTransformerPointerUp) {
                            plugin.onTransformerPointerUp(e);
                        }
                    });
                }
            }
        };
        this.transformer?.on('pointerup', onTransformerPointerUp);
        this.transformer?.on('pointercancel', onTransformerPointerUp);
        this.transformer?.on('pointerupoutside', onTransformerPointerUp);
    }
    moveDisplayToTempLayer(display) {
        this.viewport.removeChild(display);
        this.tempLayer.addChild(display);
    }
    setupOperationStackEvent() {
        this.operationStack.on(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, (canUndo, canRedo) => {
            this.emit(UNDO_HISTORY_EVENT.CAN_DO_CHANGE, canUndo, canRedo);
        });
    }
    setupSelectorEvent() {
        this.selector.on(VIEW_EVENT.SELECT_VIEWPORT_ELEMENTS, (startPoint, endPoint, e) => {
            if (startPoint && endPoint && this.enableSelectElements) {
                this.selectAreaElements(startPoint, endPoint, this.isMultiSelectKeyDown(e));
            }
            else {
                console.log('[ 框选区域取消选择 ] >');
                this.executeSelect([]);
            }
        });
    }
    setupModeEvent() {
        this.modeManager.on(MODE_EVENT.CHANGE, () => {
            this.emit(MODE_EVENT.CHANGE, this.modeManager.curMode);
        });
    }
    handleSelectModePointerDown(e) {
        const { data: { originalEvent }, } = e;
        const selected = this.selected;
        const { validDisplays, invalidDisplays } = filterInvaildDisplay(selected);
        if (this.selected.length !== validDisplays.length) {
            this.selected = validDisplays;
            // 元素销毁的方法统一调用removeElements，统一事件派发
            this.removeElements(invalidDisplays, false, true);
        }
        // console.log('handleSelectModePointerDown', e.data.global);
        if (e.target && e.target !== e.currentTarget) {
            // selected graphic
            this.pierceSelect(e);
        }
        else if (e.target && e.target === e.currentTarget) {
            if (!this.isMultiSelectKeyDown(e)) {
                // 只有使用鼠标时在空白处按下阶段才清除选中
                if (originalEvent instanceof MouseEvent) {
                    this.transformer.clear();
                }
            }
            this.selector.start(this.getPointByViewport(e));
        }
    }
    // 穿透获取元件
    getPenetrableElement(e) {
        const point = this.getPointByViewport(e);
        const currentElement = this.getMinAreaElementByPoint(point);
        return currentElement;
    }
    // 穿透选择
    pierceSelect(e) {
        const currentElement = this.getPenetrableElement(e);
        if (currentElement) {
            if (this.isMultiSelectKeyDown(e)) {
                this.multiSelect(currentElement);
                this.resetPointerOverTarget();
            }
            else {
                this.selectSameGroup(currentElement);
                this.manualSelect = true;
                this.resetPointerOverTarget();
                this.transformer.emit('pointerdown', e);
            }
        }
        else {
            // select graphic but not actived
            this.transformer.clear();
        }
    }
    // 多选处理，涉及到反选
    multiSelect(target) {
        this.selected = this.invertSelect(target, this.selected);
    }
    // 选择一个组
    selectSameGroup(target) {
        const currentSelected = this.findSameGroupElements(target);
        this.selected = currentSelected;
    }
    handleEditModePointerDown(e) {
        if (!this.curDisplayObject) {
            const curMode = this.modeManager.curMode;
            const displayType = DISPLAY_EDITMODE_MAP[curMode];
            const displayObject = createDisplay(displayType);
            if (displayObject) {
                // 绘制过程中使用当前层的颜色配置
                const { layerTag, layerColor } = this.configManager.displayConfig;
                displayObject.parseJSON({ layerTag, layerColor });
                // 绘制出的元素，取消之前的选中
                this.executeSelect([]);
            }
            this.curDisplayObject = displayObject;
            if (this.curDisplayObject) {
                this.curDisplayObject.once(DISPLAY_LIFE_CYCLE.DRAW_BEGIN, (display) => {
                    this.tempLayer.addChild(display);
                });
                this.curDisplayObject.on(DISPLAY_LIFE_CYCLE.DRAW_UPDATE, (display) => {
                    this.recordOperationLog(display);
                });
                this.curDisplayObject.once(DISPLAY_LIFE_CYCLE.DRAW_DONE, (display) => {
                    this.handleDrawDisplayDone(display);
                });
            }
        }
        // 编辑元素状态下，点击空白区域退出编辑，并选中改元素
        if (this.curDisplayObject && this.curDisplayObject.editing) {
            if (e.target && e.target === e.currentTarget) {
                this.selected = [this.curDisplayObject];
                this.updateEditMode(EDIT_MODE.SELECT);
                return;
            }
        }
        this.curDisplayObject.start(this.getPointByViewport(e), e);
    }
}
