import { isNumber, merge } from 'lodash-es';
import { DEFAULT_ELEMENT_LINE_STYLE } from '../config';
import { MGraphic } from './MGraphic';
import { DISPLAY_TYPE } from './type';
export class MRect extends MGraphic {
    radius = 0;
    constructor(options) {
        super();
        if (options) {
            this.parseJSON(options);
            this.draw();
        }
        this.type = DISPLAY_TYPE.RECT;
        this.isClosePath = true;
    }
    update(point, e) {
        const { position: { x, y }, } = this;
        let isSquared = false;
        if (e && e.data.originalEvent.shiftKey) {
            isSquared = true;
        }
        const { x: ex, y: ey } = point;
        const width = ex - x;
        const height = isSquared ? width : ey - y;
        this.originWidth = width;
        this.originHeight = height;
        this.draw();
    }
    draw() {
        const width = this.originWidth;
        const height = this.originHeight;
        let radius = this.radius;
        radius = Math.min(radius, Math.min(width / 2, height / 2));
        const rx = radius;
        const ry = radius;
        this.clear().lineStyle(DEFAULT_ELEMENT_LINE_STYLE);
        const x = 0;
        const y = 0;
        if (this.radius === 0) {
            this.drawRect(0, 0, this.originWidth, this.originHeight);
        }
        else {
            // TODO: 直接使用 drawRoundRect的方法会出现，设置为正方形一半宽度的圆角值时不是正圆的问题，后续看pixi升级是否修复该问题
            // 参考了 fabric.js/src/shapes/rect.class.js 的实现
            /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
            const k = 1 - 0.5522847498;
            this.moveTo(x + rx, y);
            this.lineTo(x + width - rx, y);
            this.bezierCurveTo(x + width - k * rx, y, x + width, y + k * ry, x + width, y + ry);
            this.lineTo(x + width, y + height - ry);
            this.bezierCurveTo(x + width, y + height - k * ry, x + width - k * rx, y + height, x + width - rx, y + height);
            this.lineTo(x + rx, y + height);
            this.bezierCurveTo(x + k * rx, y + height, x, y + height - k * ry, x, y + height - ry);
            this.lineTo(x, y + ry);
            this.bezierCurveTo(x, y + k * ry, x + k * rx, y, x + rx, y);
            this.closePath();
            this.radius = radius;
        }
        super.draw();
        this.addHitArea();
    }
    parseJSON(data) {
        super.parseJSON(data);
        const { radius } = data;
        if (isNumber(radius)) {
            this.radius = radius;
            this.draw();
        }
    }
    transformUpdate() {
        // TODO: 此处的逻辑只适用直接绘制在画布的矩形，若要支持图纸中有层级嵌套的矩形，需要优化为用矩阵变换的形式
        this.originWidth = this.width;
        this.originHeight = this.height;
        // console.log('[ this.width ] >', this.width);
        this.scale.set(1, 1);
        this.draw();
    }
    toJSON(relativeEle) {
        const baseJSON = super.toJSON(relativeEle);
        return {
            ...baseJSON,
            radius: this.radius,
            maxRadius: Math.min(this.originWidth / 2, this.originHeight / 2),
        };
    }
    end(point, e) {
        const { position: { x, y }, } = this;
        const { x: ex, y: ey } = point;
        if (ex < x) {
            this.position.x = ex;
        }
        if (ey < y) {
            this.position.y = ey;
        }
        const width = Math.abs(ex - x);
        const height = e && e.data.originalEvent.shiftKey ? width : Math.abs(ey - y);
        this.originWidth = width;
        this.originHeight = height;
        this.draw();
        this.toggleFill(this.isFill);
        this.done();
    }
    getSVGData(options) {
        const radiusAttrs = this.radius ? { rx: this.radius, ry: this.radius } : {};
        return merge(super.getSVGData(options), {
            tag: 'rect',
            attrs: {
                x: 0,
                y: 0,
                width: this.width / this.scale.x,
                height: this.height / this.scale.y,
                ...radiusAttrs,
            },
        });
    }
}
