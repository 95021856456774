import { Container } from '@pixi/display';
import { cloneDeep, debounce, merge } from 'lodash-es';
import { Graphics, Matrix } from 'pixi.js';
import { DEFAULT_TEXT_STYLE } from '../../config';
import { isSinglePoint } from '../../controllers/interaction-manager';
import { DISPLAY_TYPE } from '../../display';
import { TRANSFORMER_EVENT } from '../../types';
import { INTERACTION_EVENT } from '../../types/event';
import { TextCurveHandle } from './TextCurveHandle';
const DEFAULT_TEXT_CURVE_CONFIG = {
    cursor: 'default',
    startThreshold: 2,
    autoAdjustOnSelect: true,
    autoAdjustOnDragEnd: true,
    autoAdjustMaxOffset: 200,
    autoAdjustViewPadding: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    autoHideTransformer: true,
    drawPathWhenPointerDown: true,
    mountParent: 'stage',
};
export class TextCurveContainer extends Container {
    options;
    transformer;
    view;
    handle;
    handlePathGraphics;
    target;
    handlePointerDown = false;
    handleDragging = false;
    hasHideTransformer = false;
    transformerMoving = false;
    get group() {
        return this.transformer.group;
    }
    constructor(options) {
        super();
        this.options = merge(cloneDeep(DEFAULT_TEXT_CURVE_CONFIG), options);
    }
    init(transformer, view) {
        this.transformer = transformer;
        this.view = view;
        const options = this.options;
        this.handlePathGraphics = new Graphics();
        this.handlePathGraphics.name = 'textCurveHandlePath';
        this.addChild(this.handlePathGraphics);
        // init handle
        this.handle = new TextCurveHandle(transformer, 'textCurve', {
            ...this.transformer.handleStyle,
            ...options.styleOpts,
        }, (pointerPosition) => this.onHandleDrag(pointerPosition), () => this.onHandleDragEnd(), options.cursor ?? 'default');
        this.handle.visible = false;
        this.addChild(this.handle);
        this.handle.on('pointerdown', this.onHandlePointerDown, this);
        this.handle.on('pointerup', this.onHandlePointerUp, this);
        this.handle.on('pointerupoutside', this.onHandlePointerUp, this);
        this.handle.on(INTERACTION_EVENT.DOUBLE_CLICK, debounce(this.onHandleDoubleClick, 16), this);
        this.transformer.on(TRANSFORMER_EVENT.SELECT, () => this.onSelectedGroupChange());
        this.transformer.on('pointerdown', () => {
            this.transformerMoving = true;
        });
        this.transformer.on('pointerup', () => {
            this.transformerMoving = false;
        });
        this.transformer.viewport.on('moved', this.draw, this);
        this.transformer.viewport.on('zoomed', this.draw, this);
    }
    mount(parent) {
        if (this.options.mountParent === 'transformer') {
            parent = this.transformer;
        }
        if (this.options.zIndex !== undefined) {
            parent.addChildAt(this, this.options.zIndex);
        }
        else {
            parent.addChild(this);
        }
    }
    onSelectedGroupChange() {
        const group = this.group;
        const target = group.length === 1 && group[0].type === DISPLAY_TYPE.TEXT
            ? group[0]
            : undefined;
        if (this.target !== target) {
            if (!target) {
                this.cancelHandleInteraction();
            }
            this.target = target;
            this.handle.visible = !!target;
            if (this.options.autoAdjustOnSelect) {
                this.doHandlePositionAdjust();
            }
        }
    }
    doHandlePositionAdjust() {
        if (!this.target) {
            return;
        }
        // 根据限制区域调整位置
        if (this.isOutLimitRange()) {
            this.adjustTextCurveHandle(this.target, DEFAULT_TEXT_STYLE.curveX);
        }
        // 根据限制距离调整位置
        if (this.isOutLimitOffset()) {
            this.adjustTextCurveHandle(this.target, DEFAULT_TEXT_STYLE.curveX);
        }
    }
    isOutLimitRange() {
        if (!this.options.autoAdjustViewPadding) {
            return false;
        }
        const view = this.view;
        const pos = this.handle.position;
        const padding = typeof this.options.autoAdjustViewPadding === 'function'
            ? this.options.autoAdjustViewPadding?.()
            : this.options.autoAdjustViewPadding;
        const minX = padding.left;
        const minY = padding.top;
        const maxX = view.clientWidth - padding.right;
        const maxY = view.clientHeight - padding.bottom;
        return pos.x > maxX || pos.x < minX || pos.y > maxY || pos.y < minY;
    }
    isOutLimitOffset() {
        const maxOffset = this.options.autoAdjustMaxOffset;
        if (isFinite(maxOffset)) {
            const text = this.target;
            return (Math.abs(text.style.curveX) > maxOffset ||
                Math.abs(text.style.curveY) > maxOffset);
        }
        else {
            return false;
        }
    }
    render(renderer) {
        if (this.renderable && this.visible) {
            this.draw();
        }
        super.render(renderer);
    }
    draw() {
        const target = this.target;
        if (target && !this.transformerMoving) {
            const { width, height } = 
            // @ts-ignore
            this.transformer?.groupBounds?.innerBounds ?? {};
            const minSize = this.options.styleOpts?.minSize ??
                this.transformer.handleStyle?.minSize;
            if (minSize > 0 && (width < minSize || height < minSize)) {
                this.handle.visible = false;
            }
            else {
                this.handle.visible = true;
            }
            if (this.handle.visible || this.handle.renderable) {
                const curveHandleGlobalPt = this.getHandleGlobalPt(target);
                this.handle.position.set(curveHandleGlobalPt.x, curveHandleGlobalPt.y);
            }
        }
        else {
            this.handle.position.set(0, 0);
        }
    }
    onHandlePointerDown(e) {
        if (!isSinglePoint(e)) {
            return;
        }
        this.handlePointerDown = true;
        if (this.target) {
            if (this.options.drawPathWhenPointerDown) {
                this.drawHandlePath(this.target);
                this._hideTransformer();
            }
        }
        this.options.lifecycle?.onPointerDown?.(this._getLifeCycleEventData());
    }
    onHandlePointerUp() {
        this.handlePointerDown = false;
        if (this.handleDragging) {
            this.handleDragging = false;
        }
        this.handlePathGraphics.clear();
        this._showTransformer();
        this.options.lifecycle?.onPointerUp?.(this._getLifeCycleEventData());
    }
    onHandleDrag(pointerPosition) {
        if (this.target) {
            const target = this.target;
            this.drawHandlePath(target);
            const curveHandleLocalPt = this.toHandleLocalPt(target, pointerPosition);
            this.updateTextCurveHandle(target, curveHandleLocalPt);
            if (!this.handleDragging) {
                this.handleDragging = true;
                this._hideTransformer();
                this.options.lifecycle?.onDragStart?.(this._getLifeCycleEventData());
            }
            this.options.lifecycle?.onDrag?.(this._getLifeCycleEventData());
        }
    }
    onHandleDragEnd() {
        if (!this.target) {
            return;
        }
        if (this.handleDragging) {
            this.transformer.commitGroup();
        }
        this.options.lifecycle?.onDragEnd?.(this._getLifeCycleEventData());
        if (this.options.autoAdjustOnDragEnd) {
            requestAnimationFrame(() => {
                this.doHandlePositionAdjust();
            });
        }
    }
    onHandleDoubleClick() {
        // 注意INTERACTION_EVENT.DOUBLE_CLICK在第二次pointerdown和pointerup之间派发
        this.cancelHandleInteraction();
        if (this.target && this.target.curveInfo.curveDir) {
            // 文字重置为无弯曲状态
            this.updateTextCurveHandle(this.target, {
                x: DEFAULT_TEXT_STYLE.curveX,
                y: 0,
            });
            this.transformer.commitGroup();
        }
        this.options.lifecycle?.onDoubleClick?.(this._getLifeCycleEventData());
    }
    cancelHandleInteraction() {
        if (this.handlePointerDown) {
            this.onHandlePointerUp();
        }
        this.handle.reset();
    }
    getHandleGlobalPt(text) {
        return text.toGlobal(new Matrix()
            .translate(text.curveInfo.curveAnchor.x, text.curveInfo.curveAnchor.y)
            .apply({
            x: text.style.curveX,
            y: text.style.curveY,
        }));
    }
    toHandleLocalPt(text, pointerPosition) {
        const curveHandleLocalPt = new Matrix()
            .translate(text.curveInfo.curveAnchor.x, text.curveInfo.curveAnchor.y)
            .applyInverse(text.toLocal(pointerPosition));
        this.applyStartThreshold(text, curveHandleLocalPt);
        return curveHandleLocalPt;
    }
    applyStartThreshold(text, curveHandleLocalPt) {
        const startThreshold = this.options.startThreshold;
        const threshold = isFinite(startThreshold) && startThreshold > 0 ? startThreshold : 0;
        if (threshold) {
            const { c, d } = text.worldTransform;
            const sy = Math.sqrt(c * c + d * d);
            if (Math.abs(curveHandleLocalPt.y) < threshold / sy) {
                curveHandleLocalPt.y = 0;
            }
        }
    }
    drawHandlePath(text) {
        const handlePathGraphics = this.handlePathGraphics;
        if (!handlePathGraphics || !text) {
            return;
        }
        const { curveDir, handlePath } = text.curveInfo;
        handlePathGraphics.clear();
        if (!curveDir) {
            return;
        }
        handlePathGraphics
            .lineStyle({
            width: 1,
            native: true,
            ...this.options.handlePathStyle,
        })
            .drawCircle(handlePath.c.x, handlePath.c.y, handlePath.radius);
        handlePathGraphics.worldTransform.copyFrom(text.worldTransform);
    }
    updateTextCurveHandle(text, offset) {
        if (isFinite(offset.x) && isFinite(offset.y)) {
            text.parseJSON({
                style: {
                    curveX: offset.x,
                    curveY: offset.y,
                },
            });
        }
    }
    adjustTextCurveHandle(text, targetX) {
        const curveDir = text.curveInfo.curveDir;
        let x = targetX;
        let y = 0;
        if (curveDir) {
            const r = Math.abs(text.curveInfo.curveRadius);
            x = Math.abs(targetX) < r ? targetX : Math.sign(targetX) * r;
            y = curveDir ? curveDir * (r - Math.sqrt(r ** 2 - x ** 2)) : 0;
        }
        this.updateTextCurveHandle(text, { x, y });
    }
    _hideTransformer() {
        if (this.options.autoHideTransformer &&
            this.options.mountParent !== 'transformer') {
            if (this.transformer.visible) {
                this.transformer.visible = false;
                this.hasHideTransformer = true;
            }
        }
    }
    _showTransformer() {
        if (this.options.autoHideTransformer &&
            this.options.mountParent !== 'transformer') {
            if (this.hasHideTransformer) {
                this.transformer.visible = true;
                this.hasHideTransformer = false;
            }
        }
    }
    _getLifeCycleEventData() {
        return {
            id: this.target?.id,
        };
    }
}
