import * as PIXI from 'pixi.js';
import { Mixin } from 'ts-mixer';
import { AbstractBatchCreate } from './AbstractBatchCreate';
import { AbstractCentralAxis } from './AbstractCentralAxis';
import { AbstractEvent } from './AbstractEvent';
import { AbstractHistory } from './AbstractHistory';
import { AbstractImageProcess } from './AbstractImageProcess';
import { AbstractImportExport } from './AbstractImportExport';
import { AbstractMask } from './AbstractMask';
import { AbstractOutline } from './AbstractOutline';
import { AbstractPlugin } from './AbstractPlugin';
export { MASK_EDITING_CONFIG } from './AbstractMask';
export class MCanvas extends Mixin(AbstractHistory, AbstractEvent, AbstractImageProcess, AbstractCentralAxis, AbstractBatchCreate, AbstractImportExport, AbstractOutline, AbstractPlugin, AbstractMask) {
    constructor(options) {
        super();
        this.init(options);
        this.setupEvents();
    }
}
// XXX: pixi chrome 插件调试
window['__PIXI_INSPECTOR_GLOBAL_HOOK__'] &&
    window['__PIXI_INSPECTOR_GLOBAL_HOOK__'].register({ PIXI: PIXI });
// 初始化canvas调试配置
window.canvas_debug = {};
