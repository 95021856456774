import { Input } from './Input';
import computeInputData from './InputData';
import { DoubleTap, LongPress, Pan, Pinch, TwoFingerTapRecognizer, } from './recognizers';
import { Session } from './Session';
import { getIndexInArray, getPointerEventType } from './Utils';
// 将 Pointer 转换为 Input，提供给 Recognizer 识别
export class PointerManager {
    pointers = [];
    session;
    recognizers = [];
    constructor() {
        this.addRecognizers();
    }
    processPointer(e, displayObject) {
        const ev = e.data.originalEvent;
        const eventType = getPointerEventType(ev);
        const pointerType = ev.pointerType;
        const isTouch = pointerType === "touch" /* INPUT_TYPE.TOUCH */;
        let storeIndex = getIndexInArray(this.pointers, ev.pointerId, 'pointerId');
        let removePointer = false;
        // ev.button === 0：鼠标左键
        if (eventType & 1 /* INPUT.START */ && (ev.button === 0 || isTouch)) {
            if (storeIndex < 0) {
                this.pointers.push(ev);
                storeIndex = this.pointers.length - 1;
            }
        }
        else if (eventType & (4 /* INPUT.END */ | 8 /* INPUT.CANCEL */)) {
            removePointer = true;
        }
        // 没有找到 index，则可能是 hover
        if (storeIndex < 0) {
            return;
        }
        this.pointers[storeIndex] = ev;
        // 处理 Input
        const input = new Input();
        input.pointers = this.pointers;
        input.changedPointers = [ev];
        input.pointerType = pointerType;
        input.eventType = eventType;
        input.srcEvent = ev;
        // pixi 相关
        input.interactionEvent = e;
        input.interactionEvent.currentTarget = displayObject;
        input.interactionEvent.target = displayObject;
        input.displayObject = displayObject;
        this.processInput(input);
        if (removePointer) {
            this.pointers.splice(storeIndex, 1);
        }
    }
    processInput(input) {
        if (input.isFirst) {
            this.session = new Session();
        }
        computeInputData(this.session, input);
        this.recognize(input);
        this.session.prevInput = input;
    }
    recognize(input) {
        const { recognizers } = this;
        let i = 0;
        let recognizer;
        while (i < recognizers.length) {
            recognizer = recognizers[i];
            recognizer.recognize(input);
            i++;
        }
    }
    addRecognizers() {
        const tap = new TwoFingerTapRecognizer();
        const longPress = new LongPress();
        const doubleTap = new DoubleTap();
        const pinch = new Pinch();
        const pan = new Pan();
        this.recognizers.push(tap);
        this.recognizers.push(longPress);
        this.recognizers.push(doubleTap);
        this.recognizers.push(pinch);
        this.recognizers.push(pan);
    }
}
