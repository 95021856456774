import { Matrix, Point } from '@pixi/math';
import { DEG_TO_RAD, RAD_TO_DEG } from 'pixi.js';
import svgPath from 'svgpath';
import { pointsOnPath } from '../packages/points-on-path';
/**
 * 获取geometry图形的格式化points
 *
 * @param {SVGPathNode} displayObj
 * @return realPoints
 */
export const genPointsFromGeometry = (displayObj, relativeEle) => {
    const allPoints = displayObj.getOriginalPoints();
    const formatPoints = combinePoints(allPoints);
    let realPoints = formatPoints;
    if (relativeEle) {
        realPoints = computeRealPoints(formatPoints, displayObj, relativeEle);
    }
    // const shapeType = displayObj.geometry.graphicsData[0]?.shape?.type;
    // if (shapeType && [SHAPES.CIRC, SHAPES.ELIP].includes(shapeType)) {
    //   // 去除第一个点(圆点)
    //   if (realPoints.length > 0) {
    //     realPoints.shift();
    //   }
    // }
    // 对相同点去重，smoothGraphics 在边框、填充、边框填充下部分点会重复计算
    // 对于明确标记了不需要去除重复点的图形
    if (displayObj.exportOption?.removeSamePoint) {
        realPoints = removeSamePoints(realPoints);
    }
    return [realPoints];
};
/**
 * 获取svg图形的格式化points
 *
 * @param {SVGPathNode} displayObj
 * @param {DisplayObject} relativeEle
 * @param {string} path
 * @param {boolean} judgeHollow 是否判断镂空情况
 * @return pointsList
 */
export const genPointsFromDPath = (displayObj, relativeEle, path, judgeHollow = true) => {
    if (!path) {
        path = displayObj.dPath;
    }
    const pointsList = [];
    // 同一条path上多M分割处理
    const splitPaths = splitPath(path);
    if (splitPaths && splitPaths.length > 0) {
        splitPaths.forEach((splitPath, index) => {
            // TODO: 完善镂空层级判断
            const isReverse = judgeHollow && index % 2 !== 0 ? false : true;
            const points = getPathPoints(splitPath, isReverse);
            const realPoints = computeRealPoints(points, displayObj, relativeEle);
            pointsList.push(realPoints);
        });
    }
    return pointsList;
};
/**
 * 获取一段path上的所有点
 * @param path
 * @returns
 */
export const genPathPoints = (path) => {
    const pointsList = [];
    // 同一条path上多M分割处理
    const splitPaths = splitPath(path);
    if (splitPaths && splitPaths.length > 0) {
        splitPaths.forEach((splitPath) => {
            const points = getPathPoints(splitPath);
            pointsList.push(points);
        });
    }
    return pointsList;
};
/**
 * 格式化的points路径转svg path字符串
 *
 * @param {string | any[][]} points
 * @param {number} multipleScale 路径放大比例
 * @param {boolean} bezier 采用贝塞尔指令连接
 * @return svgPath
 */
export const points2Paths = (points, multipleScale = 1, bezier = false, autoClose = true, x = 0, y = 0, 
// TODO: 将此参数设成传入
decimalPlace = 3) => {
    let svgPath = '';
    for (let i = 0; i < points.length; i++) {
        for (let j = 0; j < points[i].length; j++) {
            const allowBezier = bezier && ![0, points[i].length - 1].includes(j);
            if (!j) {
                svgPath += 'M';
            }
            else {
                svgPath += allowBezier ? 'Q' : 'L';
            }
            svgPath += allowBezier
                ? `${x + (points[i][j].x + points[i][j - 1].x) / 2 / multipleScale} ${y + (points[i][j].y + points[i][j - 1].y) / 2 / multipleScale} ${x + (points[i][j].x + points[i][j + 1].x) / 2 / multipleScale} ${y + (points[i][j].y + points[i][j + 1].y) / 2 / multipleScale}`
                : `${x + Number((points[i][j].x / multipleScale).toFixed(decimalPlace))}, ${y + Number((points[i][j].y / multipleScale).toFixed(decimalPlace))}`;
        }
        if (autoClose) {
            svgPath += 'Z';
        }
    }
    if (svgPath === '') {
        svgPath = 'M0,0';
    }
    return svgPath;
};
/**
 * 生成svg元素对象
 *
 * @param {string} path
 * @param {number} multipleScale 路径放大比例
 * @param {boolean} bezier 采用贝塞尔指令连接
 * @return svgElement
 */
export const geoSvg = (path, multipleScale = 1, bezier = false) => {
    if (path.length === 0) {
        return '';
    }
    const str = points2Paths(path, multipleScale, bezier);
    const svg = `<svg width="300" height="300" viewBox="0 0 300 300"  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#dddddd">
  <path stroke="black" fill="none" stroke-width="1" d="${str}"/>
  </svg>`;
    const svgElement = new DOMParser().parseFromString(svg, 'image/svg+xml').documentElement;
    return svgElement;
};
/**
 * 切割同一条path的多起点路径
 *
 * @param {string} path
 * @return match
 */
export const splitPath = (path) => {
    const match = path.match(/([Mm][^mM]*)+?/g) || [];
    return match;
};
/**
 * 获取svg path一条路径上的所有点
 *
 * @param {string} path
 * @param {boolean} isReverse
 * @return points
 */
export const getPathPoints = (path, isReverse = false) => {
    const contours = pointsOnPath(path)[0] || [];
    let points = contours.map((contour) => new Point(contour[0], contour[1]));
    if (isReverse) {
        points = points.reverse();
    }
    return points;
};
/**
 * 去除相同的点
 *
 * @param {Point[]} points
 * @return points
 */
const removeSamePoints = (points) => {
    const map = new Map();
    for (const item of points) {
        const key = `${item.x}-${item.y}`;
        if (!map.has(key) || map.get(key).y !== item.y) {
            map.set(key, item);
        }
    }
    return [...map.values()];
};
export const computeRealPoints = (points, displayObj, relativeEle) => {
    /*
     * 将元素的左顶点坐标转换成舞台坐标，然后再转换成相对 relativeEle的坐标
     * 因为调用的是 displayObj.toGlobal，所以 displayObj内的(0, 0)点就是 displayObj的左顶点
     */
    const worldCoordinates = displayObj.toGlobal(new Point(0, 0));
    const { x: relativeX, y: relativeY } = relativeEle.toLocal({
        x: worldCoordinates.x,
        y: worldCoordinates.y,
    });
    const matrix = new Matrix();
    const displayJSON = displayObj.toJSON(relativeEle);
    const { pivot, scale, angle, skew } = displayJSON;
    matrix.setTransform(relativeX, relativeY, pivot.x, pivot.y, scale.x, scale.y, angle * DEG_TO_RAD, skew.x, skew.y);
    for (let i = 0; i < points.length; i++) {
        if (points[i]) {
            matrix.apply(points[i], points[i]);
        }
    }
    return points;
};
export const computeRealPath = (dPath, displayObj, relativeEle) => {
    /*
     * 将元素的左顶点坐标转换成舞台坐标，然后再转换成相对 relativeEle的坐标
     * 因为调用的是 displayObj.toGlobal，所以 displayObj内的(0, 0)点就是 displayObj的左顶点
     */
    const worldCoordinates = displayObj.toGlobal(new Point(0, 0));
    const { x: relativeX, y: relativeY } = relativeEle.toLocal({
        x: worldCoordinates.x,
        y: worldCoordinates.y,
    });
    const displayJSON = displayObj.toJSON(relativeEle);
    const { pivot, scale, angle, skew } = displayJSON;
    const rotation = angle / RAD_TO_DEG;
    const a = Math.cos(rotation + skew.y) * scale.x;
    const b = Math.sin(rotation + skew.y) * scale.x;
    const c = -Math.sin(rotation - skew.x) * scale.y;
    const d = Math.cos(rotation - skew.x) * scale.y;
    const matrix = [
        a,
        b,
        c,
        d,
        relativeX - (pivot.x * a + pivot.y * c),
        relativeY - (pivot.x * b + pivot.y * d),
    ];
    const path = svgPath(dPath).matrix(matrix).round(1).toString();
    return path;
};
/**
 * 合并点数据列表为坐标列表
 *
 * @param {number[]} points
 * @return formatPoints
 */
export const combinePoints = (points) => {
    const formatPoints = [];
    for (let i = 0; i < points.length / 2; i++) {
        if (isNaN(points[i]) || typeof points[i] !== 'number') {
            console.log('pathPointUtils combinePoints points is NaN!!');
            continue;
        }
        const formatPoint = {
            x: points[i * 2],
            y: points[i * 2 + 1],
        };
        formatPoints.push(formatPoint);
    }
    return formatPoints;
};
/**
 * 拼合 svg 字符串
 *
 * @param path
 */
export const pieceSvg = (path) => {
    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve">
    <path d="${path}" />
  </svg>`;
};
/**
 * 从 svg 中获取 path
 *
 * @param svg
 * @returns
 */
export const matchPathInSvg = (svg) => {
    if (!svg) {
        return '';
    }
    const result = svg.match(/<path\s+d="([^"]+)"/);
    return result?.length > 1 ? result[1] : '';
};
