// svg 生成测试
export function previewSvg(path) {
    const svg = `<svg id="svg" version="1.1" width="800" height="800"
      xmlns="http://www.w3.org/2000/svg">${path} /></svg>`;
    const svgElement = new DOMParser().parseFromString(svg, 'image/svg+xml').documentElement;
    previewElement(svgElement);
    // ME.addSvg(svgElement);
}
export function previewElement(element) {
    if (!document.getElementById('preview')) {
        document.body.insertAdjacentHTML('afterbegin', `<div id="preview" style="position: absolute; top: 0; z-index: 2"></div>`);
    }
    if (!document.querySelector('#preview-style')) {
        addPreviewStyle();
    }
    const previewEle = document.getElementById('preview');
    previewEle.appendChild(element);
}
/**
 * 生成svg文档预览和下载链接
 */
export function previewAndDownloadSvg(svgStr, fileName = 'preview') {
    const blobURL = URL.createObjectURL(new Blob([svgStr], { type: 'image/svg+xml' }));
    const htmlStr = `<html>
        <head>
          <style>
            body { padding: 0; }
            a { position: fixed; right: 10px; }
          </style>
        </head>
        <body>
          <div><a href=${blobURL} download="${fileName}">Download</a></div>
          ${svgStr}
        </body>
      </html>
      `;
    console.log('Preview Link: %o', URL.createObjectURL(new Blob([htmlStr], { type: 'text/html' })));
}
export function previewCanvasWithDownload(canvas) {
    canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const elStr = `<img src=${url}></img>`;
        previewWithDownload(elStr, blob);
    });
    function previewWithDownload(svgStr, blob, fileName = 'preview') {
        const blobURL = URL.createObjectURL(blob);
        const htmlStr = `<html>
        <head>
          <style>
            body { padding: 0; }
            a { position: fixed; right: 10px; }
            img { max-width: 100%; max-height: 100%; outline: 1px solid; }
          </style>
        </head>
        <body>
          <div><a href=${blobURL} download="${fileName}">Download</a></div>
          ${svgStr}
        </body>
      </html>
      `;
        console.log('Preview Link: %o', URL.createObjectURL(new Blob([htmlStr], { type: 'text/html' })));
    }
}
function addPreviewStyle() {
    const previewStyle = document.createElement('style');
    previewStyle.id = 'preview-style';
    previewStyle.innerText = `
  body #preview {
    z-index: 99999 !important;
    pointer-events: none;
  }
  body #preview canvas {
    pointer-events: none;
    max-width: 160px;
    max-height: 160px;
    outline: 1px solid;
  }
`;
    document.head.append(previewStyle);
}
