import { GraphicsGeometry, SHAPES, graphicsUtils } from 'pixi.js';
import { triangulate } from '../utils/buildPoly';
export default class MPenGraphicGeometry extends GraphicsGeometry {
    processFill(data) {
        if (data.holes.length) {
            triangulate(data, this);
        }
        else {
            const command = graphicsUtils.FILL_COMMANDS[data.type];
            if (data.type === SHAPES.POLY) {
                triangulate(data, this);
            }
            else {
                command.triangulate(data, this);
            }
        }
    }
}
