import { INTERACTION_EVENT } from '../../../types';
import { Recognizer } from './Recognizer';
export class TwoFingerTapRecognizer extends Recognizer {
    constructor() {
        super();
        this.options = {
            event: INTERACTION_EVENT.TWO_FINGER_TAP,
            pointers: 2,
            time: 300,
            threshold: 9, // 移动的距离不超过
        };
    }
    process(input) {
        const { options } = this;
        const validPointers = input.pointers.length === options.pointers;
        const validMovement = input.distance < options.threshold;
        const validTouchTime = input.deltaTime < options.time;
        const validDelta = Math.abs(input.deltaX) < options.threshold &&
            Math.abs(input.deltaY) < options.threshold;
        if (input.eventType & 1 /* INPUT.START */ &&
            validMovement &&
            validTouchTime &&
            validPointers) {
            return 2 /* STATE.BEGAN */;
        }
        else if (input.isFinal &&
            input.maxPointers === options.pointers &&
            validDelta &&
            validTouchTime) {
            return 8 /* STATE.RECOGNIZED */;
        }
        return 32 /* STATE.FAILED */;
    }
    reset() { }
}
