import { Bounds, Point } from 'pixi.js';
export function boundsAddRectangle(bounds, rect, matrix) {
    const pts = rectangleToPoints(rect);
    if (matrix) {
        pts.forEach((pt) => bounds.addPointMatrix(matrix, pt));
    }
    else {
        pts.forEach((pt) => bounds.addPoint(pt));
    }
    return bounds;
}
export function rectangleToPoints(rect) {
    return [
        new Point(rect.x, rect.y),
        new Point(rect.x + rect.width, rect.y),
        new Point(rect.x + rect.width, rect.y + rect.height),
        new Point(rect.x, rect.y + rect.height),
    ];
}
export function rectTransform(rect, matrix) {
    const bounds = new Bounds();
    boundsAddRectangle(bounds, rect, matrix);
    rect.copyFrom(bounds.getRectangle());
    return rect;
}
