import { SVGScene } from '../packages/svgscene/SVGScene';
export class MSvg extends SVGScene {
    constructor(content, context, parseColor) {
        super(content, context, parseColor);
    }
    /**
     * 使当前图形显示内容的顶点与parent顶点重合
     */
    fitToParent() {
        if (!this.root) {
            return;
        }
        const { left, top } = this.root.getLocalBounds();
        this.root.x = -left;
        this.root.y = -top;
    }
}
