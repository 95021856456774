import { Point } from 'pixi.js';
import { CANVAS_EVENT } from '../types';
import { CentralAxis } from '../views';
import { AbstractBase } from './AbstractBase';
export class AbstractCentralAxis extends AbstractBase {
    /**
     * 创建中轴线
     *
     * @param {number} viewportX
     * @memberof MCanvas
     */
    createCentralAxis(viewportX, options) {
        if (this.centralAxis) {
            this.destroyCentralAxis();
        }
        this.centralAxisCb = () => {
            const globalPoint = this.viewport.toGlobal(new Point(this.centralAxis.mask.width, 0));
            this.centralAxis.axis.x = globalPoint.x;
        };
        const { worldHeight, worldWidth } = this.viewport;
        const { height: stageHeight, width: stageWidth } = this.app.screen;
        const globalPoint = this.viewport.toGlobal(new Point(viewportX, 0));
        const onUpdate = () => {
            this.emit(CANVAS_EVENT.UPDATE_CENTRAL_AXIS, this.centralAxis.mask.width);
        };
        this.centralAxis = new CentralAxis({
            viewportX,
            stageX: globalPoint.x,
            stageWidth,
            stageHeight,
            worldWidth,
            worldHeight,
            onUpdate,
            iconOption: options,
        });
        this.app.stage.addChild(this.centralAxis.axis);
        this.viewport.addChild(this.centralAxis.mask);
        this.viewport?.on('moved', this.centralAxisCb);
        this.viewport?.on('zoomed', this.centralAxisCb);
    }
    get centralAxisPosition() {
        return this.centralAxis?.mask.width || 0;
    }
    /**
     * 销毁中轴线
     *
     * @memberof MCanvas
     */
    destroyCentralAxis() {
        if (this.centralAxisCb) {
            this.viewport?.off('moved', this.centralAxisCb);
            this.viewport?.off('zoomed', this.centralAxisCb);
        }
        this.centralAxis?.clear();
        this.centralAxis = undefined;
        this.centralAxisCb = undefined;
    }
}
