export const VIEW_EVENT = {
    ADD_TO_VIEWPORT: 'ADD_TO_VIEWPORT',
    ADD_TO_TEMP_LAYER: 'ADD_TO_TEMP_LAYER',
    SELECT_VIEWPORT_ELEMENTS: 'SELECT_VIEWPORT_ELEMENTS',
    SET_VIEWPORT_MODE: 'SET_VIEWPORT_MODE',
};
// transformer 模块派发出的事件
export const TRANSFORMER_EVENT = {
    /**
     * 不可改变值, 通过transform 修改的 坐标尺寸过程中发生的事件
     */
    TRANSFORM_CHANGE: 'transformchange',
    /**
     * 通过transform 修改的 坐标尺寸结束时发生的事件
     */
    TRANSFORM_COMMIT: 'transformcommit',
    /**
     * 切换选中元素触发的事件
     */
    SELECT: 'SELECT',
};
export const INTERACTION_EVENT = {
    DOUBLE_CLICK: 'DOUBLE_CLICK',
    PINCH: 'PINCH',
    PAN: 'PAN',
    PINCH_START: 'PINCH_START',
    PINCH_CHANGE: 'PINCH_CHANGE',
    PINCH_END: 'PINCH_END',
    TWO_FINGER_TAP: 'TWO_FINGER_TAP',
    LONG_PRESS: 'LONG_PRESS',
};
// 由canvas模块派发到业务端的事件
export const CANVAS_EVENT = {
    BEFORE_HISTORY_CHANG: 'BEFORE_HISTORY_CHANG',
    /**
     * 操作栈改变
     */
    HISTORY_CHANG: 'HISTORY_CHANG',
    /**
     * TRANSFORMER_EVENT.SELECT 的转发
     */
    SELECT_OBJECT: 'SELECT_OBJECT',
    /**
     * TRANSFORMER_EVENT.TRANSFORM_COMMIT 的转发
     */
    SELECTED_TRANSFORM_COMMIT: 'SELECTED_TRANSFORM_COMMIT',
    /**
     * TRANSFORMER_EVENT.TRANSFORM_CHANGE 的转发
     */
    SELECT_OBJECT_TRANSFORMERED: 'SELECT_OBJECT_TRANSFORMERED',
    ZOOMED: 'ZOOMED',
    RESIZE: 'resize',
    UPDATE_BACKDROP_RESULT: 'UPDATE_BACKDROP_RESULT',
    UPDATE_CENTRAL_AXIS: 'UPDATE_CENTRAL_AXIS',
    DRAW_DISPLAY_DONE: 'DRAW_DISPLAY_DONE',
    UPDATE_IMAGE_PROCESS_VISIBLE: 'UPDATE_IMAGE_PROCESS_VISIBLE',
    UPDATE_IMAGE_TRACING_VISIBLE: 'UPDATE_IMAGE_TRACING_VISIBLE',
    NEW_DISPLAYS_ADDED: 'NEW_DISPLAYS_ADDED',
    DISPLAYS_REMOVED: 'DISPLAYS_REMOVED',
};
export const DISPLAY_OBJECT_EVENT = {
    ATTRS_CHANGED: 'ATTRS_CHANGED',
};
export var MODE_EVENT;
(function (MODE_EVENT) {
    MODE_EVENT["CHANGE"] = "MODE_CHANGE";
})(MODE_EVENT || (MODE_EVENT = {}));
export var VIEWPORT_EVENT;
(function (VIEWPORT_EVENT) {
    VIEWPORT_EVENT["ZOOMED"] = "zoomed";
    VIEWPORT_EVENT["MOVED"] = "moved";
})(VIEWPORT_EVENT || (VIEWPORT_EVENT = {}));
export var CONFIG_EVENT;
(function (CONFIG_EVENT) {
    CONFIG_EVENT["DISPLAY_CONFIG_CHANGE"] = "DISPLAY_CONFIG_CHANGE";
})(CONFIG_EVENT || (CONFIG_EVENT = {}));
export var GLOBAL_EVENT;
(function (GLOBAL_EVENT) {
    GLOBAL_EVENT["ENABLE_FILL"] = "ENABLE_FILL";
})(GLOBAL_EVENT || (GLOBAL_EVENT = {}));
export var UNDO_HISTORY_EVENT;
(function (UNDO_HISTORY_EVENT) {
    UNDO_HISTORY_EVENT["CAN_DO_CHANGE"] = "UNDO_HISTORY_CAN_DO_CHANGE";
    UNDO_HISTORY_EVENT["IMAGE_TRACING_LOADING"] = "IMAGE_TRACING_LOADING";
})(UNDO_HISTORY_EVENT || (UNDO_HISTORY_EVENT = {}));
export var ASSISTANT_EVENT;
(function (ASSISTANT_EVENT) {
    ASSISTANT_EVENT["GET_POSITION"] = "GET_POSITION";
    ASSISTANT_EVENT["CANCEL"] = "CANCEL";
    ASSISTANT_EVENT["CURVE_GET_POSITION"] = "CURVE_GET_POSITION";
    ASSISTANT_EVENT["CURVE_GET_POSITION_ERROR"] = "CURVE_GET_POSITION_ERROR";
})(ASSISTANT_EVENT || (ASSISTANT_EVENT = {}));
