import { HANDLE_MODE as HandleMode } from '@makeblock/transformer';
import { DISPLAY_TYPE } from '../display/type';
export * from './edit';
export * from './event';
export const HANDLE_MODE = HandleMode;
export var EDIT_MODE;
(function (EDIT_MODE) {
    // 选择模式
    EDIT_MODE["SELECT"] = "SELECT";
    // 文字模式
    EDIT_MODE["TEXT"] = "TEXT";
    // 画矩形模式
    EDIT_MODE["RECT"] = "RECT";
    // 画圆形模式
    EDIT_MODE["CIRCLE"] = "CIRCLE";
    // 画线条模式
    EDIT_MODE["LINE"] = "LINE";
    // 钢笔模式
    EDIT_MODE["PEN"] = "PEN";
    // 拖拽模式
    EDIT_MODE["DRAG"] = "DRAG";
    // 缩放模式
    EDIT_MODE["ZOOM"] = "ZOOM";
    // 插入图形
    EDIT_MODE["INSERT"] = "INSERT";
    // 位图蒙版
    EDIT_MODE["BITMAP_MASK"] = "BITMAP_MASK";
})(EDIT_MODE || (EDIT_MODE = {}));
export const DISPLAY_EDITMODE_MAP = {
    [EDIT_MODE.CIRCLE]: DISPLAY_TYPE.CIRCLE,
    [EDIT_MODE.RECT]: DISPLAY_TYPE.RECT,
    [EDIT_MODE.LINE]: DISPLAY_TYPE.LINE,
    [EDIT_MODE.TEXT]: DISPLAY_TYPE.TEXT,
    [EDIT_MODE.PEN]: DISPLAY_TYPE.PEN,
};
export const EDITMODE_DISPLAY_MAP = {
    [DISPLAY_TYPE.CIRCLE]: EDIT_MODE.CIRCLE,
    [DISPLAY_TYPE.RECT]: EDIT_MODE.RECT,
    [DISPLAY_TYPE.LINE]: EDIT_MODE.LINE,
    [DISPLAY_TYPE.TEXT]: EDIT_MODE.TEXT,
    [DISPLAY_TYPE.PEN]: EDIT_MODE.PEN,
};
export var RULER_POSITION;
(function (RULER_POSITION) {
    RULER_POSITION["TOP"] = "TOP";
    RULER_POSITION["BOTTOM"] = "BOTTOM";
    RULER_POSITION["LEFT"] = "LEFT";
    RULER_POSITION["RIGHT"] = "RIGHT";
})(RULER_POSITION || (RULER_POSITION = {}));
export var ViewportAlignOption;
(function (ViewportAlignOption) {
    ViewportAlignOption[ViewportAlignOption["CENTER"] = 0] = "CENTER";
    ViewportAlignOption[ViewportAlignOption["TOP_LEFT"] = 1] = "TOP_LEFT";
    ViewportAlignOption["AUTO"] = "auto";
    ViewportAlignOption["WIDTH"] = "width";
    ViewportAlignOption["HEIGHT"] = "height";
})(ViewportAlignOption || (ViewportAlignOption = {}));
export var XAlignOption;
(function (XAlignOption) {
    XAlignOption["LEFT"] = "left";
    XAlignOption["CENTER"] = "center";
    XAlignOption["RIGHT"] = "right";
})(XAlignOption || (XAlignOption = {}));
export var YAlignOption;
(function (YAlignOption) {
    YAlignOption["TOP"] = "top";
    YAlignOption["CENTER"] = "center";
    YAlignOption["BOTTOM"] = "bottom";
})(YAlignOption || (YAlignOption = {}));
export var CHANGE_LAYER_TYPE;
(function (CHANGE_LAYER_TYPE) {
    CHANGE_LAYER_TYPE["UP"] = "UP";
    CHANGE_LAYER_TYPE["DOWN"] = "DOWN";
    CHANGE_LAYER_TYPE["TOP"] = "TOP";
    CHANGE_LAYER_TYPE["END"] = "END";
})(CHANGE_LAYER_TYPE || (CHANGE_LAYER_TYPE = {}));
export var OUTLINE_TYPE;
(function (OUTLINE_TYPE) {
    OUTLINE_TYPE["INNER"] = "inner";
    OUTLINE_TYPE["OUTER"] = "outer";
})(OUTLINE_TYPE || (OUTLINE_TYPE = {}));
export var FLIP_TYPE;
(function (FLIP_TYPE) {
    FLIP_TYPE["FLIP_X"] = "flipX";
    FLIP_TYPE["FLIP_Y"] = "flipY";
})(FLIP_TYPE || (FLIP_TYPE = {}));
export var IMAGE_PROCESS_TOOL;
(function (IMAGE_PROCESS_TOOL) {
    IMAGE_PROCESS_TOOL["CUTTER"] = "cutter";
    IMAGE_PROCESS_TOOL["MAGIC_WAND"] = "magicWand";
    IMAGE_PROCESS_TOOL["ERASER"] = "eraser";
})(IMAGE_PROCESS_TOOL || (IMAGE_PROCESS_TOOL = {}));
export var BG_ALIGN_TYPE;
(function (BG_ALIGN_TYPE) {
    BG_ALIGN_TYPE["TOP_LEFT"] = "topLeft";
    BG_ALIGN_TYPE["TOP_RIGHT"] = "topRight";
    BG_ALIGN_TYPE["BOTTOM_LEFT"] = "bottomLeft";
    BG_ALIGN_TYPE["BOTTOM_RIGHT"] = "bottomRight";
})(BG_ALIGN_TYPE || (BG_ALIGN_TYPE = {}));
export var GET_ELE_INFO_TYPE;
(function (GET_ELE_INFO_TYPE) {
    GET_ELE_INFO_TYPE["ALL"] = "all";
    GET_ELE_INFO_TYPE["SPECIFIED"] = "specified";
    GET_ELE_INFO_TYPE["SELECTED"] = "selected";
})(GET_ELE_INFO_TYPE || (GET_ELE_INFO_TYPE = {}));
export var CONATINER_TYPE;
(function (CONATINER_TYPE) {
    CONATINER_TYPE["VIEWPORT"] = "viewport";
    CONATINER_TYPE["TEMP_LAYER"] = "tempLayer";
})(CONATINER_TYPE || (CONATINER_TYPE = {}));
