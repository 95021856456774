import { isNil, isString } from 'lodash-es';
import { SHAPES } from 'pixi.js';
import { getNumByFractionPrecision } from '../../../utils/math';
import { getMatrixArray } from '../../../utils/matrix';
/**
 * SVGDataLike序列化为svg字符串
 */
export function stringifySVG(data) {
    if (Array.isArray(data)) {
        let result = '';
        data.forEach((item) => {
            if (result) {
                result += '\n';
            }
            result += stringifySVG(item);
        });
        return result;
    }
    else if (typeof data === 'string') {
        return data;
    }
    if (!data || !data.tag) {
        return '';
    }
    const attrsStr = data.attrs
        ? Object.entries(data.attrs)
            .reduce((arr, [name, value]) => {
            // value为undefined, null, ''的不导出
            if (!(isNil(value) || (!value && isString(value)))) {
                arr.push(`${name}="${value}"`);
            }
            return arr;
        }, [''])
            .join(' ')
        : '';
    const childrenStr = data.children ? stringifySVG(data.children) : '';
    return `<${data.tag}${attrsStr}>${childrenStr}</${data.tag}>`;
}
/**
 * 获取DisplayObject相应的svg transform属性
 */
export function getSVGTransformAttr(obj, options) {
    const matrixArray = getMatrixArray(obj.worldTransform, {
        relMat: options?.relativeObj?.worldTransform,
        fractionPrecision: options?.fractionPrecision,
    });
    return { transform: `matrix(${matrixArray})` };
}
/**
 * 获取MGraphic和MPen对象相应的svg fill和stroke相关属性
 */
export function getSVGFillAndStrokeAttrs(obj, options) {
    const color = obj.layerColor ?? '#000000';
    if ((options?.fillStrokeExportMode === 'as-is' &&
        obj.isFill === true) ||
        options?.fillStrokeExportMode === 'as-fill') {
        return { stroke: 'none', fill: color };
    }
    else {
        return { stroke: color, fill: 'none' };
    }
}
/**
 * 获取GraphicsGeometry相应的svg <polygon>或<polyline> points属性
 * Note: 当前只支持Pixi的Polygon
 */
export function getSVGPointsAttrByGeometry(geometry, options) {
    const fractionPrecision = options?.fractionPrecision;
    let pointsStr = '';
    geometry.graphicsData.forEach((data) => {
        const pixiShapeTypes = Object.values(SHAPES);
        if (pixiShapeTypes.includes(data.shape.type)) {
            const points = data.points;
            for (let i = 0; i < points.length; i += 2) {
                const x = getNumByFractionPrecision(points[i], fractionPrecision);
                const y = getNumByFractionPrecision(points[i + 1], fractionPrecision);
                pointsStr += `${x},${y} `;
            }
        }
    });
    return pointsStr ? { points: pointsStr } : {};
}
/**
 * 根据Commands可迭代列表获取svg <path>的d属性
 */
export function getSVGPathDAttrByCommands(commands) {
    let d = '';
    const regex = /^[MLHVQCSTAZ]$/;
    for (const { type, values } of commands) {
        if (type.match(regex)) {
            d += `${type}${values.join(' ')} `;
        }
        else {
            console.error('Invalid command type');
        }
    }
    return d;
}
/**
 * 获取导出SVG内置模板的插槽函数
 */
export function getBasicSVGDocSlots(props) {
    const docSlot = () => {
        return [
            '<?xml version="1.0"?>',
            '<!-- Created with xTool Creative Space (https://www.xtool.com/pages/software) -->',
            slots.svg?.() ?? '',
        ];
    };
    const svgSlot = (attrs) => {
        return {
            tag: 'svg',
            attrs: {
                'xmlns': 'http://www.w3.org/2000/svg',
                'xmlns:xlink': 'http://www.w3.org/1999/xlink',
                'xmlns:xcs': 'https://www.xtool.com/pages/software',
                'version': '1.1',
                'preserveAspectRatio': 'xMinYMin meet',
                'width': (props.contentWidth ?? 0) + 'mm',
                'height': (props.contentHeight ?? 0) + 'mm',
                ...(props.viewBox ? { 'viewBox': props.viewBox.join(' ') } : {}),
                ...attrs,
            },
            children: [].concat(slots.style?.() ?? '', props.content ?? ''),
        };
    };
    const styleSlot = () => {
        return {
            tag: 'style',
            children: [
                'svg * { vector-effect: non-scaling-stroke; stroke-width: 1px; }',
            ],
        };
    };
    const slots = { doc: docSlot, svg: svgSlot, style: styleSlot };
    return slots;
}
/**
 * 生成svg文档。未指定template或template不可用时使用内置的svg模板
 */
export function makeSVGDoc(props, template) {
    const slots = getBasicSVGDocSlots(props);
    const result = template?.(props, slots) ?? slots.doc();
    if (typeof result === 'string') {
        return result;
    }
    else {
        return stringifySVG(result);
    }
}
