import { MSprite, MSvg } from '../display';
const svgLoader = (data) => {
    const { source, parseColor } = data;
    source.style.visibility = 'hidden';
    document.body.appendChild(source);
    try {
        console.time('parse svg');
        const svg = new MSvg(source, null, parseColor);
        console.timeEnd('parse svg');
        svg.fitToParent();
        return svg.root;
    }
    catch (error) {
        console.log(error);
    }
    finally {
        document.body.removeChild(source);
    }
    return null;
};
const bitmapLoader = (data) => {
    const { source, width, height } = data;
    const sprite = new MSprite(source);
    if (width) {
        sprite.width = width;
    }
    if (height) {
        sprite.height = height;
    }
    return sprite;
};
const loader = {
    svg: svgLoader,
    bitmap: bitmapLoader,
};
export const loadFile = (loaderName, data) => {
    if (loaderName in loader) {
        return loader[loaderName](data);
    }
};
