import { Point } from 'pixi.js';
// 根据中心点，找到指定点的对称点
export function getSymmetryPoint(point, centerPoint) {
    const dx = point.x - centerPoint.x;
    const dy = point.y - centerPoint.y;
    const x = centerPoint.x - dx;
    const y = centerPoint.y - dy;
    return new Point(x, y);
}
// 找到当前点的两个控制点：控制点与当前点的连线平行于前后两点的连线，
// 且控制点与当前点的距离等于前后两点距离的1/4
export function getControlPoints(prePoint, curPoint, nextPoint) {
    const cp1 = new Point();
    const cp2 = new Point();
    // 特例处理：如果只有两个点的时候
    const distance = 20;
    if (prePoint === nextPoint) {
        cp1.x = curPoint.x;
        cp1.y = curPoint.y - distance;
        cp2.x = curPoint.x;
        cp2.y = curPoint.y + distance;
        return { cp1, cp2 };
    }
    const k = (nextPoint.y - prePoint.y) / (nextPoint.x - prePoint.x);
    const deltaX = nextPoint.x - prePoint.x;
    const deltaY = nextPoint.y - prePoint.y;
    const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    // 前后点的距离是控制点距离的 2 倍，也就是控制点到当前点距离的 4 倍
    const multiple = 4;
    if (nextPoint.x >= prePoint.x) {
        cp1.x = curPoint.x - length / (multiple * Math.sqrt(k * k + 1));
        cp1.y = curPoint.y - (length * k) / (multiple * Math.sqrt(k * k + 1));
        cp2.x = curPoint.x + length / (multiple * Math.sqrt(k * k + 1));
        cp2.y = curPoint.y + (length * k) / (multiple * Math.sqrt(k * k + 1));
    }
    else {
        cp1.x = curPoint.x + length / (multiple * Math.sqrt(k * k + 1));
        cp1.y = curPoint.y + (length * k) / (multiple * Math.sqrt(k * k + 1));
        cp2.x = curPoint.x - length / (multiple * Math.sqrt(k * k + 1));
        cp2.y = curPoint.y - (length * k) / (multiple * Math.sqrt(k * k + 1));
    }
    return { cp1, cp2 };
}
// 计算同一坐标系内，某一点围绕另一个点旋转后的坐标，不指定 origin 则默认为原点
export function calPointRotatedCoord(point, rotation, origin = { x: 0, y: 0 }) {
    const x = point.x - origin.x;
    const y = point.y - origin.y;
    const rx = x * Math.cos(rotation) - y * Math.sin(rotation);
    const ry = y * Math.cos(rotation) + x * Math.sin(rotation);
    return {
        x: rx + origin.x,
        y: ry + origin.y,
    };
}
// 计算同一坐标系内，Rectangle 绕另一个点旋转后的坐标（处理内部绕中心旋转产生的偏差），不指定 origin 则默认为原点
export function calRectangleRotatedCoord(rect, rotation, origin = { x: 0, y: 0 }) {
    const { x, y, width, height } = rect;
    const center = {
        x: x + width / 2,
        y: y + height / 2,
    };
    const targetCenter = calPointRotatedCoord(center, rotation, origin);
    const offset = {
        x: -width / 2,
        y: -height / 2,
    };
    const rOffset = calPointRotatedCoord(offset, rotation);
    return {
        x: targetCenter.x + rOffset.x,
        y: targetCenter.y + rOffset.y,
    };
}
/**
 * 计算保持固有宽高比的期望宽高值
 */
export function getTargetSizeKeepAspectRatio(bounds, target) {
    let { width, height } = target;
    // 确定内容展示宽高
    if (!width && !height) {
        width = bounds.width;
        height = bounds.height;
    }
    else if (!width) {
        width = (height / bounds.height) * bounds.width;
    }
    else if (!height) {
        height = (width / bounds.width) * bounds.height;
    }
    else {
        // 根据内容比例调整展示宽高（按照宽高相对变化率及其绝对值换算其中一边）
        const widthR = width / bounds.width - 1;
        const heightR = height / bounds.height - 1;
        if (widthR !== heightR) {
            const absWidthR = Math.abs(widthR);
            const absHeightR = Math.abs(heightR);
            if (absWidthR > absHeightR ||
                (absWidthR === absHeightR && widthR > 0 && heightR < 0)) {
                width = (height / bounds.height) * bounds.width;
            }
            else if (absWidthR < absHeightR ||
                (absWidthR === absHeightR && widthR < 0 && heightR > 0)) {
                height = (width / bounds.width) * bounds.height;
            }
        }
    }
    return { width, height };
}
