import { Transformer } from '@makeblock/transformer';
import { isArray } from 'lodash-es';
import { Point, Rectangle } from 'pixi.js';
import { updateDisplayPosition } from '../../utils';
export class Align {
    constructor() { }
    /**
     * 执行入口
     * @param type
     * @param group 选中元素组
     * @param groupBound 元素组边界 —— 相对于浏览器坐标系
     * @param viewport
     * @returns
     */
    execute(type, group, groupBounds, viewport) {
        if (group.length < 1) {
            return;
        }
        const handle = this[type];
        handle.call(this, group, groupBounds, viewport);
    }
    /**
     *
     * 抽取不同对齐方式的共性，简化代码。实际上，任何对齐方式都有一定的共性
     * 目的都是计算偏移值，然后更改x或y的值。
     * 本函数通过operation参数控制x偏移或y偏移
     * @private
     * @param {MDisplayObject[]} group
     * @param {MViewport} viewport
     * @param {AlignOperation} operation
     * targetX 目标x坐标，
       widthRate 元件宽度系数， 如果不需要则传0，
       targetY 目标y坐标 ，
       heightRate 元件高度系数， 如果不需要则传0
     * @memberof Align
     */
    alignGroup(target, viewport, operation) {
        const { targetX = 0, targetY = 0, widthRate = 0, heightRate = 0, } = operation;
        const bounds = this.getBounds(target);
        const group = isArray(target) ? target : [target];
        const offsetX = targetX !== 0
            ? (bounds.x + widthRate * bounds.width - targetX) / viewport.scale.x
            : 0;
        const offsetY = targetY !== 0
            ? (bounds.y + heightRate * bounds.height - targetY) / viewport.scale.y
            : 0;
        group.forEach((el) => {
            const json = el.getPointToRelativeElement(viewport);
            const dPos = new Point(json.x - offsetX, json.y - offsetY);
            updateDisplayPosition(el, dPos, viewport);
        });
    }
    calcGroupBounds(selected) {
        const bounds = Transformer.calculateGroupOrientedBounds(selected, 0, null, true);
        const x = bounds.topLeft.x;
        const y = bounds.topLeft.y;
        const width = bounds.bottomRight.x - x;
        const height = bounds.bottomRight.y - y;
        const rect = new Rectangle(x, y, width, height);
        return rect;
    }
    getBounds(ele) {
        return isArray(ele) ? this.calcGroupBounds(ele) : ele.getBounds();
    }
    /**
     * 选中元素左对齐
     */
    alignToLeft(group, groupBounds, viewport) {
        let targetX = viewport.x;
        if (group.length > 1) {
            targetX = groupBounds.x;
        }
        group.forEach((ele) => {
            this.alignGroup(ele, viewport, { targetX });
        });
    }
    /**
     * 选中元素右对齐
     */
    alignToRight(group, groupBounds, viewport) {
        let targetX = viewport.x + viewport.worldScreenWidth;
        if (group.length > 1) {
            targetX = groupBounds.x + groupBounds.width;
        }
        group.forEach((ele) => {
            this.alignGroup(ele, viewport, { targetX, widthRate: 1 });
        });
    }
    /**
     * 选中元素顶部对齐
     */
    alignToTop(group, groupBounds, viewport) {
        let targetY = viewport.y;
        if (group.length > 1) {
            targetY = groupBounds.y;
        }
        group.forEach((ele) => {
            this.alignGroup(ele, viewport, { targetY });
        });
    }
    /**
     * 选中元素底部对齐
     */
    alignToBottom(group, groupBounds, viewport) {
        let targetY = viewport.y + viewport.worldScreenHeight;
        if (group.length > 1) {
            targetY = groupBounds.y + groupBounds.height;
        }
        group.forEach((ele) => {
            this.alignGroup(ele, viewport, { targetY, heightRate: 1 });
        });
    }
    /**
     * 选中元素水平居中对齐
     */
    alignToHorizontalCenter(group, groupBounds, viewport) {
        let targetY = viewport.y + viewport.worldScreenHeight / 2;
        if (group.length > 1) {
            targetY = groupBounds.y + groupBounds.height / 2;
        }
        group.forEach((ele) => {
            this.alignGroup(ele, viewport, { targetY, heightRate: 0.5 });
        });
    }
    /**
     * 选中元素垂直居中对齐
     */
    alignToVerticalCenter(group, groupBounds, viewport) {
        let targetX = viewport.x + viewport.worldScreenWidth / 2;
        if (group.length > 1) {
            targetX = groupBounds.x + groupBounds.width / 2;
        }
        group.forEach((ele) => {
            this.alignGroup(ele, viewport, { targetX, widthRate: 0.5 });
        });
    }
    /**
     * 选中元素水平分布
     */
    horizontalDistribute(group, groupBounds, viewport) {
        if (group.length < 3) {
            return;
        }
        // 计算空白宽距
        let occupyWidth = 0;
        group.forEach((ele) => {
            const bounds = this.getBounds(ele);
            occupyWidth += bounds.width;
        });
        const spaceWidth = groupBounds.width - occupyWidth;
        const gapNum = group.length - 1;
        const gapWidth = spaceWidth / gapNum;
        // 按左边界排序对象
        const sortedGroup = group.sort((first, second) => {
            return this.getBounds(first).x - this.getBounds(second).x;
        });
        // 换算内部位移距离
        let tailX = 0;
        sortedGroup.forEach((ele, idx) => {
            const bounds = this.getBounds(ele);
            if (idx === 0) {
                tailX = bounds.x + bounds.width;
                return;
            }
            if (idx === sortedGroup.length - 1) {
                return;
            }
            const targetX = tailX + gapWidth;
            this.alignGroup(ele, viewport, { targetX });
            tailX = targetX + bounds.width;
        });
    }
    /**
     * 选中元素垂直分布
     */
    verticalDistribute(group, groupBounds, viewport) {
        if (group.length < 3) {
            return;
        }
        // 计算空白宽距
        let occupyHeight = 0;
        group.forEach((ele) => {
            const bounds = this.getBounds(ele);
            occupyHeight += bounds.height;
        });
        const spaceHeight = groupBounds.height - occupyHeight;
        const gapNum = group.length - 1;
        const gapHeight = spaceHeight / gapNum;
        // 按左边界排序对象
        const sortedGroup = group.sort((first, second) => {
            return this.getBounds(first).y - this.getBounds(second).y;
        });
        // 换算内部位移距离
        let tailY = 0;
        sortedGroup.forEach((ele, idx) => {
            const bounds = this.getBounds(ele);
            if (idx === 0) {
                tailY = bounds.y + bounds.height;
                return;
            }
            if (idx === sortedGroup.length - 1) {
                return;
            }
            const targetY = tailY + gapHeight;
            this.alignGroup(ele, viewport, { targetY });
            tailY = targetY + bounds.height;
        });
    }
}
