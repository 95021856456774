import { Stage } from '@pixi/layers';
import { EventEmitter } from '@pixi/utils';
import { groupBy, isNumber, isUndefined, map, max, pick, uniq, } from 'lodash-es';
import { Application, DEG_TO_RAD, DisplayObject, ENV, ExtensionType, GRAPHICS_CURVES, Point, extensions, settings, } from 'pixi.js';
import { v4 as uuid } from 'uuid';
import { DEFAULT_CANVAS_BACKGROUND_COLOR, DEFAULT_VIEWPORT_CONFIG, MIN_DISPLAY_SIZE, } from '../config';
import { Align } from '../controllers/align';
import { BatchCreate } from '../controllers/batch-create';
import { HitAreaManager } from '../controllers/hit-area-manager/HitAreaManager';
import { default as ProcessController } from '../controllers/image-process';
import { MInteractionManager } from '../controllers/interaction-manager';
import { LayerManager } from '../controllers/layer-manager/LayerManager';
import { OperationStack } from '../controllers/operation-stack';
import { DISPLAY_TYPE } from '../display/type';
import { amendVectorFill, createDisplay, trimCanvas } from '../display/utils';
import { updateDisplaysDemision, updateDisplaysPosition, updateDisplaysRotation, } from '../display/utils/attr';
import ConfigManager from '../models/ConfigManager';
import GlobalConfigManager from '../models/GlobalConfigManager';
import ModeManager from '../models/ModeManager';
import { TextCurveContainer } from '../packages/text-curve/TextCurveContainer';
import { CHANGE_LAYER_TYPE, CONATINER_TYPE, EDIT_MODE, ViewportAlignOption, } from '../types';
import { BOOL_OP } from '../types/edit';
import { CANVAS_EVENT, TRANSFORMER_EVENT } from '../types/event';
import { MapSet, calcAspectRatioFit, createNewGroupTagForCollection, deepDestroy, formatSelected, getBitmapMaxSize, getDisplayRenderData, getSelectedElements, setGroupTag, updateDisplayAttributes, updateDisplayPosition, } from '../utils';
import { changeGraphicsCurvesSettingFor, getGraphicsCurveSetting, setGraphicsCurvesSetting, } from '../utils/accessGraphicsCurvesSetting';
import { iterateInteractiveElements } from '../utils/container';
import { hasOneIn } from '../utils/math';
import { createOperationLog, getElementsLogAttrs, } from '../utils/operationStack';
import { genPathPoints } from '../utils/pathPoint';
import { MTransformer, MViewport, Selector, TempLayer } from '../views';
extensions.add({
    name: 'interaction',
    type: ExtensionType.RendererPlugin,
    ref: MInteractionManager,
});
// graphics 中绘制圆弧或贝塞尔曲线时两个端点生成点的片段的配置,对graphic.drawCircle无效
GRAPHICS_CURVES.minSegments = 50;
export class AbstractBase extends EventEmitter {
    plugins = [];
    app;
    viewport;
    transformer;
    tempLayer;
    selector;
    centralAxis;
    imageProcess;
    imageTracing;
    operationStack = new OperationStack();
    curDisplayObject;
    modeManager = new ModeManager();
    configManager = new ConfigManager();
    globalManager = GlobalConfigManager.getInstance();
    layerManager = new LayerManager();
    batchCreate = new BatchCreate();
    alignController = new Align();
    centralAxisCb;
    isDragMode = false;
    enableSelectElements = true;
    canvasOptions;
    tempOutline = null;
    hitAreaManager;
    textCurve;
    otherData = null;
    scaleMode;
    _id = uuid();
    contextInfo;
    get id() {
        return this._id;
    }
    set id(value) {
        this._id = value;
    }
    get view() {
        return this.app.view;
    }
    get currentState() {
        return {
            hasBackground: !!this.viewport.backgroundSprite,
            backgroundVisible: this.backgroundVisible(),
            enableCombine: !!this.selectedPathsClosed,
            scaled: this.viewport.scaled,
            enableMoveBack: false,
            enableMoveForward: false,
            enableRedo: this.canRedo,
            enableUndo: this.canUndo,
            enableSelectElements: this.enableSelectElements,
            viewportWidth: this.viewport.worldWidth,
            viewportHeight: this.viewport.worldHeight,
            ...this.getSelectedData(),
        };
    }
    get curMaxZOrder() {
        return max(this.getInteractiveElements().map(({ zOrder }) => zOrder)) || 0;
    }
    beforeAddDisplayToViewport(display, specificActions) {
        if (!display || (Array.isArray(display) && display.length === 0)) {
            return;
        }
        // 对象共有的行为
        const displays = Array.isArray(display) ? display : [display];
        displays.forEach((display) => this.commonActionToDisplay(display));
        // 特定类型对象在添加之前的特有行为
        if (specificActions && specificActions.length > 0) {
            for (let i = 0; i < specificActions.length; i++) {
                const { action } = specificActions[i];
                let { args } = specificActions[i];
                args = args || [display];
                action(...args);
            }
        }
    }
    addDisplayToViewport(display, autoSelected = true) {
        this.viewport.addToLayer(display);
        if (display.interactive && autoSelected) {
            this.selected = [display];
        }
    }
    commonActionToDisplay(display) {
        const { displayConfig } = this.configManager;
        const { enableShowFill = true } = displayConfig;
        display.interactive = true;
        amendVectorFill(display);
        // TODO: 可由外部 预处理对象加入画布的最终属性
        // 如果元素有layerColor，和layerTag的属性就用自身的属性，如果没有就用displayConfig中值
        const layerColor = display.layerColor ?? displayConfig.layerColor;
        const layerTag = display.layerTag ?? displayConfig.layerTag;
        const visible = displayConfig.layerData[layerTag]?.visible ?? true;
        display.parseJSON({
            ...displayConfig,
            // 导入的元素根据是否允许填充和元素本身是否填充决定最终的填充状态
            isFill: enableShowFill && display.isFill,
            layerColor,
            layerTag,
            visible,
        });
        display.parentGroup = this.viewport.displayGroup;
    }
    initApp(options) {
        const appOptions = Object.assign({
            resolution: window.devicePixelRatio,
            antialias: true,
            autoStart: false,
            autoDensity: true,
            backgroundColor: DEFAULT_CANVAS_BACKGROUND_COLOR,
        }, options);
        this.app = new Application(appOptions);
        this.app.view.style.width = '100%';
        this.app.view.style.height = '100%';
        this.app.stage = new Stage();
        this.app.stage.sortableChildren = true;
        this.app.ticker.maxFPS = 30;
        this.app.renderer.on('resize', (stageWidth, stageHeight) => {
            this.resizeViewport({
                width: stageWidth,
                height: stageHeight,
                scaleMode: this.scaleMode,
            });
            this.plugins.forEach((plugin) => {
                if (plugin.resize) {
                    plugin.resize(stageWidth, stageHeight);
                }
            });
            this.centralAxis?.resize({
                worldHeight: this.viewport.worldHeight,
                stageHeight,
            });
        });
        globalThis.__PIXI_APP__ = this.app;
    }
    initViewport(options) {
        const { offsetX = 0, offsetY = 0, wheelOnView = false } = options || {};
        const vpOptions = Object.assign({
            screenWidth: this.app.screen.width - offsetX,
            screenHeight: this.app.screen.height - offsetY,
            worldWidth: DEFAULT_VIEWPORT_CONFIG.worldWidth,
            worldHeight: DEFAULT_VIEWPORT_CONFIG.worldHeight,
            interaction: this.app.renderer.plugins.interaction,
            disableOnContextMenu: true,
            divWheel: wheelOnView ? this.app.view : '',
            passiveWheel: false,
            stopPropagation: true,
            minScale: DEFAULT_VIEWPORT_CONFIG.minScale,
            maxScale: DEFAULT_VIEWPORT_CONFIG.maxScale,
            fitOffsetRatio: DEFAULT_VIEWPORT_CONFIG.fitOffsetRatio,
            backgroundColor: DEFAULT_VIEWPORT_CONFIG.backgroundColor,
            backgroundAlpha: DEFAULT_VIEWPORT_CONFIG.backgroundAlpha,
            outlineNative: DEFAULT_VIEWPORT_CONFIG.outlineNative,
            outlineWidth: DEFAULT_VIEWPORT_CONFIG.outlineWidth,
            outlineColor: DEFAULT_VIEWPORT_CONFIG.outlineColor,
            outlineAlpha: DEFAULT_VIEWPORT_CONFIG.outlineAlpha,
        }, options);
        this.viewport = new MViewport(vpOptions);
        this.viewport.toWheelMode();
        this.app.stage.addChild(this.viewport);
    }
    initTransformer(options) {
        this.transformer = new MTransformer(this.viewport, options);
        this.app.stage.addChild(this.transformer);
    }
    initTextCurve(options) {
        if (options) {
            this.textCurve = new TextCurveContainer(options);
            this.textCurve.init(this.transformer, this.app.view);
            this.textCurve.mount(this.app.stage);
        }
    }
    initEnv() {
        const renderer = this.app.renderer;
        const gl = renderer.gl;
        let max_texture_size = -1;
        if (gl) {
            max_texture_size = gl.getParameter?.(gl.MAX_TEXTURE_SIZE);
        }
        this.contextInfo = {
            version: settings.PREFER_ENV,
            max_texture_size,
            get bitmapMaxSize() {
                return getBitmapMaxSize();
            },
        };
        console.log('[ canvas context Info ] >', this.contextInfo);
    }
    initHitAreaManager(options) {
        this.hitAreaManager = new HitAreaManager(options);
        this.hitAreaManager.init(this.viewport, this.transformer);
    }
    init(options) {
        const { cursorConfig, imageProcessConfig } = options || {};
        this.initViews(options);
        this.initCursor(cursorConfig);
        this.configManager.imageProcessConfig = imageProcessConfig;
        this.canvasOptions = options;
    }
    initTempLayer() {
        this.tempLayer = new TempLayer();
        this.viewport.addChild(this.tempLayer);
    }
    initSelector(options) {
        this.selector = new Selector(options);
        this.viewport.addChild(this.selector);
    }
    initViews(options) {
        const { appConfig, viewportConfig, selectorConfig, transformerConfig, hitAreaConfig, textCurveConfig, } = options || {};
        this.initApp(appConfig);
        this.initViewport(viewportConfig);
        this.initTransformer(transformerConfig);
        this.initTextCurve(textCurveConfig);
        this.initTempLayer();
        this.initSelector(selectorConfig);
        this.initHitAreaManager(hitAreaConfig);
        this.initEnv();
        this.initEventForMask();
    }
    initCursor(cursorConfig) {
        cursorConfig?.forEach((cursor) => {
            const icon = `url(${cursor.url}) ${cursor.style}`;
            this.app.renderer.plugins.interaction.cursorStyles[cursor.name] = icon;
        });
    }
    getPointByViewport(e) {
        const { data } = e;
        return data.getLocalPosition(this.viewport);
    }
    handleDrawDisplayDone(display) {
        this.curDisplayObject = undefined;
        // 绘制出的图形太小时不添加， 以图形在世界坐标中呈现的尺寸为参考
        const globalBounds = display.getBounds();
        if (globalBounds.width < MIN_DISPLAY_SIZE &&
            globalBounds.height < MIN_DISPLAY_SIZE) {
            display.destroy(true);
        }
        else {
            this.beforeAddDisplayToViewport(display);
            this.addDisplayToViewport(display);
            this.recordOperationLog(display);
        }
        this.emit(CANVAS_EVENT.DRAW_DISPLAY_DONE);
    }
    finishCurMode() {
        if (this.curDisplayObject && this.curDisplayObject.done) {
            this.curDisplayObject.done();
        }
        if (this.imageProcess) {
            this.closeImageProcess();
        }
        if (this.imageTracing) {
            this.closeImageTracing();
        }
        if (this.modeManager.curMode === EDIT_MODE.BITMAP_MASK &&
            this.bitmapMaskEditing) {
            this.endMaskEditMode(true, true);
            this.updateEditMode(EDIT_MODE.SELECT);
        }
        this.curDisplayObject = undefined;
    }
    executeSelect(selected) {
        this.transformer.selected = selected;
    }
    getSelectedData() {
        const selectedJSON = this.exportSelectedJSON();
        let groupJSON = this.transformer.groupJSON;
        if (this.selected.length === 1) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { x, y, width, height, angle, ...rest } = selectedJSON[0];
            groupJSON = { ...groupJSON, ...rest };
        }
        return {
            canvasId: this.id,
            selectedJSON,
            isGroup: this.isSelectGroup(),
            selectedLength: formatSelected(this.transformer.selected).length,
            groupJSON,
            bitmapMask: this.getBitmapMaskState(),
        };
    }
    findSameGroupElements(el, groupedElements) {
        const elements = groupedElements
            ? (el.groupTag && groupedElements[el.groupTag]) || []
            : this.getElementsByGroupTag(el.groupTag);
        if (elements.length === 0) {
            elements.push(el);
        }
        return elements;
    }
    getElementsByGroupTag(tag, activeElements) {
        activeElements = activeElements ?? this.getSelectionActiveTargets();
        const sameGroup = [];
        activeElements.forEach((el) => {
            if (el.groupTag && el.groupTag === tag) {
                sameGroup.push(el);
            }
        });
        return sameGroup;
    }
    getMinAreaElementByPoint(point) {
        const objsToFind = this.getSelectionActiveTargets({ excludeHidden: true });
        const elements = this.viewport?.getPointClickElements(point, objsToFind) || [];
        if (elements.length > 0) {
            elements.sort(this.sortByZOrder);
            const minAreaElement = this.getMinAreaElement(elements);
            return minAreaElement;
        }
        return null;
    }
    sortByZOrder(a, b) {
        return a.zOrder - b.zOrder;
    }
    /*
     * fillMode 为true，那么填充的元件无法穿透
     */
    getMinAreaElement(elements, fillMode = true, tolerance = 0.01) {
        let minAreaElement = elements.pop();
        if (fillMode && minAreaElement.isFill) {
            return minAreaElement;
        }
        for (let i = elements.length - 1; i >= 0; i--) {
            const el = elements[i];
            const area = el.width * el.height;
            const minArea = minAreaElement.width * minAreaElement.height;
            if (minArea - area > tolerance) {
                minAreaElement = el;
            }
            if (fillMode && el.isFill) {
                break;
            }
        }
        return minAreaElement;
    }
    /*
     * 反选
     */
    invertSelect(target, selected, groupedActiveElements) {
        const sameGroupElements = this.findSameGroupElements(target, groupedActiveElements);
        if (selected.includes(target)) {
            selected = selected.filter((el) => !sameGroupElements.includes(el));
        }
        else {
            // 组合的部分对象可能通过层选已经添加到selected中，这里添加组合对象时需要去重
            const selectedSet = new Set(selected);
            sameGroupElements.forEach((el) => {
                if (!selectedSet.has(el)) {
                    selected.push(el);
                }
            });
        }
        return selected;
    }
    existDuplicateGroupTag(el, checkedTags) {
        if (el.groupTag) {
            if (!checkedTags.includes(el.groupTag)) {
                checkedTags.push(el.groupTag);
                return false;
            }
            return true;
        }
        return false;
    }
    /**
     * 框选区域内的元素
     *
     * @param {Point} startPoint
     * @param {Point} endPoint
     * @param {boolean} invertSelect
     * @return {*}  {void}
     * @memberof MCanvas
     */
    selectAreaElements(startPoint, endPoint, isMultiSelect) {
        const allDisplays = this.getSelectionActiveTargets();
        const visibleElements = allDisplays.filter((display) => display.visible);
        const elements = this.viewport?.getRegionalElements(startPoint, endPoint, visibleElements) || [];
        if (elements.length <= 0) {
            if (this.transformer.selected.length > 0) {
                console.log(' 没有找到元素');
                this.executeSelect([]);
            }
            return;
        }
        const selected = getSelectedElements(this.viewport.toGlobal(startPoint), this.viewport.toGlobal(endPoint), elements);
        let currentSelected;
        const checkedTags = [];
        const groupedActiveElements = groupBy(allDisplays, 'groupTag');
        // 多选处理
        if (isMultiSelect) {
            currentSelected = this.transformer.selected.slice();
            selected.forEach((el) => {
                if (this.existDuplicateGroupTag(el, checkedTags)) {
                    return;
                }
                currentSelected = this.invertSelect(el, currentSelected, groupedActiveElements);
            });
        }
        else {
            // 找到同组元件
            const sameGroupElements = [];
            selected.forEach((el) => {
                if (this.existDuplicateGroupTag(el, checkedTags)) {
                    return;
                }
                const els = this.findSameGroupElements(el, groupedActiveElements);
                sameGroupElements.push(...els);
            });
            currentSelected = Array.from(new Set(selected.concat(sameGroupElements)));
        }
        this.executeSelect(currentSelected);
    }
    changeSelectedLayer(type) {
        const displays = this.getInteractiveElements();
        const sortedByDisplayOrder = [...displays];
        // 按当前显示从底到顶排列
        sortedByDisplayOrder.sort((a, b) => a.displayOrder - b.displayOrder);
        const sortedIDs = sortedByDisplayOrder.map((display) => display.id);
        // 按当前显示的层级关系构建链表
        const targetDisplayList = this.layerManager.changeLayer(sortedIDs, this.selected, type);
        const elements = this.transformer.viewport.getInteractiveElements();
        this.layerManager.updateOrder(targetDisplayList, elements);
        this.transformer.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, 'zOrder');
        this.recordOperationLog(...elements);
    }
    /**
     * 获取文字焊接的元素数据
     *
     * @param [type]
     * @param [text]
     * @returns
     */
    getTextWeldInfo(type = BOOL_OP.UNION, text) {
        if (!text) {
            const selected = this.transformer.selected;
            if (selected.length === 1) {
                text = selected[0];
            }
        }
        if (!text || text.type !== DISPLAY_TYPE.TEXT) {
            return undefined;
        }
        const info = this.getBoolEleInfo(type, text.children.filter((child) => child?.dPath));
        const pathJSON = pick(text, [
            'isFill',
            'lockRatio',
            'isClosePath',
            'zOrder',
            'groupTag',
            'layerTag',
            'layerColor',
            'visible',
        ]);
        pathJSON.fillColor = text.config.fillColor;
        pathJSON.lineColor = text.config.lineColor;
        pathJSON.sourceId = text.id;
        pathJSON.id = uuid();
        return { info, pathJSON };
    }
    /**
     * 获取需要进行布尔运算的元素数据
     *
     * @param operation
     * @param [selected]
     * @returns
     */
    getBoolEleInfo(operation, selected = this.transformer.selected) {
        const hasBitmap = selected.some((s) => s.type === DISPLAY_TYPE.BITMAP);
        if (hasBitmap) {
            return {};
        }
        const pointsList = [];
        const selectedIds = [];
        selected.forEach((display) => {
            const points = display.getRealPoints(this.viewport);
            pointsList.push(points);
            selectedIds.push(display.id);
        });
        const isFill = this.judgeIsFill(operation, this.transformer.selected);
        // 此处找最底层的对象 不可直接使用 displayOrder，因为 displayOrder 在对象不可见时会置为0
        // 此处也不能直接使用 zOrder, 因为在复制粘贴时会取到不一样的值
        const bottomIndex = selected.reduce((bottomIndex, current, index) => {
            return selected[bottomIndex].displayOrder > current.displayOrder
                ? index
                : bottomIndex;
        }, selected.length - 1);
        // 布尔的结果 的层的信息继承选中元素中最先渲染的元素
        const { layerTag, layerColor, visible } = selected[bottomIndex];
        return {
            pointsList,
            selectedIds,
            isFill,
            bottomIndex,
            layerTag,
            layerColor,
            visible,
        };
    }
    /**
     * 绘制布尔运算的结果
     *
     * @param operation
     * @param path
     * @param [options]
     * @returns
     */
    drawBoolResult(operation, path, options = {}) {
        if (['M0,0', ''].includes(path)) {
            this.recordOperationLog();
            return;
        }
        if (options.isFill === undefined) {
            options.isFill = this.judgeIsFill(operation, this.transformer.selected);
        }
        const displayConfig = this.configManager.displayConfig;
        const svgPathNode = createDisplay(DISPLAY_TYPE.PATH, {
            dPath: path,
            graphicX: 0,
            graphicY: 0,
            fillColor: displayConfig.fillColor,
            lineColor: displayConfig.lineColor,
            visible: displayConfig.visible,
            ...options,
        });
        this.addDisplayToViewport(svgPathNode);
        this.recordOperationLog(svgPathNode);
    }
    removeElements(query, needRecord = false, needEmit = true) {
        let removeEles = this.getViewportDisplayObjects(query);
        if (removeEles.length <= 0) {
            console.warn('没有元素可以被销毁');
            return;
        }
        const removeEleIds = removeEles.map((ele) => ele.id);
        // 必须在 元素被销毁之前记录 相关属性
        const { curIDs, curAttributes } = getElementsLogAttrs(removeEles, this.viewport, { texture: true });
        const removeedJSON = this.getMultiDisplayJSON(removeEles);
        removeEles.forEach((selected) => {
            this.removeElement(selected);
        });
        this.transformer.selected = this.transformer.selected.filter((s) => !removeEleIds.includes(s.id));
        // 必须在元素销毁之后，记录这次操作结束之后，画布中还存在的元素
        const allIDs = new Set();
        if (removeEleIds?.length > 0) {
            const allElements = this.viewport.getInteractiveElements();
            removeEles = allElements.filter((display) => removeEleIds.includes(display.id));
            allElements.forEach((element) => {
                allIDs.add(element.id);
            });
        }
        if (needEmit) {
            this.emit(CANVAS_EVENT.DISPLAYS_REMOVED, removeedJSON);
        }
        if (needRecord) {
            const log = {
                allIDs,
                curIDs,
                curAttributes,
            };
            this.recordFinalOperationLog(log);
        }
    }
    createDisplayByJSON(displayJSONs, createNewID = false, createNewGroupTag = true) {
        const displays = displayJSONs
            .map((json) => {
            const jsonData = {
                ...json,
            };
            const type = json.type;
            if (createNewID) {
                jsonData.sourceId = jsonData.id;
                jsonData.id = uuid();
            }
            const displayObject = createDisplay(type, jsonData);
            if (displayObject) {
                // 临时层主要为了解决updateDisplayPosition的计算问题
                this.tempLayer.addChild(displayObject);
                // 通过json生成 display后，根据json值重新调整坐标
                updateDisplayPosition(displayObject, new Point(jsonData.x, jsonData.y), this.viewport);
                this.tempLayer.removeChild(displayObject);
            }
            return displayObject;
        })
            .filter(Boolean);
        if (createNewGroupTag) {
            createNewGroupTagForCollection(displays);
        }
        return displays;
    }
    // JSON格式加载对象到viewport
    addDisplayToContainerByJSON(displayJSONs, createNewID = false, createNewGroupTag = true, container = CONATINER_TYPE.VIEWPORT) {
        const displays = this.createDisplayByJSON(displayJSONs, createNewID, createNewGroupTag);
        const addToContainer = {
            [CONATINER_TYPE.VIEWPORT]: (display) => {
                this.beforeAddDisplayToViewport(display);
                this.addDisplayToViewport(display, false);
            },
            [CONATINER_TYPE.TEMP_LAYER]: (display) => {
                this.beforeAddDisplayToViewport(display);
                this.tempLayer.addChild(display);
            },
        };
        displays.forEach((display) => {
            addToContainer[container](display);
        });
        return displays;
    }
    // 判断布尔计算结果是否填充
    judgeIsFill(operation, group) {
        const bottomIndex = group.reduce((topIndex, current, index) => {
            return group[topIndex].displayOrder > current.displayOrder
                ? index
                : topIndex;
        }, 0);
        switch (operation) {
            //  合并 交集 异或；只要有一个不填充，则生成的结果为不填充
            case BOOL_OP.UNION:
            case BOOL_OP.INTERSECTION:
            case BOOL_OP.XOR:
                return (group.filter((g) => g.isFill) || []).length === group.length;
            case BOOL_OP.DIFFERENCE:
                // 排除顶层；最终的填充以最底的渲染的元素的结果
                return group[bottomIndex].isFill;
            default:
                return false;
        }
    }
    /**
     * 图像对齐操作
     *
     * @param {ALIGN_TYPE} type
     * @memberof MCanvas
     */
    align(type) {
        // 先计算出有多少个组
        const selected = this.transformer.selected;
        const collection = formatSelected(selected);
        this.alignController.execute(type, collection, this.transformer.getGroupBounds(true).innerBounds, this.viewport);
        this.transformer.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, 'matrix');
        this.recordOperationLog(...this.selected);
    }
    recordOuterOperationLog(data) {
        const log = createOperationLog(this.transformer.viewport.getInteractiveElements(), [this.viewport, this.tempLayer], this.viewport, { texture: true });
        const recordLog = {
            otherData: data,
            ...log,
        };
        this.recordFinalOperationLog(recordLog);
    }
    resizeViewport(data = {}) {
        const { screen } = this.app;
        const { worldWidth, worldHeight } = this.viewport;
        const { width = screen.width, height = screen.height, viewportWidth = worldWidth, viewportHeight = worldHeight, gridOption, scaleMode = ViewportAlignOption.AUTO, } = data;
        this.scaleMode = scaleMode;
        this.viewport.resize(width, height, viewportWidth, viewportHeight, scaleMode, gridOption);
    }
    fitImageToViewport(sprite) {
        const { worldWidth, worldHeight } = this.viewport || {};
        const { width, height } = sprite;
        const { width: w, height: h } = calcAspectRatioFit(width, height, worldWidth, worldHeight);
        sprite.width = w;
        sprite.height = h;
    }
    /**
     * 更改 viewport 背景
     *
     * @param {HTMLImageElement} img
     * @memberof MCanvas
     */
    updateBackdrop(options, img) {
        if (this.viewport) {
            this.viewport.updateBackgroundImage(options, img);
            if (!img || img instanceof HTMLImageElement) {
                this.emit(CANVAS_EVENT.UPDATE_BACKDROP_RESULT, !!this.viewport.backgroundSprite);
            }
        }
    }
    /**
     * 销毁 viewport 背景
     */
    destroyBackdrop() {
        if (this.viewport) {
            this.viewport.destroyBackgroundImage();
        }
    }
    /**
     * 更改 viewport 近景摄像头照片
     *
     * @param {HTMLImageElement} img
     * @memberof MCanvas
     */
    setLocalBackdrop(img, options) {
        if (this.viewport) {
            this.viewport.setLocalBackgroundImage(img, options);
        }
    }
    /**
     * 设置是否隐藏
     *
     * @param {LocalBackdropOption} [options]
     * @memberof AbstractBase
     */
    setLocalBackdropVisible(show) {
        if (this.viewport) {
            this.viewport.setLocalBackdropVisible(show);
        }
    }
    // 不更新照片，只更新属性
    updateLocalBackgroundImage(options) {
        if (this.viewport) {
            this.viewport.updateLocalBackgroundImage(options);
        }
    }
    /**
     * 销毁插件
     *
     * @memberof MCanvas
     */
    destroy() {
        // 销毁webgl 上下文,
        // Note 在pixi源码中只对 webgl1 增加了销毁处理
        const renderer = this.app.renderer;
        if (settings.PREFER_ENV === ENV.WEBGL2) {
            // const renderer = this.app.renderer as Renderer;
            renderer.gl?.getExtension('WEBGL_lose_context')?.loseContext?.();
        }
        // renderer.reset();
        // renderer.clear();
        const plugins = this.plugins.slice(0);
        plugins.reverse();
        const options = {
            children: true,
            texture: true,
            // // 清除可能会影响一些必要的位图数据
            baseTexture: true,
        };
        plugins.forEach((plugin) => {
            plugin.destroy && plugin.destroy(options);
        });
        // 目前 canvas的texture 必须销毁，因为textureSystem中有gl的引用，不销毁无法释放webgl上下文
        this.app?.destroy(true, options);
    }
    /**
     *
     this.updateDisplayObjectsAttributes(attr, {
          displayObjectsQuery: 'all',
          displaysAsGroup: true,
        });
     */
    updateDisplayObjectsAttributes(attr, options) {
        const displays = this.getViewportDisplayObjects(options?.displayObjectsQuery);
        if (displays.length === 0) {
            return;
        }
        const { width, height, x, y, angle, ...rest } = attr;
        updateDisplaysDemision(this.viewport, displays, {
            width: width,
            height: height,
            displaysAsGroup: options?.displaysAsGroup,
        });
        updateDisplaysPosition(this.viewport, displays, {
            x: x,
            y: y,
            displaysAsGroup: options?.displaysAsGroup,
        });
        updateDisplaysRotation(displays, {
            angle: angle,
            displaysAsGroup: options?.displaysAsGroup,
        });
        if (hasOneIn(attr, ['width', 'height', 'x', 'y', 'angle'])) {
            // 对与以上属性的改变，需要让被改变的元素执行最终的调整， 例如文字的width改变后需要重新改变字号
            displays.forEach((child) => {
                if (child.transformCommit) {
                    child.transformCommit();
                }
            });
        }
        displays.forEach((display) => {
            updateDisplayAttributes(display, rest, this.viewport);
        });
        this.transformer.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, attr);
        this.recordOperationLog(...displays);
        this.emitTransformerCommit();
    }
    /**
     * 更改 Display 属性值
     * 界面上没有失焦的时候，借助transformer的groupBounds的rotation存储临时数据
     * 修改的时候，修改的是增量
     * @param {Record<string, any>} attr
     * @memberof MCanvas
     */
    updateSelectedAttributes(attr, query) {
        let angle = 0;
        const angleChanged = isNumber(attr.angle);
        if (angleChanged) {
            angle = attr.angle;
            const newAngle = angle - this.transformer.getGroupBounds().rotation / DEG_TO_RAD;
            attr.angle = newAngle;
        }
        this.updateDisplayObjectsAttributes(attr, {
            displayObjectsQuery: query ?? 'selected',
            displaysAsGroup: true,
        });
        if (angleChanged) {
            this.transformer.updateGroupboundsRotation(angle);
        }
    }
    /**
     * 锁定已选中取反
     *
     * @memberof AbstractBase
     */
    updateLockAspectRatio() {
        const lockRatio = !this.transformer.lockAspectRatio;
        this.updateSelectedAttributes({
            lockRatio,
        });
        return lockRatio;
    }
    /**
     * 切换 transformer handle 模式
     *
     * @param {HANDLE_MODE} mode
     * @memberof AbstractBase
     */
    updateTransformHandleMode(mode) {
        this.transformer.changeMode(mode);
    }
    /**
     * 更改编辑模式
     *
     * @param {EDIT_MODE} mode
     * @memberof MCanvas
     */
    updateEditMode(mode) {
        this.finishCurMode();
        this.modeManager.curMode = mode;
        if (mode === EDIT_MODE.DRAG) {
            this.toDragMode();
        }
        else {
            this.toWheelMode();
        }
        this.updateEditModeCursor(mode);
        if (EDIT_MODE.SELECT !== mode) {
            this.selected = [];
        }
    }
    updateEditModeCursor(mode) {
        const { RECT, CIRCLE, LINE, PEN, DRAG } = EDIT_MODE;
        if ([RECT, CIRCLE, LINE].includes(mode)) {
            this.viewport.cursor = 'crosshair';
        }
        else if (mode === PEN) {
            this.viewport.cursor = 'pen';
        }
        else if (mode === DRAG) {
            this.viewport.cursor = 'grab';
        }
        else {
            this.viewport.cursor = 'default';
        }
    }
    updateDisplayConfig(config) {
        const { enableShowFill } = this.configManager.displayConfig;
        const { updateCanvasDisplay = true, enableFill = enableShowFill, ...rest } = config;
        // configManager.displayConfig 中的配置要把传入的 enableFill 转换成enableShowFill， 并将原 enableFill 的字段剔除，以防透传后影响对象内部逻辑
        this.configManager.displayConfig = { enableShowFill: enableFill, ...rest };
        if (updateCanvasDisplay) {
            this.viewport.updateInteractivedDisplay(config);
        }
    }
    getGlobalConfig() {
        return this.globalManager.config;
    }
    updateGlobalConfig(config) {
        Object.assign(this.globalManager.config, config);
    }
    updateMultiDisplayConfig(configs) {
        const displays = this.viewport.getInteractiveElements();
        configs.forEach((item) => {
            // TODO: getInteractiveElements 返回map
            const target = displays.find((display) => display.id === item.id);
            if (target) {
                target.parseJSON({ ...item.config });
            }
        });
    }
    /**
     * 删除选中的元素
     *
     * @memberof MCanvas
     */
    removeSelected() {
        this.removeElements(this.transformer.selectedIDs, true, true);
    }
    // TODO: elementsFillMap的存在是为了临时方案修复撤销重做时加工参数不同步的问题
    // 后续撤销重做有新方案时，可以将 elementsFillMap 相关的逻辑都删除
    elementsFillMap = {};
    removeElement(el, deep = true) {
        if (el) {
            if (deep) {
                deepDestroy(el);
            }
            else {
                el.destroy();
            }
            // @ts-ignore
            this.elementsFillMap[el.id] = el.isFill;
        }
    }
    /**
     * 获取选中元素
     */
    get selected() {
        return this.transformer.selected;
    }
    set selected(objects) {
        this.transformer.selected = objects;
    }
    /**
     * 全选 viewport 可操作的元素
     * 全选时需要选中可见的，并且和可见对象同组的对象
     * @memberof MCanvas
     */
    selectAll() {
        const allDisplays = this.getSelectionActiveTargets() || [];
        const allVisibleDisplays = allDisplays.filter((display) => {
            return display.visible;
        });
        const groupedAllDisplays = groupBy(allDisplays, 'groupTag');
        const visibleGroupList = uniq(map(allVisibleDisplays, (display) => display.groupTag));
        let toSelect = [];
        visibleGroupList.forEach((groupTag) => {
            toSelect = toSelect.concat(groupedAllDisplays[groupTag]);
        });
        this.executeSelect(toSelect);
    }
    // // TODO: 体验接口
    // hightlightAll() {
    //   this.selected.forEach((item: any) => {
    //     // if (item.)
    //     if (isFunction(item.highlightBound)) {
    //       item.highlightBound();
    //     }
    //   });
    // }
    // // TODO: 体验接口
    // resetAll() {
    //   this.selected.forEach((el: any) => {
    //     // if (item.)
    //     if (el && el.parent && el.reset) {
    //       el.reset();
    //     }
    //   });
    // }
    selectSpecDisplays(ids) {
        this.transformer.selectedIDs = ids;
    }
    /**
     * 清空 viewport 已选中的元素
     */
    clearSelected() {
        this.selected = [];
    }
    /**
     * 获取 viewport 所有可操作的元素
     *
     * @return {*}  {any[]}
     * @memberof MCanvas
     */
    getInteractiveElements(options) {
        return this.viewport.getInteractiveElements(options);
    }
    /**
     * 当前状态下可选择的目标对象
     */
    getSelectionActiveTargets(options) {
        let targets;
        const curMode = this.modeManager.curMode;
        if (curMode === EDIT_MODE.BITMAP_MASK && this.bitmapMaskEditing) {
            targets = this.bitmapMaskEditing.selectionTargets;
        }
        else {
            targets = this.getInteractiveElements(options);
        }
        return (targets ?? []);
    }
    resizeCanvasTo(ele) {
        this.app.resizeTo = ele;
    }
    /**
     * 导出当前画布中选中的 Display 数据
     *
     * @return {*}  {DisplayJSONType[]}
     * @memberof MCanvas
     */
    exportSelectedJSON(options = { texture: false }) {
        const selected = this.transformer.selected;
        const data = this.getMultiDisplayJSON(selected, options);
        return data;
    }
    /**
     * 获取单个目标 Display 数据
     *
     * @param {MDisplayObject} target
     * @return {*}  {DisplayJSONType}
     * @memberof MCanvas
     */
    getDisplayJSON(target, options = { texture: false }) {
        return target.toJSON(this.viewport, options);
    }
    /**
     * 获取多个目标 Display 数据
     *
     * @param {MDisplayObject[]} data 选中的 displays
     * @return {*}  {DisplayJSONType[]}
     * @memberof MCanvas
     */
    getMultiDisplayJSON(data, options) {
        const displays = this.getViewportDisplayObjects(data);
        return displays.map((display) => {
            return this.getDisplayJSON(display, options);
        });
    }
    getDisplayRenderData(display, scaleNum = 1, padding = 0, frame) {
        const parentGroup = display.parentGroup;
        // 层级转换功能引入后渲染流程有改变，导出\显示对象的渲染数据时先把parentGroup置空，导出后重置
        display.parentGroup = null;
        this.app.renderer.render(this.app.stage);
        const data = getDisplayRenderData(this.app.renderer.plugins.extract, display, scaleNum, padding, frame, this.contextInfo.bitmapMaxSize);
        display.parentGroup = parentGroup;
        return data;
    }
    /**
     * 控制画布内网格显示/隐藏
     *
     * @memberof MCanvas
     */
    set gridVisible(visible) {
        this.viewport.gridVisible = visible;
    }
    toWheelMode() {
        this.isDragMode = false;
        this.viewport.toWheelMode();
        this.updateEditModeCursor(this.modeManager.curMode);
    }
    toDragMode() {
        if (!this.isDragMode) {
            this.isDragMode = true;
            this.viewport.toDragMode();
        }
    }
    toZoomMode() {
        this.viewport.toZoomMode();
    }
    /**
     * 设置画布自适应
     *
     * @memberof MCanvas
     */
    setViewportToFit(type) {
        this.viewport.align(type || this.scaleMode);
        // 清除保存的手势数据
        this.viewport.input.clear();
    }
    /**
     * 获取画布缩放比例
     *
     * @memberof MCanvas
     */
    get viewportScaled() {
        return this.viewport.scaled;
    }
    /**
     * 根据指定值缩放画布
     * @param scaled
     * @param center
     * @memberof MCanvas
     */
    setZoom(scaled, center = true) {
        this.viewport.setZoom(scaled, center);
        this.viewport.emit('zoomed', { viewport: this.viewport, type: 'manual' });
    }
    /**
     * 以递增比例缩放画布
     * @param percent
     * @param center
     * @memberof MCanvas
     */
    zoomPercent(percent, center = true) {
        this.viewport.zoomPercent(percent, center);
        this.viewport.emit('zoomed', { viewport: this.viewport, type: 'manual' });
    }
    flipSelected(option) {
        this.transformer.flipSelected(option);
        this.recordOperationLog(...this.selected);
    }
    /**
     * 获取路径上的所有点
     * @param path
     * @returns
     */
    getPathPoints(path) {
        return genPathPoints(path);
    }
    getSelectAreaViewport(position) {
        const { start, end } = position;
        // 单击不处理
        const isEqualX = start.x.toFixed() === end.x.toFixed();
        const isEqualY = start.y.toFixed() === end.y.toFixed();
        if (isEqualX && isEqualY) {
            return null;
        }
        const display = this.viewport.bgLayer;
        const { imageData } = this.getDisplayRenderData(display);
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        const ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0);
        const localPoints = {
            minLocalPoint: start,
            rtLocalPoint: new Point(end.x, start.y),
            maxLocalPoint: end,
            lbLocalPoint: new Point(start.x, end.y),
        };
        const processController = new ProcessController();
        processController.setOptions({ canvas, ctx });
        processController.clip(localPoints);
        if (canvas.width === 0 || canvas.height === 0) {
            return null;
        }
        const { width, height, data } = trimCanvas(canvas);
        canvas.width = width;
        canvas.height = height;
        ctx.putImageData(data, 0, 0);
        return canvas;
    }
    /**
     * 转换选中元素
     *
     * @param data
     * @memberof MCanvas
     */
    translateSelected(data) {
        this.transformer.translateSelected(data);
        this.recordOperationLog(...this.selected);
    }
    get selectedPathsClosed() {
        if (this.selected.length < 2) {
            return false;
        }
        return !this.selected.some((display) => !display.isClosePath);
    }
    /**
     * 获取选中元素边界矩阵中心点
     */
    get selectedGroupBoundCenter() {
        return this.transformer.worldGroupBoundsCenter;
    }
    /**
     * 浏览器坐标点转换至 viewport 坐标
     *
     * @memberof MCanvas
     */
    clientPointToViewportPoint(point, newPoint) {
        const { top, left } = this.view.getBoundingClientRect();
        // 换算成 canvas 的相对坐标
        const globalPoint = {
            x: point.x - left,
            y: point.y - top,
        };
        return this.viewport.toLocal(globalPoint, null, newPoint);
    }
    isSelectGroup(selected = this.selected) {
        if (selected.length > 1) {
            let isGroup;
            for (let i = 1; i < selected.length; i++) {
                const a = selected[i - 1];
                const b = selected[i];
                if (a.groupTag && a.groupTag === b.groupTag) {
                    isGroup = true;
                    continue;
                }
                else {
                    isGroup = false;
                    break;
                }
            }
            return isGroup;
        }
        return false;
    }
    createGroup(selected = this.selected) {
        setGroupTag(selected);
        this.selected = selected;
        this.changeSelectedLayer(CHANGE_LAYER_TYPE.TOP);
    }
    breakGroup(selected = this.selected) {
        selected.forEach((el) => {
            el.groupTag = undefined;
        });
        this.selected = selected;
        this.recordOperationLog(...this.selected);
    }
    // 暂停渲染
    stop() {
        this.app.render();
        this.app.stop();
    }
    // 恢复渲染
    start() {
        this.app.start();
    }
    render() {
        this.app.render();
    }
    showBackground() {
        this.viewport.bgContainer.visible = true;
    }
    hideBackground() {
        this.viewport.bgContainer.visible = false;
    }
    backgroundVisible() {
        return this.viewport.bgContainer.visible;
    }
    setupAssistantEle(options) {
        return this.viewport?.setupAssistantEle(options);
    }
    setEnableSelectElements(value) {
        this.enableSelectElements = value;
    }
    /**
     * 更新新增元素的zOrder，这里的新增指的是zOrder为0的元素
     */
    updateNewElesOrder(displays) {
        let curMaxZOrder = this.curMaxZOrder;
        displays
            .filter((display) => !display.zOrder) // 只对新创建的元素执行order赋值，焊接是对已存在的元素操作，不执行
            .forEach((display) => {
            display.zOrder = ++curMaxZOrder;
        });
    }
    /**
     * 根据query参数获取Viewport内展示对象，默认获取全部对象
     */
    getViewportDisplayObjects(query = 'all') {
        let objs = [];
        if (Array.isArray(query) && query.length > 0) {
            const isDisplays = query.every((v) => v instanceof DisplayObject);
            if (isDisplays) {
                // 查询参数为DisplayObject引用数组
                objs = query;
            }
            else {
                // 查询参数为id数组或其他类型混合数组
                const allElements = this.getInteractiveElements();
                const targetSet = new Set(query);
                objs = allElements.filter((elem) => targetSet.has(elem.id) || targetSet.has(elem));
            }
        }
        else if (query === 'selected') {
            objs = [...this.selected];
        }
        else if (query === 'all') {
            objs = this.getInteractiveElements();
        }
        return objs;
    }
    queryInteractiveDisplayObjects(ids, containers) {
        containers = containers ?? [this.viewport.displayLayer, this.tempLayer];
        const idSet = new Set(ids);
        const objs = new MapSet();
        for (const obj of iterateInteractiveElements(containers)) {
            if (idSet.has(obj.id)) {
                objs.add(obj.id, obj);
            }
        }
        return objs;
    }
    getOtherData() { }
    /**
     * 获取GRAPHICS_CURVES配置
     */
    getGraphicsCurves() {
        return getGraphicsCurveSetting(GRAPHICS_CURVES);
    }
    /**
     * 设置GRAPHICS_CURVES配置
     */
    setGraphicsCurves(data) {
        setGraphicsCurvesSetting(GRAPHICS_CURVES, data);
    }
    /**
     * 临时改变GRAPHICS_CURVES配置执行指定action
     */
    changeGraphicsCurvesFor(action, data) {
        return changeGraphicsCurvesSettingFor(action, GRAPHICS_CURVES, data);
    }
    updateAppConfig(config) {
        const { interactiveChildren } = config;
        if (!isUndefined(interactiveChildren)) {
            this.app.stage.interactiveChildren = interactiveChildren;
        }
    }
}
