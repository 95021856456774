import { merge } from 'lodash-es';
import { Polygon } from 'pixi.js';
import { DEFAULT_DISPLAY_CONFIG, DEFAULT_ELEMENT_LINE_STYLE } from '../config';
import { DISPLAY_TYPE } from '../display/type';
import { getSVGPointsAttrByGeometry } from '../packages/export/svg';
import { MGraphic } from './MGraphic';
// TODO: 优化这个类，Polygon的意思的是闭合不规则图形,
// 最初是将除了 path的 其他标签解析成的元素都是都统一成一种类型便于数据操作
// 问题1： 一个Polyline的标签复制后会被解析成Polygon ，结果就是非闭合的会变成闭合的
// 问题2：一个Line标签复制后会被解析成Polygon，结果就是一来一回重复了
// 所以类名称与实际不符
export class MPolygon extends MGraphic {
    points = [];
    type = DISPLAY_TYPE.POLYGON;
    constructor(options, config = { ...DEFAULT_DISPLAY_CONFIG }) {
        super(config);
        if (options) {
            this.type = DISPLAY_TYPE.POLYGON;
            this.parseJSON(options);
            // 添加一层处理，确保point是数值，确保导入points存成字符串数组的项目可以正常导入
            this.points = options.points.map((point) => Number(point));
            this.draw();
        }
    }
    toJSON(relativeEle) {
        const baseJSON = super.toJSON(relativeEle);
        const allPoints = this.getOriginalPoints();
        return {
            ...baseJSON,
            points: allPoints,
        };
    }
    draw() {
        if (this.points.length > 0) {
            const polygon = new Polygon(this.points);
            polygon.closeStroke = this.isClosePath;
            this.clear().lineStyle(DEFAULT_ELEMENT_LINE_STYLE).drawPolygon(polygon);
        }
        super.draw();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update(point, e) {
        throw new Error('Method not implemented.');
    }
    getSVGData(options) {
        /* Note: SVGPathNode当前继承了MPolygon对象，这里注意避免输出points */
        const pointsAttrs = getSVGPointsAttrByGeometry(this.geometry, options);
        return merge(super.getSVGData(options), {
            tag: this.isClosePath ? 'polygon' : 'polyline',
            attrs: {
                ...pointsAttrs,
            },
        });
    }
}
