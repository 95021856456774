let SkPathCtor;
export function buildSkPath() {
    return SkPathCtor && new SkPathCtor();
}
export function deleteSkPath(skPath) {
    try {
        skPath.delete();
    }
    catch (e) { }
}
export function isSkPath(obj) {
    return SkPathCtor ? obj instanceof SkPathCtor : false;
}
async function setup() {
    const canvasKitInit = (await import('canvaskit-wasm'))?.default;
    const canvasKitWasmURL = (await import('canvaskit-wasm/bin/canvaskit.wasm?url'))?.default;
    const CanvasKit = await canvasKitInit({
        locateFile: () => canvasKitWasmURL,
    });
    const DEFAULT_STROKE_OPTIONS = {
        width: 1,
        miter_limit: 4,
        cap: CanvasKit.StrokeCap.Square,
        join: CanvasKit.StrokeJoin.Miter,
        precision: 1,
    };
    const Path = CanvasKit.Path;
    class SkPath extends Path {
        constructor() {
            // CanvasKit.Path()内部逻辑限定了必须使用 new 调用，否则会报错
            // 这里用try...catch对此做相应的特殊处理
            try {
                super();
            }
            catch (e) {
                const obj = new Path();
                Object.setPrototypeOf(obj, SkPath.prototype);
                return obj;
            }
        }
        style(opts) {
            if (opts) {
                if (opts.fill && (opts.mode === 'stroke' || opts.mode === 'none')) {
                    return this;
                }
                const { sx = 1, sy = 1, viewportScale = 1 } = opts;
                const widthRatio = 1 + Math.max(Math.log10(viewportScale), -0.3);
                const width = opts.width * widthRatio;
                const strokeOpts = {
                    width,
                    // 默认使用square型端点扩展(storke-cap)
                    cap: CanvasKit.StrokeCap.Square,
                };
                this.transform(sx, 0, 0, 0, sy, 0, 0, 0, 1);
                if (opts.fill && (opts.mode === 'fill' || opts.mode === 'all')) {
                    this.unionStroke(strokeOpts);
                }
                else if (!opts.fill &&
                    (opts.mode === 'stroke' || opts.mode === 'all')) {
                    this.stroke(strokeOpts);
                }
                else {
                    this.stroke(DEFAULT_STROKE_OPTIONS);
                }
                this.transform(1 / sx, 0, 0, 0, 1 / sy, 0, 0, 0, 1);
            }
            return this;
        }
        stroke(opts) {
            if (opts && opts.visible !== false) {
                super.stroke(opts);
            }
            return this;
        }
        unionStroke(opts) {
            const copied = this.copy().stroke(opts);
            this.op(copied, CanvasKit.PathOp.Union);
            copied.delete(); // 使用完主动删除paths
            return this;
        }
        addFromSVGPathD(pathD) {
            const basePath = CanvasKit.Path.MakeFromSVGString(pathD);
            this.addPath(basePath);
            basePath.delete();
            return this;
        }
    }
    return SkPath;
}
let initPromise;
export async function init() {
    if (SkPathCtor) {
        return SkPathCtor;
    }
    if (!initPromise) {
        initPromise = setup()
            .then((ctor) => {
            SkPathCtor = ctor;
            return SkPathCtor;
        })
            .catch(console.warn);
    }
    return initPromise;
}
