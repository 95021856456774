import { DEFAULT_POINT_RADIUS, PAINT_COLOR } from '../../config';
const defaultDrawPointOptions = {
    color: PAINT_COLOR.Point,
    radius: DEFAULT_POINT_RADIUS,
    hollow: false,
    hollowColor: PAINT_COLOR.Hollow,
};
export function drawPointOnGraphics(graphics, options) {
    const ops = {
        ...defaultDrawPointOptions,
        ...options,
    };
    graphics.clear();
    graphics.beginFill(ops.color, 1);
    graphics.drawCircle(0, 0, ops.radius);
    if (ops.hollow) {
        graphics.beginFill(ops.hollowColor, 1);
        graphics.drawCircle(0, 0, ops.radius - 1);
    }
    if (ops.position) {
        graphics.position.set(ops.position.x, ops.position.y);
    }
    graphics.endFill();
}
export function removePointerEvents(graphics) {
    graphics.off('pointerdown');
    graphics.off('pointermove');
    graphics.off('pointerup');
}
export function isClosePathPoint(point, points, radius = DEFAULT_POINT_RADIUS) {
    if (points.length < 2) {
        return false;
    }
    const p1 = points[0];
    // 是否所有点都是相近的点
    let allPointsAreClose = true;
    for (let index = 1; index < points.length; index++) {
        const p2 = points[index];
        if (!isClosePoint(p1, p2, radius)) {
            allPointsAreClose = false;
            break;
        }
    }
    if (allPointsAreClose) {
        return false;
    }
    return isClosePoint(p1, point, radius);
}
function isClosePoint(p1, p2, radius = DEFAULT_POINT_RADIUS) {
    return Math.abs(p1.x - p2.x) < radius && Math.abs(p1.y - p2.y) < radius;
}
