import { BaseTexture, Graphics, Point, Sprite, Texture } from 'pixi.js';
import { DEFAULT_CENTRAL_AXIS_HOVER_WIDTH, DEFAULT_CENTRAL_AXIS_WIDTH, } from '../../config';
// 中轴线
export class CentralAxis {
    #options;
    mask;
    axis;
    #dragEventData;
    #dragging;
    interactiveIcon;
    constructor(options) {
        this.#options = options;
        this.clear();
        this.drawMask();
        this.drawAxis();
    }
    drawMask() {
        this.mask = new Graphics()
            .beginFill(0x000, 0.5)
            .drawRect(0, 0, this.#options.viewportX, this.#options.worldHeight)
            .endFill();
        this.mask.name = 'AXIS_MASK';
    }
    // TODO: 圆柱的中轴线要重构， 色值统一配置
    drawAxis() {
        this.axis = new Graphics()
            .beginFill(0x28be44, 1)
            .drawRect(0, 0, DEFAULT_CENTRAL_AXIS_WIDTH, this.#options.stageHeight)
            .endFill();
        this.axis.x = this.#options.stageX;
        this.axis.interactive = true;
        this.axis.cursor = 'ew-resize';
        this.axis.name = 'CENTRAL_AXIS';
        const { iconOption } = this.#options;
        if (iconOption) {
            this.initInteractiveIcon(iconOption);
        }
        this.axis.on('pointerdown', this.onDragStart);
        this.axis.on('pointermove', this.onDragMove);
        this.axis.on('pointerup', this.onDragEnd);
        this.axis.on('pointerupoutside', this.onDragEnd);
    }
    initInteractiveIcon(iconOption) {
        const { icon, x, y, width, height } = iconOption;
        if (!icon) {
            return;
        }
        const iconTexture = Texture.from(new BaseTexture(icon));
        const iconSprite = new Sprite(iconTexture);
        iconSprite.anchor.set(0.5, 0.5);
        iconSprite.x = x ?? iconSprite.x;
        iconSprite.y = y ?? iconSprite.y;
        iconSprite.width = width ?? iconSprite.width;
        iconSprite.height = height ?? iconSprite.height;
        this.interactiveIcon = iconSprite;
        this.axis.addChild(iconSprite);
    }
    onDragStart = (event) => {
        this.#dragEventData = event.data;
        if (!this.interactiveIcon) {
            this.axis.width = DEFAULT_CENTRAL_AXIS_HOVER_WIDTH;
        }
        this.axis.alpha = 0.5;
        this.#dragging = true;
    };
    onDragMove = () => {
        if (this.#dragging) {
            const globalPoint = this.#dragEventData.getLocalPosition(this.axis.parent);
            const dragPoint = this.mask.parent.toLocal(new Point(globalPoint.x, 0));
            if (dragPoint.x < 0) {
                globalPoint.x = this.mask.parent.toGlobal(new Point(0, 0)).x;
                dragPoint.x = 0;
            }
            else if (dragPoint.x > this.#options.worldWidth) {
                globalPoint.x = this.mask.parent.toGlobal(new Point(this.#options.worldWidth, 0)).x;
                dragPoint.x = this.#options.worldWidth;
            }
            this.axis.position.x = globalPoint.x;
            this.mask.width = dragPoint.x;
        }
    };
    onDragEnd = () => {
        if (this.#dragging) {
            if (!this.interactiveIcon) {
                this.axis.width = DEFAULT_CENTRAL_AXIS_WIDTH;
            }
            this.axis.alpha = 1;
            this.#dragEventData = null;
            this.#dragging = false;
            this.#options.onUpdate();
        }
    };
    resize(options) {
        const { worldHeight, stageHeight } = options;
        this.mask.height = worldHeight;
        this.axis.height = stageHeight;
    }
    clear() {
        this.axis?.destroy(true);
        this.mask?.destroy(true);
    }
}
