import { Bounds, Container, Matrix, Texture, TilingSprite } from 'pixi.js';
import { ColorUtil, DISPLAY_TYPE, calcFitAndAlignCenterMatrix, createDisplay, } from '../../display';
import { boundsAddRectangle, convertBounds, getDisplayBounds, } from '../../utils';
const TRACE_BG_DISPLAY_TAG = 'trace_background';
export class ImageTracing {
    container;
    options;
    moldDisplay;
    traceDisplays;
    imageDataScale = 1;
    constructor(options) {
        this.options = options;
        this.createContainer();
        this.renderBackdrop();
        this.addDisplay();
        this.options.addViewportChild(this.container);
        this.updateCloneDisplayAttrs();
    }
    get originDisplay() {
        return this.options.display;
    }
    removeTraceDisplay() {
        if (this.traceDisplays && this.traceDisplays.length > 0) {
            this.traceDisplays.forEach((display) => {
                display.destroy(true);
                display = null;
            });
        }
        this.traceDisplays = [];
    }
    getTraceDisplay() {
        this.traceDisplays.forEach((display) => {
            this.reproducePosition(display, this.options.display, true);
        });
        return this.traceDisplays;
    }
    addPathToContainer(path, color, originColor) {
        if (!path || path === 'M0,0') {
            return null;
        }
        const { lineColor } = this.options.displayConfig;
        const traceDisplay = createDisplay(DISPLAY_TYPE.PATH, {
            dPath: path,
            graphicX: 0,
            graphicY: 0,
            fillColor: null,
            lineColor,
            layerColor: color,
            layerTag: ColorUtil.convertToLayerColorHex(originColor),
            isFill: false,
            enableFill: false,
        });
        traceDisplay.originColor = originColor;
        this.addTraceDisplay(traceDisplay);
        return traceDisplay;
    }
    setTransformBy = (source, target, resetRotate) => {
        const matrix = new Matrix();
        // 设置截图imageData的缩放补偿
        matrix.scale(1 / this.imageDataScale, 1 / this.imageDataScale);
        // 带蒙版位图
        if (target.mask) {
            // 计算实际localBounds
            const localBounds = getDisplayBounds(target, target);
            const offset = {
                x: localBounds.minX,
                y: localBounds.minY,
            };
            matrix.translate(offset.x, offset.y);
        }
        matrix.prepend(target.localTransform);
        if (this.container.parent !== target.parent) {
            target = target.parent;
            matrix.prepend(target.localTransform);
        }
        source.transform.setFromMatrix(matrix);
        if (resetRotate) {
            // source.rotation = 0;
        }
    };
    reproducePosition(source, target, resetRotate) {
        this.setTransformBy(source, target, resetRotate);
    }
    addTraceDisplay(traceDisplay) {
        // this.removeTraceDisplay();
        this.reproducePosition(traceDisplay, this.moldDisplay, false);
        this.traceDisplays.push(traceDisplay);
        this.container.addChild(traceDisplay);
    }
    createContainer() {
        const { viewportWorldWidth, viewportWorldHeight } = this.options;
        const container = new Container();
        container.width = viewportWorldWidth;
        container.height = viewportWorldHeight;
        container.position.set(0, 0);
        this.container = container;
    }
    renderBackdrop() {
        const { viewportWorldWidth, viewportWorldHeight, imageProcessConfig } = this.options;
        const img = new Image();
        img.src = imageProcessConfig.backdrop;
        const texture = Texture.WHITE;
        const sprite = new TilingSprite(texture, viewportWorldWidth, viewportWorldHeight);
        sprite.name = TRACE_BG_DISPLAY_TAG;
        sprite.interactive = false;
        this.container.addChild(sprite);
    }
    addDisplay() {
        const display = this.options.moldDisplay;
        this.moldDisplay = display;
        this.moldDisplay.alpha = 0.2;
        this.container.addChild(this.moldDisplay);
    }
    updateCloneDisplayAttrs() {
        return this.fitToViewport(this.options.display, this.moldDisplay);
    }
    fitToViewport(display, newSprite) {
        const targetRect = [
            0,
            0,
            this.options.viewportWorldWidth,
            this.options.viewportWorldHeight,
        ];
        let baseBounds;
        if (display.mask) {
            baseBounds = getDisplayBounds(display, display.parent).getRectangle();
        }
        else {
            const bounds = new Bounds();
            boundsAddRectangle(bounds, display.getBounds());
            baseBounds = convertBounds(display.parent, bounds).getRectangle();
        }
        const matrix = calcFitAndAlignCenterMatrix(baseBounds, targetRect);
        newSprite.transform.setFromMatrix(newSprite.localTransform.prepend(matrix));
    }
    getOriginalDisplay() {
        return this.options?.display;
    }
    destroyAll() {
        this.container.children.forEach((ele) => {
            ele.destroy(true);
        });
        this.container.destroy();
        this.container = null;
        this.moldDisplay = null;
    }
}
