/**
 * Inherited paint, used for &lt;use /&gt; elements. The properties used on the inherited paint do not
 * override those on the parent.
 *
 * @public
 */
export class InheritedPaintProvider {
    parent;
    provider;
    /**
     * Composes a `Paint` that will inherit properties from the `parent` if the `provider` does not
     * define them.
     *
     * @param parent
     * @param provider
     */
    constructor(parent, provider) {
        this.parent = parent;
        this.provider = provider;
    }
    get dirtyId() {
        return this.parent.dirtyId + this.provider.dirtyId;
    }
    get fill() {
        return this.provider.fill !== null ? this.provider.fill : this.parent.fill;
    }
    get opacity() {
        return typeof this.provider.opacity === 'number'
            ? this.provider.opacity
            : this.parent.opacity;
    }
    get stroke() {
        return this.provider.stroke !== null
            ? this.provider.stroke
            : this.parent.stroke;
    }
    get strokeDashArray() {
        return Array.isArray(this.provider.strokeDashArray)
            ? this.provider.strokeDashArray
            : this.parent.strokeDashArray;
    }
    get strokeDashOffset() {
        return typeof this.provider.strokeDashOffset === 'number'
            ? this.provider.strokeDashOffset
            : this.parent.strokeDashOffset;
    }
    get strokeLineCap() {
        return typeof this.provider.strokeLineCap === 'string'
            ? this.provider.strokeLineCap
            : this.parent.strokeLineCap;
    }
    get strokeLineJoin() {
        return typeof this.provider.strokeLineJoin === 'string'
            ? this.provider.strokeLineJoin
            : this.parent.strokeLineJoin;
    }
    get strokeMiterLimit() {
        return typeof this.provider.strokeMiterLimit === 'number'
            ? this.provider.strokeMiterLimit
            : this.parent.strokeMiterLimit;
    }
    get strokeWidth() {
        return typeof this.provider.strokeWidth === 'number'
            ? this.provider.strokeWidth
            : this.parent.strokeWidth;
    }
}
