import { CONATINER_TYPE } from '../types';
import { AbstractBase } from './AbstractBase';
export class AbstractBatchCreate extends AbstractBase {
    /**
     * 批量创建图像，实时预览过程中会频繁调用此方法
     *
     * @param {BATCH_CREATE_TYPE} type
     * @param {(ILinerArraysParams | IRadialArraysParams)} params
     * @memberof MCanvas
     */
    batchCreateOnTemp(type, params) {
        const displayObjectList = this.batchCreate.create(type, this.exportSelectedJSON({ texture: true }), this.transformer.worldGroupBounds.getRectangle(), params);
        displayObjectList.map((data) => {
            const displays = this.addDisplayToContainerByJSON(data, false, true, CONATINER_TYPE.TEMP_LAYER);
            this.batchCreate.appendToTemp(displays);
        });
    }
    /**
     * 清除批量创建的图像，destroy为false时，会将创建图形保留在画布内
     *
     * @param {boolean} [destroy=true]
     * @memberof MCanvas
     */
    finishBatchCreate(destroy = true, isNeedGroup) {
        let res = [];
        if (!destroy) {
            this.recordOperationLog(...this.batchCreate.temp);
            const displays = this.batchCreate.temp;
            displays.forEach((display) => {
                this.addDisplayToViewport(display, false);
            });
            if (isNeedGroup) {
                this.createGroup([...this.transformer.selected, ...displays]);
            }
            res = this.getMultiDisplayJSON(displays, { texture: false });
        }
        this.batchCreate.temp.forEach((display) => this.tempLayer.removeChild(display));
        this.batchCreate.clearTemp(destroy);
        return {
            res,
            matrixResult: this.batchCreate.matrixResult,
            descriptionResult: this.batchCreate.descriptionResult,
        };
    }
    /**
     * 获取智能填充元素数据
     * @returns
     */
    getShapeMatchData() {
        if (this.selected.length === 0) {
            return {};
        }
        const background = this.viewport.bgLayer;
        // @TODO: 当前的应用场景应该难以遇到背景的截图返回的imagDataScale不为1的情况
        // 鉴于时间紧张，后续进行验证和兼容这种边界情况
        const { imageData: { width, height, data }, } = this.getDisplayRenderData(background);
        const { x, y } = this.transformer.worldGroupBoundsCenter;
        const options = { x, y, bitmap: data, w: width, h: height };
        const selectedJSON = this.exportSelectedJSON({ texture: true });
        return {
            displaysJSON: selectedJSON,
            options,
        };
    }
    /**
     * 添加智能填充结果
     * @param JSONList
     * @returns
     */
    addShapeMatchResult(JSONList) {
        if (JSONList.length > 0) {
            const displayList = [];
            JSONList.forEach((cloneJSON) => {
                const displays = this.addDisplayToContainerByJSON(cloneJSON, true);
                displayList.push(...displays);
            });
            this.recordOperationLog(...displayList);
            const res = this.getMultiDisplayJSON(displayList, { texture: false });
            return res;
        }
        return [];
    }
}
