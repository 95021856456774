/**
 * 该类实现的是P2近景拍照时用于显示照片大小的辅助框，具有跟随鼠标移动，点击后获取鼠标坐标的功能
 * 辅助框出现的时候，伴随着会有裁剪画布的需求，通过limitBounds来限制可以动范围，并加上mask遮罩仅显示可移动范围，辅助框消失后还原所有的mask
 */
import { Graphics, Sprite } from 'pixi.js';
import { isSinglePoint } from '../../../controllers/interaction-manager';
import { ASSISTANT_EVENT } from '../../../types';
export class Assistant extends Graphics {
    ele = new Graphics();
    setup(worldWidth, worldHeight, options) {
        const { width = 100, height = 100, x = 0, y = 0, color = 0x0000ff, angle = 0, alpha = 1, offsetX = 0, offsetY = 0, showStar = true, icon, limitBounds, } = options;
        this.ele.x = x || this.ele.x;
        this.ele.y = y || this.ele.y;
        this.ele.beginFill(color, alpha);
        this.ele.drawRect(0, 0, width, height);
        this.ele.endFill();
        // 画准星
        if (showStar) {
            this.ele.lineStyle(1, 0xff0000);
            this.ele.moveTo(-offsetX - 5, -offsetY);
            this.ele.lineTo(-offsetX + 5, -offsetY);
            this.ele.moveTo(-offsetX, -offsetY - 5);
            this.ele.lineTo(-offsetX, -offsetY + 5);
        }
        if (icon) {
            const iconSprite = Sprite.from(icon.url);
            icon.width && (iconSprite.width = icon.width);
            icon.height && (iconSprite.height = icon.height);
            iconSprite.angle = icon.angle || 0;
            iconSprite.x = icon.x;
            iconSprite.y = icon.y;
            this.ele.addChild(iconSprite);
        }
        this.ele.angle = angle;
        this.ele.interactive = true;
        this.addChild(this.ele);
        const { topLeft = { x: 0, y: 0 }, bottomRight = { x: worldWidth, y: worldHeight }, } = limitBounds || {};
        const tempMask = new Graphics();
        tempMask.beginFill(0);
        tempMask.drawRect(topLeft.x, topLeft.y, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
        tempMask.endFill();
        this.addChild(tempMask);
        const onPointerMove = (e) => {
            if (!isSinglePoint(e)) {
                return;
            }
            this.ele.cursor = null;
            const point = e.data.getLocalPosition(this);
            this.ele.x = point.x + offsetX;
            this.ele.y = point.y + offsetY;
            // 限左
            if (this.ele.x < topLeft.x) {
                this.ele.cursor = 'none';
                this.ele.x = topLeft.x;
            }
            // 限上
            if (this.ele.y < topLeft.y) {
                this.ele.cursor = 'none';
                this.ele.y = topLeft.y;
            }
            // 限右
            if (this.ele.x + this.ele.width > bottomRight.x) {
                this.ele.cursor = 'none';
                this.ele.x = bottomRight.x - this.ele.width;
            }
            // 限下
            if (this.ele.y + this.ele.height > bottomRight.y) {
                this.ele.cursor = 'none';
                this.ele.y = bottomRight.y - this.ele.height;
            }
        };
        const clearFn = () => {
            this.ele.clear();
            this.ele.children.forEach((child) => {
                child.destroy();
            });
            clearListener();
            if (this.ele.parent) {
                this.ele.parent.removeChild(this.ele);
            }
            if (tempMask.parent) {
                tempMask.destroy();
            }
        };
        const handlePointerDown = (e) => {
            if (!isSinglePoint(e)) {
                return;
            }
            const { button, buttons, pointerType, originalEvent } = e.data;
            if (window.TouchEvent && originalEvent instanceof TouchEvent) {
                // 特殊处理移动端单击时取景框跟随
                onPointerMove(e);
            }
            // 如果不是左键或者为pencil，则跳过
            if (!(button === 0 && buttons === 1) || pointerType === 'pen') {
                return;
            }
            e.stopPropagation();
            clearListener();
            confirm();
            console.log('拍摄点：', x, y);
        };
        const confirm = () => {
            this.parent.emit(ASSISTANT_EVENT.GET_POSITION, {
                x: this.ele.x,
                y: this.ele.y,
            });
        };
        const clearListener = () => {
            this.parent.off('pointermove', onPointerMove);
            this.parent.off('pointerdown', handlePointerDown);
        };
        this.parent.on('pointermove', onPointerMove);
        this.parent.on('pointerdown', handlePointerDown);
        return { clearFn, mask: tempMask, confirm };
    }
}
