import { string2hex } from '@pixi/utils';
import { merge } from 'lodash-es';
import { Bounds, FillStyle, Graphics, LineStyle, Point, Polygon, Rectangle, } from 'pixi.js';
import { DEFAULT_DISPLAY_CONFIG } from '../config';
import { getSVGFillAndStrokeAttrs } from '../packages/export/svg';
import { hasOneIn } from '../utils/math';
import { genPointsFromGeometry } from '../utils/pathPoint';
import { DISPLAY_LIFE_CYCLE, DISPLAY_TYPE } from './type';
export class MGraphic extends Graphics {
    /**
     * 颜色配置
     */
    config;
    originWidth = 0;
    originHeight = 0;
    isFill = false;
    enableFill = true;
    type = DISPLAY_TYPE.LINE;
    constructor(config) {
        super();
        this.interactive = true;
        // this.setUpHoverEvent();
        this.config = config || { ...DEFAULT_DISPLAY_CONFIG };
        // this.enableFill = GlobalConfigManager.getInstance().enableFill;
    }
    draw() {
        this.toggleFill(this.isFill);
    }
    addHitArea(area) {
        if (area) {
            this.hitArea = area;
        }
        else {
            const { x, y, width, height } = this.getLocalBounds();
            // 在一些尺寸很小的图形中得到的宽高可能是0，这里交互宽高默认设置为1
            const minSize = 1;
            const w = width || minSize;
            const h = height || minSize;
            // 让热区均匀分布
            this.hitArea = new Rectangle(x - (width ? 0 : minSize / 2), y - (height ? 0 : minSize / 2), w, h);
        }
    }
    addLineHitArea(startP, endP, minSize = 1) {
        const rotation = Math.atan2(endP.y - startP.y, endP.x - startP.x);
        // 坐标小数点太长，取整有利于做单元测试
        const fix = (n) => {
            return parseFloat(n.toFixed(1));
        };
        // 与线段垂直方向的逆时针角
        const o1x = fix(Math.cos(rotation - 0.5 * Math.PI) * minSize);
        const o1y = fix(Math.sin(rotation - 0.5 * Math.PI) * minSize);
        // 与线段垂直方向的顺时针角
        const o2x = fix(Math.cos(rotation + 0.5 * Math.PI) * minSize);
        const o2y = fix(Math.sin(rotation + 0.5 * Math.PI) * minSize);
        // 逆时针矩形将线段包围
        const p1 = new Point(startP.x + o1x, startP.y + o1y);
        const p2 = new Point(startP.x + o2x, startP.y + o2y);
        const p3 = new Point(endP.x + o2x, endP.y + o2y);
        const p4 = new Point(endP.x + o1x, endP.y + o1y);
        this.hitArea = new Polygon([p1, p2, p3, p4]);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    start(point, e) {
        this.emit(DISPLAY_LIFE_CYCLE.DRAW_BEGIN, this);
        this.position.set(point.x, point.y);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    end(point, e) {
        this.toggleFill(this.isFill);
        this.done();
    }
    destroy(options) {
        super.destroy(options);
    }
    done() {
        this.emit(DISPLAY_LIFE_CYCLE.DRAW_DONE, this);
    }
    // reDraw的目的是
    // 1. 将 graphicsData 的 fillStyle.visible 设为true fillStyle.visible = true 对象才可以响应鼠标事件
    // 2. 对于没有主动调用 graphic.beginFill 的对象也可以计算处填充数据
    // 3. TODO: 部分复杂的路径不重新redraw会存在渲染不完整的情况 (具体原因需继续定位)
    isRedrawed = false;
    reDraw() {
        const { geometry, isFill } = this;
        const { lineColor, fillColor } = this.getRenderColor();
        const { geometry: { graphicsData }, } = this;
        graphicsData.forEach((data) => {
            data.fillStyle.color = fillColor;
            data.fillStyle.alpha = isFill ? 1 : 0;
            // 若 graphic没有主动调用 fill的方法，此属性默认是false
            const lastFill = data.fillStyle.visible;
            // enableFill 的默认值是true
            data.fillStyle.visible = this.enableFill;
            // enableFill 切换成true时 并且前后不一致 则强行redraw
            if (this.enableFill && lastFill !== this.enableFill) {
                this.isRedrawed = false;
            }
            data.lineStyle.color = lineColor;
            data.lineStyle.native = true;
            data.lineStyle.width = 1;
            data.lineStyle.visible = true;
        });
        if (!this.isRedrawed) {
            // @ts-ignore
            geometry.invalidate();
            this.isRedrawed = true;
        }
    }
    undo() {
        this.clear();
    }
    toggleFill(isFill = this.isFill) {
        this.isFill = isFill;
        if (isFill) {
            this.setFillMode();
        }
        else {
            this.setLineMode();
        }
        this.reDraw();
    }
    setUpHoverEvent() {
        this.on('pointerover', this.highlightBound.bind(this));
        this.on('pointerout', this.reset.bind(this));
    }
    reset() {
        const { lineColor, fillColor } = this.getRenderColor();
        this.changeColor({
            lineColor: lineColor,
            fillColor,
            isFill: this.isFill,
        });
        this.line.color = lineColor;
    }
    // 最终渲染的颜色
    getRenderColor() {
        let lineColor = this.config.lineColor || 0x0;
        let fillColor = this.config.fillColor || 0x0;
        // 如果有 layerColor 则填充和描边都用layerColor
        if (this.layerColor) {
            lineColor = string2hex(this.layerColor);
            fillColor = string2hex(this.layerColor);
        }
        return {
            lineColor,
            fillColor,
        };
        // 如果没有 layerColor 而则填充直接使用fillColor，描边使用lineColor
    }
    changeColor(config) {
        const { lineColor, fillColor, isFill = this.isFill } = config;
        const batchs = this.geometry.batches;
        batchs.forEach((batchPart) => {
            const tempLineStyle = new LineStyle();
            const tempFillStyle = new FillStyle();
            if (batchPart.style &&
                batchPart.style.constructor.name === tempLineStyle.constructor.name) {
                batchPart.style.color = lineColor;
            }
            if (batchPart.style &&
                batchPart.style.constructor.name === tempFillStyle.constructor.name) {
                batchPart.style.color = fillColor;
                batchPart.style.alpha = isFill ? 1 : 0;
            }
        });
        // @ts-ignore
        this.geometry.buildDrawCalls();
    }
    highlightBound() {
        const { hoverColor: hover } = this.config;
        const { fillColor } = this.getRenderColor();
        this.changeColor({
            lineColor: hover,
            isFill: this.isFill,
            fillColor,
        });
        this.line.color = hover;
    }
    setFillMode() {
        const { fillColor, lineColor } = this.getRenderColor();
        this.fill.color = fillColor;
        this.line.color = lineColor;
        this.changeColor({
            lineColor,
            fillColor,
            isFill: this.isFill,
        });
    }
    // TODO: graphic 设置了native属性之后 lineStyle.width 将不生效
    setLineMode() {
        const { lineColor, fillColor } = this.getRenderColor();
        this.line.color = lineColor;
        this.changeColor({
            lineColor,
            fillColor,
            isFill: this.isFill,
        });
    }
    // 获取图形真实边框，即对于旋转后的图形，先对原始数据进行矩阵换算，然后取出min和max的四个顶点
    getRealBounds(bounds) {
        this.finishPoly();
        // getRealPoints 不传参数就是获取没有经过矩阵变换的原始数据
        const graphicsData = this.getRealPoints();
        const transform = this.worldTransform;
        const _bounds = bounds ?? new Bounds();
        graphicsData.forEach((points) => {
            for (let i = 0; i < points.length; i++) {
                const p = points[i];
                _bounds.addPointMatrix(transform, new Point(p.x, p.y));
            }
        });
        return _bounds;
    }
    getOriginalPoints() {
        const { geometry: { graphicsData }, } = this;
        let allPoints = [];
        graphicsData.forEach((data) => {
            const { points = [] } = data;
            allPoints = allPoints.concat(points.slice(0));
        });
        return allPoints;
    }
    /**
     * 通过保存的原始path数据，进行transform运算，得到realPoint
     */
    getRealPoints(relativeEle) {
        const points = genPointsFromGeometry(this, relativeEle);
        return points;
    }
    /**
     * toJSON和parseJSON 是 在 MixinDisplay.ts中 扩展pixi.DisplayObject的方法，
     * 在子类中直接重写会被ts检测 出 ts(2425)的错误
     * 通过箭头函数形式 toJSON = () => {} 可以消除这个错误 ，但是无法得到正确的继承关系
     * 此处先直接忽略检测
     */
    // @ts-ignore
    toJSON(relativeEle) {
        const { width, height, isFill } = this;
        const baseJSON = super.toJSON(relativeEle);
        return {
            ...baseJSON,
            width,
            height,
            isFill,
            lineColor: this.config.lineColor,
            fillColor: this.config.fillColor,
        };
    }
    // @ts-ignore
    parseJSON(data) {
        const { width = this.width, height = this.height, scale = this.scale, isFill = this.isFill, lineColor = this.config.lineColor, fillColor = this.config.fillColor, enableFill = this.enableFill, } = data;
        super.parseJSON(data);
        this.enableFill = enableFill;
        if (hasOneIn(data, [
            'isFill',
            'lineColor',
            'fillColor',
            'layerColor',
            'layerTag',
            'enableFill',
        ])) {
            this.isFill = isFill;
            this.config.lineColor = lineColor;
            this.config.fillColor = fillColor;
            this.toggleFill(isFill);
        }
        this.originWidth = width / scale.x;
        this.originHeight = height / scale.y;
    }
    setDefaultColor() {
        this.config.fillColor = this.fill.color;
        this.config.lineColor = this.line.color;
        this.isFill = !!this.fill.visible;
    }
    isVaild() {
        // 当graphicsData为空时 认为是不合法元素
        const result = this.isValid &&
            this.geometry.graphicsData.length > 0 &&
            (this.width > 0 || this.height > 0);
        return result;
    }
    // @ts-ignore
    getSVGData(options) {
        const fillAndStrokeAttrs = getSVGFillAndStrokeAttrs(this, options);
        return merge(super.getSVGData(options), {
            attrs: fillAndStrokeAttrs,
        });
    }
}
