import { graphicsUtils } from '@pixi/graphics';
import { Point } from 'pixi.js';
// import { SVGPathData } from 'svg-pathdata';
import { merge } from 'lodash-es';
import { DISPLAY_TYPE } from '../../display/type';
import { combinePoints, computeRealPoints } from '../../utils/pathPoint';
import { SVGGraphicsNode } from './SVGGraphicsNode';
import { FILL_RULE, PATH, Path } from './utils/Path';
import { buildPath } from './utils/buildPath';
import dParser from './utils/parser';
graphicsUtils.FILL_COMMANDS[PATH] = buildPath;
/**
 * Draws SVG &lt;path /&gt; elements.
 *
 * @public
 */
export class SVGPathNode extends SVGGraphicsNode {
    currentPath2;
    dPath;
    fillRule = FILL_RULE.NONZERO;
    type = DISPLAY_TYPE.PATH;
    constructor(context, options) {
        super(context);
        if (options) {
            const dAttr = typeof options.dPath === 'string' ? `d="${options.dPath}"` : '';
            const path = new DOMParser().parseFromString(`<path ${dAttr} />`, 'image/svg+xml').documentElement;
            this.embedPath(path, options.fillRule);
            this.addHitArea();
            this.parseJSON(options);
            this.draw();
        }
        this.interactive = true;
    }
    startPath() {
        if (this.currentPath2) {
            const pts = this.currentPath2.points;
            if (pts.length > 0) {
                this.currentPath2.closeContour();
            }
        }
        else {
            this.currentPath2 = new Path();
        }
    }
    finishPath() {
        if (this.currentPath2) {
            this.currentPath2.closeContour();
        }
    }
    /**
     * 通过用以渲染的contours数据，进行transform运算，得到realPoint
     */
    getRealPoints(relativeEle) {
        return this.graphicsPoints.map((points) => {
            if (relativeEle) {
                points = computeRealPoints(points, this, relativeEle);
            }
            return points;
        });
    }
    // @ts-expect-error
    get currentPath() {
        return this.currentPath2;
    }
    set currentPath(nothing) {
        if (nothing) {
            throw new Error('currentPath cannot be set');
        }
        // readonly
    }
    get graphicsPoints() {
        const pointsArr = [];
        const graphicsData = this.geometry.graphicsData;
        graphicsData.forEach((graphicData) => {
            const { shape } = graphicData;
            const contours = shape.contours;
            contours.forEach((contour) => {
                if (contour.length > 0) {
                    const points = combinePoints(contour);
                    pointsArr.push(points);
                }
            });
        });
        return pointsArr;
    }
    decideClosePath() {
        const EP = 0.05;
        let isClosePath = true;
        const hasOpenPath = this.graphicsPoints.some((points) => {
            if (points.length === 0) {
                return false;
            }
            else if (points.length <= 2) {
                return true;
            }
            return (Math.abs(points[0].x - points[points.length - 1].x) > EP ||
                Math.abs(points[0].y - points[points.length - 1].y) > EP);
        });
        if (hasOpenPath) {
            isClosePath = false;
        }
        return isClosePath;
    }
    closePath() {
        this.currentPath2.points.push(this.currentPath2.points[0], this.currentPath2.points[1]);
        this.finishPath();
        return this;
    }
    checkPath() {
        if (this.currentPath2.points.find((e) => isNaN(e)) !== undefined) {
            throw new Error('NaN is bad');
        }
    }
    // Redirect moveTo, lineTo, ... onto paths!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! :P
    startPoly = this.startPath;
    finishPoly = this.finishPath;
    // 检测指令是否有效
    isCommandsValid(commands) {
        const checkIsMCommand = (command) => command.code.toUpperCase() === 'M';
        // 情况1：path中所有的指令都是m的被视为无效，因为无法渲染出实际的内容，对于路径中前一部分或后一部分是连续m的情况暂不处理（实际中的情况比较复杂）
        const isAllMCommand = commands.every(checkIsMCommand);
        const isValid = !isAllMCommand;
        return isValid;
    }
    parseElement(element) {
        const d = element.getAttribute('d');
        this.dPath = d;
        if (!d) {
            return;
        }
        // Parse path commands using d-path-parser. This is an inefficient solution that causes excess memory allocation
        // and should be optimized in the future.
        const commands = dParser(d.trim());
        if (!this.isCommandsValid(commands)) {
            return;
        }
        // Current point
        let x = 0;
        let y = 0;
        for (let i = 0, j = commands.length; i < j; i++) {
            const lastCommand = commands[i - 1];
            const command = commands[i];
            if (isNaN(x) || isNaN(y)) {
                throw new Error('Data corruption');
            }
            // Taken from: https://github.com/bigtimebuddy/pixi-svg/blob/main/src/SVG.js
            // Copyright Matt Karl
            switch (command.code) {
                case 'm': {
                    this.moveTo((x += command.end.x), (y += command.end.y));
                    break;
                }
                case 'M': {
                    this.moveTo((x = command.end.x), (y = command.end.y));
                    break;
                }
                case 'H': {
                    this.lineTo((x = command.value), y);
                    break;
                }
                case 'h': {
                    this.lineTo((x += command.value), y);
                    break;
                }
                case 'V': {
                    this.lineTo(x, (y = command.value));
                    break;
                }
                case 'v': {
                    this.lineTo(x, (y += command.value));
                    break;
                }
                case 'z':
                case 'Z': {
                    x = this.currentPath2?.points[0] || 0;
                    y = this.currentPath2?.points[1] || 0;
                    this.closePath();
                    break;
                }
                case 'L': {
                    this.lineTo((x = command.end.x), (y = command.end.y));
                    break;
                }
                case 'l': {
                    this.lineTo((x += command.end.x), (y += command.end.y));
                    break;
                }
                case 'C': {
                    this.bezierCurveTo(command.cp1.x, command.cp1.y, command.cp2.x, command.cp2.y, (x = command.end.x), (y = command.end.y));
                    break;
                }
                case 'c': {
                    const currX = x;
                    const currY = y;
                    this.bezierCurveTo(currX + command.cp1.x, currY + command.cp1.y, currX + command.cp2.x, currY + command.cp2.y, (x += command.end.x), (y += command.end.y));
                    break;
                }
                case 's':
                case 'S': {
                    const cp1 = { x, y };
                    const lastCode = commands[i - 1] ? commands[i - 1].code : null;
                    if (i > 0 &&
                        (lastCode === 's' ||
                            lastCode === 'S' ||
                            lastCode === 'c' ||
                            lastCode === 'C')) {
                        const lastCommand = commands[i - 1];
                        const lastCp2 = { ...(lastCommand.cp2 || lastCommand.cp) };
                        if (commands[i - 1].relative) {
                            lastCp2.x += x - lastCommand.end.x;
                            lastCp2.y += y - lastCommand.end.y;
                        }
                        cp1.x = 2 * x - lastCp2.x;
                        cp1.y = 2 * y - lastCp2.y;
                    }
                    const cp2 = { x: command.cp.x, y: command.cp.y };
                    if (command.relative) {
                        cp2.x += x;
                        cp2.y += y;
                        x += command.end.x;
                        y += command.end.y;
                    }
                    else {
                        x = command.end.x;
                        y = command.end.y;
                    }
                    this.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, x, y);
                    break;
                }
                case 'q': {
                    const currX = x;
                    const currY = y;
                    this.quadraticCurveTo(currX + command.cp.x, currY + command.cp.y, (x += command.end.x), (y += command.end.y));
                    break;
                }
                case 'Q': {
                    this.quadraticCurveTo(command.cp.x, command.cp.y, (x = command.end.x), (y = command.end.y));
                    break;
                }
                case 'A':
                    this.ellipticArcTo((x = command.end.x), (y = command.end.y), command.radii.x, command.radii.y, ((command.rotation || 0) * Math.PI) / 180, !command.clockwise, command.large);
                    break;
                case 'a':
                    this.ellipticArcTo((x += command.end.x), (y += command.end.y), command.radii.x, command.radii.y, ((command.rotation || 0) * Math.PI) / 180, !command.clockwise, command.large);
                    break;
                case 't':
                case 'T': {
                    let cx;
                    let cy;
                    if (lastCommand && lastCommand.cp) {
                        let lcx = lastCommand.cp.x;
                        let lcy = lastCommand.cp.y;
                        if (lastCommand.relative) {
                            const lx = x - lastCommand.end.x;
                            const ly = y - lastCommand.end.y;
                            lcx += lx;
                            lcy += ly;
                        }
                        cx = 2 * x - lcx;
                        cy = 2 * y - lcy;
                    }
                    else {
                        cx = x;
                        cy = y;
                    }
                    if (command.code === 't') {
                        this.quadraticCurveTo(cx, cy, (x += command.end.x), (y += command.end.y));
                    }
                    else {
                        this.quadraticCurveTo(cx, cy, (x = command.end.x), (y = command.end.y));
                    }
                    break;
                }
                default: {
                    console.warn('[PIXI.SVG] Draw command not supported:', command.code, command);
                    break;
                }
            }
        }
    }
    // parseElement2(element: SVGPathElement) {
    //   const d = element.getAttribute('d') as string;
    //   if (!d) {
    //     return;
    //   }
    //   this.dPath = d;
    //   const { commands } = new SVGPathData(d.trim()).toAbs();
    //   // Current point
    //   let x = 0;
    //   let y = 0;
    //   const types: any[] = [];
    //   for (let i = 0, j = commands.length; i < j; i++) {
    //     const command = commands[i];
    //     if (isNaN(x) || isNaN(y)) {
    //       throw new Error('Data corruption');
    //     }
    //     const { type } = command;
    //     if (!types.includes(type)) {
    //       types.push(type);
    //     }
    //     switch (type) {
    //       // m
    //       case SVGPathData.MOVE_TO: {
    //         this.moveTo((x = command.x), (y = command.y));
    //         break;
    //       }
    //       // l
    //       case SVGPathData.LINE_TO: {
    //         this.lineTo((x = command.x), (y = command.y));
    //         break;
    //       }
    //       // h
    //       case SVGPathData.HORIZ_LINE_TO: {
    //         this.lineTo((x = command.x), y);
    //         break;
    //       }
    //       // v
    //       case SVGPathData.VERT_LINE_TO: {
    //         this.lineTo(x, (y = command.y));
    //         break;
    //       }
    //       // z
    //       case SVGPathData.CLOSE_PATH: {
    //         x = this.currentPath2?.points[0] || 0;
    //         y = this.currentPath2?.points[1] || 0;
    //         this.closePath();
    //         break;
    //       }
    //       // q
    //       case SVGPathData.QUAD_TO: {
    //         const currX = x;
    //         const currY = y;
    //         this.quadraticCurveTo(
    //           currX + command.x,
    //           currY + command.y,
    //           (x += command.x1),
    //           (y += command.y1),
    //         );
    //         break;
    //       }
    //       // t
    //       case SVGPathData.SMOOTH_QUAD_TO: {
    //         // let cx: number;
    //         // let cy: number;
    //         // if (lastCommand && lastCommand.cp) {
    //         //   let lcx = lastCommand.cp.x;
    //         //   let lcy = lastCommand.cp.y;
    //         //   if (lastCommand.relative) {
    //         //     const lx = x - lastCommand.end.x;
    //         //     const ly = y - lastCommand.end.y;
    //         //     lcx += lx;
    //         //     lcy += ly;
    //         //   }
    //         //   cx = 2 * x - lcx;
    //         //   cy = 2 * y - lcy;
    //         // } else {
    //         //   cx = x;
    //         //   cy = y;
    //         // }
    //         // this.quadraticCurveTo(
    //         //   cx,
    //         //   cy,
    //         //   (x = command.end.x),
    //         //   (y = command.end.y),
    //         // );
    //         // break;
    //       }
    //       // c
    //       case SVGPathData.CURVE_TO: {
    //         this.bezierCurveTo(
    //           command.x1,
    //           command.y1,
    //           command.x2,
    //           command.y2,
    //           (x = command.x),
    //           (y = command.y),
    //         );
    //         break;
    //       }
    //       // s
    //       case SVGPathData.SMOOTH_CURVE_TO: {
    //         this.bezierCurveTo(
    //           command.x,
    //           command.y,
    //           command.x2,
    //           command.y2,
    //           (x = command.x),
    //           (y = command.y),
    //         );
    //         break;
    //       }
    //       // a
    //       case SVGPathData.ARC: {
    //         this.ellipticArcTo(
    //           (x = command.x),
    //           (y = command.y),
    //           command.rX,
    //           command.rY,
    //           ((command.xRot || 0) * Math.PI) / 180,
    //           !command.sweepFlag,
    //           Boolean(command.lArcFlag),
    //         );
    //         break;
    //       }
    //       default: {
    //         console.warn('[PIXI.SVG] Draw command not supported:', command);
    //         break;
    //       }
    //     }
    //   }
    // }
    /**
     * Embeds the `SVGPathElement` into this node.
     *
     * @param element - the path to draw
     */
    embedPath(element, fillRule) {
        this.parseElement(element);
        if (this.currentPath2) {
            this.currentPath2.fillRule =
                fillRule ||
                    element.getAttribute('fill-rule') ||
                    this.currentPath2.fillRule;
            this.fillRule = this.currentPath2.fillRule;
            this.drawShape(this.currentPath2);
            this.isClosePath = this.decideClosePath();
            this.currentPath2 = null;
        }
        return this;
    }
    toJSON(relativeEle) {
        const { dPath, x, y } = this;
        const baseJSON = super.toJSON(relativeEle);
        // XXX: 不同于常规graphic，绘制从M坐标开始，此处获取graphic相对它的canvas坐标(不可视canvas左上角坐标)转化为世界坐标，再转为相对于viewport的坐标进行导出
        const worldCoordinates = this.parent.toGlobal(new Point(x, y));
        const { x: relativeX, y: relativeY } = relativeEle.toLocal({
            x: worldCoordinates.x,
            y: worldCoordinates.y,
        });
        return {
            ...baseJSON,
            dPath,
            fillRule: this.fillRule,
            graphicX: relativeX,
            graphicY: relativeY,
        };
    }
    getSVGData(options) {
        return merge(super.getSVGData(options), {
            tag: 'path',
            attrs: {
                d: this.dPath,
                fillRule: this.fillRule,
            },
        });
    }
}
