import { cloneDeep, has, merge, pick, round } from 'lodash-es';
import { Matrix, Point } from 'pixi.js';
import { DEFAULT_TEXT_STYLE } from '../../config';
import { createGroupUUID, rotatePtByAnchor } from '../../utils';
import { calOffset } from '../../utils/math';
import { toUnicodeCharArray } from '../../utils/stringUtils';
import { DISPLAY_TYPE } from '../type';
const DEFAULT_TEXT = {
    'fontInfo': {
        'unitsPerEm': 2000,
        'lineHeight': 30.48,
        'ascent': 25.0698,
        'descent': -5.4102,
        'capHeight': 18.199099999999998,
        'xHeight': 12.8651,
        'lineGap': 0,
    },
    'glyphData': {
        'H': {
            'dPath': 'M16.97 0L14.49 0L14.49 -8.28L4.69 -8.28L4.69 0L2.21 0L2.21 -18.2L4.69 -18.2L4.69 -10.08L14.49 -10.08L14.49 -18.2L16.97 -18.2Z',
            'advanceWidth': 19.2024,
            'advanceHeight': 25.4,
            'leftBearing': 2.2098,
            'topBearing': 2.2479,
            'bbox': {
                'minX': 2.2098,
                'minY': 0,
                'maxX': 16.9672,
                'maxY': 18.199099999999998,
            },
        },
        'E': {
            'dPath': 'M13.42 -18.2L13.42 -16.19L4.69 -16.19L4.69 -10.15L11.76 -10.15L11.76 -8.22L4.69 -8.22L4.69 -2.01L13.42 -2.01L13.42 0L2.21 0L2.21 -18.2Z',
            'advanceWidth': 14.757399999999999,
            'advanceHeight': 25.4,
            'leftBearing': 2.2098,
            'topBearing': 2.2479,
            'bbox': {
                'minX': 2.2098,
                'minY': 0,
                'maxX': 13.4239,
                'maxY': 18.199099999999998,
            },
        },
        'L': {
            'dPath': 'M4.67 -2.07L12.55 -2.07L12.55 0L2.21 0L2.21 -18.2L4.67 -18.2Z',
            'advanceWidth': 13.0556,
            'advanceHeight': 25.4,
            'leftBearing': 2.2098,
            'topBearing': 2.2479,
            'bbox': {
                'minX': 2.2098,
                'minY': 0,
                'maxX': 12.5476,
                'maxY': 18.199099999999998,
            },
        },
        'O': {
            'dPath': 'M19.11 -9.09Q19.11 -7.05 18.47 -5.34Q17.82 -3.63 16.64 -2.4Q15.46 -1.17 13.8 -0.49Q12.14 0.19 10.13 0.19Q8.13 0.19 6.48 -0.49Q4.83 -1.17 3.64 -2.4Q2.46 -3.63 1.82 -5.34Q1.17 -7.05 1.17 -9.09Q1.17 -11.14 1.82 -12.85Q2.46 -14.55 3.64 -15.79Q4.83 -17.03 6.48 -17.72Q8.13 -18.4 10.13 -18.4Q12.14 -18.4 13.8 -17.72Q15.46 -17.03 16.64 -15.79Q17.82 -14.55 18.47 -12.85Q19.11 -11.14 19.11 -9.09ZM16.59 -9.09Q16.59 -10.77 16.13 -12.1Q15.67 -13.44 14.83 -14.36Q14 -15.28 12.8 -15.77Q11.61 -16.27 10.13 -16.27Q8.67 -16.27 7.48 -15.77Q6.29 -15.28 5.44 -14.36Q4.6 -13.44 4.14 -12.1Q3.68 -10.77 3.68 -9.09Q3.68 -7.42 4.14 -6.09Q4.6 -4.76 5.44 -3.84Q6.29 -2.92 7.48 -2.43Q8.67 -1.94 10.13 -1.94Q11.61 -1.94 12.8 -2.43Q14 -2.92 14.83 -3.84Q15.67 -4.76 16.13 -6.09Q16.59 -7.42 16.59 -9.09Z',
            'advanceWidth': 20.269199999999998,
            'advanceHeight': 25.4,
            'leftBearing': 1.1683999999999999,
            'topBearing': 2.0446999999999997,
            'bbox': {
                'minX': 1.1683999999999999,
                'minY': -0.1905,
                'maxX': 19.1135,
                'maxY': 18.4023,
            },
        },
    },
};
const MM_PER_IN = 25.4;
const PT_PER_IN = 72;
// 文字图形的基准字号
export const BASIC_FONT_SIZE = 72; // 单位：pt
// letterSpacing leading 的调整的系数
export const MULTI_RATIO = 0.1;
export class TextGlyphs {
    // 在canvas中measureText提供一个默认的返回值，具体的算法策略由xcs中替换此方法为electron中的读取系统字体列表的方法
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static measureText(data) {
        return DEFAULT_TEXT;
    }
    /**
     * ptToMM
     * pt单位转换成毫米, 72pt = 1in = 25.4mm
     */
    static ptToMM(pt) {
        return (pt / PT_PER_IN) * MM_PER_IN;
    }
    /**
     * mmToPt
     * 毫米转换成pt单位, 72pt = 1in = 25.4mm
     */
    static mmToPT(mm) {
        return (mm / MM_PER_IN) * PT_PER_IN;
    }
    // 将MTEXT中保存的fontWeight与fontStyle转换成 MSVGText中的fontSubFamily
    static converStyleToSubFamily(fontWeight, fontStyle) {
        const fontWeightMap = {
            '700': 'Bold',
            '200': 'Thin',
        };
        const fontStyleMap = {
            'italic': 'Italic',
        };
        const subFontFamily = `${fontWeightMap[fontWeight] || ''} ${fontStyleMap[fontStyle] || ''}`;
        return subFontFamily.trim() || 'Regular';
    }
    /**
     * name
     */
    static calSpaceByFontSize(fontSize, percent) {
        return TextGlyphs.ptToMM(Number(fontSize)) * percent;
    }
    static splitFontFamily(fontFamily) {
        if (Array.isArray(fontFamily)) {
            fontFamily = fontFamily[0];
        }
        const usualStyle = [
            'Regular',
            'Regular Italic',
            'Italic',
            'Oblique',
            'Thin',
            'Thin Italic',
            'ExtraLight',
            'Extra Light',
            'ExtraLight Italic',
            'Ultra Light',
            'UltraLight',
            'UltraLight Italic',
            'Light',
            'Light Italic',
            'Light Oblique',
            'Medium',
            'Medium Italic',
            'Medium Oblique',
            'Semibold',
            'SemiBold Italic',
            'Demi',
            'DemiBold',
            'DemiBold Italic',
            'Bold',
            'Bold Italic',
            'Bold Oblique',
            'ExtraBold',
            'ExtraBold Italic',
            'UltraBold',
            'UltraBold Italic',
            'Black',
            'Black Italic',
            'Black Oblique',
            'Heavy',
            'Heavy Italic',
            'Heavy Oblique',
            'ExtraBlack',
            'UltraBlack',
            'Condensed',
            'Condensed Thin',
            'Condensed Light',
            'Condensed Medium',
            'Condensed Bold',
            'Condensed ExtraBold',
            'Condensed Black',
        ];
        let includeStyle = '';
        let matchLength = 0;
        usualStyle.forEach((item) => {
            // 从常用的样式中匹配，找到名称中包含的样式名称最长的
            if (fontFamily.includes(item)) {
                if (item.length > matchLength) {
                    matchLength = item.length;
                    includeStyle = item;
                }
            }
        });
        const includeStyleIsEnd = fontFamily.endsWith(includeStyle);
        fontFamily = includeStyleIsEnd
            ? fontFamily.replace(includeStyle, '').trim()
            : fontFamily;
        return {
            fontFamily,
            fontSubfamily: includeStyleIsEnd ? includeStyle : '',
        };
    }
    static isTextNeededAdapt(data) {
        return data.type === DISPLAY_TYPE.TEXT && has(data, 'style.lineSize');
    }
    static adaptMText(data) {
        const { x, y, height, style = {}, scale, text = '', angle } = data;
        // 将 lineSize 去除
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { lineSize, ...restStyle } = style;
        const adaptScaleNum = 0.8;
        const targetHeight = height * adaptScaleNum;
        // 直接使用保存的高度转换pt
        const lines = text.split(/(?:\r\n|\r|\n)/);
        const adaptedFontSize = TextGlyphs.mmToPT(targetHeight / lines.length);
        const offsetY = (height * (1 - adaptScaleNum + 0.2)) / 2;
        const targetX = x - offsetY * Math.sin((angle / 180) * Math.PI);
        const targetY = y + offsetY * Math.cos((angle / 180) * Math.PI);
        const { fontFamily: splitFontFamily, fontSubfamily: splitFontSubFamily } = TextGlyphs.splitFontFamily(style.fontFamily);
        return {
            ...data,
            scale: {
                ...scale,
            },
            style: {
                ...restStyle,
                fontSize: adaptedFontSize,
                fontFamily: splitFontFamily,
                fontSubfamily: splitFontSubFamily ||
                    TextGlyphs.converStyleToSubFamily(style.fontWeight, style.fontStyle) ||
                    style.fontSubfamily,
                letterSpacing: 0,
                leading: 0,
            },
            x: targetX,
            y: targetY,
            height: targetHeight,
        };
    }
    static converHeightToFontSize(fontSize, scale) {
        const tempFontSize = Number(fontSize) * scale.y;
        const lastScaleX = scale.x;
        const lastScaleY = scale.y;
        const ratio = lastScaleY / lastScaleX;
        return {
            scale: {
                x: 1 / ratio,
                y: 1,
            },
            fontSize: tempFontSize,
        };
    }
    static splitTextToLines(text) {
        return text.split(/(?:\r\n|\r|\n)/);
    }
    /**
     * 更新文字字符的排版位置
     */
    static updateGlyphPositions(children, lines, fontData, style, relativePt = { x: 0, y: 0 }) {
        const { glyphData = {}, fontInfo: { lineHeight = 0 }, } = fontData;
        const { fontSize, leading, letterSpacing, align } = style;
        const { maxWidth, linesWidth } = calLinesWidth();
        setChildrenPos();
        function calLinesWidth() {
            const linesWidth = {};
            let maxWidth = 0;
            lines.forEach((line, lineIndex) => {
                const chars = toUnicodeCharArray(line);
                let lineWidth = 0;
                for (let strIndex = 0; strIndex < chars.length; strIndex++) {
                    const str = chars[strIndex];
                    const glyph = glyphData[str];
                    if (!glyph) {
                        console.warn('There is no valid glyph to create display object for', str, glyphData);
                    }
                    else {
                        lineWidth += glyph.advanceWidth;
                    }
                }
                lineWidth +=
                    (chars.length - 1) *
                        TextGlyphs.calSpaceByFontSize(fontSize, letterSpacing * MULTI_RATIO);
                linesWidth[lineIndex] = lineWidth;
                maxWidth = lineWidth > maxWidth ? lineWidth : maxWidth;
            });
            return {
                maxWidth,
                linesWidth,
            };
        }
        function setChildrenPos() {
            let idx = 0;
            let y = 0;
            lines.forEach((line, lineIndex) => {
                const chars = toUnicodeCharArray(line);
                let x = calOffset(align, maxWidth, linesWidth[lineIndex]);
                for (let strIndex = 0; strIndex < chars.length; strIndex++) {
                    const str = chars[strIndex];
                    const glyph = glyphData[str];
                    if (!glyph) {
                        console.warn('There is no valid glyph to create display object for', str, glyphData);
                    }
                    else {
                        const node = children[idx];
                        if (node) {
                            node.x = relativePt.x + x;
                            node.y = relativePt.y + y;
                            node.rotation = 0;
                            x +=
                                glyph.advanceWidth +
                                    TextGlyphs.calSpaceByFontSize(fontSize, letterSpacing * MULTI_RATIO);
                        }
                        idx += 1;
                    }
                }
                y +=
                    lineHeight +
                        TextGlyphs.calSpaceByFontSize(fontSize, leading * MULTI_RATIO);
            });
        }
        return {
            maxWidth,
            linesWidth,
        };
    }
    /**
     * 检测是否textJSON支持转换为pathJSON
     */
    static detectSupportTextToPath(text) {
        return 'fontData' in text && 'charJSONs' in text;
    }
    /**
     * 转换TextJSON为SvgPathJSON
     */
    static convertTextJSONToPathJSONs(text) {
        if (!text.text || !text.fontData || !text.charJSONs) {
            console.warn('can not covert textJSON with an empty text, fontData or charJSONs');
            return [];
        }
        const pickedAttrs = pick(text, [
            'isFill',
            'lockRatio',
            'isClosePath',
            'zOrder',
            'lineColor',
            'fillColor',
            'groupTag',
            'layerTag',
            'layerColor',
            'visible',
        ]);
        // 如果text没有groupTag，把charJSONs分配到新的groupTag
        if (!pickedAttrs.groupTag) {
            pickedAttrs.groupTag = createGroupUUID();
        }
        const paths = [];
        for (const path of text.charJSONs) {
            Object.assign(path, pickedAttrs);
            path.sourceId = text.id;
            paths.push(path);
        }
        return paths;
    }
    /**
     * 测量文字对象
     */
    static measureTextDisplay(display) {
        return TextGlyphs.measureTextJSON(display.text, display.style, display.fontData);
    }
    /**
     * 测量文字Json
     */
    static measureTextJSON(text, style, fontData) {
        style = merge(cloneDeep(DEFAULT_TEXT_STYLE), style);
        const { fontFamily, fontSubfamily, fontSource, letterSpacing, leading, align, } = style;
        const fontSize = BASIC_FONT_SIZE;
        const styleFontSize = isFinite(Number(style.fontSize))
            ? Number(style.fontSize)
            : BASIC_FONT_SIZE;
        const fontScale = styleFontSize / BASIC_FONT_SIZE;
        if (!fontData) {
            fontData = TextGlyphs.measureText({
                fontFamily,
                fontSubfamily,
                fontSource,
                text,
                fontSize: TextGlyphs.ptToMM(fontSize),
            });
        }
        const { fontInfo: { lineHeight }, glyphData, } = fontData;
        const lines = TextGlyphs.splitTextToLines(text);
        const glyphSpace = TextGlyphs.calSpaceByFontSize(fontSize, letterSpacing * MULTI_RATIO);
        const lineSpace = TextGlyphs.calSpaceByFontSize(fontSize, leading * MULTI_RATIO);
        const lineInfos = TextGlyphs.measureLines(lines, glyphData, lineHeight, lineSpace, glyphSpace, align);
        return {
            ...lineInfos,
            fontData,
            fontScale,
        };
    }
    /**
     * 测量多行文字
     */
    static measureLines(lines, glyphData, lineHeight, lineSpace = 0, glyphSpace = 0, align = 'left') {
        const { maxAdvWidth, linesAdvWidth } = TextGlyphs.calcLineAdvWidth(lines, glyphData, glyphSpace);
        const advWidth = maxAdvWidth;
        let maxWidth = 0;
        let advHeight = 0;
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;
        const lineMetrics = lines.map((line, i) => {
            if (advHeight) {
                advHeight += lineSpace;
            }
            const lineOffsetX = calOffset(align, maxAdvWidth, linesAdvWidth[i]);
            const lineOffsetY = i * (lineHeight + lineSpace);
            const lineMetric = TextGlyphs.measureLine(line, glyphData, glyphSpace, lineOffsetX, lineOffsetY);
            minY = Math.min(minY, lineMetric.bbox.minY);
            maxY = Math.max(maxY, lineMetric.bbox.maxY);
            minX = Math.min(minX, lineMetric.bbox.minX);
            maxX = Math.max(maxX, lineMetric.bbox.maxX);
            maxWidth = Math.max(maxWidth, lineMetric.width);
            advHeight += lineMetric.advHeight;
            return lineMetric;
        });
        [minX, maxX, minY, maxY] = [minX, maxX, minY, maxY].map((v) => isFinite(v) ? v : 0);
        return {
            maxWidth,
            maxAdvWidth,
            ...TextGlyphs.createMetric(minX, minY, maxX, maxY, advWidth, advHeight),
            lineMetrics,
        };
    }
    /**
     * 计算多行advanceWidth信息
     */
    static calcLineAdvWidth(lines, glyphData, glyphSpace) {
        let maxAdvWidth = 0;
        const linesAdvWidth = {};
        lines.forEach((line, lineIndex) => {
            const chars = toUnicodeCharArray(line);
            let advWidth = 0;
            for (let strIndex = 0; strIndex < chars.length; strIndex++) {
                const str = chars[strIndex];
                const glyph = glyphData[str];
                if (glyph) {
                    advWidth += glyph.advanceWidth;
                    if (advWidth) {
                        advWidth += glyphSpace;
                    }
                }
            }
            linesAdvWidth[lineIndex] = advWidth;
            maxAdvWidth = advWidth > maxAdvWidth ? advWidth : maxAdvWidth;
        });
        return { maxAdvWidth, linesAdvWidth };
    }
    /**
     * 计算一行文字
     */
    static measureLine(line, glyphData, glyphSpace = 0, lineOriginX = 0, lineOriginY = 0, flipY = true) {
        let advWidth = 0;
        let advHeight = 0;
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;
        const chars = toUnicodeCharArray(line);
        const charMetrics = {};
        chars.forEach((char, i) => {
            const glyph = glyphData[char];
            if (glyph) {
                if (advWidth) {
                    advWidth += glyphSpace;
                }
                const charMetric = TextGlyphs.createMetric(lineOriginX + advWidth + glyph.bbox.minX, lineOriginY + (flipY ? -glyph.bbox.maxY : glyph.bbox.minY), lineOriginX + advWidth + glyph.bbox.maxX, lineOriginY + (flipY ? -glyph.bbox.minY : glyph.bbox.maxY), glyph.advanceWidth, glyph.advanceHeight);
                minY = Math.min(minY, charMetric.bbox.minY);
                maxY = Math.max(maxY, charMetric.bbox.maxY);
                minX = Math.min(minX, charMetric.bbox.minX);
                maxX = Math.max(maxX, charMetric.bbox.maxX);
                advWidth += glyph.advanceWidth;
                advHeight = Math.max(glyph.advanceHeight, advHeight);
                charMetrics[i] = charMetric;
            }
        });
        [minX, maxX, minY, maxY] = [minX, maxX, minY, maxY].map((v) => isFinite(v) ? v : 0);
        const lineMetric = {
            ...TextGlyphs.createMetric(minX, minY, maxX, maxY, advWidth, advHeight),
            charMetrics,
        };
        return lineMetric;
    }
    /**
     * 创建文字测量数据
     */
    static createMetric(minX, minY, maxX, maxY, advWidth, advHeight, fragDigitals) {
        const roundNum = (value, fragDigitals) => isFinite(fragDigitals) && fragDigitals > 0
            ? round(value, fragDigitals)
            : value;
        const bbox = {
            minX: roundNum(minX, fragDigitals),
            maxX: roundNum(maxX, fragDigitals),
            minY: roundNum(minY, fragDigitals),
            maxY: roundNum(maxY, fragDigitals),
        };
        return {
            bbox,
            x: bbox.minX,
            y: bbox.minY,
            width: roundNum(maxX - minX, fragDigitals),
            height: roundNum(maxY - minY, fragDigitals),
            advWidth: roundNum(advWidth, fragDigitals),
            advHeight: roundNum(advHeight, fragDigitals),
        };
    }
    static updateTextCurveEffect(display, style, lines, fontData) {
        let curveInfo;
        const { lineHeight = 0, capHeight = 0 } = fontData.fontInfo;
        const { curveX, curveY, align, leading } = style;
        const curveHandle = { x: curveX, y: curveY };
        const curveDir = Math.sign(curveHandle.y) || 0;
        const curveRadius = curveDir * TextGlyphs.calcCurveRadiusByHandle(curveHandle);
        // 计算curveAnchor
        const bounds = display.getLocalBounds();
        const curveAnchorX = TextGlyphs.calcCurveAnchorX(align, bounds);
        const curveAnchorY = TextGlyphs.calcCurveAnchorY(bounds);
        if (curveDir) {
            const baselineSpace = lineHeight +
                TextGlyphs.calSpaceByFontSize(BASIC_FONT_SIZE, leading * MULTI_RATIO);
            const curveBaselineIndex = curveDir > 0 ? lines.length - 1 : 0;
            const glyphOffsetX = 0;
            const glyphOffsetY = (curveDir > 0 ? 0 : -1) * capHeight;
            // handle路径
            const handlePath = TextGlyphs.calcTextCurvePath(curveRadius, 0, curveAnchorX, curveAnchorY);
            // 每行弯曲路径
            const linePaths = lines.map((line, i) => {
                const lineOffsetY = i * baselineSpace;
                const lineRelBaseOffsetY = lineOffsetY + glyphOffsetY - curveAnchorY;
                const radiusExpansion = -curveDir * lineRelBaseOffsetY;
                const currentAnchorY = lineOffsetY + glyphOffsetY;
                const linePath = TextGlyphs.calcTextCurvePath(curveRadius, radiusExpansion, curveAnchorX, currentAnchorY);
                return linePath;
            });
            curveInfo = {
                curveDir,
                curveRadius,
                linePaths,
                glyphOffsetX,
                glyphOffsetY,
                curveAnchor: {
                    x: curveAnchorX,
                    y: curveAnchorY,
                },
                curveBaselineIndex,
                handlePath,
            };
            TextGlyphs.applyTextCurve(display, curveInfo);
        }
        else {
            curveInfo = {
                curveDir: 0,
                curveAnchor: {
                    x: curveAnchorX,
                    y: curveAnchorY,
                },
            };
        }
        return curveInfo;
    }
    static calcCurveRadiusByHandle(handle) {
        const dx = handle.x;
        const dy = handle.y;
        const radius = (dx ** 2 + dy ** 2) / (2 * Math.abs(dy));
        return radius;
    }
    static calcCurveAnchorX(align, bounds) {
        let curveAnchorX;
        if (align === 'center') {
            curveAnchorX = bounds.x + bounds.width / 2;
        }
        else if (align === 'right') {
            curveAnchorX = bounds.x + bounds.width;
        }
        else {
            curveAnchorX = bounds.x;
        }
        return curveAnchorX;
    }
    static calcCurveAnchorY(bounds) {
        return bounds.y + bounds.height / 2;
    }
    static applyTextCurve(display, { linePaths, glyphOffsetX, glyphOffsetY }) {
        let idx = 0;
        display.lines.forEach((line, i) => {
            const chars = toUnicodeCharArray(line);
            const linePath = linePaths[i];
            for (let strIndex = 0; strIndex < chars.length; strIndex++) {
                const char = chars[strIndex];
                const child = display.children[idx];
                const glyph = display.fontData.glyphData[char];
                const glyphWidth = glyph.bbox.maxX - glyph.bbox.minX;
                const glyphBottomCenterX = child.position.x + glyph.leftBearing + glyphWidth / 2 - glyphOffsetX;
                const transform = linePath.getTransformAt(glyphBottomCenterX, new Point(glyph.leftBearing + glyphWidth / 2, glyphOffsetY));
                child.x = transform.position.x;
                child.y = transform.position.y;
                child.rotation = transform.rotation;
                idx += 1;
            }
        });
    }
    /**
     * 获取文字弯曲的圆形路径
     * @param curveRadius 弯曲圆半径（带方向）
     * @param baseLength 文字advanceWidth
     * @param radiusExp 半径扩展量
     * @param anchorX 弯曲锚点X
     * @param anchorY 弯曲锚点Y
     * @returns
     */
    static calcTextCurvePath(curveRadius, radiusExp = 0, anchorX = 0, anchorY = 0) {
        if (!curveRadius) {
            return null;
        }
        const ccw = curveRadius < 0;
        const dir = ccw ? -1 : 1;
        const radius = Math.abs(curveRadius) + radiusExp;
        const a = new Point(anchorX, anchorY);
        const c = new Matrix().translate(0, dir * radius).apply(a);
        const oNormalsRotation = (-dir * anchorX) / radius;
        const o = rotatePtByAnchor(a, oNormalsRotation, c);
        const arc = {
            c,
            o,
            a,
            radius,
            oNormalsRotation,
            ccw,
            getTransformAt(length, offset) {
                const dir = this.ccw ? -1 : 1;
                const theta = (length / this.radius) * dir;
                const position = rotatePtByAnchor(this.o, theta, this.c);
                const rotation = this.oNormalsRotation + theta;
                if (offset) {
                    const vec = new Matrix()
                        .translate(-offset.x, -offset.y)
                        .rotate(rotation)
                        .apply(new Point(0, 0));
                    new Matrix().translate(vec.x, vec.y).apply(position, position);
                }
                return {
                    position,
                    rotation,
                };
            },
        };
        return arc;
    }
}
