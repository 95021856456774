const COMPUTE_INTERVAL = 25;
const POINTER_INPUT_MAP = new Map([
    ['pointerdown', 1 /* INPUT.START */],
    ['pointermove', 2 /* INPUT.MOVE */],
    ['pointerup', 4 /* INPUT.END */],
    ['pointercancel', 8 /* INPUT.CANCEL */],
    ['pointerout', 8 /* INPUT.CANCEL */],
    ['pointerleave', 8 /* INPUT.CANCEL */],
    ['pointerover', 8 /* INPUT.CANCEL */],
]);
const PROPS_XY = ['x', 'y'];
const PROPS_CLIENT_XY = ['clientX', 'clientY'];
export { POINTER_INPUT_MAP, PROPS_XY, PROPS_CLIENT_XY, COMPUTE_INTERVAL, };
