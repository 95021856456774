import { merge } from 'lodash-es';
import { Graphics } from 'pixi.js';
import { DEFAULT_ELEMENT_LINE_STYLE, PAINT_COLOR } from '../config';
import { getSVGFillAndStrokeAttrs, getSVGPathDAttrByCommands, } from '../packages/export/svg';
import { isSkPath } from '../packages/skia/SkPath';
import { INTERACTION_EVENT } from '../types';
import { deepCopy } from '../utils';
import { getControlPoints, getSymmetryPoint } from '../utils/geometry';
import { MPolygon } from './MPolygon';
import MContainer from './base/MContainer';
import MPenGraphicGeometry from './base/MPenGraphicGeometry';
import { DISPLAY_LIFE_CYCLE, DISPLAY_TYPE } from './type';
import { drawPointOnGraphics, isClosePathPoint, removePointerEvents, } from './utils/graphics';
var Z_INDEX;
(function (Z_INDEX) {
    Z_INDEX[Z_INDEX["Point"] = 3] = "Point";
    Z_INDEX[Z_INDEX["ControlPoint"] = 2] = "ControlPoint";
    Z_INDEX[Z_INDEX["ControlPointLine"] = 1] = "ControlPointLine";
    Z_INDEX[Z_INDEX["Line"] = 0] = "Line";
})(Z_INDEX || (Z_INDEX = {}));
export class MPen extends MContainer {
    type = DISPLAY_TYPE.PEN;
    points = [];
    // 由于使用 Map<number, Point[]> 类型，导入文件出了问题，所以改成 object
    controlPoints = {};
    // 是否是闭合的，在保存数据的时候需要设置
    isClosePath = false;
    // 是否已经结束绘制
    isDone = false;
    // 管理各组绘制的 Graphics
    lineGraphics;
    pointGraphics = [];
    controlPointGraphics = [];
    // 点击移动点的下标
    movingPointIndex = -1;
    // 编辑点的下标
    editingPointIndex = -1;
    // 编辑控制点的下标
    editingControlPointIndex = -1;
    // 是否处于编辑状态
    #editing = false;
    constructor(options) {
        super();
        // 当通过 json 参数创建时需要按 1.添加 lineGraphics 到容器，2.通过 points 绘制出原始大小的内容 3.设置尺寸坐标等参数
        this.lineGraphics = new MPolygon();
        this.addChild(this.lineGraphics);
        // @ts-ignore
        this.lineGraphics._geometry = new MPenGraphicGeometry();
        if (options) {
            const { points, isClosePath, controlPoints, ...rest } = options;
            this.points = points;
            this.isClosePath = isClosePath;
            this.controlPoints = controlPoints;
            this.draw();
            this.parseJSON(rest);
            this.x = options.offsetX;
            this.y = options.offsetY;
        }
        this.type = DISPLAY_TYPE.PEN;
        this.lineGraphics.zIndex = Z_INDEX.Line;
        this.sortableChildren = true;
        // 复制粘贴/批量创建的的钢笔也可选中，并且已经是结束绘制的
        if (this.points.length > 0) {
            this.interactive = true;
            this.isDone = true;
            this.setupHitArea();
        }
        this.setUpLineGraphics();
    }
    highlightBound() {
        // 正在绘制的时候不可响应
        if (!this.#editing && this.isDone) {
            this.lineGraphics.highlightBound();
        }
    }
    reset() {
        // 正在绘制的时候不可响应
        if (!this.#editing && this.isDone) {
            this.lineGraphics.reset();
        }
    }
    setUpLineGraphics() {
        // lineGraphics 是 MPolygon的实例默认的interactive为true, 允许被选中的是Pen，所以要设为false
        this.lineGraphics.interactive = false;
        // this.on('pointerover', () => {
        //   if (!this.#editing) {
        //     this.lineGraphics.highlightBound();
        //   }
        // });
        // this.on('pointerout', () => {
        //   if (!this.#editing) {
        //     this.lineGraphics.reset();
        //   }
        // });
    }
    // abstract methods implementation
    start(point) {
        if (this.#editing) {
            return;
        }
        if (isClosePathPoint(point, this.points)) {
            this.isClosePath = true;
            this.done();
        }
        else {
            this.points.push(point);
            this.update(point);
            if (this.points.length === 1) {
                this.emit(DISPLAY_LIFE_CYCLE.DRAW_BEGIN, this);
                this.interactive = true;
            }
            else {
                this.emit(DISPLAY_LIFE_CYCLE.DRAW_UPDATE, this);
            }
        }
    }
    update(point) {
        if (this.#editing) {
            return;
        }
        if (this.points.length < 1) {
            return;
        }
        this.draw();
        this.drawAuxiliaryLine(point);
        this.drawPoints();
    }
    getRealBounds() {
        return this.lineGraphics.getRealBounds();
    }
    end() { }
    draw() {
        if (this.points.length < 1) {
            return;
        }
        this.lineGraphics.clear().lineStyle(DEFAULT_ELEMENT_LINE_STYLE);
        this.drawLine(this.isClosePath);
    }
    done() {
        this.isDone = true;
        this.#editing = false;
        this.interactive = true;
        this.removePointsEvents();
        this.removePointGraphics();
        this.removeControlPointsEvents();
        this.removeControlPointGraphics();
        this.draw();
        this.lineGraphics.draw();
        this.setupHitArea();
        this.emit(DISPLAY_LIFE_CYCLE.DRAW_DONE, this);
    }
    updateData(data, byRedo) {
        const { points, isClosePath, controlPoints } = data;
        this.points = points;
        this.isClosePath = isClosePath;
        this.controlPoints = controlPoints;
        // 由 redo 触发的时候，结束绘制
        if (byRedo) {
            this.done();
        }
        else {
            this.draw();
            this.removePointGraphics();
            this.removeControlPointGraphics();
            if (this.#editing) {
                this.enterEditMode();
            }
            else {
                if (!this.isDone) {
                    this.drawPoints();
                }
            }
            this.setupHitArea();
        }
    }
    /**
     * 通过保存的原始path数据，进行transform运算，得到realPoint
     */
    getRealPoints(relativeEle) {
        return this.lineGraphics.getRealPoints(relativeEle);
    }
    undo() {
        this.clear();
    }
    // @ts-ignore
    toJSON(relativeEle) {
        const { points, controlPoints, width, height } = this;
        const baseJSON = super.toJSON(relativeEle);
        const { isFill, config: { fillColor, lineColor }, } = this.lineGraphics;
        return {
            ...baseJSON,
            points: deepCopy(points),
            controlPoints: deepCopy(controlPoints),
            isFill,
            fillColor,
            lineColor,
            width,
            height,
        };
    }
    // @ts-ignore
    parseJSON(data) {
        const { points = this.points, controlPoints = this.controlPoints, isFill, fillColor, lineColor, layerTag, layerColor, width = this.width, height = this.height, enableFill = this.enableFill, } = data;
        super.parseJSON(data);
        this.lineGraphics.parseJSON({
            isFill,
            fillColor,
            lineColor,
            layerTag,
            layerColor,
            x: this.lineGraphics.x,
            y: this.lineGraphics.y,
            enableFill,
        });
        this.points = deepCopy(points);
        this.controlPoints = deepCopy(controlPoints);
        this.width = width;
        this.height = height;
        // TODO: 将这个值的设置提到 DisplayObject中
        this.enableFill = enableFill;
    }
    enterEditMode = () => {
        // 进入编辑状态，默认选中第一个点
        if (this.editingPointIndex === -1) {
            this.editingPointIndex = 0;
        }
        this.drawPoints();
        this.drawControlPoints();
        this.#editing = true;
        this.setupPointsEvents();
    };
    get editing() {
        return this.#editing;
    }
    get isFill() {
        return this.lineGraphics.isFill;
    }
    // private methods
    drawLine(closePath = false) {
        // 移动到第一个点
        const firstPoint = this.points[0];
        this.lineGraphics.moveTo(firstPoint.x, firstPoint.y);
        // 处理第二个点到最后一个点
        for (let index = 1; index < this.points.length; index++) {
            const curPoint = this.points[index];
            const controlPoints = this.controlPoints[index];
            const preControlPoints = this.controlPoints[index - 1];
            this.drawLineWithControlPoints(controlPoints, preControlPoints, curPoint);
        }
        if (closePath) {
            // 处理第一个点
            const controlPoints = this.controlPoints[0];
            // 第一个点的“前一个点”是最后一个点
            const preControlPoints = this.controlPoints[this.points.length - 1];
            this.drawLineWithControlPoints(controlPoints, preControlPoints, firstPoint);
        }
        // 结束多边形绘制生成graphicData
        this.lineGraphics.finishPoly();
    }
    drawLineWithControlPoints(controlPoints, preControlPoints, curPoint) {
        const hasPreControlPoint = preControlPoints && preControlPoints.length > 1;
        if (controlPoints) {
            if (hasPreControlPoint) {
                // 前面的点有控制点 & 当前点也有控制点，则用三次贝塞尔曲线
                this.lineGraphics.bezierCurveTo(preControlPoints[1].x, preControlPoints[1].y, controlPoints[0].x, controlPoints[0].y, curPoint.x, curPoint.y);
            }
            else {
                // 只有当前点有控制点，则用二次贝塞尔曲线
                this.lineGraphics.quadraticCurveTo(controlPoints[0].x, controlPoints[0].y, curPoint.x, curPoint.y);
            }
        }
        else {
            if (hasPreControlPoint) {
                // 只有前面的点有控制点，则用二次贝塞尔曲线
                this.lineGraphics.quadraticCurveTo(preControlPoints[1].x, preControlPoints[1].y, curPoint.x, curPoint.y);
            }
            else {
                // 无控制点，则用直线
                this.lineGraphics.lineTo(curPoint.x, curPoint.y);
            }
        }
    }
    drawAuxiliaryLine(point) {
        const lastPoint = this.points[this.points.length - 1];
        this.lineGraphics.moveTo(lastPoint.x, lastPoint.y).lineTo(point.x, point.y);
    }
    drawPoints() {
        for (let index = 0; index < this.points.length; index++) {
            const curPoint = this.points[index];
            let graphics = this.pointGraphics[index];
            if (!graphics) {
                graphics = new Graphics();
                graphics.zIndex = Z_INDEX.Point;
                this.pointGraphics.push(graphics);
                this.addChild(graphics);
            }
            drawPointOnGraphics(graphics, {
                position: curPoint,
                hollow: index !== this.editingPointIndex,
            });
        }
    }
    setupPointsEvents() {
        this.pointGraphics.forEach((graphics) => {
            graphics.interactive = true;
            graphics.on('pointerdown', this.onPointPointerDown.bind(this));
            graphics.on('pointermove', this.onPointPointerMove.bind(this));
            graphics.on('pointerup', this.onPointPointerUp.bind(this));
            graphics.on(INTERACTION_EVENT.DOUBLE_CLICK, this.onPointDoubleClick.bind(this));
        });
    }
    addHitArea() {
        this.setupHitArea();
    }
    drawControlPoints() {
        if (this.editingPointIndex < 0) {
            return;
        }
        const controlPoints = this.controlPoints[this.editingPointIndex];
        if (!controlPoints) {
            return;
        }
        this.removeControlPointGraphics();
        // 绘制两个控制点中间的连线
        const line = new Graphics();
        line.zIndex = Z_INDEX.ControlPointLine;
        line.clear().lineStyle(DEFAULT_ELEMENT_LINE_STYLE);
        this.controlPointGraphics.push(line);
        this.addChild(line);
        // 绘制两个控制点
        for (let i = 0; i < controlPoints.length; i++) {
            const curPoint = controlPoints[i];
            const graphics = new Graphics();
            graphics.zIndex = Z_INDEX.ControlPoint;
            this.controlPointGraphics.push(graphics);
            this.addChild(graphics);
            drawPointOnGraphics(graphics, {
                position: curPoint,
                color: PAINT_COLOR.ControlPoint,
            });
            graphics.interactive = true;
            graphics.on('pointerdown', this.onControlPointPointerDown.bind(this));
            graphics.on('pointermove', this.onControlPointPointerMove.bind(this));
            graphics.on('pointerup', this.onControlPointPointerUp.bind(this));
            if (i === 0) {
                line.moveTo(curPoint.x, curPoint.y);
            }
            else {
                line.lineTo(curPoint.x, curPoint.y);
            }
        }
    }
    onControlPointPointerDown(e) {
        e.stopPropagation();
        if (!(e.currentTarget instanceof Graphics)) {
            return;
        }
        // 绘制控制点的时候，第一个绘制的是中间的线，而不是两个控制点，所以在获取控制点下标的时候需要 -1
        this.editingControlPointIndex =
            this.controlPointGraphics.indexOf(e.currentTarget) - 1;
        this.on('pointerupoutside', this.onControlPointPointerUp);
    }
    onControlPointPointerMove(e) {
        if (this.editingPointIndex < 0 || this.editingControlPointIndex < 0) {
            return;
        }
        const point = e.data.getLocalPosition(this);
        // 更新两个控制点的坐标
        const controlPoints = this.controlPoints[this.editingPointIndex];
        if (controlPoints) {
            controlPoints[this.editingControlPointIndex] = point;
            const centerPoint = this.points[this.editingPointIndex];
            const symmetryPoint = getSymmetryPoint(point, centerPoint);
            const index = this.editingControlPointIndex === 0 ? 1 : 0;
            controlPoints[index] = symmetryPoint;
            this.controlPoints[this.editingPointIndex] = controlPoints;
        }
        this.draw();
        this.drawControlPoints();
        this.drawPoints();
    }
    onControlPointPointerUp(e) {
        e.stopPropagation();
        this.editingControlPointIndex = -1;
        this.off('pointerupoutside', this.onControlPointPointerUp);
        this.emit(DISPLAY_LIFE_CYCLE.DRAW_UPDATE, this);
    }
    // 区分单击还是双击
    onPointPointerDown(e) {
        e.stopPropagation();
        if (!(e.currentTarget instanceof Graphics)) {
            return;
        }
        this.movingPointIndex = this.pointGraphics.indexOf(e.currentTarget);
        this.editingPointIndex = this.movingPointIndex;
        this.drawPoints();
        this.removeControlPointGraphics();
        this.drawControlPoints();
        this.on('pointerupoutside', this.onPointPointerUp);
    }
    // 双击处理
    onPointDoubleClick(e) {
        if (!(e.currentTarget instanceof Graphics)) {
            return;
        }
        const pointIndex = this.pointGraphics.indexOf(e.currentTarget);
        if (pointIndex === -1) {
            return;
        }
        this.movingPointIndex = -1;
        this.editingPointIndex = pointIndex;
        const curPoint = this.points[this.editingPointIndex];
        let prePoint;
        let nextPoint;
        if (this.editingPointIndex === 0) {
            prePoint = this.points[this.points.length - 1];
        }
        else {
            prePoint = this.points[this.editingPointIndex - 1];
        }
        if (this.editingPointIndex === this.points.length - 1) {
            nextPoint = this.points[0];
        }
        else {
            nextPoint = this.points[this.editingPointIndex + 1];
        }
        this.removeControlPointsEvents();
        this.removeControlPointGraphics();
        if (!this.controlPoints[this.editingPointIndex]) {
            const { cp1, cp2 } = getControlPoints(prePoint, curPoint, nextPoint);
            this.controlPoints[this.editingPointIndex] = [cp1, cp2];
        }
        else {
            delete this.controlPoints[this.editingPointIndex];
        }
        this.draw();
        this.drawControlPoints();
        this.drawPoints();
    }
    onPointPointerMove(e) {
        if (this.movingPointIndex < 0) {
            return;
        }
        const originPoint = this.points[this.movingPointIndex];
        const point = e.data.getLocalPosition(this);
        this.points[this.movingPointIndex] = point;
        this.draw();
        this.removeControlPointGraphics();
        this.updateControlPoints(point, originPoint);
        this.drawPoints();
    }
    updateControlPoints(point, originPoint) {
        const deltaX = point.x - originPoint.x;
        const deltaY = point.y - originPoint.y;
        const controlPoint = this.controlPoints[this.movingPointIndex];
        if (!controlPoint) {
            return;
        }
        for (let index = 0; index < controlPoint.length; index++) {
            controlPoint[index].x += deltaX;
            controlPoint[index].y += deltaY;
        }
        this.controlPoints[this.movingPointIndex] = controlPoint;
        this.drawControlPoints();
    }
    onPointPointerUp(e) {
        e.stopPropagation();
        this.movingPointIndex = -1;
        this.emit(DISPLAY_LIFE_CYCLE.DRAW_UPDATE, this);
        this.off('pointerupoutside', this.onPointPointerUp);
    }
    removePointsEvents() {
        this.pointGraphics.forEach((graphics) => {
            removePointerEvents(graphics);
        });
    }
    removeControlPointsEvents() {
        this.controlPointGraphics.forEach((graphics) => {
            removePointerEvents(graphics);
        });
    }
    removePointGraphics() {
        this.pointGraphics.forEach((graphics) => {
            graphics.destroy();
        });
        this.pointGraphics = [];
    }
    removeControlPointGraphics() {
        this.controlPointGraphics.forEach((graphics) => {
            graphics.destroy();
        });
        this.controlPointGraphics = [];
    }
    containsPoint(p) {
        return this.lineGraphics.containsPoint(p);
    }
    setupHitArea() {
        if (isSkPath(this.hitArea)) {
            return;
        }
        const ctrlPtLength = Object.values(this.controlPoints).length;
        if (this.points.length === 2 && !ctrlPtLength) {
            const [p1, p2] = this.points;
            this.lineGraphics.addLineHitArea(p1, p2);
            this.hitArea = this.lineGraphics.hitArea;
        }
        else {
            if (!this.lineGraphics.enableFill) {
                this.lineGraphics.addHitArea();
                this.hitArea = this.lineGraphics.hitArea;
            }
        }
    }
    /**
     * @TODO: 后续做矢量编辑功能优化时替换掉drawLine()和drawLineWithControlPoints()
     */
    *generateCommands() {
        // 移动到第一个点
        const firstPoint = this.points[0];
        yield { type: 'M', values: [firstPoint.x, firstPoint.y] };
        // 处理第二个点到最后一个点
        for (let index = 1; index < this.points.length; index++) {
            const curPoint = this.points[index];
            const controlPoints = this.controlPoints[index];
            const preControlPoints = this.controlPoints[index - 1];
            yield this.controlPtsToCommand(controlPoints, preControlPoints, curPoint);
        }
        if (this.isClosePath) {
            // 处理第一个点
            const controlPoints = this.controlPoints[0];
            // 第一个点的“前一个点”是最后一个点
            const preControlPoints = this.controlPoints[this.points.length - 1];
            yield this.controlPtsToCommand(controlPoints, preControlPoints, firstPoint);
        }
    }
    controlPtsToCommand(controlPoints, preControlPoints, curPoint) {
        const hasPreControlPoint = preControlPoints && preControlPoints.length > 1;
        if (controlPoints) {
            if (hasPreControlPoint) {
                // 前面的点有控制点 & 当前点也有控制点，则用三次贝塞尔曲线
                return {
                    type: 'C',
                    values: [
                        preControlPoints[1].x,
                        preControlPoints[1].y,
                        controlPoints[0].x,
                        controlPoints[0].y,
                        curPoint.x,
                        curPoint.y,
                    ],
                };
            }
            else {
                // 只有当前点有控制点，则用二次贝塞尔曲线
                return {
                    type: 'Q',
                    values: [
                        controlPoints[0].x,
                        controlPoints[0].y,
                        curPoint.x,
                        curPoint.y,
                    ],
                };
            }
        }
        else {
            if (hasPreControlPoint) {
                // 只有前面的点有控制点，则用二次贝塞尔曲线
                return {
                    type: 'Q',
                    values: [
                        preControlPoints[1].x,
                        preControlPoints[1].y,
                        curPoint.x,
                        curPoint.y,
                    ],
                };
            }
            else {
                // 无控制点，则用直线
                return {
                    type: 'L',
                    values: [curPoint.x, curPoint.y],
                };
            }
        }
    }
    // @ts-ignore
    getSVGData(options) {
        const fillAndStrokeAttrs = getSVGFillAndStrokeAttrs(this, options);
        return merge(super.getSVGData(options), {
            tag: 'path',
            attrs: {
                ...fillAndStrokeAttrs,
                d: getSVGPathDAttrByCommands(this.generateCommands()),
            },
        });
    }
}
