import { COMPUTE_INTERVAL } from './const';
import { Input } from './Input';
import { getCenter, getDirection, getPointsAngle, getPointsDistance, getRotation, getScale, getVelocity, simpleCloneInput, } from './Utils';
// 计算识别手势所需要的 Input 数据，比如角度、速度、大小等
export default function computeInputData(session, input) {
    const pointersLength = input.pointers.length;
    const pointers = input.pointers;
    // 记录第一个 Input，用于计算距离和方向
    if (!session.firstInput) {
        session.firstInput = simpleCloneInput(input);
    }
    // 计算 scale 和 rotation，需要记录多个触摸事件
    if (pointersLength > 1 && !session.firstMultiple) {
        session.firstMultiple = simpleCloneInput(input);
    }
    else if (pointersLength === 1) {
        session.firstMultiple = null;
    }
    const { firstInput, firstMultiple } = session;
    // time
    input.timeStamp = Date.now();
    input.deltaTime = input.timeStamp - firstInput.timeStamp;
    // center
    const firstCenter = firstMultiple !== null ? firstMultiple.center : firstInput.center;
    const curCenter = (input.center = getCenter(pointers));
    input.angle = getPointsAngle(firstCenter, curCenter);
    input.distance = getPointsDistance(firstCenter, curCenter);
    // delta
    computeDeltaXY(session, input);
    input.offsetDirection = getDirection(input.deltaX, input.deltaY);
    const overallVelocity = getVelocity(input.deltaTime, input.deltaX, input.deltaY);
    input.overallVelocityX = overallVelocity.x;
    input.overallVelocityY = overallVelocity.y;
    input.overallVelocity =
        Math.abs(overallVelocity.x) > Math.abs(overallVelocity.y)
            ? overallVelocity.x
            : overallVelocity.y;
    // pointers
    input.scale = getScale(firstMultiple ? firstMultiple.pointers : undefined, pointers);
    const prevScale = session.prevScale ?? input.scale;
    input.deltaScale = input.scale - prevScale;
    session.prevScale = input.scale;
    input.rotation = getRotation(firstMultiple ? firstMultiple.pointers : undefined, pointers);
    input.maxPointers = !session.prevInput
        ? input.pointers.length
        : input.pointers.length > session.prevInput.maxPointers
            ? input.pointers.length
            : session.prevInput.maxPointers;
    // Interval
    computeIntervalInputData(session, input);
}
function computeDeltaXY(session, input) {
    const { center } = input;
    let offset = session.offsetDelta || { x: 0, y: 0 };
    let prevDelta = session.prevDelta || { x: 0, y: 0 };
    const prevInput = session.prevInput || new Input();
    if (input.eventType === 1 /* INPUT.START */ || prevInput.eventType === 4 /* INPUT.END */) {
        prevDelta = session.prevDelta = {
            x: prevInput.deltaX || 0,
            y: prevInput.deltaY || 0,
        };
        offset = session.offsetDelta = {
            x: center.x,
            y: center.y,
        };
    }
    input.deltaX = prevDelta.x + (center.x - offset.x);
    input.deltaY = prevDelta.y + (center.y - offset.y);
}
function computeIntervalInputData(session, input) {
    const last = session.lastInterval || input;
    const deltaTime = input.timeStamp - last.timeStamp;
    let velocity;
    let velocityX;
    let velocityY;
    let direction;
    if (input.eventType !== 8 /* INPUT.CANCEL */ &&
        (deltaTime > COMPUTE_INTERVAL || last.velocity === undefined)) {
        const deltaX = input.deltaX - last.deltaX;
        const deltaY = input.deltaY - last.deltaY;
        const v = getVelocity(deltaTime, deltaX, deltaY);
        velocityX = v.x;
        velocityY = v.y;
        velocity = Math.abs(v.x) > Math.abs(v.y) ? v.x : v.y;
        direction = getDirection(deltaX, deltaY);
        session.lastInterval = input;
    }
    else {
        // use latest velocity info if it doesn't overtake a minimum period
        velocity = last.velocity;
        velocityX = last.velocityX;
        velocityY = last.velocityY;
        direction = last.direction;
    }
    input.velocity = velocity;
    input.velocityX = velocityX;
    input.velocityY = velocityY;
    input.direction = direction;
}
