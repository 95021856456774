import { Graphics, Point } from 'pixi.js';
import { DEFAULT_SELECTOR_CONFIG } from '../config';
import { VIEW_EVENT } from '../types';
export class Selector extends Graphics {
    config;
    constructor(style = DEFAULT_SELECTOR_CONFIG) {
        super();
        this.config = style;
        this.visible = false;
        this.name = 'SELECTOR';
    }
    start(point) {
        this.visible = true;
        this.scale.set(0.01, 0.01);
        // this.emit(VIEW_EVENT.SELECT_VIEWPORT_ELEMENTS);
        this.clear();
        this.beginFill(this.config.fillColor, this.config.fillAlpha)
            .drawRect(0, 0, 1, 1)
            .endFill();
        this.position.set(point.x, point.y);
    }
    update(point) {
        if (this.visible) {
            const { x, y } = this.position;
            const { x: ex, y: ey } = point;
            this.scale.set(ex - x, ey - y);
        }
    }
    end(point, e) {
        // 当鼠标在画布之外再抬起 时会主动结束，当点击了与画布有交集的dom节点时也会触发，例如一些操作的下拉框，添加判断条件执行，避免其他异常情况，
        if (this.visible) {
            // 以global的偏移值作为判定是否单击的标准
            const delta = 5;
            const { width, height } = this.getBounds();
            // 鼠标移动小于 delta 值就认为是单击
            if (Math.abs(width) > delta && Math.abs(height) > delta) {
                this.emit(VIEW_EVENT.SELECT_VIEWPORT_ELEMENTS, new Point(this.position.x, this.position.y), point, e);
            }
        }
        this.done();
    }
    done() {
        this.visible = false;
        this.scale.set(0.01, 0.01);
    }
}
