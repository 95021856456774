import { Bounds, Matrix, Rectangle } from 'pixi.js';
import dParser from '../../../packages/svgscene/utils/parser';
import { getImageSourceSize, getMatrixArray } from '../../../utils';
export function canvasDrawPath(ctx, d) {
    // Parse path commands using d-path-parser. This is an inefficient solution that causes excess memory allocation
    // and should be optimized in the future.
    const commands = dParser(d.trim());
    // Current point
    let x = 0;
    let y = 0;
    const startPt = { x: 0, y: 0 };
    for (let i = 0, j = commands.length; i < j; i++) {
        const lastCommand = commands[i - 1];
        const command = commands[i];
        if (isNaN(x) || isNaN(y)) {
            throw new Error('Data corruption');
        }
        // Taken from: https://github.com/bigtimebuddy/pixi-svg/blob/main/src/SVG.js
        // Copyright Matt Karl
        switch (command.code) {
            case 'M':
            case 'm': {
                if (command.code === 'M') {
                    (x = command.end.x), (y = command.end.y);
                }
                else {
                    (x += command.end.x), (y += command.end.y);
                }
                startPt.x = x;
                startPt.y = y;
                ctx.moveTo(x, y);
                break;
            }
            case 'H': {
                ctx.lineTo((x = command.value), y);
                break;
            }
            case 'h': {
                ctx.lineTo((x += command.value), y);
                break;
            }
            case 'V': {
                ctx.lineTo(x, (y = command.value));
                break;
            }
            case 'v': {
                ctx.lineTo(x, (y += command.value));
                break;
            }
            case 'z':
            case 'Z': {
                x = startPt.x || 0;
                y = startPt.y || 0;
                ctx.closePath();
                break;
            }
            case 'L': {
                ctx.lineTo((x = command.end.x), (y = command.end.y));
                break;
            }
            case 'l': {
                ctx.lineTo((x += command.end.x), (y += command.end.y));
                break;
            }
            case 'C': {
                ctx.bezierCurveTo(command.cp1.x, command.cp1.y, command.cp2.x, command.cp2.y, (x = command.end.x), (y = command.end.y));
                break;
            }
            case 'c': {
                const currX = x;
                const currY = y;
                ctx.bezierCurveTo(currX + command.cp1.x, currY + command.cp1.y, currX + command.cp2.x, currY + command.cp2.y, (x += command.end.x), (y += command.end.y));
                break;
            }
            case 's':
            case 'S': {
                const cp1 = { x, y };
                const lastCode = commands[i - 1] ? commands[i - 1].code : null;
                if (i > 0 &&
                    (lastCode === 's' ||
                        lastCode === 'S' ||
                        lastCode === 'c' ||
                        lastCode === 'C')) {
                    const lastCommand = commands[i - 1];
                    const lastCp2 = { ...(lastCommand.cp2 || lastCommand.cp) };
                    if (commands[i - 1].relative) {
                        lastCp2.x += x - lastCommand.end.x;
                        lastCp2.y += y - lastCommand.end.y;
                    }
                    cp1.x = 2 * x - lastCp2.x;
                    cp1.y = 2 * y - lastCp2.y;
                }
                const cp2 = { x: command.cp.x, y: command.cp.y };
                if (command.relative) {
                    cp2.x += x;
                    cp2.y += y;
                    x += command.end.x;
                    y += command.end.y;
                }
                else {
                    x = command.end.x;
                    y = command.end.y;
                }
                ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, x, y);
                break;
            }
            case 'q': {
                const currX = x;
                const currY = y;
                ctx.quadraticCurveTo(currX + command.cp.x, currY + command.cp.y, (x += command.end.x), (y += command.end.y));
                break;
            }
            case 'Q': {
                ctx.quadraticCurveTo(command.cp.x, command.cp.y, (x = command.end.x), (y = command.end.y));
                break;
            }
            case 'A':
            case 'a':
                const lastX = x;
                const lastY = y;
                if (command.code === 'A') {
                    (x = command.end.x), (y = command.end.y);
                }
                else {
                    (x += command.end.x), (y += command.end.y);
                }
                canvasEllipticArcTo(ctx, lastX, lastY, x, y, command.radii.x, command.radii.y, ((command.rotation || 0) * Math.PI) / 180, !command.clockwise, command.large);
                break;
            case 't':
            case 'T': {
                let cx;
                let cy;
                if (lastCommand && lastCommand.cp) {
                    let lcx = lastCommand.cp.x;
                    let lcy = lastCommand.cp.y;
                    if (lastCommand.relative) {
                        const lx = x - lastCommand.end.x;
                        const ly = y - lastCommand.end.y;
                        lcx += lx;
                        lcy += ly;
                    }
                    cx = 2 * x - lcx;
                    cy = 2 * y - lcy;
                }
                else {
                    cx = x;
                    cy = y;
                }
                if (command.code === 't') {
                    ctx.quadraticCurveTo(cx, cy, (x += command.end.x), (y += command.end.y));
                }
                else {
                    ctx.quadraticCurveTo(cx, cy, (x = command.end.x), (y = command.end.y));
                }
                break;
            }
            default: {
                console.warn('Draw command not supported:', command.code, command);
                break;
            }
        }
    }
}
// type EllipticArcFn = (params: {
//   cx: number;
//   cy: number;
//   rx: number;
//   ry: number;
//   rotation: number;
//   startAngle: number;
//   endAngle: number;
//   anticlockwise: boolean;
// }) => void;
export function canvasEllipticArcTo(ctx, startX, startY, endX, endY, rx, ry, xAxisRotation = 0, anticlockwise = false, largeArc = false) {
    const { cx, cy, startAngle, endAngle } = arcEndpointParamsToCenterParams(startX, startY, endX, endY, rx, ry, xAxisRotation, anticlockwise, largeArc);
    // draw({
    //   cx,
    //   cy,
    //   rx,
    //   ry,
    //   xAxisRotation,
    //   startAngle,
    //   endAngle,
    //   anticlockwise,
    // });
    ctx.ellipse(cx, cy, rx, ry, xAxisRotation, startAngle, endAngle, anticlockwise);
}
export function arcEndpointParamsToCenterParams(startX, startY, endX, endY, rx, ry, xAxisRotation = 0, anticlockwise = false, largeArc = false) {
    // See https://www.w3.org/TR/SVG2/implnote.html#ArcImplementationNotes
    // const points = this.currentPath.points;
    // const startX = points[points.length - 2];
    // const startY = points[points.length - 1];
    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;
    // Transform into a rotated frame with the origin at the midpoint.
    const matrix = new Matrix()
        .identity()
        .translate(-midX, -midY)
        .rotate(-xAxisRotation);
    const { x: xRotated, y: yRotated } = matrix.apply({ x: startX, y: startY });
    const a = Math.pow(xRotated / rx, 2) + Math.pow(yRotated / ry, 2);
    if (a > 1) {
        // Ensure radii are large enough to connect start to end point.
        rx = Math.sqrt(a) * rx;
        ry = Math.sqrt(a) * ry;
    }
    const rx2 = rx * rx;
    const ry2 = ry * ry;
    // Calculate the center of the ellipse in this rotated space.
    // See implementation notes for the equations: https://svgwg.org/svg2-draft/implnote.html#ArcImplementationNotes
    const sgn = anticlockwise === largeArc ? 1 : -1;
    const coef = sgn *
        Math.sqrt(
        // use Math.abs to prevent numerical imprecision from creating very small -ve
        // values (which should be zero instead). Otherwise, NaNs are possible
        Math.abs(rx2 * ry2 - rx2 * yRotated * yRotated - ry2 * xRotated * xRotated) /
            (rx2 * yRotated * yRotated + ry2 * xRotated * xRotated));
    const cxRotated = coef * ((rx * yRotated) / ry);
    const cyRotated = -coef * ((ry * xRotated) / rx);
    // Calculate the center of the ellipse back in local space.
    const { x: cx, y: cy } = matrix.applyInverse({
        x: cxRotated,
        y: cyRotated,
    });
    // Calculate startAngle
    const x1Norm = (xRotated - cxRotated) / rx;
    const y1Norm = (yRotated - cyRotated) / ry;
    const dist1Norm = Math.sqrt(x1Norm ** 2 + y1Norm ** 2);
    const startAngle = (y1Norm >= 0 ? 1 : -1) * Math.acos(x1Norm / dist1Norm);
    // Calculate endAngle
    const x2Norm = (-xRotated - cxRotated) / rx;
    const y2Norm = (-yRotated - cyRotated) / ry;
    const dist2Norm = Math.sqrt(x2Norm ** 2 + y2Norm ** 2);
    let endAngle = (y2Norm >= 0 ? 1 : -1) * Math.acos(x2Norm / dist2Norm);
    // Ensure endAngle is on the correct side of startAngle
    if (endAngle > startAngle && anticlockwise) {
        endAngle -= Math.PI * 2;
    }
    else if (startAngle > endAngle && !anticlockwise) {
        endAngle += Math.PI * 2;
    }
    return {
        cx,
        cy,
        rx,
        ry,
        startAngle,
        endAngle,
        xAxisRotation,
        anticlockwise,
    };
}
/**
 * 绘制位图到透明背景canvas并添加剪切路径，返回绘制后的canvas
 * Note: clip模式下，直线类图形变成全空白，composition模式不存在此问题
 * @param img
 * @param clipPath
 * @returns
 */
export function drawImageWithClipPath(img, clipPath, options) {
    options = options ?? {};
    const { imgRotation = 0, mode = 'composition' } = options;
    let { imgLocalBounds } = options;
    const { width, height } = getImageSourceSize(img);
    // 计算canvas的size和transform
    imgLocalBounds = imgLocalBounds ?? new Rectangle(0, 0, width, height);
    const canvasBounds = new Bounds();
    const angle = imgRotation;
    const matrix = new Matrix().rotate(angle);
    canvasBounds.addFrameMatrix(matrix, imgLocalBounds.x, imgLocalBounds.y, imgLocalBounds.x + imgLocalBounds.width, imgLocalBounds.y + imgLocalBounds.height);
    const pos = { x: -canvasBounds.minX, y: -canvasBounds.minY };
    const canvasWidth = canvasBounds.maxX - canvasBounds.minX;
    const canvasHeight = canvasBounds.maxY - canvasBounds.minY;
    const clipCanvas = document.createElement('canvas');
    clipCanvas.width = canvasWidth;
    clipCanvas.height = canvasHeight;
    const clipCtx = clipCanvas.getContext('2d');
    // canvas transform
    clipCtx.translate(pos.x, pos.y);
    clipCtx.rotate(angle);
    clipCtx.beginPath();
    canvasDrawPath(clipCtx, clipPath.toString());
    if (mode === 'clip') {
        clipCtx.clip();
    }
    else {
        clipCtx.strokeStyle = 'rgba(0, 0, 0, 1)';
        clipCtx.stroke();
        clipCtx.fillStyle = 'rgba(0, 0, 0, 1)';
        clipCtx.fill();
        clipCtx.globalCompositeOperation = 'source-in';
    }
    clipCtx.drawImage(img, 0, 0);
    return clipCanvas;
}
export function drawImageMatrix(img, bounds, transform, clipPath) {
    const canvas = document.createElement('canvas');
    canvas.width = bounds.width;
    canvas.height = bounds.height;
    const ctx = canvas.getContext('2d');
    ctx.translate(-bounds.x, -bounds.y);
    ctx.fillStyle = 'rgba(0, 0, 0, 0)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    if (clipPath) {
        ctx.beginPath();
        canvasDrawPath(ctx, clipPath.toString());
        ctx.clip();
    }
    if (transform) {
        const matArr = getMatrixArray(transform);
        // @ts-ignore
        ctx.transform(...matArr);
    }
    ctx.drawImage(img, 0, 0);
    ctx.resetTransform();
    return canvas;
}
