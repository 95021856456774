import { Transformer } from '@makeblock/transformer';
import { OrientedBounds } from '@pixi-essentials/bounds';
import { Group, Layer } from '@pixi/layers';
import { isNumber } from 'lodash-es';
import { Viewport } from 'pixi-viewport';
import { Container, Graphics } from 'pixi.js';
import { MLine, MPen, MSprite } from '../../display';
import { BG_ALIGN_TYPE, ViewportAlignOption, VIEWPORT_EVENT, XAlignOption, YAlignOption, } from '../../types';
import { calcIntersect, calculateHitAreaCorners, createFitSpriteCanvas, deepDestroy, getDisplaysBounds, recurseDisplay, } from '../../utils';
import { Grid } from './grid';
import { Assistant } from './local-assistant';
import { DragWins } from './plugins/DragWins';
const createBackdrop = (width, height, options) => {
    const { backgroundColor, backgroundAlpha, outlineColor, outlineAlpha, outlineWidth, outlineNative, } = options;
    const backdrop = new Graphics();
    backdrop.name = 'BACKDROP';
    backdrop
        .lineStyle({
        width: outlineWidth,
        native: outlineNative,
        color: outlineColor,
        alpha: outlineAlpha,
        alignment: 0,
    })
        .beginFill(backgroundColor, backgroundAlpha)
        .drawRect(0, 0, width, height)
        .endFill();
    return backdrop;
};
/**
 * 编辑器视窗
 *
 * @date 02/12/2021
 * @class MViewport
 * @extends {Viewport}
 */
export class MViewport extends Viewport {
    /**
     * 视窗背景
     */
    #backdrop;
    /**
     * 顶端图层
     */
    // #frontEndLayer: Layer;
    // #backEndLayer: Layer;
    // #frontEndGroup: Group;
    // #backEndGroup: Group;
    #backgroundImage;
    #localBgImage;
    #localBgContainer = new Container();
    #localBgBounds = new Graphics();
    #bgContainer = new Container();
    bgLayer = new Container();
    #bgMask = new Graphics();
    // 近景摄像头拍摄辅助定位框
    #assistantEle;
    #grid;
    displayGroup = new Group(0, true);
    displayLayer;
    #fitOffsetRatio;
    #align;
    #offsetX;
    #offsetY;
    #offsetBottom;
    // selector: Selector;
    /**
     * Creates an instance of MViewport.
  
     * @date 02/12/2021
     * @param {ViewportConfig} options
     */
    constructor(options) {
        super(options);
        // screenWidth, screenHeight,
        const { worldWidth, worldHeight, minScale, maxScale, fitOffsetRatio, align = ViewportAlignOption.CENTER, offsetX = 0, offsetY = 0, offsetBottom = 0, } = options;
        this.#backdrop = createBackdrop(worldWidth || 0, worldHeight || 0, options);
        this.#fitOffsetRatio = fitOffsetRatio;
        this.#offsetX = offsetX;
        this.#offsetY = offsetY;
        this.#offsetBottom = offsetBottom;
        this.#align = align;
        this.pinch({
            factor: 1,
            noDrag: false,
        });
        // 设置最大和最小缩放比例
        this.clampZoom({
            minScale: minScale,
            maxScale: maxScale,
        });
        // this.input.clear();
        this.#grid = new Grid(Object.assign({ width: worldWidth || 0, height: worldHeight || 0 }, options.grid));
        this.addChild(this.bgLayer, this.#grid);
        this.#assistantEle = new Assistant();
        this.sortableChildren = true;
        // 防止在获取背景截图时会移动层级，将背景固定在最低层中
        this.bgLayer.zIndex = -1;
        this.bgLayer.addChild(this.#backdrop, this.#bgContainer, this.#bgMask);
        this.setupLocalBgContainer();
        this.align(align);
        this.setupDisplayLayer();
        this.setupBgContainer();
        this.addChild(this.#assistantEle);
    }
    setupLocalBgContainer() {
        this.#bgContainer.addChild(this.#localBgContainer);
        this.#localBgContainer.addChild(this.#localBgBounds);
        this.#localBgContainer.name = 'localBgContainer';
        this.#localBgContainer.visible = false;
    }
    setupBgContainer() {
        this.#bgMask
            .beginFill(0x000000, 0.5)
            .drawRect(0, 0, this.worldWidth, this.worldHeight)
            .endFill();
        this.#bgContainer.mask = this.#bgMask;
    }
    setupDisplayLayer() {
        this.displayLayer = new Layer(this.displayGroup);
        this.displayLayer.name = 'displayLayer';
        // 让 #displayLayer 里默认有一个元素，防止  #displayLayer 被 destroy 掉
        const layerContainer = new Container();
        layerContainer.name = 'layerContainer';
        layerContainer.addChild(this.displayLayer);
        this.displayLayer.addChild(new Layer());
        this.addChild(layerContainer);
    }
    toWheelMode() {
        this.cursor = 'default';
        const dragOptions = {
            pressDrag: false,
            ignoreKeyToPressOnTouch: true,
        };
        if (globalThis.navigator?.platform === 'Win32') {
            this.plugins.add('drag', new DragWins(this, dragOptions));
        }
        else {
            this.drag(dragOptions);
        }
        this.wheel({
            wheelZoom: false,
            trackpadPinch: true,
        });
    }
    toDragMode() {
        this.cursor = 'grab';
        // this.plugins.remove('wheel');
        this.drag({
            wheel: false,
            pressDrag: true,
        });
    }
    toZoomMode() { }
    alignment(xAlign, yAlign) {
        let targetX = 0;
        let targetY = 0;
        switch (xAlign) {
            case XAlignOption.LEFT:
                targetX = this.screenWidth * this.#fitOffsetRatio + this.#offsetX;
                break;
            case XAlignOption.CENTER:
                targetX =
                    (this.screenWidth - this.screenWorldWidth - this.#offsetX) / 2 +
                        this.#offsetX;
                break;
            case XAlignOption.RIGHT:
            default:
                targetX =
                    this.screenWidth -
                        this.screenWorldWidth -
                        this.screenWidth * this.#fitOffsetRatio;
                break;
        }
        switch (yAlign) {
            case YAlignOption.TOP:
                targetY = this.screenHeight * this.#fitOffsetRatio + this.#offsetY;
                break;
            case YAlignOption.CENTER:
                targetY =
                    (this.screenHeight -
                        this.screenWorldHeight -
                        this.#offsetY -
                        this.#offsetBottom) /
                        2 +
                        this.#offsetY;
                break;
            case YAlignOption.BOTTOM:
            default:
                targetY =
                    this.screenHeight -
                        this.#offsetBottom -
                        this.screenHeight * this.#fitOffsetRatio -
                        this.screenWorldHeight;
                break;
        }
        this.position.set(targetX, targetY);
    }
    fit(center, width = this.worldWidth, height = this.worldHeight) {
        let save;
        if (center) {
            save = this.center;
        }
        const paddingX = this.screenWidth * this.#fitOffsetRatio + this.#offsetX;
        const paddingY = this.screenHeight * this.#fitOffsetRatio +
            this.#offsetY +
            this.#offsetBottom;
        this.scale.x = (this.screenWidth - paddingX) / width;
        this.scale.y = (this.screenHeight - paddingY) / height;
        if (this.scale.x < this.scale.y) {
            this.scale.y = this.scale.x;
        }
        else {
            this.scale.x = this.scale.y;
        }
        const clampZoom = this.plugins.get('clamp-zoom', true);
        if (clampZoom) {
            clampZoom.clamp();
        }
        if (center && save) {
            this.moveCenter(save);
        }
        return this;
    }
    fitWidth(width = this.worldWidth, center, scaleY = true, noClamp) {
        let save;
        if (center) {
            save = this.center;
        }
        const paddingX = this.screenWidth * this.#fitOffsetRatio * 2 + this.#offsetX;
        this.scale.x = (this.screenWidth - paddingX) / width;
        if (scaleY) {
            this.scale.y = this.scale.x;
        }
        const clampZoom = this.plugins.get('clamp-zoom', true);
        if (!noClamp && clampZoom) {
            clampZoom.clamp();
        }
        if (center && save) {
            this.moveCenter(save);
        }
        return this;
    }
    fitHeight(height = this.worldHeight, center, scaleX = true, noClamp) {
        let save;
        if (center) {
            save = this.center;
        }
        const paddingY = this.screenHeight * this.#fitOffsetRatio * 2 +
            this.#offsetY +
            this.#offsetBottom;
        this.scale.y = (this.screenHeight - paddingY) / height;
        if (scaleX) {
            this.scale.x = this.scale.y;
        }
        const clampZoom = this.plugins.get('clamp-zoom', true);
        if (!noClamp && clampZoom) {
            clampZoom.clamp();
        }
        if (center && save) {
            this.moveCenter(save);
        }
        return this;
    }
    addToLayer(object) {
        object.parentGroup = this.displayGroup;
        return this.displayLayer.addChild(object);
    }
    clearLayer() {
        const displays = this.getInteractiveElements();
        displays.forEach((display) => {
            deepDestroy(display);
        });
    }
    setupAssistantEle(options) {
        const { clearFn, mask, confirm } = this.#assistantEle.setup(this.worldWidth, this.worldHeight, options);
        // 记录当前的mask以便后面还原
        const axisMaskEl = this.getChildByName('AXIS_MASK');
        const axisMask = axisMaskEl ? axisMaskEl.mask : null;
        const gridMask = this.#grid.mask;
        const bgLayerMask = this.bgLayer.mask;
        axisMaskEl && (axisMaskEl.mask = mask);
        this.#grid.mask = mask;
        this.bgLayer.mask = mask;
        /**
         * @param isConfirm - 取消前是否执行近景拍照
         *
         */
        return (isConfirm) => {
            this.#grid.mask = gridMask;
            this.bgLayer.mask = bgLayerMask;
            axisMaskEl && (axisMaskEl.mask = axisMask);
            if (isConfirm) {
                confirm();
            }
            clearFn();
        };
    }
    setLocalBackgroundImage(img, options) {
        this.#localBgImage?.destroy({ texture: true });
        this.#localBgImage = null;
        this.#localBgContainer.visible = false;
        if (img && img instanceof HTMLImageElement) {
            const sprite = new MSprite(img);
            sprite.filters = [];
            sprite.interactive = false;
            const { x = 0, y = 0, angle = 0, width = 140, height = 100, boundColor = 0xff0000, boundAlpha = 0.5, } = options;
            sprite.width = width;
            sprite.height = height;
            this._redrawLocalBgBounds(this.worldWidth, this.worldHeight, boundColor, boundAlpha);
            sprite.x = x;
            sprite.y = y;
            sprite.angle = angle;
            this.#localBgImage = sprite;
            this.#localBgContainer.addChild(sprite);
            this.#localBgContainer.visible = true;
        }
    }
    updateLocalBackgroundImage(options) {
        if (this.#localBgImage) {
            const { x = this.#localBgImage.x, y = this.#localBgImage.y, angle = this.#localBgImage.angle, width = this.#localBgImage.width, height = this.#localBgImage.height, boundColor = 0xff0000, boundAlpha = 0.5, } = options;
            this.#localBgImage.x = x;
            this.#localBgImage.y = y;
            this.#localBgImage.angle = angle;
            this.#localBgImage.width = width;
            this.#localBgImage.height = height;
            this._redrawLocalBgBounds(this.worldWidth, this.worldHeight, boundColor, boundAlpha);
        }
    }
    setLocalBackdropVisible(show) {
        this.#localBgContainer.visible = show;
    }
    _redrawLocalBgBounds(w, h, color = 0xffffff, alpha = 0.5) {
        const b = this.#localBgBounds;
        // b.lineStyle(2, color);
        b.clear();
        b.beginFill(color, alpha);
        b.drawRect(0, 0, w, h);
        b.endFill();
    }
    set backgroundImage(img) {
        // 更新背景图先销毁原来的图片
        this.#backgroundImage?.destroy({ texture: true });
        this.#backgroundImage = null;
        if (img && img instanceof HTMLImageElement) {
            const sprite = new MSprite(img);
            sprite.filters = [];
            sprite.interactive = false;
            this.#backgroundImage = sprite;
            this.#bgContainer.addChildAt(sprite, 0);
        }
    }
    get backgroundSprite() {
        return this.#backgroundImage;
    }
    get bgContainer() {
        return this.#bgContainer;
    }
    // 适应当前 viewport 尺寸的背景图 imageDatab
    get backgroundImageData() {
        const result = createFitSpriteCanvas(this.#backgroundImage, this.#backgroundImage.width, this.#backgroundImage.height);
        if (!result) {
            return null;
        }
        const { canvas, ctx } = result;
        return ctx.getImageData(0, 0, canvas.width, canvas.height);
    }
    align(type) {
        const { worldWidth, worldHeight } = this;
        const alignType = this.#align || type;
        // 适应窗口
        switch (alignType) {
            case ViewportAlignOption.TOP_LEFT:
                this.fit(true, worldWidth, worldHeight);
                this.position.set(this.#offsetX, this.#offsetY);
                break;
            case ViewportAlignOption.WIDTH:
                this.fitWidth(worldWidth, false);
                this.alignment(XAlignOption.CENTER, YAlignOption.TOP);
                break;
            case ViewportAlignOption.HEIGHT:
                this.fitHeight(worldHeight, false);
                this.alignment(XAlignOption.LEFT, YAlignOption.CENTER);
                break;
            case ViewportAlignOption.AUTO:
            case ViewportAlignOption.CENTER:
            default:
                this.fit(true, worldWidth, worldHeight);
                this.alignment(XAlignOption.CENTER, YAlignOption.CENTER);
                break;
        }
        this.emit(VIEWPORT_EVENT.ZOOMED, { viewport: this });
    }
    destroyBackgroundImage() {
        this.#backgroundImage?.destroy({ texture: true });
        this.#backgroundImage = null;
    }
    bgMaskSize;
    updateBackgroundImage(options, img) {
        if (img) {
            this.backgroundImage = img;
        }
        const bg = this.#backgroundImage;
        if (options && bg) {
            this.fitBGToCenter();
            const { align = BG_ALIGN_TYPE.TOP_LEFT, offsetX, offsetY, width = bg.width, height = bg.height, bgMaskSize, } = options;
            this.bgMaskSize = bgMaskSize;
            // 更新背景时同时更新对应的裁切尺寸，默认是覆盖整个viewport
            this.#bgMask.width = bgMaskSize?.width ?? this.worldWidth;
            this.#bgMask.height = bgMaskSize?.height ?? this.worldHeight;
            bg.width = width;
            bg.height = height;
            bg.parseJSON(options);
            const { angle = bg.angle } = options;
            let deltaX = width - this.worldWidth;
            let deltaY = height - this.worldHeight;
            if (angle % 360 === 90 || angle % 360 === 270) {
                deltaX = height - this.worldWidth;
                deltaY = width - this.worldHeight;
            }
            const offsetMap = {
                [BG_ALIGN_TYPE.TOP_LEFT]: {
                    x: deltaX / 2,
                    y: deltaY / 2,
                },
                [BG_ALIGN_TYPE.BOTTOM_LEFT]: {
                    x: deltaX / 2,
                    y: -deltaY / 2,
                },
                [BG_ALIGN_TYPE.TOP_RIGHT]: {
                    x: -deltaX / 2,
                    y: deltaY / 2,
                },
                [BG_ALIGN_TYPE.BOTTOM_RIGHT]: {
                    x: -deltaX / 2,
                    y: -deltaY / 2,
                },
            };
            const offset = offsetMap[align];
            if (offset) {
                bg.position.x += offset.x;
                bg.position.y += offset.y;
            }
            if (isNumber(offsetX)) {
                bg.position.x += offsetX;
            }
            if (isNumber(offsetY)) {
                bg.position.y += offsetY;
            }
        }
    }
    fitBGToCenter() {
        const sprite = this.#backgroundImage;
        if (sprite) {
            sprite.anchor.set(0.5, 0.5);
            sprite.position.x = this.worldWidth / 2;
            sprite.position.y = this.worldHeight / 2;
        }
    }
    isOutSide(display, range, tolerance = 0.001) {
        const { minX, minY, maxX, maxY } = getDisplaysBounds([display], this);
        let isOutSide = false;
        const { x = 0, y = 0, width = this.worldWidth, height = this.worldHeight, } = range || {};
        isOutSide =
            maxX < x - tolerance ||
                maxY < y - tolerance ||
                minX > x + width + tolerance ||
                minY > y + height + tolerance;
        return isOutSide;
    }
    /**
     * 获取可交互的元素
     *
     * @date 08/12/2021
     * @readonly
     */
    getInteractiveElements(options = { excludeOutSide: false, excludeHidden: false }) {
        if (!this.displayLayer.visible) {
            return [];
        }
        const { excludeOutSide, outSideRange, excludeHidden = false, ids, excludeIds, } = options;
        // const objects = this.displayLayer.children;
        const idSet = ids ? new Set(ids) : null;
        const excludeIdSet = excludeIds ? new Set(excludeIds) : null;
        let data = [];
        const filter = (display) => {
            let result = true;
            const id = display.id;
            if (idSet && !idSet.has(id)) {
                return false;
            }
            if (excludeIdSet && excludeIdSet.has(id)) {
                return false;
            }
            if (display.interactive) {
                if (excludeOutSide) {
                    if (this.isOutSide(display, outSideRange)) {
                        result = false;
                    }
                }
            }
            else {
                result = false;
            }
            if (excludeHidden) {
                if (display.visible === false) {
                    result = false;
                }
            }
            return result;
        };
        data = recurseDisplay(this.displayLayer, filter);
        return data;
    }
    resize(stageWidth, stageHeight, viewportWidth, viewportHeight, scaleMode, gridOption) {
        super.resize(stageWidth, stageHeight, viewportWidth, viewportHeight);
        this.#grid.updateOptions({
            ...gridOption,
            width: viewportWidth,
            height: viewportHeight,
        });
        this.#grid.redraw();
        this.align(scaleMode);
        this.#backdrop.width = viewportWidth;
        this.#backdrop.height = viewportHeight;
        this.#bgMask.width = this.bgMaskSize?.width ?? viewportWidth;
        this.#bgMask.height = this.bgMaskSize?.height ?? viewportHeight;
    }
    updateGrid(gridOption) {
        this.#grid.updateOptions(gridOption);
        this.#grid.redraw();
    }
    /**
     * 获取点选的元素
     *
     * @param {Point} point
     * @memberof MViewport
     */
    getPointClickElements(point, eles) {
        eles = eles ?? this.getInteractiveElements({ excludeHidden: true });
        const data = [];
        const globalPt = this.toGlobal(point);
        eles.forEach((ele) => {
            const localPoint = ele.worldTransform.applyInverse(globalPt);
            // 优先使用hitArea
            if (ele.hitArea) {
                // 将鼠标坐标转成元素内部坐标，这样就不用怕旋转之后hitArea的角度问题了
                if (ele.hitArea.contains(localPoint.x, localPoint.y)) {
                    data.push(ele);
                }
            }
            else {
                let isHit = false;
                if (ele instanceof MSprite) {
                    isHit = ele.hitTest(localPoint);
                }
                else if (ele.containsPoint) {
                    // 利用 graphics 内部的 containsPoint 判断是否包含鼠标点
                    isHit = ele.containsPoint(globalPt);
                }
                if (isHit) {
                    data.push(ele);
                    return;
                }
            }
        });
        return data;
    }
    /**
     * 获取选择区域内的元素
     *
     * @date 08/12/2021
     */
    getRegionalElements(startPoint, endPoint, activeElements) {
        const { x, y } = startPoint;
        const { x: ex, y: ey } = endPoint;
        const eles = activeElements ?? this.getInteractiveElements();
        const sBoxBounds = new OrientedBounds(Math.min(x, ex), Math.min(y, ey), Math.abs(x - ex), Math.abs(y - ey));
        const sBoxCorners = sBoxBounds.hull;
        const data = [];
        eles.forEach((ele) => {
            // 框选
            let eleCorners;
            if (ele.hitArea && ele.hitArea.type !== undefined) {
                eleCorners = calculateHitAreaCorners(ele, ele.hitArea);
                eleCorners = eleCorners.map((p) => this.toLocal(p));
            }
            else {
                if (ele instanceof MPen || ele instanceof MLine) {
                    eleCorners = ele.getRealPoints(this)[0];
                }
                else {
                    eleCorners = Transformer.calculateTransformedCorners(ele);
                    eleCorners = eleCorners.map((p) => this.toLocal(p));
                }
            }
            // 判断回字形全包含和半包含
            let isHit = eleCorners.some((p) => sBoxBounds.contains(p));
            if (isHit) {
                // console.debug('选框包含元素顶点');
                data.push(ele);
                return;
            }
            // 判断相交
            // 元素的边
            const lines1 = [];
            for (let i = 0; i < eleCorners.length; i++) {
                if (i === eleCorners.length - 1) {
                    lines1.push([eleCorners[i], eleCorners[0]]);
                }
                else {
                    lines1.push([eleCorners[i], eleCorners[i + 1]]);
                }
            }
            // 选框的四条边
            const lines2 = [
                [sBoxCorners[0], sBoxCorners[1]],
                [sBoxCorners[1], sBoxCorners[2]],
                [sBoxCorners[2], sBoxCorners[3]],
                [sBoxCorners[3], sBoxCorners[0]],
            ];
            // 计算两个图形的边框是否相交
            isHit = calcIntersect(lines1, lines2);
            if (isHit) {
                console.debug('选框与元素边框相交');
                data.push(ele);
                return;
            }
        });
        return data;
    }
    /**
     * 控制网格显示/隐藏
     */
    set gridVisible(visible) {
        this.#grid.visible = visible;
    }
    set displayLayerVisible(visible) {
        this.displayLayer.visible = visible;
    }
    addToContainer(display, container) {
        switch (container) {
            case 'bgLayer':
                this.bgLayer.addChild(display);
                break;
            case 'displayLayer':
                this.displayLayer.addChild(display);
                break;
            default:
                this.addChild(display);
                break;
        }
    }
    updatePosition(x, y) {
        this.position.set(x, y);
        this.emit(VIEWPORT_EVENT.MOVED, { viewport: this });
    }
    updateInteractivedDisplay(newConfig) {
        const displays = this.getInteractiveElements();
        displays.forEach((display) => {
            display.parseJSON({ ...newConfig });
        });
    }
}
