import { RULER_POSITION } from '../types';
// 手动绘制元素在全局呈现的最小尺寸，避免鼠标移动一点就会创建出一个图形
export const MIN_DISPLAY_SIZE = 5;
export const DEFAULT_GET_CANVAS_DATA_OPTIONS = {
    vectorScaleNum: 10,
    exportVectorPathWhenFill: false,
    imageData: true,
    path: true,
    excludeOutSide: true,
    clearSelected: true,
};
// TODO: hovet 的color可配置
export const DEFAULT_DISPLAY_CONFIG = {
    hoverColor: 0x04fffd,
    enableFill: true,
    visible: true,
    layerData: {},
};
export const DEFAULT_ELEMENT_LINE_STYLE = {
    width: 1,
    color: DEFAULT_DISPLAY_CONFIG.fillColor,
    alignment: 0,
    native: true,
};
export const DEFAULT_GRAPHIC_FILL_STYLE = {
    native: true,
    width: 1,
};
export const DEFAULT_VIEWPORT_CONFIG = {
    worldWidth: 388,
    worldHeight: 300,
    minScale: 0.25,
    maxScale: 10,
    fitOffsetRatio: 0.1,
    backgroundColor: 0xffffff,
    backgroundAlpha: 1,
    outlineNative: true,
    outlineWidth: 1,
    outlineColor: 0x36ad6a,
    outlineAlpha: 0.5,
    offsetX: 0,
    offsetY: 0,
};
export const DEFAULT_SELECTOR_CONFIG = {
    fillColor: 0xccc,
    fillAlpha: 0.2,
};
export const DEFAULT_CUTTER_CONFIG = {
    fillColor: 0xccc,
    fillAlpha: 0.2,
    buttonWidth: 30,
    buttonHeight: 27,
};
export const DEFAULT_CANVAS_BACKGROUND_COLOR = 0xefefef;
// 默认标尺样式
export const DEFAULT_RULER_CONFIG = {
    positions: [
        RULER_POSITION.TOP,
        RULER_POSITION.BOTTOM,
        RULER_POSITION.LEFT,
        RULER_POSITION.RIGHT,
    ],
    pixelPerUnit: 1,
    backgroundColor: 0xffffff,
    backgroundAlpha: 1,
    borderColor: 0x000000,
    borderAlpha: 1,
    scaleColor: 0x000000,
    scaleAlpha: 1,
    fontSize: 10,
    fontColor: 0x000000,
    fontOffset: 2,
    markColor: 0x87cefa,
    markAlpha: 1,
    size: 20,
};
// 默认网格样式
export const DEFAULT_GRID_CONFIG = {
    cellSize: 10,
    lineNative: true,
    lineWidth: 1,
    lineColor: 0x0,
    lineAlpha: 0.3,
    cellSize2: 100,
    lineNative2: true,
    lineWidth2: 1,
    lineColor2: 0x0,
    lineAlpha2: 0.8,
};
export const DEFAULT_GUIDE_LINE_CONFIG = {
    alignLineColor: 0x6495ed,
    distanceLineColor: 0xdc143c,
    unit: 'mm',
    pixelPerUnit: 1,
    fractionDigits: 0,
    fontSize: 12,
    fontColor: 0x000000,
    enableAdsorb: true,
};
export var PAINT_COLOR;
(function (PAINT_COLOR) {
    PAINT_COLOR[PAINT_COLOR["Point"] = 3583338] = "Point";
    PAINT_COLOR[PAINT_COLOR["Hollow"] = 16777215] = "Hollow";
    PAINT_COLOR[PAINT_COLOR["ControlPoint"] = 8900346] = "ControlPoint";
})(PAINT_COLOR || (PAINT_COLOR = {}));
export const DEFAULT_IMAGE_PROCESS_ATTR = {
    SHARPNESS: 50,
    GRAY_VALUE: [0, 255],
    COLOR_INVERTED: false,
};
export const DEFAULT_POINT_RADIUS = 3;
export const DOUBLE_CLICK_DELAY = 300; // ms
export const DEFAULT_TRANSFORMER_CONFIG = {
    rotateEnabled: true,
    boxRotationEnabled: false,
    group: [],
    wireframeStyle: {
        thickness: 1,
        color: 0x000000,
    },
    // 元件最小尺寸
    elementMinWidth: 1,
};
export const DEFAULT_PATH_SCALE = 20;
export const DEFAULT_OUTLINE_CANVAS_BORDER = 1;
export const DEFAULT_OUTLINE_OFFSET = 2;
export const DEFAULT_OUTLINE_BITMAP_SCALE = 5;
export const DEFAULT_OUTLINE_SCALE = 100;
export const DEFAULT_CENTRAL_AXIS_WIDTH = 2;
export const DEFAULT_CENTRAL_AXIS_HOVER_WIDTH = 3;
export const DEFAULT_TEXT = 'HELLO';
export const DEFAULT_TEXT_STYLE = {
    fontSize: 72,
    fontFamily: 'Lato',
    fontSubfamily: 'Regular',
    fontSource: 'build-in',
    letterSpacing: 0,
    leading: 0,
    align: 'center',
    curveX: 56,
    curveY: 0,
};
export const DEFAULT_DISPLAY_SHOT_SCALE_NUM = 10;
export const DEFAULT_EXPORT_SVG_OPTIONS = {
    objs: 'all',
    getDataOptions: {
        fractionPrecision: 6,
        fillStrokeExportMode: 'as-stroke',
        textExportMode: 'path',
    },
    sortByZOrder: true,
};
