import { Transformer } from '@makeblock/transformer';
import { isNumber } from 'lodash-es';
import { Bounds, DEG_TO_RAD, Matrix, Point, RAD_TO_DEG } from 'pixi.js';
import { v4 as uuid } from 'uuid';
import { DEFAULT_TRANSFORMER_CONFIG } from '../../config/index';
import { getPointerId, getPointerIdArr, isSinglePoint, } from '../../controllers/interaction-manager';
import { TRANSFORMER_EVENT } from '../../types/event';
import { calculateBoundsDemisionChanged, deepDestroy, groupTransferOffset, multiplyTransform, rotateMatrix, } from '../../utils';
/**
 * MTransformer
 *
 * @date 03/12/2021
 * @class Transform
 * @extends {Transformer}
 */
export class MTransformer extends Transformer {
    viewport;
    enableEmit = true;
    elementMinWidth;
    // Transform里面的_pointdown无法复用，因为里面的pointerup监听先响应，_pointdown提前置为false，所以要自己维护一个状态
    isPointDown;
    pointerId;
    constructor(viewport, options) {
        const config = Object.assign(DEFAULT_TRANSFORMER_CONFIG, options);
        super(config);
        this.elementMinWidth = config.elementMinWidth;
        this.viewport = viewport;
        this.initEvent();
    }
    scaleGroup(handle, pointerPosition) {
        super.scaleGroup(handle, pointerPosition, this.elementMinWidth, this.viewport?.scale.x || 1);
    }
    clear() {
        if (this.selected.length > 0) {
            this.selected = [];
        }
    }
    initEvent() {
        this.on(TRANSFORMER_EVENT.TRANSFORM_CHANGE, () => {
            this.update();
        });
        this.on(TRANSFORMER_EVENT.TRANSFORM_COMMIT, () => {
            this.commit();
            this.onUpdateState({
                lockRatio: this.groupJSON.lockRatio,
            });
        });
    }
    translateSelected(data) {
        const { deltaX = 0, deltaY = 0 } = data;
        this.selected.forEach((display) => {
            display.x += deltaX;
            display.y += deltaY;
        });
        this.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, 'matrix');
    }
    // 选中元素整体按中心点翻转，涵盖单个元素的操作
    flipSelected(option) {
        const { flipX = false, flipY = false } = option;
        const scaleX = flipX ? -1 : 1;
        const scaleY = flipY ? -1 : 1;
        const matrix = Matrix.IDENTITY;
        const bounds = Transformer.calculateGroupOrientedBounds(this.selected, 0);
        const center = bounds.center;
        matrix
            .translate(-center.x, -center.y)
            .scale(scaleX, scaleY)
            .translate(center.x, center.y);
        this.updateSelectedTransform(matrix);
    }
    // 选中元素整体按中心点旋转，涵盖单个元素的操作
    rotateSelected(angle) {
        if (isNumber(angle)) {
            const matrix = rotateMatrix(this.group, angle);
            this.updateSelectedTransform(matrix);
            this.groupBounds.rotation = angle * DEG_TO_RAD;
        }
    }
    updateSelectedTransform(matrix) {
        this.selected.forEach((display) => {
            multiplyTransform(display, matrix);
        });
        this.emit(TRANSFORMER_EVENT.TRANSFORM_CHANGE, 'matrix');
    }
    updateGroupSize(width, height, lockAspectRatio = this.lockAspectRatio) {
        if (!isNumber(width) && !isNumber(height)) {
            return;
        }
        // 在选中框没有回到水平的情况下设置尺寸，先将选中框回正
        // 1. 将输入操作和鼠标操作保持统一
        // 2. 便于后续的坐标计算
        if (this.groupBounds.rotation !== 0) {
            this.updateGroupBounds(0);
        }
        const bound = calculateBoundsDemisionChanged(this.viewport, this.group, width, height, lockAspectRatio);
        const point = new Point(bound.maxX, bound.maxY);
        this.scaleGroup('bottomRight', point);
    }
    updateGroupPosition(x, y) {
        if (isNumber(x) || isNumber(y)) {
            const delta = groupTransferOffset(this.viewport, this.group, { x, y });
            this.translateGroup(delta);
        }
    }
    set selectedIDs(ids) {
        const displays = this.viewport.getInteractiveElements();
        const selected = displays.filter((display) => ids.includes(display.id));
        this.selected = selected;
    }
    get selectedIDs() {
        return this.selected.map((display) => display.id);
    }
    /**
     * 选中的元素
     *
  
     * @date 07/12/2021
     * @readonly
     */
    get selected() {
        return this.group;
    }
    /**
     * 设置选中的元素
     *
  
     * @date 08/12/2021
     */
    set selected(objects) {
        if (Array.isArray(objects)) {
            this.setSelection(objects);
            this.updateLockRatio();
            this.emit(TRANSFORMER_EVENT.SELECT);
        }
        this.onUpdateState({
            lockRatio: this.groupJSON.lockRatio,
        });
    }
    updateLockRatio() {
        const displays = this.selected;
        const lockAspectRatio = displays.some((display) => display.lockRatio);
        this.lockAspectRatio = lockAspectRatio;
    }
    changeMode(mode) {
        super.changeMode(mode);
        this.onUpdateState({
            lockRatio: this.groupJSON.lockRatio,
        });
    }
    update() {
        const selectedObjects = this.group;
        selectedObjects.forEach((child) => {
            if (child.transformUpdate) {
                child.transformUpdate();
            }
        });
        this.updateLockRatio();
        this.onUpdateState({
            lockRatio: this.groupJSON.lockRatio,
        });
    }
    commit() {
        const selectedObjects = this.group;
        selectedObjects.forEach((child) => {
            if (child.transformCommit) {
                child.transformCommit();
            }
        });
    }
    /**
     * 返回相对viewport坐标系的选中元素边界
     */
    get worldGroupBounds() {
        // 传入 calculateGroupOrientedBounds 第二个参数0是指，当选中的元素进行旋转时，始终以水平和垂直投影进行边界计算
        const groupBounds = Transformer.calculateGroupOrientedBounds(this.group, 0);
        const bounds = new Bounds();
        const topLeft = this.viewport.toLocal(groupBounds.topLeft);
        const bottomRight = this.viewport.toLocal(groupBounds.bottomRight);
        bounds.minX = topLeft.x;
        bounds.minY = topLeft.y;
        bounds.maxX = bottomRight.x;
        bounds.maxY = bottomRight.y;
        return bounds;
    }
    /**
     * 返回相对viewport坐标系的选中元素边界中心点
     */
    get worldGroupBoundsCenter() {
        const rect = this.worldGroupBounds.getRectangle();
        const x = rect.x + rect.width / 2;
        const y = rect.y + rect.height / 2;
        const point = new Point(x, y);
        return point;
    }
    get groupJSON() {
        const { x, y, width, height } = this.worldGroupBounds.getRectangle();
        const angle = this.groupBounds.rotation * RAD_TO_DEG;
        const type = 'GROUP';
        const id = uuid();
        return {
            x,
            y,
            width,
            height,
            id,
            type,
            angle,
            lockRatio: this.lockAspectRatio,
        };
    }
    /**
     * 删除选中的元素
     */
    removeSelected() {
        const elements = this.selected;
        // TODO: 图片的销毁还需 销毁 texture
        elements?.forEach((ele) => {
            deepDestroy(ele);
        });
        this.clear();
    }
    get unSelected() {
        const all = this.viewport.getInteractiveElements() || [];
        const selected = this.selected;
        const unSelected = [];
        if (Array.isArray(all) && all.length > 0) {
            all?.forEach((element) => {
                if (!selected.includes(element)) {
                    unSelected.push(element);
                }
            });
        }
        return unSelected;
    }
    onPointerMove(e) {
        if (isSinglePoint(e) && this.pointerId === getPointerId(e)) {
            super.onPointerMove(e);
        }
    }
    onPointerDown(e) {
        if (isSinglePoint(e)) {
            this.pointerId = getPointerId(e);
            super.onPointerDown(e);
        }
    }
    onPointerUp(e) {
        if (getPointerIdArr(e).includes(this.pointerId)) {
            super.onPointerUp(e);
        }
    }
}
