import { Container } from 'pixi.js';
export default class MContainer extends Container {
    done() {
        throw new Error('Method not implemented.');
    }
    clear() {
        this.children.forEach((c) => {
            c.clear();
        });
    }
}
