import { POINTER_INPUT_MAP } from './const';
import { Input } from './Input';
export function getIndexInArray(src, find, findByKey) {
    if (src.indexOf && !findByKey) {
        return src.indexOf(find);
    }
    else {
        let i = 0;
        while (i < src.length) {
            if ((findByKey && src[i][findByKey] === find) ||
                (!findByKey && src[i] === find)) {
                return i;
            }
            i++;
        }
        return -1;
    }
}
export function simpleCloneInput(input) {
    const pointers = [];
    let i = 0;
    while (i < input.pointers.length) {
        pointers[i] = {
            clientX: Math.round(input.pointers[i].clientX),
            clientY: Math.round(input.pointers[i].clientY),
        };
        i++;
    }
    const result = new Input();
    result.pointers = pointers;
    result.timeStamp = Date.now();
    result.center = getCenter(pointers);
    result.deltaX = input.deltaX;
    result.deltaY = input.deltaY;
    return result;
}
export function getCenter(pointers) {
    const pointersLength = pointers.length;
    if (pointersLength === 1) {
        return {
            x: Math.round(pointers[0].clientX),
            y: Math.round(pointers[0].clientY),
        };
    }
    let x = 0;
    let y = 0;
    let i = 0;
    while (i < pointersLength) {
        x += pointers[i].clientX;
        y += pointers[i].clientY;
        i++;
    }
    return {
        x: Math.round(x / pointersLength),
        y: Math.round(y / pointersLength),
    };
}
export function getPointsAngle(p1, p2) {
    const x = p2.x - p1.x;
    const y = p2.y - p1.y;
    return (Math.atan2(y, x) * 180) / Math.PI;
}
export function getPointerEventAngle(p1, p2) {
    const x = p2.clientX - p1.clientX;
    const y = p2.clientY - p1.clientY;
    return (Math.atan2(y, x) * 180) / Math.PI;
}
export function getPointsDistance(p1, p2) {
    const x = p2.x - p1.x;
    const y = p2.y - p1.y;
    return Math.sqrt(x * x + y * y);
}
export function getPointerEventDistance(p1, p2) {
    const x = p2.clientX - p1.clientX;
    const y = p2.clientY - p1.clientY;
    return Math.sqrt(x * x + y * y);
}
export function getDirection(x, y) {
    if (x === y) {
        return 1 /* DIRECTION.NONE */;
    }
    if (Math.abs(x) >= Math.abs(y)) {
        return x < 0 ? 2 /* DIRECTION.LEFT */ : 4 /* DIRECTION.RIGHT */;
    }
    return y < 0 ? 8 /* DIRECTION.UP */ : 16 /* DIRECTION.DOWN */;
}
export function getVelocity(deltaTime, x, y) {
    return {
        x: x / deltaTime || 0,
        y: y / deltaTime || 0,
    };
}
export function getScale(start, end) {
    if (!start || !end) {
        return 1;
    }
    if (start.length < 2 || end.length < 2) {
        return 1;
    }
    return (getPointerEventDistance(end[0], end[1]) /
        getPointerEventDistance(start[0], start[1]));
}
export function getRotation(start, end) {
    if (!start || !end) {
        return 0;
    }
    if (start.length < 2 || end.length < 2) {
        return 0;
    }
    return (getPointerEventAngle(end[1], end[0]) +
        getPointerEventAngle(start[1], start[0]));
}
export function getPointerEventType(ev) {
    const eventTypeNormalized = ev.type.toLowerCase().replace('ms', '');
    const eventType = POINTER_INPUT_MAP.get(eventTypeNormalized);
    return eventType;
}
// 判断是否单点事件，默认返回true，touch事件下只有单指按下才为true
// 注意 当 touchup的事件触发时 touches.length 为 0, 表示是最后一只手势抬起
export function isSinglePoint(e) {
    const { data: { originalEvent }, } = e;
    let isSinglePoint = true;
    if (window.TouchEvent && originalEvent instanceof TouchEvent) {
        const { touches, type } = originalEvent;
        if (type !== 'touchend') {
            if (touches.length !== 1) {
                isSinglePoint = false;
            }
        }
        else {
            if (touches.length !== 0) {
                isSinglePoint = false;
            }
        }
    }
    return isSinglePoint;
}
// 获取 interactive event的id
export function getPointerId(e) {
    const { data: { originalEvent }, } = e;
    let id = null;
    if (window.TouchEvent && originalEvent instanceof TouchEvent) {
        const { changedTouches } = originalEvent;
        if (changedTouches.length === 1) {
            id = changedTouches[0].identifier;
        }
    }
    if (originalEvent instanceof PointerEvent) {
        const { pointerId } = originalEvent;
        id = pointerId;
    }
    return id;
}
export function getPointerIdArr(e) {
    const { data: { originalEvent }, } = e;
    const idArr = [];
    if (window.TouchEvent && originalEvent instanceof TouchEvent) {
        const { changedTouches } = originalEvent;
        for (let index = 0; index < changedTouches.length; index++) {
            const element = changedTouches[index];
            idArr.push(element.identifier);
        }
    }
    if (originalEvent instanceof PointerEvent) {
        const { pointerId } = originalEvent;
        idArr.push(pointerId);
    }
    return idArr;
}
// 判断是否是触摸、笔触事件
export function isTouchEvent(e) {
    return window.TouchEvent && ['touch', 'pen'].includes(e.data.pointerType);
}
// 判断是否可以触发hover事件
export function hoverable(e) {
    // 鼠标事件，且不是触摸、笔触
    return !isTouchEvent(e) && e.data.originalEvent instanceof MouseEvent;
}
// export function getPointerId(e: InteractionPointerEvent) {
//   const {
//     data: { pointerId },
//   } = e;
//   // let id = null;
//   // if (originalEvent instanceof TouchEvent) {
//   //   const { changedTouches } = originalEvent;
//   //   if (changedTouches.length === 1) {
//   //     id = changedTouches[0].identifier;
//   //   }
//   // }
//   // if (originalEvent instanceof PointerEvent) {
//   //   const { pointerId } = originalEvent;
//   //   id = pointerId;
//   // }
//   return pointerId;
// }
// export function getTrackedPointers(e: InteractionEvent) {
//   const { currentTarget } = e;
//   return currentTarget.trackedPointers;
// }
