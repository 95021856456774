export var BOOL_OP;
(function (BOOL_OP) {
    BOOL_OP["UNION"] = "union";
    BOOL_OP["DIFFERENCE"] = "difference";
    BOOL_OP["INTERSECTION"] = "intersection";
    BOOL_OP["XOR"] = "xor";
})(BOOL_OP || (BOOL_OP = {}));
export var ALIGN_TYPE;
(function (ALIGN_TYPE) {
    ALIGN_TYPE["LEFT"] = "alignToLeft";
    ALIGN_TYPE["RIGHT"] = "alignToRight";
    ALIGN_TYPE["TOP"] = "alignToTop";
    ALIGN_TYPE["BOTTOM"] = "alignToBottom";
    ALIGN_TYPE["HORIZONTAL_CENTER"] = "alignToHorizontalCenter";
    ALIGN_TYPE["VERTICAL_CENTER"] = "alignToVerticalCenter";
    ALIGN_TYPE["HORIZONTAL_DISTRIBUTE"] = "horizontalDistribute";
    ALIGN_TYPE["VERTICAL_DISTRIBUTE"] = "verticalDistribute";
})(ALIGN_TYPE || (ALIGN_TYPE = {}));
export var BATCH_CREATE_TYPE;
(function (BATCH_CREATE_TYPE) {
    BATCH_CREATE_TYPE["LINER"] = "createLinerArrays";
    BATCH_CREATE_TYPE["RADIAL"] = "createRadialArrays";
})(BATCH_CREATE_TYPE || (BATCH_CREATE_TYPE = {}));
