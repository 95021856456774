/**
 * To Path
 */
import { Graphics, SHAPES, } from 'pixi.js';
import svgPath from 'svgpath';
import { DISPLAY_TYPE, SMOOTH_SCALE } from '../..';
import { SVGGraphicsNode } from '../../../packages/svgscene';
import { getMatrixArray } from '../../../utils';
import { PathData } from './PathData';
export function displayObjectToPath(obj) {
    let path = '';
    switch (obj.type) {
        case DISPLAY_TYPE.LINE:
            path = mLineToPath(obj);
            break;
        case DISPLAY_TYPE.RECT:
            path = mRectToPath(obj);
            break;
        case DISPLAY_TYPE.CIRCLE:
            path = mCircleToPath(obj);
            break;
        case DISPLAY_TYPE.POLYGON:
            if (obj instanceof SVGGraphicsNode) {
                path = pixiShapeToPath(obj.geometry.graphicsData[0]?.shape);
            }
            else {
                path = mPolygonToPath(obj);
            }
            break;
        case DISPLAY_TYPE.PEN:
            path = mPenToPath(obj);
            break;
        case DISPLAY_TYPE.TEXT:
            path = mTextToPath(obj);
            break;
        case DISPLAY_TYPE.PATH:
            path = obj.dPath;
            break;
        default:
            if (obj instanceof Graphics) {
                const shape = obj.geometry.graphicsData[0]?.shape;
                if (shape) {
                    path = pixiShapeToPath(shape);
                }
            }
            break;
    }
    return path;
}
export function pixiShapeToPath(shape) {
    switch (shape.type) {
        case SHAPES.POLY: {
            return pixiPolygonToPath(shape);
        }
        case SHAPES.RECT: {
            return pixiRectToPath(shape);
        }
        case SHAPES.RREC: {
            return pixiRoundedRectToPath(shape);
        }
        case SHAPES.CIRC: {
            return pixiCircleToPath(shape);
        }
        case SHAPES.ELIP: {
            return pixiEllipseToPath(shape);
        }
        default:
            return '';
    }
}
function mLineToPath(obj) {
    const { x1, x2, y1, y2 } = obj.getSVGData().attrs;
    const pathData = new PathData();
    pathData.moveTo(x1, y1);
    pathData.lineTo(x2, y2);
    return pathData.toString();
}
function mRectToPath(obj) {
    const { x, y, width, height, rx, ry } = obj.getSVGData().attrs;
    const pathData = new PathData();
    if (!rx || !ry) {
        pathData.rect(x, y, width, height);
    }
    else {
        pathData.roundRect(x, y, width, height, [rx, ry]);
    }
    return pathData.toString();
}
function mCircleToPath(obj) {
    const attrs = obj.getSVGData().attrs;
    const { r } = attrs;
    let { rx = r, ry = r } = attrs;
    const scale = SMOOTH_SCALE;
    rx *= scale;
    ry *= scale;
    const cx = rx;
    const cy = ry;
    const pathData = new PathData();
    pathData.ellipse(cx, cy, rx, ry);
    return pathData.toString();
}
function mPolygonToPath(obj) {
    const pathData = new PathData();
    const points = obj.points;
    for (let i = 0; i < points.length; i += 2) {
        const x = points[i];
        const y = points[i + 1];
        if (!i) {
            pathData.moveTo(x, y);
        }
        else {
            pathData.lineTo(x, y);
        }
    }
    return pathData.toString();
}
function mPenToPath(obj) {
    const { d } = obj.getSVGData().attrs;
    return d;
}
function mTextToPath(obj) {
    let path = '';
    obj.children.forEach((c) => {
        path += svgPath(c.dPath)
            .matrix(getMatrixArray(c.localTransform, { fractionPrecision: 6 }))
            .toString();
    });
    return path;
}
function pixiPolygonToPath(polygon) {
    const pathData = new PathData();
    const points = polygon.points;
    for (let i = 0; i < points.length; i += 2) {
        const pt = { x: points[i], y: points[i + 1] };
        if (i === 0) {
            pathData.moveTo(pt.x, pt.y);
        }
        else {
            pathData.lineTo(pt.x, pt.y);
        }
    }
    if (polygon.closeStroke) {
        pathData.closePath();
    }
    return pathData.toString();
}
function pixiRectToPath(rect) {
    const { x, y, width, height } = rect;
    const pathData = new PathData();
    pathData.rect(x, y, width, height);
    return pathData.toString();
}
function pixiRoundedRectToPath(roundRect) {
    const { x, y, width, height, radius } = roundRect;
    const pathData = new PathData();
    pathData.roundRect(x, y, width, height, [radius, radius]);
    return pathData.toString();
}
function pixiCircleToPath(circle) {
    const { x, y, radius } = circle;
    const pathData = new PathData();
    pathData.circle(x, y, radius);
    return pathData.toString();
}
function pixiEllipseToPath(ellipse) {
    const { x, y, width, height } = ellipse;
    const rx = width / 2;
    const ry = height / 2;
    const pathData = new PathData();
    pathData.ellipse(x + rx, y + ry, rx, ry);
    return pathData.toString();
}
