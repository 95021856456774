import { has } from 'lodash-es';
export function hasOneIn(source, paths) {
    return paths.some((path) => {
        return has(source, path);
    });
}
export function calOffset(align, maxWidth, lineWidth) {
    let offset = 0;
    switch (align) {
        case 'left':
            offset = 0;
            break;
        case 'right':
            offset = maxWidth - lineWidth;
            break;
        case 'center':
            offset = (maxWidth - lineWidth) / 2;
            break;
        default:
            offset = 0;
    }
    return offset;
}
/**
 * 依照指定小数有效位数获取数值
 */
export function getNumByFractionPrecision(value, precision) {
    return isFinite(precision) && isFinite(value)
        ? Number(toFractionPrecision(value, precision))
        : Number(value);
}
/**
 * 转换数值为具有指定小数有效位数的字符串形式
 */
export function toFractionPrecision(value, precision) {
    return isFinite(precision)
        ? Math.abs(value) < 1 && precision > 0
            ? value.toPrecision(precision)
            : value.toFixed(precision)
        : String(value);
}
