import { CHANGE_LAYER_TYPE } from '../../types';
import { LinkList } from './LinkList';
var DIRECTION;
(function (DIRECTION) {
    DIRECTION[DIRECTION["DESC"] = 0] = "DESC";
    DIRECTION[DIRECTION["ESC"] = 1] = "ESC";
})(DIRECTION || (DIRECTION = {}));
export class LayerManager {
    changeLayer(displayIDs, selected, type) {
        const displayLinkList = LinkList.create(displayIDs);
        //  从大到小
        const sortedSelected = [...selected];
        sortedSelected.sort((a, b) => b.displayOrder - a.displayOrder);
        const methodMap = {
            [CHANGE_LAYER_TYPE.UP]: this.moveUp,
            [CHANGE_LAYER_TYPE.DOWN]: this.moveDown,
            [CHANGE_LAYER_TYPE.TOP]: this.moveToTop,
            [CHANGE_LAYER_TYPE.END]: this.moveToEnd,
        };
        methodMap[type].bind(this)(displayLinkList, sortedSelected);
        return displayLinkList;
    }
    // 向下一层
    moveDown(list, selected) {
        const endOfSelected = selected[selected.length - 1];
        const nodeOfEnd = list.search(endOfSelected.id);
        let targetNode = nodeOfEnd.prev || nodeOfEnd;
        targetNode = this.findFirstDiffEleInListByOrderedArr(list, selected, targetNode, DIRECTION.ESC);
        if (!targetNode) {
            return;
        }
        this.moveToPrev(list, selected, targetNode);
    }
    // 移到顶层
    moveToTop(list, selected) {
        // 查找第一个与排序列表不一致的节点，基准进行插入操作
        const targetNode = this.findFirstDiffEleInListByOrderedArr(list, selected, list.head);
        if (!targetNode) {
            return;
        }
        // targetNode为基准，往前插入元素
        this.moveToNext(list, selected, targetNode);
    }
    // 向上一层
    moveUp(list, selected) {
        const frontOfSelected = selected[0];
        const nodeOfFront = list.search(frontOfSelected.id);
        let targetNode = nodeOfFront.next || nodeOfFront;
        targetNode = this.findFirstDiffEleInListByOrderedArr(list, selected, targetNode);
        if (!targetNode) {
            return;
        }
        this.moveToNext(list, selected, targetNode);
    }
    // 移到底层
    moveToEnd(list, selected) {
        const targetNode = this.findFirstDiffEleInListByOrderedArr(list, selected, list.end, DIRECTION.ESC);
        if (!targetNode) {
            return;
        }
        this.moveToPrev(list, selected, targetNode);
    }
    moveToPrev(list, selected, targetNode) {
        if (targetNode) {
            const sorted = [...selected];
            // 从小到大排列
            sorted.sort((a, b) => a.displayOrder - b.displayOrder);
            // 往下移时，先移动选中元素中的最底层
            sorted.forEach((display) => {
                const node = list.search(display.id);
                if (node) {
                    list.moveToPrev(targetNode, node);
                }
            });
        }
    }
    moveToNext(list, selected, targetNode) {
        if (targetNode) {
            selected.forEach((display) => {
                const node = list.search(display.id);
                if (node) {
                    list.moveToNext(targetNode, node);
                }
            });
        }
    }
    updateOrder(list, selected) {
        list.forEach((node, index) => {
            const target = selected.find((display) => node.value === display.id);
            if (target) {
                target.zOrder = index + 1;
            }
        });
    }
    /**
     * 根据给定的数组，查找双向列表中第一个与数组不一致的元素
     * @param {LinkList<string>} list
     * @param {MDisplayObject[]} orderArr 默认是降序
     * @param {ListNode<string>} [startNode] 列表开始比较的位置
     * @param {DIRECTION} [directions=DIRECTION.DESC]
     */
    findFirstDiffEleInListByOrderedArr(list, orderArr, startNode, directions = DIRECTION.DESC) {
        let targetNode = startNode;
        orderArr = directions === DIRECTION.DESC ? orderArr : orderArr.reverse();
        for (const idx in orderArr) {
            const nodeOfFront = list.search(orderArr[idx].id);
            targetNode = targetNode || list.head;
            if (nodeOfFront !== targetNode) {
                break;
            }
            if (Number(idx) === orderArr.length - 1) {
                targetNode = null;
                break;
            }
            targetNode = targetNode[directions === DIRECTION.DESC ? 'prev' : 'next'];
        }
        return targetNode;
    }
}
