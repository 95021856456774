// @ts-nocheck
import createTess2Wasm from 'tess2-wasm';
import { FILL_RULE } from './Path';
let TessWasm = null;
export const setup = async () => {
    // console.time('setUpTess2Wasm');
    const wasm = await createTess2Wasm();
    // console.timeEnd('setUpTess2Wasm');
    TessWasm = wasm.Tess;
};
setup();
export const triangulate = (data) => {
    const tess = new TessWasm();
    try {
        const { contours, fillRule = FILL_RULE.EVENODD } = data;
        if (contours.length <= 0) {
            return;
        }
        tess.addContours(contours);
        const windingRule = fillRule === FILL_RULE.EVENODD
            ? 0 /* WINDONG_RULE.TESS_WINDING_ODD */
            : 1 /* WINDONG_RULE.TESS_WINDING_NONZERO */;
        const options = {
            windingRule,
            elementType: 0 /* ELEMENT_TYPE.TESS_POLYGONS */,
            polySize: 3,
            vertexSize: 2,
        };
        const res = tess.tesselate(options);
        if (!res) {
            tess.dispose();
            return { elements: [], vertices: [] };
        }
        const elements = new Uint32Array(res.elements);
        const vertices = new Float32Array(res.vertices);
        tess.dispose();
        return { elements, vertices };
    }
    catch (e) {
        tess.dispose();
        console.error(e.message);
    }
};
// Not used yet, tess2 isn't so good.
export const buildPath = {
    build() {
        /* This method is intentionally blank. */
    },
    triangulate(graphicsData, graphicsGeometry) {
        const process = () => {
            const path = graphicsData.shape;
            const contours = path.contours
                .filter((c) => c.length > 0)
                .filter((c) => c.find((e) => isNaN(e)) === undefined);
            if (contours.length <= 0) {
                return;
            }
            const res = triangulate({
                contours,
                fillRule: path.fillRule,
            });
            if (res && res.elements.length > 0) {
                res.elements.forEach((ele) => {
                    graphicsGeometry.indices.push(ele);
                });
                res.vertices.forEach((vert) => {
                    graphicsGeometry.points.push(vert);
                });
            }
        };
        if (TessWasm) {
            // console.time('triangulate');
            process();
            // console.timeEnd('triangulate');
        }
        else {
            createTess2Wasm().then(async ({ Tess }) => {
                TessWasm = Tess;
                process();
            });
        }
    },
};
