import { TransformerHandle } from '@makeblock/transformer';
import { Rectangle } from 'pixi.js';
export class TextCurveHandle extends TransformerHandle {
    transformer;
    name = 'textCurveHandle';
    constructor(transformer, handle, styleOpts = {}, handler, commit, cursor) {
        super(transformer, handle, styleOpts, handler, commit, cursor);
        this.transformer = transformer;
        this.name = TextCurveHandle.name;
    }
    draw() {
        const handle = this._handle;
        const style = this._style;
        const radius = style.radius;
        this.removeChildren();
        this.clear()
            .lineStyle(style.outlineThickness, style.outlineColor)
            .beginFill(style.color);
        if (style.shape === 'square') {
            this.drawRect(-radius / 2, -radius / 2, radius, radius);
        }
        else if (style.shape === 'component') {
            const ele = this._style?.shapeAttrs?.[handle]?.component;
            if (ele) {
                ele.on('DOUBLE_CLICK', () => {
                    this.emit('DOUBLE_CLICK');
                });
                this.addChild(ele);
            }
        }
        else {
            this.drawCircle(0, 0, radius);
        }
        const { style: { hitAreaPadding }, } = this;
        if (hitAreaPadding) {
            const { x, y, width, height } = this.getLocalBounds();
            if (width > 0 && height > 0) {
                this.hitArea = new Rectangle(x - hitAreaPadding, y - hitAreaPadding, width + hitAreaPadding * 2, height + hitAreaPadding * 2);
            }
        }
        this.endFill();
    }
}
