export var DISPLAY_TYPE;
(function (DISPLAY_TYPE) {
    DISPLAY_TYPE["CIRCLE"] = "CIRCLE";
    DISPLAY_TYPE["RECT"] = "RECT";
    DISPLAY_TYPE["TEXT"] = "TEXT";
    DISPLAY_TYPE["PATH"] = "PATH";
    DISPLAY_TYPE["LINE"] = "LINE";
    DISPLAY_TYPE["PEN"] = "PEN";
    DISPLAY_TYPE["BITMAP"] = "BITMAP";
    DISPLAY_TYPE["POLYGON"] = "POLYGON";
})(DISPLAY_TYPE || (DISPLAY_TYPE = {}));
export var DISPLAY_LIFE_CYCLE;
(function (DISPLAY_LIFE_CYCLE) {
    DISPLAY_LIFE_CYCLE["DRAW_BEGIN"] = "DRAW_BEGIN";
    DISPLAY_LIFE_CYCLE["DRAW_DONE"] = "DRAW_DONE";
    DISPLAY_LIFE_CYCLE["DRAW_UPDATE"] = "DRAW_UPDATE";
})(DISPLAY_LIFE_CYCLE || (DISPLAY_LIFE_CYCLE = {}));
