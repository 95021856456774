export class ListNode {
    prev;
    next;
    value;
    constructor(value) {
        // 指向前一个节点
        this.prev = null;
        // 指向后一个节点
        this.next = null;
        // 节点的数据
        this.value = value;
    }
}
/**
 * 双向链表
 */
export class LinkList {
    head;
    end;
    length = 0;
    constructor() {
        this.head = null;
        this.end = null;
    }
    static createNode(value) {
        return new ListNode(value);
    }
    static create(values) {
        const linkList = new LinkList();
        if (values) {
            for (let i = 0; i <= values.length - 1; i++) {
                linkList.insert(LinkList.createNode(values[i]));
            }
        }
        return linkList;
    }
    insert(node) {
        node.next = null;
        node.prev = this.head;
        if (this.head) {
            this.head.next = node;
        }
        if (!this.end) {
            this.end = node;
        }
        this.head = node;
        this.length += 1;
    }
    insertPrev(node, newNode) {
        const oldPrev = node.prev;
        newNode.prev = oldPrev;
        newNode.next = node;
        node.prev = newNode;
        if (oldPrev) {
            oldPrev.next = newNode;
        }
        else {
            this.end = newNode;
        }
        this.length += 1;
    }
    moveToNext(node, movedNode) {
        if (movedNode === this.head || node === movedNode) {
            return;
        }
        this.delete(movedNode);
        this.insertNext(node, movedNode);
    }
    moveToPrev(node, movedNode) {
        if (movedNode === this.end || node === movedNode) {
            return;
        }
        this.delete(movedNode);
        this.insertPrev(node, movedNode);
    }
    insertNext(node, newNode) {
        const oldNext = node.next;
        node.next = newNode;
        newNode.next = oldNext;
        newNode.prev = node;
        if (oldNext) {
            oldNext.prev = newNode;
        }
        else {
            this.head = newNode;
        }
        this.length += 1;
    }
    search(value) {
        let node = this.head;
        while (node !== null && node.value !== value) {
            node = node.prev;
        }
        return node;
    }
    delete(node) {
        const { prev, next } = node;
        delete node.prev;
        delete node.next;
        if (node === this.head) {
            this.head = prev;
        }
        if (node === this.end) {
            this.end = next;
        }
        if (prev) {
            prev.next = next;
        }
        if (next) {
            next.prev = prev;
        }
        this.length -= 1;
    }
    forEach(cb) {
        let currentNode = this.end;
        let index = 0;
        cb(currentNode, index);
        while (currentNode.next) {
            currentNode = currentNode.next;
            index++;
            cb(currentNode, index);
        }
    }
}
