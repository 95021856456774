import { SHAPES } from 'pixi.js';
import { MCircle, MPen, MPolygon, MRect } from '../../display';
import { MLine } from '../../display/MLine';
import { SVGGraphicsNode, SVGPathNode } from '../../packages/svgscene';
export function updateSkPathByDisplay(display, skPath, opts) {
    switch (display.constructor) {
        case MLine:
            updateByMLine(display, skPath, opts);
            break;
        case MRect:
            updateByMRect(display, skPath);
            break;
        case MCircle:
            updateByMCircle(display, skPath);
            break;
        case MPolygon:
            updateByMPolygon(display, skPath);
            break;
        case MPen:
            updateByMPen(display, skPath);
            break;
        case SVGPathNode:
            updateBySVGPathNode(display, skPath);
            break;
        case SVGGraphicsNode:
            updateBySVGGraphicsNode(display, skPath);
            break;
        default:
            updateByBounds(display, skPath);
            break;
    }
}
function updateByBounds(display, skPath) {
    const bounds = display.getBounds?.();
    if (bounds) {
        addPixiShape(skPath, bounds);
    }
}
function updateByMLine(display, skPath, opts) {
    skPath.moveTo(0, 0).lineTo(display.endPoint.x, display.endPoint.y);
    opts.fill = false;
}
function updateByMRect(display, skPath) {
    // @ts-ignore
    skPath.addRect([0, 0, display.originWidth, display.originHeight]);
}
function updateByMCircle(display, skPath) {
    // @ts-ignore
    skPath.addOval([0, 0, display.originWidth, display.originHeight]);
}
function updateByMPolygon(display, skPath) {
    display.geometry.graphicsData.forEach((data) => {
        addPixiShape(skPath, data.shape);
    });
}
function updateByMPen(display, skPath) {
    // @ts-ignore
    return updateSkPathByDisplay(display.lineGraphics, skPath);
}
function updateBySVGPathNode(display, skPath) {
    skPath.addFromSVGPathD(display.dPath);
}
function updateBySVGGraphicsNode(display, skPath) {
    display.geometry.graphicsData.forEach((data) => {
        addPixiShape(skPath, data.shape);
    });
}
function addPixiShape(skPath, shape) {
    switch (shape.type) {
        case SHAPES.POLY: {
            return addPixiPolygon(skPath, shape);
        }
        case SHAPES.RECT: {
            return addPixiRect(skPath, shape);
        }
        case SHAPES.RREC: {
            return addPixiRoundedRect(skPath, shape);
        }
        case SHAPES.CIRC: {
            return addPixiCircle(skPath, shape);
        }
        case SHAPES.ELIP: {
            return addPixiEllipse(skPath, shape);
        }
        default:
            return;
    }
}
function addPixiPolygon(skPath, polygon) {
    const points = polygon.points;
    for (let i = 0; i < points.length; i += 2) {
        const pt = { x: points[i], y: points[i + 1] };
        if (i === 0) {
            skPath.moveTo(pt.x, pt.y);
        }
        else {
            skPath.lineTo(pt.x, pt.y);
        }
    }
    if (polygon.closeStroke) {
        skPath.close();
    }
}
function addPixiRect(skPath, rect) {
    skPath.addRect([rect.x, rect.y, rect.x + rect.width, rect.y + rect.height]);
}
function addPixiRoundedRect(skPath, roundRect) {
    skPath.addRRect([
        roundRect.x,
        roundRect.y,
        roundRect.x + roundRect.width,
        roundRect.y + roundRect.height,
        roundRect.radius,
        roundRect.radius,
        roundRect.radius,
        roundRect.radius,
        roundRect.radius,
        roundRect.radius,
        roundRect.radius,
        roundRect.radius,
    ]);
}
function addPixiCircle(skPath, circle) {
    skPath.addOval([circle.x, circle.y, circle.radius, circle.radius]);
}
function addPixiEllipse(skPath, ellipse) {
    skPath.addOval([
        ellipse.x,
        ellipse.x,
        ellipse.x + ellipse.width,
        ellipse.x + ellipse.height,
    ]);
}
