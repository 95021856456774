import { merge } from 'lodash-es';
import { Container, Graphics, Sprite } from 'pixi.js';
import { MSprite, createDisplay } from '../display';
import { getMaskObj, removeMask, setMask } from '../display/utils';
import { contains, moveTo, replaceWith } from '../display/utils/objTree';
import { EDIT_MODE, INTERACTION_EVENT, TRANSFORMER_EVENT } from '../types';
import { divisionMatrix, setGroupTag } from '../utils';
import { AbstractBase } from './AbstractBase';
/**
 * 剪切蒙版编辑模式配置
 */
export const MASK_EDITING_CONFIG = {
    /**
     * 是否启用剪切蒙版编辑模式
     */
    enabled: true,
    /**
     * 是否启用双击进入或结束剪切蒙版编辑模式
     */
    enabledDoubleClickInteraction: false,
    bitmapBgLineStyle: {
        width: 1,
        color: 0x008177,
        native: true,
    },
    bitmapBgFillStyle: {
        color: 0xffffff,
        alpha: 1,
    },
    bitmapAlpha: 0.3,
    displayLayerAlpha: 0.05,
};
export class AbstractMask extends AbstractBase {
    selectedBuildAndStartEditMaskAction() {
        const [bitmap, mask] = this.selectedPickBitmapMaskEditTarget();
        this.startMaskEditMode(bitmap, mask, 'create', { willRecord: true });
    }
    selectedStartEditMaskAction() {
        if (this.canModifyBitmapMask()) {
            const bitmap = this.selected[0];
            this.startMaskEditMode(bitmap, getMaskObj(bitmap), 'update', {
                willRecord: true,
            });
        }
    }
    selectedReleaseMaskAction() {
        if (this.canModifyBitmapMask()) {
            const bitmap = this.selected[0];
            this.releaseMask(bitmap, true);
            this.selected = [];
        }
    }
    endEditMaskAction() {
        this.endMaskEditMode(true, true);
    }
    initEventForMask() {
        if (!MASK_EDITING_CONFIG.enabled) {
            return;
        }
        if (MASK_EDITING_CONFIG.enabledDoubleClickInteraction) {
            this.viewport?.on(INTERACTION_EVENT.DOUBLE_CLICK, () => {
                if (this.bitmapMaskEditing) {
                    this.endEditMaskAction();
                }
            });
        }
        this.transformer?.on(TRANSFORMER_EVENT.TRANSFORM_COMMIT, () => {
            if (this.bitmapMaskEditing) {
                this.recordOperationLog(...this.selected);
            }
        });
    }
    onSelectedDoubleClick(display) {
        if (MASK_EDITING_CONFIG.enabled &&
            MASK_EDITING_CONFIG.enabledDoubleClickInteraction) {
            this.startMaskEditMode(display, getMaskObj(display), 'update', { willRecord: true });
        }
    }
    selectedPickBitmapMaskEditTarget() {
        const objs = this.selected;
        const logFail = () => console.error('not valid input objs');
        if (!(objs.length === 2)) {
            logFail();
            return [];
        }
        let mask;
        let bitmap;
        for (const obj of objs) {
            if (obj instanceof MSprite && !obj.mask) {
                bitmap = obj;
            }
            else if (!(obj instanceof MSprite) && !obj.isMask) {
                mask = obj;
            }
        }
        if (!mask || !bitmap) {
            logFail();
            return [];
        }
        return [bitmap, mask];
    }
    buildMaskThenStartEdit(bitmap, mask, willRecord = false, helperRecord) {
        this.buildMask(mask, bitmap);
        this.startMaskEditMode(bitmap, mask, 'create', {
            willRecord,
            helperRecord,
        });
    }
    endEditThenReleaseMask(bitmap, willRecord = false) {
        this.endMaskEditMode(willRecord);
        this.releaseMask(bitmap, false);
    }
    buildMask(mask, bitmap) {
        if (bitmap.mask) {
            removeMask(bitmap);
            // 嵌套的mask如何处理？
        }
        // mask移动到displayLayer
        moveTo(mask, this.viewport.displayLayer);
        mask.parentGroup = null;
        mask.interactive = false;
        mask.visible = true;
        // mask嵌套到目标对象
        const matrix = divisionMatrix(mask.worldTransform.clone(), bitmap.worldTransform.clone());
        mask.transform.setFromMatrix(mask.transform.localTransform.copyFrom(matrix));
        // 某些对象在transform变化后需要执行特定处理，如文字更新fontSize等
        mask.transformCommit?.();
        // 设置mask
        setMask(bitmap, mask);
        bitmap.addChild(mask);
        bitmap.updateMaskedCache();
        return bitmap;
    }
    releaseMask(bitmap, willRecord = false) {
        if (!bitmap.mask) {
            return [];
        }
        const mask = getMaskObj(bitmap);
        if (!mask) {
            return [];
        }
        const matrix = mask.localTransform.clone().prepend(bitmap.localTransform);
        mask.transform.setFromMatrix(mask.transform.localTransform.copyFrom(matrix));
        // 某些对象在transform变化后需要执行特定处理，如文字更新fontSize等
        mask.transformCommit?.();
        removeMask(bitmap);
        bitmap.updateMaskedCache();
        this.beforeAddDisplayToViewport(mask);
        this.addDisplayToViewport(mask, false);
        if (willRecord) {
            this.recordReleaseMask(bitmap, mask);
        }
        return [bitmap, mask];
    }
    bitmapMaskEditing;
    startMaskEditMode(bitmap, mask, type, options) {
        if (!bitmap || !mask || !MASK_EDITING_CONFIG.enabled) {
            return;
        }
        const { willRecord = false, helperRecord } = options ?? {};
        this.updateEditMode(EDIT_MODE.BITMAP_MASK);
        this.prepareMaskEdit(bitmap, mask, type, helperRecord);
        if (willRecord) {
            if (type === 'create') {
                this.recordBuildAndStartEditMask(bitmap, mask, this.bitmapMaskEditing.helperRecord, this.bitmapMaskEditing.helpers);
            }
            else {
                this.recordStartEditMask(bitmap, mask, this.bitmapMaskEditing.helperRecord, this.bitmapMaskEditing.helpers);
            }
        }
    }
    endMaskEditMode(willRecord = false, autoSelected = false) {
        let editTarget;
        if (this.bitmapMaskEditing) {
            const { bitmap, mask, clearHelpers, cachedTargetJSON, cachedMaskJSON } = this.bitmapMaskEditing;
            editTarget = bitmap;
            // 清除helpers
            clearHelpers?.();
            // 还原对象属性
            bitmap.groupTag = cachedTargetJSON.groupTag;
            bitmap.zOrder = cachedTargetJSON.zOrder;
            bitmap.visible = cachedTargetJSON.visible;
            mask.groupTag = cachedMaskJSON.groupTag;
            mask.zOrder = cachedMaskJSON.zOrder;
            mask.visible = cachedMaskJSON.visible;
            // 建立嵌套形式的mask
            this.buildMask(mask, bitmap);
            // 移动到viewport.displayLayer
            if (!contains(this.viewport.displayLayer, bitmap)) {
                this.beforeAddDisplayToViewport(bitmap);
                this.addDisplayToViewport(bitmap, false);
            }
            // 根据mask是否与位图重叠
            if (bitmap.isMaskOverlap) {
                if (willRecord) {
                    this.bitmapMaskEditing.helperRecord,
                        this.recordEndEditMask(bitmap, this.bitmapMaskEditing.helperRecord);
                }
            }
            else {
                this.releaseMask(bitmap);
                if (willRecord) {
                    this.recordEndEditAndReleaseMask(bitmap, mask, this.bitmapMaskEditing.helperRecord);
                }
            }
            this.bitmapMaskEditing = null;
        }
        this.emitTransformerCommit();
        // 使displayLayer内对象可触发交互事件
        this.viewport.displayLayer.interactiveChildren = true;
        // 恢复displayLayer透明度
        this.viewport.displayLayer.alpha = 1;
        // 开启派发transformer事件
        this.transformer.enableEmit = true;
        if (editTarget && autoSelected) {
            this.transformer.selected = [editTarget];
        }
        else {
            this.transformer.selected = [];
        }
        // 恢复显示textCurve控制点
        this.textCurve.visible = true;
        this.updateEditMode(EDIT_MODE.SELECT);
    }
    prepareMaskEdit(bitmap, mask, type, helperRecord) {
        // 结束残留的编辑模式
        if (this.bitmapMaskEditing) {
            try {
                this.endMaskEditMode();
            }
            catch (e) {
                console.error(e);
            }
        }
        // 使displayLayer内对象不触发交互事件
        this.viewport.displayLayer.interactiveChildren = false;
        // 使displayLayer内对象变得透明
        this.viewport.displayLayer.alpha = MASK_EDITING_CONFIG.displayLayerAlpha;
        // 暂停派发transformer事件
        this.transformer.enableEmit = false;
        this.transformer.selected = [];
        // 隐藏textCurve控制点
        this.textCurve.visible = false;
        if (!contains(bitmap, mask)) {
            // 创建时, 移动mask到viewport.displayLayer
            moveTo(mask, this.viewport.displayLayer);
        }
        else {
            // 修改时，嵌套的mask转为平铺
            this.tempLayer.addChild(bitmap);
            replaceWith(bitmap, [bitmap, mask]);
            const matrix = mask.localTransform.clone().prepend(bitmap.localTransform);
            mask.transform.setFromMatrix(mask.localTransform.copyFrom(matrix));
        }
        // 备份对象属性
        const cachedTargetJSON = {
            groupTag: bitmap.groupTag,
            zOrder: bitmap.zOrder,
            visible: bitmap.visible,
        };
        const cachedMaskJSON = {
            groupTag: mask.groupTag,
            zOrder: mask.zOrder,
            visible: mask.visible,
        };
        // 临时修改对象属性
        bitmap.groupTag = '';
        bitmap.zOrder = 0;
        bitmap.visible = true;
        mask.groupTag = '';
        mask.zOrder = 1;
        mask.visible = true;
        bitmap.parentGroup = null;
        mask.parentGroup = null;
        mask.interactive = true;
        // 创建helpers
        const { helpers, clear, helperRecord: usedHelperRecord, } = this.createMaskEditHelpers(bitmap, mask, helperRecord);
        this.bitmapMaskEditing = {
            type,
            bitmap,
            mask,
            cachedTargetJSON,
            cachedMaskJSON,
            helpers,
            helperRecord: usedHelperRecord,
            clearHelpers() {
                clear();
            },
            selectionTargets: [bitmap, mask, ...helpers],
        };
        // this.transformer.selected = [mask];
    }
    createMaskEditHelpers(bitmap, mask, helperRecord) {
        const bitmapHelpers = this.createBitmapHelpers(bitmap);
        const maskHelpers = this.createMaskHelpers(mask);
        const [bitmapShadow, bitmapBg] = bitmapHelpers;
        const [maskOutline] = maskHelpers;
        if (bitmap.mask) {
            removeMask(bitmap);
        }
        bitmap.alpha = MASK_EDITING_CONFIG.bitmapAlpha;
        bitmap.interactive = true;
        mask.interactive = true;
        setMask(bitmapShadow, mask);
        // 添加到tempLayer
        [bitmapBg, bitmap, bitmapShadow, mask, maskOutline].forEach((o, i) => {
            this.tempLayer.addChildAt(o, i);
        });
        if (!helperRecord) {
            // 使用组合的方式实现同步transform
            setGroupTag([
                bitmap,
                bitmapShadow,
                bitmapBg,
            ]);
            setGroupTag([mask, maskOutline]);
            helperRecord = {
                helperIDs: {
                    bitmapShadow: bitmapShadow.id,
                    bitmapBg: bitmapBg.id,
                    maskOutline: maskOutline.id,
                },
                groupTags: {
                    bitmapShadow: bitmapShadow.groupTag,
                    bitmapBg: bitmapBg.groupTag,
                    maskOutline: maskOutline.groupTag,
                },
            };
        }
        else {
            const { helperIDs, groupTags } = helperRecord;
            bitmapShadow.id = helperIDs.bitmapShadow;
            bitmapBg.id = helperIDs.bitmapBg;
            maskOutline.id = helperIDs.maskOutline;
            bitmapShadow.groupTag = groupTags.bitmapShadow;
            bitmapBg.groupTag = groupTags.bitmapBg;
            maskOutline.groupTag = groupTags.maskOutline;
            // 临时解决退出蒙版编辑时因为不记录mask, redo回到蒙版编辑时，
            // 无法还原mask为编辑过程中的groupTag的问题
            Promise.resolve().then(() => {
                mask.groupTag = groupTags.maskOutline;
            });
        }
        const helpers = [...bitmapHelpers, ...maskHelpers];
        const clear = () => {
            bitmap.alpha = 1;
            bitmap.groupTag = '';
            mask.groupTag = '';
            removeMask(bitmapShadow);
            // 注意bitmapShadow因为直接复用原始MSprite的texture，删除时不要执行destroy()
            this.tempLayer.removeChild(bitmapShadow);
            bitmapShadow.destroy({
                children: true,
            });
            this.tempLayer.removeChild(bitmapBg);
            bitmapBg.destroy(true);
            this.tempLayer.removeChild(maskOutline);
            maskOutline.destroy(true);
        };
        return { helpers, clear, helperRecord };
    }
    createBitmapHelpers(bitmap, exactly = false) {
        const bitmapBounds = bitmap.getLocalBounds();
        const bitmapBg = new Graphics();
        const bitmapBgFillStyle = merge({
            color: 0xffffff,
            alpha: 1,
        }, MASK_EDITING_CONFIG.bitmapBgFillStyle);
        if (MASK_EDITING_CONFIG.bitmapBgLineStyle) {
            bitmapBg.lineStyle(MASK_EDITING_CONFIG.bitmapBgLineStyle);
        }
        bitmapBg
            .beginFill(bitmapBgFillStyle.color, bitmapBgFillStyle.alpha)
            .drawRect(bitmapBounds.x, bitmapBounds.y, bitmapBounds.width, bitmapBounds.height)
            .endFill();
        bitmapBg.transform.setFromMatrix(bitmap.localTransform);
        bitmapBg.transform.updateLocalTransform();
        bitmapBg.name = 'bitmapBg';
        bitmapBg.interactive = true;
        // 创建bitmapShadow
        let bitmapShadow;
        if (exactly) {
            // 真实克隆
            const bitmapJSON = bitmap.toJSON(this.viewport, { texture: true });
            delete bitmapJSON.mask;
            delete bitmapJSON.id;
            bitmapShadow = createDisplay(bitmapJSON.type, bitmapJSON);
        }
        else {
            // 模拟克隆
            bitmapShadow = new Sprite(bitmap.texture);
            bitmapShadow.filters = bitmap.filters;
            bitmapShadow.transform.setFromMatrix(bitmap.localTransform);
            bitmapShadow.transform.updateLocalTransform();
        }
        bitmapShadow.name = 'bitmapShadow';
        bitmapShadow.interactive = true;
        return [bitmapShadow, bitmapBg];
    }
    createMaskHelpers(mask) {
        // 创建maskOutline
        const maskJSON = mask.toJSON(this.viewport);
        maskJSON.isFill = false;
        delete maskJSON.id;
        const maskOutline = createDisplay(maskJSON.type, maskJSON);
        maskOutline.transform.setFromMatrix(mask.localTransform);
        maskOutline.transform.updateLocalTransform();
        maskOutline.name = 'maskOutline';
        maskOutline.interactive = true;
        return [maskOutline];
    }
    canBuildBitmapMask() {
        const objs = this.selected;
        if (!(objs.length === 2)) {
            return false;
        }
        let mask;
        let bitmap;
        for (const obj of objs) {
            if (obj instanceof MSprite) {
                if (!obj.mask) {
                    bitmap = obj;
                }
            }
            else {
                if (!obj.isMask) {
                    mask = obj;
                }
            }
        }
        return Boolean(mask && bitmap);
    }
    canModifyBitmapMask() {
        const objs = this.selected;
        if (!(objs.length === 1)) {
            return false;
        }
        const obj = objs[0];
        return obj instanceof MSprite && Boolean(obj.mask);
    }
    getBitmapMaskState() {
        return this.bitmapMaskEditing
            ? this.bitmapMaskEditing.type
            : this.canBuildBitmapMask()
                ? 'can-build'
                : this.canModifyBitmapMask()
                    ? 'can-modify'
                    : '';
    }
    recordBuildAndStartEditMask(bitmap, mask, helperRecord, helpers) {
        console.log('[ Record ] BuildAndStartEditMask');
        const bitmapId = bitmap.id;
        const maskId = mask.id;
        this.recordOperationLogWithProps([bitmap, mask, ...helpers], {
            cmd: 'BuildAndStartEditMask',
            helperRecord,
            onUndo: () => {
                if (this.bitmapMaskEditing) {
                    console.log('[ MaskEdit ] exit edit mask by undo');
                    const objs = this.queryInteractiveDisplayObjects([bitmapId]);
                    const bitmap = [...objs.getKey(bitmapId)][0];
                    if (bitmap instanceof MSprite) {
                        this.endEditThenReleaseMask(bitmap, false);
                    }
                }
            },
            onRedo: () => {
                console.log('[ MaskEdit ] start edit mask by redo');
                const objs = this.queryInteractiveDisplayObjects([bitmapId, maskId]);
                const bitmap = [...objs.getKey(bitmapId)][0];
                const mask = [...objs.getKey(maskId)][0];
                if (bitmap instanceof MSprite && mask instanceof Container) {
                    this.buildMaskThenStartEdit(bitmap, mask, false, helperRecord);
                }
            },
        });
    }
    recordEndEditAndReleaseMask(bitmap, mask, helperRecord) {
        console.log('[ Record ] EndEditAndReleaseMask');
        const bitmapId = bitmap.id;
        const maskId = mask.id;
        this.recordOperationLogWithProps([bitmap, mask], {
            cmd: 'EndEditAndReleaseMask',
            helperRecord,
            onUndo: () => {
                const objs = this.queryInteractiveDisplayObjects([bitmapId, maskId]);
                const bitmap = [...objs.getKey(bitmapId)][0];
                const mask = [...objs.getKey(maskId)][0];
                if (bitmap instanceof MSprite && mask instanceof Container) {
                    this.buildMaskThenStartEdit(bitmap, mask, false, helperRecord);
                }
            },
            onRedo: () => {
                if (this.bitmapMaskEditing) {
                    const objs = this.queryInteractiveDisplayObjects([bitmapId]);
                    const bitmap = [...objs.getKey(bitmapId)][0];
                    if (bitmap instanceof MSprite) {
                        this.endEditThenReleaseMask(bitmap, false);
                    }
                }
            },
        });
    }
    recordStartEditMask(bitmap, mask, helperRecord, helpers) {
        console.log('[ Record ] StartEditMask');
        const bitmapId = bitmap.id;
        this.recordOperationLogWithProps([bitmap, mask, ...helpers], {
            cmd: 'StartEditMask',
            helperRecord,
            onUndo: () => {
                this.endMaskEditMode(false);
            },
            onRedo: () => {
                const objs = this.queryInteractiveDisplayObjects([bitmapId]);
                const bitmap = [...objs.getKey(bitmapId)][0];
                if (bitmap instanceof MSprite) {
                    this.startMaskEditMode(bitmap, getMaskObj(bitmap), 'update', {
                        willRecord: false,
                        helperRecord,
                    });
                }
            },
        });
    }
    recordEndEditMask(bitmap, helperRecord) {
        console.log('[ Record ] EndEditMask');
        const bitmapId = bitmap.id;
        this.recordOperationLogWithProps([bitmap], {
            cmd: 'EndEditMask',
            helperRecord,
            onUndo: () => {
                const objs = this.queryInteractiveDisplayObjects([bitmapId]);
                const bitmap = [...objs.getKey(bitmapId)][0];
                if (bitmap instanceof MSprite) {
                    this.startMaskEditMode(bitmap, getMaskObj(bitmap), 'update', {
                        willRecord: false,
                        helperRecord,
                    });
                }
            },
            onRedo: () => {
                this.endMaskEditMode(false);
            },
        });
    }
    recordReleaseMask(bitmap, mask) {
        console.log('[ Record ] ReleaseMask');
        const bitmapId = bitmap.id;
        const maskId = mask.id;
        this.recordOperationLogWithProps([bitmap, mask], {
            cmd: 'ReleaseMask',
            onUndo: () => {
                const objs = this.queryInteractiveDisplayObjects([bitmapId, maskId]);
                const bitmap = [...objs.getKey(bitmapId)][0];
                const mask = [...objs.getKey(maskId)][0];
                if (bitmap instanceof MSprite && mask instanceof Container) {
                    this.buildMask(mask, bitmap);
                }
            },
            onRedo: () => {
                const objs = this.queryInteractiveDisplayObjects([bitmapId, maskId]);
                const bitmap = [...objs.getKey(bitmapId)][0];
                if (bitmap instanceof MSprite) {
                    this.releaseMask(bitmap, false);
                }
            },
        });
    }
}
