import { INTERACTION_EVENT } from '../../../types';
import { Recognizer } from './Recognizer';
export class DoubleTap extends Recognizer {
    preTimeStamp = 0;
    constructor() {
        super();
        this.options = {
            event: INTERACTION_EVENT.DOUBLE_CLICK,
            pointers: 2,
            lt: 300,
            gte: 50, // 两次点击时间间隔不小于
        };
    }
    process(input) {
        const { options } = this;
        const validTime = input.timeStamp - this.preTimeStamp < options.lt &&
            input.timeStamp - this.preTimeStamp > options.gte;
        const validType = input.eventType & 1 /* INPUT.START */;
        if (validType) {
            this.preTimeStamp = input.timeStamp;
            if (validTime) {
                return 8 /* STATE.RECOGNIZED */;
            }
        }
        return 32 /* STATE.FAILED */;
    }
    reset() { }
}
