export function stateStr(state) {
    if (state & 16 /* STATE.CANCELLED */) {
        return 'cancel';
    }
    else if (state & 8 /* STATE.ENDED */) {
        return 'end';
    }
    else if (state & 4 /* STATE.CHANGED */) {
        return 'move';
    }
    else if (state & 2 /* STATE.BEGAN */) {
        return 'start';
    }
    return '';
}
